import { ReactComponent as ListIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/menu/navigation-menu.svg';
import { ReactComponent as HouseIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/real-estate/real-estate-building-house.svg';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router';

import type { PropertyObjectSerializerDTO } from '../../../../../connectors/property';
import { IconWrapper } from '../../../../shared/components/IconWrapper';
import { NavigateButton } from '../../../../shared/components/ShowLocationMap/components/NavigateButton';
import { mergeSx } from '../../../../shared/helpers/sx.helper';
import { paths } from '../../../../shared/routes/paths';
import { setNewFilter } from '../../../../shared/services/ticket.service';
import { useAppSelector } from '../../../../shared/store/hooks';
import { selectLoading } from '../../../../shared/store/loadingSlice';
import { selectMyPropertyObjects } from '../../../../shared/store/propertySlice';
import { globalSxProps } from '../../../../shared/style';
import { useT } from '../../../../shared/translation/translation.provider';
import { sxProps } from './my-property-objects.style';

export const MyPropertyObjects: FC = () => {
  const t = useT();
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => selectLoading(state));
  const myPropertyObjects = useAppSelector((state) =>
    selectMyPropertyObjects(state),
  );

  const onShowRequest = useCallback(
    (propertyObject: PropertyObjectSerializerDTO) => {
      setNewFilter({ propertyObject });
      navigate(paths.root);
    },
    [navigate],
  );

  const propertyObjectGrid = useMemo(() => {
    return myPropertyObjects.map((po) => {
      const { latitude, longitude, name, uuid, addressLine, streetNumber } = po;

      return (
        <Grid
          item
          container
          xs={6}
          key={uuid}
          sx={sxProps.propertyItemGrid}
          data-test={`property-object-${uuid}`}
        >
          <Box>
            <IconWrapper icon={HouseIcon} sx={sxProps.propertyIcon} />
            <Typography
              sx={mergeSx(
                globalSxProps.singleLineOverflow,
                globalSxProps.textBold,
              )}
            >
              {name}
            </Typography>
            <Typography>
              {addressLine} {streetNumber}
            </Typography>
          </Box>

          <Button
            data-test={`property-object-${uuid}-filter-button`}
            disabled={isLoading}
            sx={sxProps.propertyButton}
            fullWidth
            variant="contained"
            onClick={() => onShowRequest(po)}
          >
            <IconWrapper icon={ListIcon} sx={sxProps.listIcon} />
            {t('+profile.properties.requests')}
          </Button>
          <NavigateButton
            data-test={`property-object-${uuid}-navigate-button`}
            buttonSx={sxProps.propertyButton}
            labelKey="+profile.properties.navigate"
            coordinates={{ latitude, longitude, name, uuid }}
          />
        </Grid>
      );
    });
  }, [isLoading, myPropertyObjects, onShowRequest, t]);

  if (myPropertyObjects.length === 0) return null;

  return (
    <Box sx={sxProps.propertyBox}>
      <Typography sx={sxProps.propertyLabel}>
        {t('+profile.properties.label')}
      </Typography>
      <Divider sx={sxProps.divider} variant="fullWidth" />
      <Grid container>{propertyObjectGrid}</Grid>
    </Box>
  );
};
