import { ReactComponent as ApartmentNumberIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as DueDateIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-check-1.svg';
import { ReactComponent as AddressIcon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/pins/pin-location-1.svg';
import { ReactComponent as MessagesCountIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-square-smile.svg';
import { Paper, Grid, Box, Typography, Divider, Stack } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../../../../../connectors/ticket/dto/universal-tickets-with-additional-fields-serializer-dto';
import { IconWrapper } from '../../../../../../shared/components/IconWrapper';
import {
  formatDate,
  isOverdue,
} from '../../../../../../shared/helpers/date.helper';
import { mergeSx } from '../../../../../../shared/helpers/sx.helper';
import { paths } from '../../../../../../shared/routes/paths';
import { useT } from '../../../../../../shared/translation/translation.provider';
import { ListItemStatusIndicator } from '../ListItemStatusIndicator';
import { sxProps } from './ticket-list-item.style';
import { createIssueString } from './util';

export type TicketListItemProps = {
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO;
};

export const TicketListItem: FC<TicketListItemProps> = ({
  ticket,
}: TicketListItemProps) => {
  const t = useT();
  const navigate = useNavigate();

  const overdueSxProps = useMemo<SxProps<Theme>>(() => {
    return isOverdue(ticket.dueDate) ? sxProps.ticketListItemOverdue : {};
  }, [ticket.dueDate]);

  const issue = useMemo<string>(() => {
    return createIssueString(ticket, t);
  }, [ticket, t]);

  const address = useMemo(() => {
    const apartmentNumberIconSxProps = mergeSx(
      sxProps.ticketListItemIcon,
      sxProps.ticketListItemDoorIcon,
    );

    return (
      <Typography variant="body2">
        <IconWrapper sx={sxProps.ticketListItemIcon} icon={AddressIcon} />
        {ticket.addressLine}
        {ticket.apartmentNumber ? (
          <>
            <IconWrapper
              sx={apartmentNumberIconSxProps}
              icon={ApartmentNumberIcon}
            />

            {ticket.apartmentNumber}
          </>
        ) : null}
      </Typography>
    );
  }, [ticket]);

  const dueDate = useMemo(() => {
    if (!ticket.dueDate) return null;

    return (
      <Typography variant="body2" component="span" sx={overdueSxProps}>
        <IconWrapper sx={sxProps.ticketListItemIcon} icon={DueDateIcon} />
        {formatDate(ticket.dueDate, 'd mmm')}
      </Typography>
    );
  }, [overdueSxProps, ticket.dueDate]);

  const onClick = useCallback((): void => {
    navigate(`${paths.ticket}/${ticket.referenceNumber}/`);
  }, [ticket.referenceNumber, navigate]);

  return (
    <Paper
      sx={sxProps.ticketListItem}
      onClick={onClick}
      data-test={`ticket-list-item-${ticket.uuid}`}
    >
      <Box sx={sxProps.ticketListItemContentWrapper}>
        <Grid container direction="column" justifyItems="center">
          <Grid item>
            <Typography sx={sxProps.ticketListItemTopText} variant="overline">
              {issue}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={sxProps.ticketListItemCenterText} variant="h3">
              {ticket.summary}
            </Typography>
          </Grid>
          <Divider variant="fullWidth" />
          <Stack sx={sxProps.ticketListItemBottomText}>
            <Grid item sx={sxProps.ticketListItemAddressBox}>
              {address}
            </Grid>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              width="auto"
              item
            >
              <Grid sx={sxProps.ticketListItemDetail} item>
                <Typography variant="body2" component="span">
                  <IconWrapper
                    sx={sxProps.ticketListItemIcon}
                    icon={MessagesCountIcon}
                  />
                  {ticket.messageCount}
                </Typography>
              </Grid>

              <Grid sx={sxProps.ticketListItemDetail} item>
                {dueDate}
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Box>
      <ListItemStatusIndicator status={ticket.status} />
    </Paper>
  );
};
