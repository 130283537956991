import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { App } from './app/shared/app.component';
import { getEnvValue } from './app/shared/helpers/env.helper';
import { store } from './app/shared/store';
import { initTransaltion } from './app/shared/translation';
import { TranslationProvider } from './app/shared/translation/translation.provider';
import './index.css';
import reportWebVitals from './reportWebVitals';

const sentryDsn = getEnvValue('SENTRY_DSN');
const gitBranchPrefixRegExp = /^(feature?|release?|bugfix)-/;
const publicUrl = process.env.PUBLIC_URL || '';
// Due to limitation of the CloudFront we need to add "index.html" to display an app
const basename = gitBranchPrefixRegExp.test(publicUrl)
  ? `${publicUrl}/index.html`
  : publicUrl;

if (sentryDsn) {
  const sentryProjectTag = getEnvValue('SENTRY_PROJECT_TAG');
  const tracesSampleRate = getEnvValue('SENTRY_TRACES_SAMPLE_RATE');

  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: tracesSampleRate ? Number(tracesSampleRate) : undefined,
  });
  Sentry.setTag('project', sentryProjectTag);
}

initTransaltion();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={basename}>
        <TranslationProvider>
          <App />
        </TranslationProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
