import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  attachmentUploadBox: {
    alignItems: 'center',
    background: (theme) => theme.palette.grey[200],
    borderColor: (theme) => theme.palette.grey[300],
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    height: (theme) => theme.spacing(11),
    justifyContent: 'center',
    mt: 3,
    width: '100%',
  },

  attachmentUploadIcon: {
    color: (theme) => theme.palette.secondary.light,
    height: (theme) => theme.spacing(4.5),
    width: (theme) => theme.spacing(5.5),
  },

  attachmentUploadLabel: {
    color: (theme) => theme.palette.grey[800],
  },
};
