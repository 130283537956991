import React, { useMemo } from 'react';
import type { FC } from 'react';

import type {
  RoomTypeEnumDTO,
  LocationTypeEnumDTO,
  TicketMetadataSerializerDTO,
} from '../../../../../../../connectors/ticket';
import { ModalSelect } from '../../../../../../shared/components/ModalSelect';
import { getAvailableIssueRooms, getIssueRoomOptions } from './util';

export type IssueRoomPickerProps = {
  metadata?: TicketMetadataSerializerDTO;
  issueLocation?: LocationTypeEnumDTO;
  issueRoom?: RoomTypeEnumDTO;
  onChange: (issueRoom: RoomTypeEnumDTO) => void;
  error?: string;
};

export const IssueRoomPicker: FC<IssueRoomPickerProps> = ({
  metadata,
  onChange,
  error,
  issueLocation,
  issueRoom,
}: IssueRoomPickerProps) => {
  const isHidden = useMemo(() => {
    return Boolean(!issueLocation);
  }, [issueLocation]);

  const issueRoomOptions = useMemo(() => {
    const avaiableRooms = getAvailableIssueRooms(metadata, issueLocation);

    return getIssueRoomOptions(avaiableRooms);
  }, [issueLocation, metadata]);

  if (isHidden) return null;

  return (
    <ModalSelect
      fieldname="issue_room_picker"
      value={issueRoom || ''}
      onSelect={(item) => onChange(item.value as RoomTypeEnumDTO)}
      options={issueRoomOptions}
      renderAsSelect={{
        error,
        labelKey: '+createTicket.issueRoomLabel',
      }}
    />
  );
};
