import { Box, Avatar, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';

import type { EmployeeSerializerDTO } from '../../../../../../connectors/user/dto/employee-serializer-dto';
import {
  getUserMentionString,
  getUserFullname,
} from '../../../../helpers/user.helper';
import { sxProps } from './mentions-popper-item.style';

export type MentionsPopperItemProps = {
  user: EmployeeSerializerDTO;
  selectUser: (user: EmployeeSerializerDTO) => void;
};

export const MentionsPopperItem: FC<MentionsPopperItemProps> = ({
  user,
  selectUser,
}: MentionsPopperItemProps) => {
  const onClick = useCallback((): void => {
    selectUser(user);
  }, [selectUser, user]);

  return (
    <Box sx={sxProps.mention} onClick={onClick}>
      <Avatar
        sx={sxProps.mentionAvatar}
        alt={getUserMentionString(user)}
        src={user.profilePicture}
        sizes="48px"
      />
      <Typography sx={sxProps.mentionLabel}>{getUserFullname(user)}</Typography>
    </Box>
  );
};
