import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  assigneeLabel: {
    color: (theme) => theme.palette.grey[500],
    display: 'block',
    mb: (theme) => theme.spacing(1.5),
  },
  assigneePickerWrapper: {
    mt: 2,
  },
  boxWrapper: {
    padding: (theme) => theme.spacing(1.8, 1, 0, 1),
  },
};
