import type { FC } from 'react';
import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { selectLoggedUser } from '../store/authSlice';
import { useAppSelector } from '../store/hooks';
import { paths } from './paths';

export const PublicRoute: FC<RouteProps> = ({ children }: RouteProps) => {
  const loggedUser = useAppSelector((state) => selectLoggedUser(state));

  if (!loggedUser) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return <Navigate to={paths.root} />;
};
