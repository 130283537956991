import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  buttonIcon: {
    height: (theme) => theme.spacing(2),
    marginRight: (theme) => theme.spacing(1),
    width: (theme) => theme.spacing(2),
  },

  filterBarWrapper: {
    alignItems: 'center',
    background: (theme) => theme.palette.text.primary,
    height: (theme) => theme.custom.filterBarHeight,
    justifyContent: 'center',
    padding: 1,
  },

  filterButton: {
    '&:hover': {
      backgroundColor: (theme) => `${theme.palette.grey[700]} !important`,
    },
    backgroundColor: (theme) => theme.palette.grey[700],
    borderRadius: (theme) => `${theme.spacing(2)} !important`,
    height: (theme) => theme.spacing(4.75),
  },
};
