import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  flagBorder: {
    border: (theme) => ` 1px solid ${theme.palette.grey[500]}`,
    borderRadius: '50%',
  },

  flagIcon: {
    height: (theme) => theme.spacing(4),
    width: (theme) => theme.spacing(4),
  },
};
