import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const attachmentSxProps: Record<string, SxProps<Theme>> = {
  attachmentBorder: {
    borderColor: (theme) => theme.palette.grey[300],
    borderStyle: 'solid',
    borderWidth: '3px',
  },

  attachmentItemImageWrapper: {
    '& img': {
      borderRadius: '16px',
      height: '100%',
      objectFit: 'cover',
      width: '100%',
    },
    height: (theme) => theme.spacing(41),
    width: '100%',
  },

  attachmentItemWrapper: {
    mt: 3,
  },
};
