import { Box, Container, Grid } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { useAppSelector } from '../../../../shared/store/hooks';
import { selectIsBackgroundDimmed } from '../../../../shared/store/responseTemplate';
import { sxProps as parentSxProps } from '../../ticket-view-screen.style';
import { TicketHeader } from '../TicketHeader';
import { CommentInput } from './components/CommentInput';
import { CommentList } from './components/CommentList';
import { CommentsHeader } from './components/CommentsHeader';
import { sxProps } from './ticket-comments.style';

export const TicketMessages: FC = () => {
  const isBackgroundDimmed = useAppSelector((state) =>
    selectIsBackgroundDimmed(state),
  );

  const dimmer = useMemo(() => {
    if (!isBackgroundDimmed) return null;

    const sx = isBackgroundDimmed ? sxProps.dimmer : {};

    return <Box sx={sx} />;
  }, [isBackgroundDimmed]);

  return (
    <Container sx={sxProps.commentsBox}>
      {dimmer}
      <Grid container direction="column" sx={sxProps.commentsContainer}>
        <Grid item flex={1}>
          <Box sx={parentSxProps.ticketHeaderBox}>
            <CommentsHeader />
            <TicketHeader ellipsis />
          </Box>
        </Grid>
        <Grid item flex={10} sx={sxProps.commentList}>
          <CommentList />
        </Grid>
        <Grid item flex={1}>
          <CommentInput />
        </Grid>
      </Grid>
    </Container>
  );
};
