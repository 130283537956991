import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  profileDetailsBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: (theme) => theme.spacing(3),
  },
  profileDetailsEmail: {
    color: (theme) => theme.palette.primary.main,
    marginTop: (theme) => theme.spacing(1),
  },
  profileDetailsPhoneNumber: {
    color: (theme) => theme.palette.secondary.main,
    marginTop: (theme) => theme.spacing(1),
  },
};
