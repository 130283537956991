import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  menuItemActive: {
    background: (theme) => theme.palette.primary.contrastText,
    color: (theme) => theme.palette.primary.main,
  },

  menuItemBox: {
    alignItems: 'center',
    borderRadius: '10px',
    display: 'flex',
    height: (theme) => theme.spacing(5),
    justifyContent: 'center',
    p: 1,
    width: (theme) => theme.spacing(5),
  },
};
