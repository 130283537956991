import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as idGenerator } from 'uuid';

import type { Snackbar } from '../types/Snackbar';
import { SnackbarSeverity } from '../types/Snackbar';
import type { RootState } from './index';

export interface SnackbarState {
  snackbars: Snackbar[];
}

const generateNewSnackbar = (
  message: string,
  severity: SnackbarSeverity,
  data?: Record<string, string>,
): Snackbar => {
  const snackbar: Snackbar = {
    data,
    id: idGenerator(),
    message,
    severity,
  };

  return snackbar;
};

const initialState: SnackbarState = { snackbars: [] };

/* eslint-disable no-param-reassign */
const snackbarSlice = createSlice({
  initialState,
  name: 'snackbar',
  reducers: {
    addErrorSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbars = [
        ...state.snackbars,
        generateNewSnackbar(action.payload, SnackbarSeverity.ERROR),
      ];
    },
    addInfoSnackbar: (
      state,
      {
        payload: { message, data },
      }: PayloadAction<{ message: string; data?: Record<string, string> }>,
    ) => {
      state.snackbars = [
        ...state.snackbars,
        generateNewSnackbar(message, SnackbarSeverity.INFO, data),
      ];
    },
    addSuccessSnackbar: (
      state,
      {
        payload: { message, data },
      }: PayloadAction<{ message: string; data?: Record<string, string> }>,
    ) => {
      state.snackbars = [
        ...state.snackbars,
        generateNewSnackbar(message, SnackbarSeverity.SUCCESS, data),
      ];
    },
    addWarningSnackbar: (
      state,
      {
        payload: { message, data },
      }: PayloadAction<{ message: string; data?: Record<string, string> }>,
    ) => {
      state.snackbars = [
        ...state.snackbars,
        generateNewSnackbar(message, SnackbarSeverity.WARNING, data),
      ];
    },
    removeSnackbar: (state, action: PayloadAction<string>) => {
      state.snackbars = state.snackbars.filter((s) => s.id !== action.payload);
    },
  },
});

export const {
  addErrorSnackbar,
  addInfoSnackbar,
  addSuccessSnackbar,
  addWarningSnackbar,
  removeSnackbar,
} = snackbarSlice.actions;
export const selectSnackbars = (state: RootState): Snackbar[] =>
  state.snackbar.snackbars;

export default snackbarSlice.reducer;
