import type {
  LocationTypeEnumDTO,
  TicketMetadataSerializerDTO,
  RoomTypeEnumDTO,
  IssueTypeEnumDTO,
} from '../../../../../../../connectors/ticket';
import { DamagedItemEnumDTO } from '../../../../../../../connectors/ticket';
import type { ModalSelectOption } from '../../../../../../shared/components/ModalSelect';

export const getAvailableIssueItems = (
  metadata?: TicketMetadataSerializerDTO,
  issueLocation?: LocationTypeEnumDTO,
  issueRoom?: RoomTypeEnumDTO,
  issueType?: IssueTypeEnumDTO,
): DamagedItemEnumDTO[] => {
  if (
    !metadata?.serviceRequestsValidationMap ||
    !issueLocation ||
    !issueRoom ||
    !issueType
  )
    return [];

  return Object.values(DamagedItemEnumDTO).filter((item) =>
    Boolean(
      metadata.serviceRequestsValidationMap[issueLocation][issueRoom][
        issueType
      ][item],
    ),
  );
};

export const getIssueItemOptions = (
  issueItems: DamagedItemEnumDTO[],
): ModalSelectOption[] =>
  issueItems.map((item) => ({
    id: item,
    labelKey: `issue.${item}`,
    value: item,
  }));
