import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  divider: {
    marginBottom: '0 !important',
    marginTop: '0 !important',
  },

  hiddenItem: {
    display: 'none',
  },

  menuItem: {
    background: (theme) => `${theme.palette.common.white} !important`,
    minHeight: (theme) => theme.spacing(3),
    padding: (theme) => theme.spacing(0),
  },

  selectIcon: {
    display: 'inline-block',
    height: (theme) => theme.spacing(2),
    position: 'absolute',
    right: (theme) => theme.spacing(2),
    width: (theme) => theme.spacing(2),
  },

  statusSelectColored: {
    '& .Mui-disabled': {
      WebkitTextFillColor: (theme) =>
        `${theme.palette.common.white} !important`,
    },
    '& svg': {
      color: (theme) => `${theme.palette.common.white} !important`,
    },
    borderRadius: '16px !important',
    color: (theme) => theme.palette.common.white,
    height: (theme) => theme.spacing(5.2),
    width: '100%',
    zIndex: 2,
  },

  statusSelectInput: {
    background: 'none !important',
    padding: 0,
  },

  statusSelectMenu: {
    '& .MuiList-root': {
      '& hr:last-child': {
        display: 'none',
      },
      borderRadius: '8px',
      padding: (theme) => theme.spacing(2.5, 0, 0.5, 0),
      paddingBottom: (theme) => theme.spacing(0.5),
      paddingTop: (theme) => theme.spacing(2.5),
    },
    zIndex: 1,
  },

  statusSelectSimple: {
    background: (theme) => `${theme.palette.common.white} !important`,
    borderRadius: '8px !important',
    height: (theme) => theme.spacing(3),
    width: '100%',
    zIndex: 2,
  },

  subheaderProps: {
    color: (theme) => theme.palette.grey[500],
    lineHeight: '3rem',
    padding: (theme) => theme.spacing(0, 0, 0, 2.5),
  },
};
