import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  ListPropertiesSerializerDTO,
  ListPropertyObjectsSerializerDTO,
  ListTenantSerializerDTO,
  ListUnitsSerializerDTO,
  PropertyObjectSerializerDTO,
  PropertySerializerDTO,
  TenantSerializerDTO,
  UnitSerializerDTO,
} from '../../../connectors/property';
import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../connectors/ticket';
import type { EntityType } from '../services/types/property.types';
import type { RootState } from './index';

export interface PropertyState {
  entities: EntityType[];
  myPropertyObjects: PropertyObjectSerializerDTO[];
  queriedUnits: UnitSerializerDTO[];
  queriedPropertyObjects: PropertyObjectSerializerDTO[];
  queriedProperties: PropertySerializerDTO[];
  unitsForEntity: UnitSerializerDTO[];
  tenantsForUnit: TenantSerializerDTO[];
}

const initialState: PropertyState = {
  entities: [],
  myPropertyObjects: [],
  queriedProperties: [],
  queriedPropertyObjects: [],
  queriedUnits: [],
  tenantsForUnit: [],
  unitsForEntity: [],
};

/* eslint-disable no-param-reassign */
const propertySlice = createSlice({
  initialState,
  name: 'comment',
  reducers: {
    addEntity: (state, action: PayloadAction<EntityType>) => {
      const entity = action.payload;

      if (!state.entities.find((e) => e.uuid === entity.uuid)) {
        state.entities = [...state.entities, entity];
      }
    },

    clearQueriedEntities: (state) => {
      state.queriedUnits = [];
      state.queriedProperties = [];
      state.queriedPropertyObjects = [];
      state.unitsForEntity = [];
    },

    clearTenantsForUnit: (state) => {
      state.tenantsForUnit = [];
    },

    setMyPropertyObjects: (
      state,
      { payload: { results } }: PayloadAction<ListPropertyObjectsSerializerDTO>,
    ) => {
      state.myPropertyObjects = results;
    },

    setQueriedProperties: (
      state,
      { payload: { results } }: PayloadAction<ListPropertiesSerializerDTO>,
    ) => {
      state.queriedProperties = results;
    },

    setQueriedPropertyObjects: (
      state,
      { payload: { results } }: PayloadAction<ListPropertyObjectsSerializerDTO>,
    ) => {
      state.queriedPropertyObjects = results;
    },

    setQueriedUnits: (
      state,
      { payload: { results } }: PayloadAction<ListUnitsSerializerDTO>,
    ) => {
      state.queriedUnits = results;
    },

    setTenantsForUnit: (
      state,
      { payload: { results } }: PayloadAction<ListTenantSerializerDTO>,
    ) => {
      state.tenantsForUnit = results || [];
    },

    setUnitsForEntity: (
      state,
      { payload: { results } }: PayloadAction<ListUnitsSerializerDTO>,
    ) => {
      state.unitsForEntity = results;
    },
  },
});

export const {
  addEntity,
  clearQueriedEntities,
  setMyPropertyObjects,
  setQueriedProperties,
  setQueriedPropertyObjects,
  setQueriedUnits,
  setUnitsForEntity,
  clearTenantsForUnit,
  setTenantsForUnit,
} = propertySlice.actions;
export const selectEntityForTicket = (
  state: RootState,
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): EntityType | undefined =>
  state.property.entities.find((p) => p.uuid === ticket.entityUuid);
export const selectMyPropertyObjects = (
  state: RootState,
): PropertyObjectSerializerDTO[] => state.property.myPropertyObjects;
export const selectQueriedEntities = (state: RootState): EntityType[] => [
  ...state.property.queriedProperties,
  ...state.property.queriedPropertyObjects,
  ...state.property.queriedUnits,
];
export const selectUnitsForEntity = (state: RootState): UnitSerializerDTO[] =>
  state.property.unitsForEntity;
export const selectTenantsForUnit = (state: RootState): TenantSerializerDTO[] =>
  state.property.tenantsForUnit;

export default propertySlice.reducer;
