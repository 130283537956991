import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  iconWrapperBackground: {
    backgroundPosition: 'center !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundSize: 'cover !important',
  },

  iconWrapperBoxMedium: {
    '& path, & rect, & circle, & ellipse, & line, & polyline, & polygon': {
      stroke: 'currentcolor',
      strokeWidth: '1px',
    },
  },

  iconWrapperBoxNormal: {
    '& path, & rect, & circle, & ellipse, & line, & polyline, & polygon': {
      stroke: 'currentcolor',
      strokeWidth: '1.5px',
    },
  },

  iconWrapperBoxThin: {
    '& path, & rect, & circle, & ellipse, & line, & polyline, & polygon': {
      stroke: 'currentcolor',
      strokeWidth: '0.5px',
    },
  },
};
