import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  backButton: {
    color: (theme) => theme.palette.primary.contrastText,
    display: 'flex',
    height: (theme) => theme.spacing(2.5),
    width: (theme) => theme.spacing(2.5),
  },

  ticketViewNavbar: {
    alignItems: 'center',
    background: (theme) => theme.palette.background.default,
    height: (theme) => theme.custom.tabMenuHeight,
    justifyContent: 'space-around',
    padding: (theme) => theme.spacing(0, 1),
  },

  ticketViewNavbarItem: {
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(1),
  },
};
