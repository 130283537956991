import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  attachment: {
    border: (theme) => `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '8px',
    height: (theme) => theme.spacing(15),
    objectFit: 'cover',
    width: (theme) => theme.spacing(15),
  },

  authorSx: {
    color: (theme) => theme.palette.grey[300],
  },

  dateBox: {
    marginBottom: (theme) => theme.spacing(1),
  },

  dateBoxAlignLeft: {
    textAlign: 'left',
  },

  dateBoxAlignRight: {
    textAlign: 'right',
  },

  focussedComment: {
    position: 'relative',
    zIndex: 4,
  },

  focussedMessageBox: {
    background: (theme) => theme.palette.common.white,
  },

  internalMessageBox: {
    background: (theme) => `${theme.palette.warning.light}20`,
    border: (theme) => `2px solid ${theme.custom.activeReading}`,
  },

  mentionStyle: {
    color: (theme) => theme.palette.primary.main,
    textDecoration: 'underline',
  },

  messageBox: {
    background: (theme) => theme.palette.grey[100],
    borderRadius: (theme) => theme.spacing(2),
    overflowWrap: 'break-word',
    padding: (theme) => theme.spacing(2, 1, 2, 2),
    whiteSpace: 'pre-wrap',
  },

  primaryText: {
    color: (theme) => theme.palette.primary.main,
  },

  saveAsATemplateButton: {
    borderRadius: '12px !important',
    height: (theme) => theme.spacing(5.5),
    mt: 1.5,
    zIndex: 4,
  },

  saveAsATemplateButtonIcon: {
    height: (theme) => theme.spacing(3),
    mr: 1.5,
    width: (theme) => theme.spacing(3),
  },

  thirdPartyMessageBox: {
    background: (theme) => `${theme.palette.primary.main}20`,
  },
  wrapperBox: {
    marginBottom: (theme) => theme.spacing(3),
  },

  wrapperBoxLeft: {
    marginLeft: (theme) => theme.spacing(1),
    marginRight: (theme) => theme.spacing(6),
  },

  wrapperBoxRight: {
    marginLeft: (theme) => theme.spacing(6),
    marginRight: (theme) => theme.spacing(1),
  },
};
