import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  button: {
    height: (theme) => theme.spacing(5.5),
  },

  buttonContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    mt: 2,
  },

  buttonIcon: {
    height: (theme) => theme.spacing(3),
    mr: 1,
    width: (theme) => theme.spacing(3),
  },
};
