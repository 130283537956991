import type { SelectChangeEvent } from '@mui/material';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useMemo } from 'react';

import { filterOrder } from '../../../../../../../../shared/helpers/filter.helper';
import { useT } from '../../../../../../../../shared/translation/translation.provider';
import type { Filter } from '../../../../../../../../shared/types/Filter';
import { sxProps } from './filter-order.style';

const filterOrderLabelId = 'filterOrderLabelId';

export type FilterOrderProps = {
  filter: Filter;
  setFilter: Dispatch<SetStateAction<Filter>>;
};

export const FilterOrder: FC<FilterOrderProps> = ({
  filter,
  setFilter,
}: FilterOrderProps) => {
  const t = useT();

  const onChange = useCallback(
    (event: SelectChangeEvent<string>): void => {
      setFilter({ ...filter, order: event.target.value });
    },
    [setFilter, filter],
  );

  const selectItems = useMemo(() => {
    return filterOrder.map(({ value, modalLabelKey }) => {
      return (
        <MenuItem
          key={value}
          value={value}
          data-test={`ticket-filter-modal-order-select-item-${value}`}
        >
          {t(modalLabelKey)}
        </MenuItem>
      );
    });
  }, [t]);

  return (
    <Box sx={sxProps.filterOrderWrapper}>
      <Typography variant="overline" sx={sxProps.label}>
        {t('+ticketListScreen.filter.modal.orderLabel')}
      </Typography>

      <Select
        data-test="ticket-filter-modal-order-select"
        sx={sxProps.select}
        labelId={filterOrderLabelId}
        value={filter.order || ''}
        onChange={onChange}
        fullWidth
      >
        {selectItems}
      </Select>
    </Box>
  );
};
