import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const mergeSx = (...sxs: SxProps<Theme>[]): SxProps<Theme> => {
  return sxs.reduce<SxProps<Theme>>(
    (acc, sx) => {
      return { ...acc, ...sx };
    },
    [{}],
  );
};
