export const tenantConfirmationThatTaskIsClosedKey =
  'tenantConfirmationThatTaskIsClosed';
export const tenantConfirmationThatTaskIsClosedOptions = ['yes', 'no'];
export const solutionKey = 'solution';
export const solutionOptions = [
  'doneByHeimstaden',
  'doneByExternalPartner',
  'doneByTheTenantHimself',
  'agreementOnTheTenantsMaintenanceObligation',
  'other',
];
