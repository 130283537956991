import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  mentionsBox: {
    backgroundColor: (theme) => theme.palette.common.white,
    display: 'block',
    width: '100%',
  },
};

export const popper = {
  style: {
    display: 'flex',
    maxHeight: '60vh',
    overflow: 'scroll',
    paddingBottom: '16px',
    width: '100%',
    zIndex: 99,
  },
};
