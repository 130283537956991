import { ReactComponent as RemoveIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { mergeSx } from '../../../../helpers/sx.helper';
import type { AttachmentForUpload } from '../../../../types/AttachmentForUpload';
import { IconWrapper } from '../../../IconWrapper';
import { sxProps } from './added-attachments.style';

export type AddedAttachmentsProps = {
  attachments: AttachmentForUpload[];
  onRemove: (attachment: AttachmentForUpload) => void;
};

export const AddedAttachments: FC<AddedAttachmentsProps> = ({
  attachments,
  onRemove,
}: AddedAttachmentsProps) => {
  const removeButton = useCallback(
    (a: AttachmentForUpload) => {
      return (
        <IconWrapper
          icon={RemoveIcon}
          sx={sxProps.removeIcon}
          onClick={() => onRemove(a)}
        />
      );
    },
    [onRemove],
  );

  const imageWrapperSx = useMemo(() => {
    return mergeSx(sxProps.attachmentWrapper, sxProps.imageWrapper);
  }, []);

  const imageAttachment = useCallback(
    (a: AttachmentForUpload) => {
      return (
        <Box sx={imageWrapperSx}>
          <Box
            component="img"
            src={URL.createObjectURL(a.file)}
            sx={sxProps.imageBox}
          />
          {removeButton(a)}
        </Box>
      );
    },
    [imageWrapperSx, removeButton],
  );

  if (attachments.length === 0) {
    return null;
  }

  return (
    <Box>
      {attachments.map((a) => {
        return (
          <Box key={a.title}>
            {imageAttachment(a)}
            <Typography variant="body2">{a.title}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
