import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import React from 'react';

import { useT } from '../../../../../../../../shared/translation/translation.provider';
import type { Filter } from '../../../../../../../../shared/types/Filter';
import { sxProps } from './filter-additional.style';

export type FilterAdditionalProps = {
  filter: Filter;
  setFilter: Dispatch<SetStateAction<Filter>>;
};

export const FilterAdditional: FC<FilterAdditionalProps> = ({
  filter,
  setFilter,
}: FilterAdditionalProps) => {
  const t = useT();

  const renderCheckbox = ({
    value,
    labelKey,
    onChange,
    fieldName,
  }: {
    value: boolean;
    labelKey: string;
    onChange: () => void;
    fieldName: string;
  }): ReactNode => {
    return (
      <FormControlLabel
        data-test={`ticket-filter-modal-additional-label-${fieldName}`}
        control={<Checkbox checked={value} />}
        label={t(labelKey) as string}
        componentsProps={{ typography: { variant: 'button' } }}
        sx={sxProps.checkbox}
        onChange={onChange}
      />
    );
  };

  return (
    <Box sx={sxProps.filterAdditionalWrapper}>
      <Typography variant="overline" sx={sxProps.label}>
        {t('+ticketListScreen.filter.modal.additionalLabel')}
      </Typography>
      <FormGroup
        sx={sxProps.formGroup}
        data-test="ticket-filter-modal-addition-form-group"
      >
        {renderCheckbox({
          fieldName: 'overdue',
          labelKey: '+ticketListScreen.filter.modal.additional.overdue',
          onChange: () =>
            setFilter({
              ...filter,
              isOverdue: Boolean(!filter.isOverdue),
            }),
          value: Boolean(filter.isOverdue),
        })}
        {renderCheckbox({
          fieldName: 'display-unassigned',
          labelKey:
            '+ticketListScreen.filter.modal.additional.displayUnassignedLabel',
          onChange: () =>
            setFilter({
              ...filter,
              displayUnassigned: !filter.displayUnassigned,
            }),
          value: Boolean(filter.displayUnassigned),
        })}
      </FormGroup>
    </Box>
  );
};
