import { Box, Divider, List } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { loadNextNotificationsPage } from '../../../../services/notificaiton.service';
import { useAppSelector } from '../../../../store/hooks';
import {
  selectNotifications,
  selectNotificationsNextPage,
} from '../../../../store/notificationsSlice';
import { NotificationItem } from '../NotificationItem';
import { sxProps } from './notification-list.style';

const notificationListId = 'notificationListId';

export const NotificationList: FC = () => {
  const notifications = useAppSelector((state) => selectNotifications(state));
  const nextPage = useAppSelector((state) =>
    selectNotificationsNextPage(state),
  );

  return (
    <List sx={sxProps.list} id={notificationListId}>
      <InfiniteScroll
        dataLength={notifications.length}
        next={() => {
          loadNextNotificationsPage();
        }}
        loader={null}
        hasMore={nextPage !== undefined}
        scrollableTarget={notificationListId}
      >
        {notifications.map((n, i) => (
          <Box key={n.uuid}>
            <NotificationItem notification={n} />
            {i < notifications.length ? <Divider variant="fullWidth" /> : null}
          </Box>
        ))}
      </InfiniteScroll>
    </List>
  );
};
