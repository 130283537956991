import { TextField } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { useT } from '../../../../../../../../shared/translation/translation.provider';
import { sxProps } from '../../status-update-modal.style';

export type StatusUpdateFormDateProps = {
  fieldname: string;
  value: string;
  onChange: (key: string, value: string) => void;
};

export const StatusUpdateFormDate: FC<StatusUpdateFormDateProps> = ({
  fieldname,
  value,
  onChange,
}: StatusUpdateFormDateProps) => {
  const t = useT();

  return (
    <TextField
      data-test={`status-update-modal-form-datepicker-${fieldname}`}
      key={fieldname}
      sx={sxProps.inputField}
      fullWidth
      type="date"
      onChange={(e) => onChange(fieldname, e.target.value)}
      label={t(`+ticketView.details.statusUpdateForm.${fieldname}`)}
      value={value}
    />
  );
};
