import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useMemo,
} from 'react';
import type { FC } from 'react';

import type {
  AttachmentSerializerDTO,
  CommentSerializerDTO,
  UniversalTicketsWithAdditionalFieldsSerializerDTO,
} from '../../../../../../../connectors/ticket';
import { AttachmentViewModal } from '../../../../../../shared/components/Attachment/AttachmentViewModal';
import { loadCountries } from '../../../../../../shared/services/company.service';
import { loadAccountData } from '../../../../../../shared/services/profile.service';
import {
  createResponseTemplate,
  loadResponseTemplates,
  loadTicketComments,
} from '../../../../../../shared/services/ticket.service';
import { selectLoggedUser } from '../../../../../../shared/store/authSlice';
import { selectCommentsForTicket } from '../../../../../../shared/store/commentSlice';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { useTicket } from '../../../../ticket.context';
import { CommentWrapper } from '../CommentWrapper';
import { ResponseTemplates } from '../ResponseTemplates';
import type { ResponseTemplateConfirmationPopupProps } from '../ResponseTemplates/component/ResponseTemplateConfirmationPopup';
import { ResponseTemplateConfirmationPopup } from '../ResponseTemplates/component/ResponseTemplateConfirmationPopup';

export const CommentList: FC = () => {
  const ticket = useTicket();
  const comments = useAppSelector((state) =>
    selectCommentsForTicket(state, ticket),
  );

  const loggedUser = useAppSelector((state) => selectLoggedUser(state));
  const bottomRef = useRef<HTMLDivElement>(null);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [responseTemplatePopupData, setResponseTemplatePopupData] =
    useState<ResponseTemplateConfirmationPopupProps>();
  const [commentAttachments, setCommentAttachments] = useState<
    AttachmentSerializerDTO[]
  >([]);
  const [selectedAttachmentIndex, setSelectedAttachmentIndex] =
    useState<number>(0);

  const fetchData = useCallback(
    async (ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO) => {
      await loadAccountData();
      await loadTicketComments(ticket);
      await loadResponseTemplates();
      await loadCountries();
    },
    [],
  );

  const openAttachmentModal = useCallback(
    (
      attachments: AttachmentSerializerDTO[],
      selectedAttachmentIndex: number,
    ) => {
      setCommentAttachments(attachments);
      setSelectedAttachmentIndex(selectedAttachmentIndex);
      setAttachmentModalOpen(true);
    },
    [],
  );

  const showSaveResponseTemplatePopup = useCallback(
    (comment: CommentSerializerDTO) => {
      const close = (): void => setResponseTemplatePopupData(undefined);

      setResponseTemplatePopupData({
        action: 'save',
        close,
        onSaveCallback: (text, language) => {
          createResponseTemplate(text, language);
          close();
        },
        open: true,
        popupContent: comment,
      });
    },
    [],
  );

  const responseTemplatePopup = useMemo(() => {
    if (!responseTemplatePopupData) return null;

    return <ResponseTemplateConfirmationPopup {...responseTemplatePopupData} />;
  }, [responseTemplatePopupData]);

  useEffect(() => {
    fetchData(ticket);
  }, [ticket, fetchData]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [comments]);

  if (!comments || !loggedUser?.currentUserData) return null;
  const { currentUserData } = loggedUser;

  return (
    <>
      <AttachmentViewModal
        open={attachmentModalOpen}
        setOpen={setAttachmentModalOpen}
        uploadedAttachments={commentAttachments}
        startIndex={selectedAttachmentIndex}
      />
      {responseTemplatePopup}
      {comments.map((comment) => {
        return (
          <CommentWrapper
            comment={comment}
            currentUser={currentUserData}
            key={comment.uuid}
            openAttachmentModal={openAttachmentModal}
            showSaveResponseTemplatePopup={() =>
              showSaveResponseTemplatePopup(comment)
            }
          />
        );
      })}
      <div ref={bottomRef} />
      <ResponseTemplates />
    </>
  );
};
