import type { MeSerializerDTO } from '../../../connectors/user';
import { store } from '../store';
import { setCurrentUser } from '../store/authSlice';
import { setLoading } from '../store/loadingSlice';
import { setTicketCount } from '../store/ticketSlice';
import { authClient } from './clients/auth.client';
import { ticketClient } from './clients/ticket.client';

export const loadAccountData = async (): Promise<
  MeSerializerDTO | undefined
> => {
  let currentUser: MeSerializerDTO | undefined;

  if (store.getState().auth.loggedUser?.currentUserData) {
    return store.getState().auth.loggedUser?.currentUserData;
  }
  store.dispatch(setLoading(true));
  try {
    currentUser = await authClient.me();
    store.dispatch(setCurrentUser(currentUser));
  } catch {
    //  fails silently
  }
  store.dispatch(setLoading(false));

  return currentUser;
};

export const loadTicketCount = async (): Promise<void> => {
  const accountData = await loadAccountData();
  let assigneeUuid: string | undefined;

  store.dispatch(setLoading(true));

  try {
    if (accountData) {
      assigneeUuid = accountData.uuid;
    }
    const ticketCount = await ticketClient.getTicketCount(assigneeUuid);

    store.dispatch(setTicketCount(ticketCount));
  } catch {
    //  fails silently
  }
  store.dispatch(setLoading(false));
};
