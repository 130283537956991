import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  button: {
    ml: 0.5,
    mr: 0.5,
    pl: 2.5,
    pr: 2.5,
  },

  buttonIcon: {
    height: (theme) => theme.spacing(3),
    mr: 1,
    width: (theme) => theme.spacing(3),
  },

  buttonsBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    mt: 2,
  },

  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  headerCloseIcon: {
    height: (theme) => theme.spacing(3),
    width: (theme) => theme.spacing(3),
  },

  headerIcon: {
    color: (theme) => theme.palette.grey[500],
    height: (theme) => theme.spacing(3),
    mr: 1,
    width: (theme) => theme.spacing(3),
  },

  headerTitle: { flex: 1 },

  languagePickerBox: {
    mt: 2,
  },

  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  modalContent: {
    background: (theme) => theme.palette.common.white,
    borderRadius: '8px',
    maxHeight: '70vh',
    overflowY: 'scroll',
    p: 3,
    width: '90vw',
  },

  searchBoxBox: {
    mt: 2,
  },

  templateListBox: {
    mt: 2,
  },
};
