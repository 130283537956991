import type { CoordinatesSerializerDTO } from '../../../../connectors/property';
import { detectPlatform, isApplePlatform } from '../../helpers/platfrom.helper';

export const navigateOnMap = ({
  latitude,
  longitude,
}: CoordinatesSerializerDTO): void => {
  const platform = detectPlatform();

  if (isApplePlatform(platform))
    /* else Apple maps */
    window.open(`maps://maps.google.com/maps?daddr=${latitude},${longitude}`);
  /* else use Google mpas */ else
    window.open(`https://maps.google.com/maps?daddr=${latitude},${longitude}`);
};
