import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  ticketListItem: {
    '&:last-child': {
      marginBottom: (theme) => theme.spacing(0.875),
    },
    background: (theme) => theme.palette.common.white,
    borderRadius: (theme) => theme.spacing(0.5),
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);',
    margin: (theme) => theme.spacing(0.875, 0.875, 0, 0.875),
    minHeight: (theme) => theme.spacing(12.5),
    position: 'relative',
  },

  ticketListItemAddressBox: {
    marginBottom: (theme) => theme.spacing(0.5),
  },

  ticketListItemBottomText: {
    color: (theme) => theme.palette.text.primary,
    paddingTop: (theme) => theme.spacing(1.5),
  },

  ticketListItemCenterText: {
    color: (theme) => theme.palette.text.secondary,
    display: '-webkit-box',
    fontWeight: 700,
    marginBottom: (theme) => theme.spacing(1.5),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },

  ticketListItemContentWrapper: {
    marginRight: (theme) => theme.spacing(3.625),
    padding: (theme) => theme.spacing(1.625, 0, 1.625, 2.375),
  },

  ticketListItemDetail: {
    marginRight: (theme) => theme.spacing(2),
  },

  ticketListItemDoorIcon: {
    marginLeft: (theme) => theme.spacing(1),
  },

  ticketListItemIcon: {
    height: (theme) => theme.spacing(2),
    marginBottom: (theme) => theme.spacing(-0.5),
    marginRight: (theme) => theme.spacing(0.5),
    width: (theme) => theme.spacing(2),
  },

  ticketListItemOverdue: {
    color: (theme) => theme.palette.error.main,
  },

  ticketListItemTopText: {
    color: (theme) => theme.palette.grey[500],
    display: '-webkit-box',
    fontWeight: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    whiteSpace: 'pre-wrap',
  },
};
