import { Box, Container } from '@mui/material';
import type { FC, ReactNode } from 'react';
import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import type { Settings } from 'react-slick';
import Carousel from 'react-slick';

import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../../../connectors/ticket';
import { ShowLocationMap } from '../../../../shared/components/ShowLocationMap';
import { paths } from '../../../../shared/routes/paths';
import { useAppSelector } from '../../../../shared/store/hooks';
import { selectTicketsToDisplay } from '../../../../shared/store/ticketSlice';
import { sxProps as parentSxProps } from '../../ticket-view-screen.style';
import { useTicket } from '../../ticket.context';
import { TicketHeader } from '../TicketHeader';
import { TicketDetailsActions } from './components/TicketDetailsActions';
import { TicketDetailsAssignee } from './components/TicketDetailsAssignee';
import { TicketDetailsInformation } from './components/TicketDetailsInformation';
import { TicketDetailsReferenceLabel } from './components/TicketDetailsReferenceLabel';
import { sxProps } from './ticket-details.style';

export const TicketDetails: FC = () => {
  const ticket = useTicket();
  const navigate = useNavigate();
  const carouselTickets = useAppSelector((state) =>
    selectTicketsToDisplay(state),
  );

  const [orderedTicketKeys, setOrderedTicketKeys] = useState<string[]>([]);

  //  we want this effect only to be run when component is just loaded
  //  it will create list of ordered keys that represents order of tickets,
  //  even if they change state
  useEffect(() => {
    if (orderedTicketKeys.length === 0) {
      setOrderedTicketKeys(carouselTickets.map((t) => t.referenceNumber));
    }
  }, [carouselTickets, orderedTicketKeys.length]);

  const getTicketIndexByKey = (ticketKey: string | undefined): number => {
    return orderedTicketKeys.findIndex((key: string) => {
      return key === ticketKey;
    });
  };

  const getTicketByKey = (
    ticketKey: string | undefined,
  ): UniversalTicketsWithAdditionalFieldsSerializerDTO | undefined => {
    if (!ticketKey) return undefined;

    return carouselTickets.find((t) => t.referenceNumber === ticketKey);
  };

  const map = useMemo<ReactNode>(() => {
    if (!ticket.addressLine) return null;

    return (
      <ShowLocationMap ticket={ticket} containerSx={sxProps.ticketDetailsMap} />
    );
  }, [ticket]);

  const settings: Settings = {
    adaptiveHeight: true,
    arrows: false,
    beforeChange(currentSlide: number, nextSlide) {
      const nextTicket = getTicketByKey(orderedTicketKeys[nextSlide]);

      if (nextTicket) {
        navigate(
          // eslint-disable-next-line max-len
          `/${paths.ticket}/${nextTicket.referenceNumber}${paths.ticketDetails}/`,
        );
      }
    },
    dots: false,
    infinite: true,
    initialSlide: getTicketIndexByKey(ticket?.referenceNumber),
    lazyLoad: 'ondemand',
    slidesToScroll: 1,
    slidesToShow: 1,
    swipe: true,
  };

  if (!ticket && orderedTicketKeys.length === 0) return null;

  // if we are accessing page by clicking ticket in ticket list, we will show
  // all ticket from that list in carousel view
  if (orderedTicketKeys.length > 0) {
    return (
      <Box sx={sxProps.ticketDetailsBox}>
        <Carousel {...settings}>
          {orderedTicketKeys.map((key: string) => {
            const t = getTicketByKey(key);

            if (!t) return null;

            return (
              <Box key={key}>
                <Container>
                  <Box sx={parentSxProps.ticketHeaderBox}>
                    <TicketDetailsReferenceLabel />
                    <TicketHeader />
                  </Box>
                  <TicketDetailsActions />
                  <TicketDetailsAssignee />
                  <TicketDetailsInformation />
                </Container>
                {map}
              </Box>
            );
          })}
        </Carousel>
      </Box>
    );
  }

  // if we are showing ticket this page by loading url with ticket key, then we will only have one ticket
  // and carousel is not needed
  return (
    <Box sx={sxProps.ticketDetailsBox}>
      <Container>
        <Box sx={parentSxProps.ticketHeaderBox}>
          <TicketDetailsReferenceLabel />
          <TicketHeader />
        </Box>
        <TicketDetailsActions />
        <TicketDetailsAssignee />
        <TicketDetailsInformation />
      </Container>
      {map}
    </Box>
  );
};
