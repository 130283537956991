export const LOCAL_STORAGE_AUTH_ITEM_KEY = 'auth';
export const LOCAL_STORAGE_LANGUAGE_ITEM_KEY = 'language';
export const LOCAL_STORAGE_CREATE_TICKET_ITEM_KEY = 'create-ticket';
export const TICKETS_PER_PAGE = 10;
export const ATTACHMENTS_PER_PAGE = 100;
export const COMMENTS_PER_PAGE = 100;
export const RESPONSE_TEMPLATES_PER_PAGE = 100;
export const USERS_PER_PAGE = 20;
export const NOTIFICATIONS_PER_PAGE = 50;
export const MAX_FILE_SIZE = 20 * 1024 * 1024;
export const MENTION_CHAR = '@';
export const COUNTRIES_PER_PAGE = 20;
export const CITIES_PER_PAGE = 20;
export const ENTITIES_PER_PAGE = 5;

export const DEBOUNCE_TIMEOUT = 1000;
export const NOTIFICATION_PULL_INTERVAL = 30000; // half a minute
export const LONG_PRESS_DELAY = 1000;
