import type { FC } from 'react';
import React, { useState } from 'react';

import type { LinkedUserSerializerDTO } from '../../../../connectors/ticket';
import type { EmployeeSerializerDTO } from '../../../../connectors/user';
import { EmptyAssigneePicker, TypingAssigneePicker } from './components';
import { AssigneeDetails } from './components/assignee-details.component';

export type AssigneePickerProps = {
  onUserSelect: (user?: EmployeeSerializerDTO) => void;
  assignee?: LinkedUserSerializerDTO | EmployeeSerializerDTO;
  isHidden?: boolean;
  showConfirmationPopup?: boolean;
};

export const AssigneePicker: FC<AssigneePickerProps> = ({
  onUserSelect,
  assignee,
  isHidden,
  showConfirmationPopup,
}: AssigneePickerProps) => {
  const [typing, setTyping] = useState(false);

  if (typing) {
    return (
      <TypingAssigneePicker
        quitTyping={() => {
          setTyping(false);
        }}
        onAssigneeSelect={onUserSelect}
      />
    );
  }

  if (isHidden) return null;

  return assignee ? (
    <AssigneeDetails
      onSearch={() => setTyping(true)}
      onRemove={() => {
        setTyping(false);
        onUserSelect(undefined);
      }}
      assignee={assignee}
      showConfirmationPopup={showConfirmationPopup}
    />
  ) : (
    <EmptyAssigneePicker onClick={() => setTyping(true)} />
  );
};
