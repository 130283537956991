import React, { useMemo } from 'react';
import type { FC } from 'react';

import type {
  IssueTypeEnumDTO,
  RoomTypeEnumDTO,
  LocationTypeEnumDTO,
  TicketMetadataSerializerDTO,
  DamagedItemEnumDTO,
  IssueSubtypeEnumDTO,
} from '../../../../../../../connectors/ticket';
import { ModalSelect } from '../../../../../../shared/components/ModalSelect';
import {
  getAvailableIssueSubtypesAndDepartments,
  getIssueSubtypesOptions,
} from './util';

export type IssueSubtypePickerProps = {
  metadata?: TicketMetadataSerializerDTO;
  issueLocation?: LocationTypeEnumDTO;
  issueRoom?: RoomTypeEnumDTO;
  issueType?: IssueTypeEnumDTO;
  issueItem?: DamagedItemEnumDTO;
  issueSubtype?: IssueSubtypeEnumDTO;
  onChange: (issueSubtype: IssueSubtypeEnumDTO) => void;
  error?: string;
};

export const IssueSubtypePicker: FC<IssueSubtypePickerProps> = ({
  metadata,
  onChange,
  issueSubtype,
  issueItem,
  error,
  issueLocation,
  issueRoom,
  issueType,
}: IssueSubtypePickerProps) => {
  const isHidden = useMemo(
    () => !issueLocation || !issueRoom || !issueType || !issueItem,
    [issueItem, issueLocation, issueRoom, issueType],
  );

  const issueItemSubtypes = useMemo(() => {
    const avaiableSubtypesAndDepartments =
      getAvailableIssueSubtypesAndDepartments(
        metadata,
        issueLocation,
        issueRoom,
        issueType,
        issueItem,
      );

    return getIssueSubtypesOptions(avaiableSubtypesAndDepartments);
  }, [metadata, issueLocation, issueRoom, issueType, issueItem]);

  if (isHidden) return null;

  return (
    <ModalSelect
      fieldname="issue_subtype_picker"
      value={issueSubtype || ''}
      onSelect={(item) => onChange(item.value as IssueSubtypeEnumDTO)}
      options={issueItemSubtypes}
      renderAsSelect={{
        error,
        labelKey: '+createTicket.issueSubtypeLabel',
      }}
    />
  );
};
