import { ReactComponent as FencePavingPlantingIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-fence-1.svg';
import { ReactComponent as FacadeIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/buildings/building.svg';
import { ReactComponent as FacadeIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/construction/construction-fence.svg';
import { ReactComponent as CommonOutdoorAreaIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/houses/house-nature.svg';
import { ReactComponent as HallwayIcon } from '@heimstaden/icons-library/img/streamline-regular/business-products/strategy/maze-strategy.svg';
import { ReactComponent as SprinklingSystemIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/ecology/global-warming/global-warming-dead-plant.svg';
import { ReactComponent as KitchenFanIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/ecology/renewable-energy/renewable-energy-wind-turbine.svg';
import { ReactComponent as WaterDrainPipesIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/ecology/water-protection/water-protection-faucet.svg';
import { ReactComponent as MailBoxNameplateIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/emails/mailbox/mailbox-in.svg';
import { ReactComponent as OutdoorAreaIcon } from '@heimstaden/icons-library/img/streamline-regular/family-babies-kids/outdoor-activities/family-outdoors-playhouse.svg';
import { ReactComponent as PlaygroundIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/family-babies-kids/outdoor-activities/family-outdoors-teeter.svg';
import { ReactComponent as OvenCookerIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/appliances/appliances-oven.svg';
import { ReactComponent as RefrigeratorFreezerIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/freezer-cooking-temperatures/temperature-snowflake.svg';
import { ReactComponent as GasIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/stove/stove-gas-1.svg';
import { ReactComponent as BoilerRoomIcon } from '@heimstaden/icons-library/img/streamline-regular/food-drinks/stove/stove-induction-pot.svg';
import { ReactComponent as ApartmentIcon } from '@heimstaden/icons-library/img/streamline-regular/furnitures-decoration/asian-interiors/asian-interior-painting.svg';
import { ReactComponent as StorageHallwayIcon } from '@heimstaden/icons-library/img/streamline-regular/furnitures-decoration/dressers-drawers/dresser-wardrobe-mirror.svg';
import { ReactComponent as KitchenIcon } from '@heimstaden/icons-library/img/streamline-regular/furnitures-decoration/kitchen/kitchen-storage.svg';
import { ReactComponent as LivingRoomIcon } from '@heimstaden/icons-library/img/streamline-regular/furnitures-decoration/shelves/shelf-tv.svg';
import { ReactComponent as InventoryFurnitureIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/furnitures-decoration/sofas-benches/sofa-double.svg';
import { ReactComponent as StairwellIcon } from '@heimstaden/icons-library/img/streamline-regular/furnitures-decoration/stairs/stairs.svg';
import { ReactComponent as HeatingVentilationIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/ac-heating/ac-heat.svg';
import { ReactComponent as BathroomIcon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/bathroom/bathroom-tub-towel.svg';
import { ReactComponent as ParkingSpaceIcon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/garage-parking/car-garage.svg';
import { ReactComponent as AtticIcon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/hotel-bedrooms/hotel-bedroom.svg';
import { ReactComponent as BedroomIcon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/hotel-bedrooms/hotel-double-bed.svg';
import { ReactComponent as OutdoorSmellIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/hotels-spas/sauna/sauna-heat-stone.svg';
import { ReactComponent as OtherIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alerts/question-circle.svg';
import { ReactComponent as MoistureMoldIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alerts/radioactive-circle.svg';
import { ReactComponent as KeysLockIntercomIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/login-logout/login-keys.svg';
import { ReactComponent as PrivateOutdoorAreaIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/login-logout/logout-2.svg';
import { ReactComponent as NoneIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove-square-1.svg';
import { ReactComponent as WrenchIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/settings/wench-1.svg';
import { ReactComponent as TvInternetIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/internet-networks-servers/wifi/wifi-monitor.svg';
import { ReactComponent as HallIcon } from '@heimstaden/icons-library/img/streamline-regular/lamps-lights-fire/ceiling-lamps/ceiling-ball-chandelier-retro.svg';
import { ReactComponent as ElectricityIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/lamps-lights-fire/light-bubbles/light-bulb.svg';
import { ReactComponent as FirebreakIcon } from '@heimstaden/icons-library/img/streamline-regular/lamps-lights-fire/lighter-matches-candles/matches-fire.svg';
import { ReactComponent as BalconyOrRoofTerraceIcon } from '@heimstaden/icons-library/img/streamline-regular/nature-farming/outdoors/outdoors-landscape-meadow.svg';
import { ReactComponent as OutdoorAreaOrGardenIcon } from '@heimstaden/icons-library/img/streamline-regular/nature-farming/plants/plant-flowers.svg';
import { ReactComponent as VerminIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/programing-apps-websites/bug-security/computer-bug-search.svg';
import { ReactComponent as StorageIcon } from '@heimstaden/icons-library/img/streamline-regular/shipping-delivery/warehouse-fullfiment/warehouse-storage-2.svg';
import { ReactComponent as RecreationRoomIcon } from '@heimstaden/icons-library/img/streamline-regular/sports/tennis-badminton/ping-pong-paddle.svg';
import { ReactComponent as SurfacesIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/tools-constructions/materials/material-stone.svg';
import { ReactComponent as OnTheRoofIcon } from '@heimstaden/icons-library/img/streamline-regular/tools-constructions/materials/material-tile-roof-1.svg';
import { ReactComponent as BikeStorageIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/transportation/bicycles/bicycle-lock-key.svg';
import { ReactComponent as GarageParkingIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/transportation/parking/parking-p-1.svg';
import { ReactComponent as ContainerGarbageIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/garbages/garbage-bin.svg';
import { ReactComponent as GarbageRoomIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/garbages/garbage-throw.svg';
import { ReactComponent as LaundryRoomIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/laundry/laundry-machine-1.svg';
import { ReactComponent as ElevatorIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/lifts/lift-1.svg';
import { ReactComponent as ElevatorsIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/lifts/lift.svg';
import { ReactComponent as FireAlarmIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/safety/safety-fire-alarm.svg';
import { ReactComponent as BasementIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/stairs/stairs-descend.svg';
import { ReactComponent as CommonAreaIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/stairs/stairs-person-ascend.svg';
import { ReactComponent as HallwayStairsIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/stairs/stairs-person-descend.svg';
import { ReactComponent as ToiletRoomIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/toilets/toilet-seat.svg';
import { ReactComponent as KitchenSinkIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/water-fountain/water-fountain-drop.svg';
import { ReactComponent as SinkIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/wayfinding/water-fountain/water-fountain-sink-1.svg';
import { ReactComponent as DishwasherIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/weather/humidity/humidity-high.svg';
import { ReactComponent as SnowRemovalIssueIcon } from '@heimstaden/icons-library/img/streamline-regular/weather/ice-snow/ice-road.svg';

import {
  IssueTypeEnumDTO,
  LocationTypeEnumDTO,
  RoomTypeEnumDTO,
} from '../../../connectors/ticket';

export const getLocationFailureIcon = (
  location: LocationTypeEnumDTO,
): React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
> => {
  switch (location) {
    case LocationTypeEnumDTO.Apartment:
      return ApartmentIcon;
    case LocationTypeEnumDTO.CommonArea:
      return CommonAreaIcon;
    case LocationTypeEnumDTO.CommonOutdoorArea:
      return CommonOutdoorAreaIcon;
    case LocationTypeEnumDTO.OutdoorArea:
      return OutdoorAreaIcon;
    case LocationTypeEnumDTO.PrivateOutdoorArea:
      return PrivateOutdoorAreaIcon;
    default:
      return WrenchIcon;
  }
};

export const getRoomFailureIcon = (
  room: RoomTypeEnumDTO,
): React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
> => {
  switch (room) {
    case RoomTypeEnumDTO.Attic:
      return AtticIcon;
    case RoomTypeEnumDTO.BalconyOrRoofTerrace:
      return BalconyOrRoofTerraceIcon;
    case RoomTypeEnumDTO.Basement:
      return BasementIcon;
    case RoomTypeEnumDTO.Bathroom:
      return BathroomIcon;
    case RoomTypeEnumDTO.Bedroom:
      return BedroomIcon;
    case RoomTypeEnumDTO.BoilerRoom:
      return BoilerRoomIcon;
    case RoomTypeEnumDTO.Elevator:
      return ElevatorIcon;
    case RoomTypeEnumDTO.Facade:
      return FacadeIcon;
    case RoomTypeEnumDTO.Firebreak:
      return FirebreakIcon;
    case RoomTypeEnumDTO.Hall:
      return HallIcon;
    case RoomTypeEnumDTO.Hallway:
      return HallwayIcon;
    case RoomTypeEnumDTO.Kitchen:
      return KitchenIcon;
    case RoomTypeEnumDTO.LivingRoom:
      return LivingRoomIcon;
    case RoomTypeEnumDTO.OnTheRoof:
      return OnTheRoofIcon;
    case RoomTypeEnumDTO.OutdoorAreaOrGarden:
      return OutdoorAreaOrGardenIcon;
    case RoomTypeEnumDTO.ParkingSpace:
      return ParkingSpaceIcon;
    case RoomTypeEnumDTO.RecreationRoom:
      return RecreationRoomIcon;
    case RoomTypeEnumDTO.ToiletRoom:
      return ToiletRoomIcon;
    case RoomTypeEnumDTO.Stairwell:
      return StairwellIcon;
    case RoomTypeEnumDTO.Storage:
      return StorageIcon;
    case RoomTypeEnumDTO.StorageHallway:
      return StorageHallwayIcon;
    case RoomTypeEnumDTO.None:
      return NoneIcon;

    default:
      return WrenchIcon;
  }
};

export const getFailureTypeIcon = (
  issue: IssueTypeEnumDTO,
): React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
> => {
  switch (issue) {
    case IssueTypeEnumDTO.BikeStorageIssue:
      return BikeStorageIssueIcon;
    case IssueTypeEnumDTO.ContainerGarbageIssue:
      return ContainerGarbageIssueIcon;
    case IssueTypeEnumDTO.DishwasherIssue:
      return DishwasherIssueIcon;
    case IssueTypeEnumDTO.ElectricityIssue:
      return ElectricityIssueIcon;
    case IssueTypeEnumDTO.ElevatorsIssue:
      return ElevatorsIssueIcon;
    case IssueTypeEnumDTO.FacadeIssue:
      return FacadeIssueIcon;
    case IssueTypeEnumDTO.FencePavingPlantingIssue:
      return FencePavingPlantingIssueIcon;
    case IssueTypeEnumDTO.FireAlarmIssue:
      return FireAlarmIssueIcon;
    case IssueTypeEnumDTO.GarageParkingIssue:
      return GarageParkingIssueIcon;
    case IssueTypeEnumDTO.GarbageRoomIssue:
      return GarbageRoomIssueIcon;
    case IssueTypeEnumDTO.GasIssue:
      return GasIssueIcon;
    case IssueTypeEnumDTO.GreenAreasIssue:
      return OutdoorAreaIcon;
    case IssueTypeEnumDTO.HallwayStairsIssue:
      return HallwayStairsIssueIcon;
    case IssueTypeEnumDTO.HeatingVentilationIssue:
      return HeatingVentilationIssueIcon;
    case IssueTypeEnumDTO.FurnitureIssue:
      return InventoryFurnitureIssueIcon;
    case IssueTypeEnumDTO.KeysLockIntercomIssue:
      return KeysLockIntercomIssueIcon;
    case IssueTypeEnumDTO.KitchenFanIssue:
      return KitchenFanIssueIcon;
    case IssueTypeEnumDTO.KitchenSinkIssue:
      return KitchenSinkIssueIcon;
    case IssueTypeEnumDTO.KitchenUnit:
      return KitchenIcon;
    case IssueTypeEnumDTO.LaundryRoomIssue:
      return LaundryRoomIssueIcon;
    case IssueTypeEnumDTO.MailBoxNameplateIssue:
      return MailBoxNameplateIssueIcon;
    case IssueTypeEnumDTO.MoistureMoldIssue:
      return MoistureMoldIssueIcon;
    case IssueTypeEnumDTO.Other:
      return OtherIcon;
    case IssueTypeEnumDTO.OutdoorSmellIssue:
      return OutdoorSmellIssueIcon;
    case IssueTypeEnumDTO.OvenCookerIssue:
      return OvenCookerIssueIcon;
    case IssueTypeEnumDTO.PlaygroundIssue:
      return PlaygroundIssueIcon;
    case IssueTypeEnumDTO.RefrigeratorFreezerIssue:
      return RefrigeratorFreezerIssueIcon;
    case IssueTypeEnumDTO.RooftopIssue:
      return OnTheRoofIcon;
    case IssueTypeEnumDTO.ShowerBathIssue:
      return BathroomIcon;
    case IssueTypeEnumDTO.SinkIssue:
      return SinkIssueIcon;
    case IssueTypeEnumDTO.SnowRemovalIssue:
      return SnowRemovalIssueIcon;
    case IssueTypeEnumDTO.SprinklingSystemIssue:
      return SprinklingSystemIssueIcon;
    case IssueTypeEnumDTO.StorageRoomIssue:
      return StorageIcon;
    case IssueTypeEnumDTO.SurfacesIssue:
      return SurfacesIssueIcon;
    case IssueTypeEnumDTO.ToiletIssue:
      return ToiletRoomIcon;
    case IssueTypeEnumDTO.TvInternetIssue:
      return TvInternetIssueIcon;
    case IssueTypeEnumDTO.VerminIssue:
      return VerminIssueIcon;
    case IssueTypeEnumDTO.WashingMachineIssue:
      return LaundryRoomIssueIcon;
    case IssueTypeEnumDTO.WasteIssue:
      return ContainerGarbageIssueIcon;
    case IssueTypeEnumDTO.WaterDrainPipesIssue:
      return WaterDrainPipesIssueIcon;
    default:
      return WrenchIcon;
  }
};
