import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';

import type { AttachmentSerializerDTO } from '../../../../../connectors/ticket';
import { mergeSx } from '../../../helpers/sx.helper';
import { globalSxProps } from '../../../style';
import { attachmentSxProps } from '../attachment.style';
import { sxProps } from './attachment-item.style';

export type AttachmentItemProps = {
  attachment: AttachmentSerializerDTO;
  onClick?: () => void;
};

export const AttachmentItem: FC<AttachmentItemProps> = ({
  attachment,
  onClick,
}: AttachmentItemProps) => {
  const onAttachmentClick = useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  const { description, title, url } = attachment;

  return (
    <Box
      sx={attachmentSxProps.attachmentItemWrapper}
      data-test={`ticket-view-attachment-item-${attachment.uuid}`}
    >
      <Box
        sx={attachmentSxProps.attachmentItemImageWrapper}
        onClick={onAttachmentClick}
      >
        <img src={url} alt="attachment" />
      </Box>
      <Typography
        variant="h3"
        sx={mergeSx(sxProps.title, globalSxProps.textBold)}
      >
        {title}
      </Typography>
      <Typography sx={sxProps.description}>{description}</Typography>
    </Box>
  );
};
