import React from 'react';
import type { RouteObject } from 'react-router';
import { Navigate } from 'react-router';

import { WelcomeScreen } from '../../+auth';
import { AuthCallbackScreen } from '../../+auth/AuthCallback';
import { CreateTicketScreen } from '../../+create-ticket';
import { CreateTicketProblemScreen } from '../../+create-ticket/CreateTicket/components/CreateTicketProblemScreen';
import { CreateTicketSuccessScreen } from '../../+create-ticket/CreateTicket/components/CreateTicketSuccessScreen';
import { ProfileScreen } from '../../+profile';
import {
  TicketAttachments,
  TicketDetails,
  TicketListScreen,
  TicketMessages,
  TicketViewScreen,
} from '../../+tickets';
import { GuardedRoute } from './GuardedRoute';
import { PublicRoute } from './PublicRoute';
import { paths } from './paths';

export const getRoutes = (): RouteObject[] => [
  {
    children: [
      {
        element: <Navigate to={paths.welcome} />,
        path: '*',
      },
    ],
    element: (
      <PublicRoute>
        <WelcomeScreen />
      </PublicRoute>
    ),
    path: paths.welcome,
  },
  {
    children: [
      {
        element: (
          <PublicRoute>
            <AuthCallbackScreen />
          </PublicRoute>
        ),
        path: ':authType/authorize',
      },
    ],
    path: paths.authCallback,
  },
  {
    children: [
      {
        element: <Navigate to={paths.root} />,
        path: '*',
      },
    ],
    element: (
      <GuardedRoute>
        <TicketListScreen />
      </GuardedRoute>
    ),

    path: paths.root,
  },
  {
    children: [
      {
        children: [
          {
            element: <TicketAttachments />,
            path: paths.ticketAttachments,
          },
          {
            element: <TicketDetails />,
            path: paths.ticketDetails,
          },
          {
            element: <TicketMessages />,
            path: paths.ticketMessages,
          },
          {
            element: <Navigate to={`${paths.ticketDetails}`} />,
            path: '*',
          },
        ],
        element: (
          <GuardedRoute>
            <TicketViewScreen />
          </GuardedRoute>
        ),
        path: ':ticketKey',
      },
      {
        element: <Navigate to={paths.root} />,
        path: '*',
      },
    ],

    path: paths.ticket,
  },
  {
    children: [
      {
        element: <Navigate to={paths.profile} />,
        path: '*',
      },
    ],
    element: (
      <GuardedRoute>
        <ProfileScreen />
      </GuardedRoute>
    ),
    path: paths.profile,
  },
  {
    children: [
      {
        children: [
          { element: <CreateTicketSuccessScreen />, path: ':ticketKey' },
          {
            element: <Navigate to={paths.createTicket} />,
            path: '*',
          },
        ],

        path: paths.success,
      },
      {
        element: <CreateTicketProblemScreen />,
        path: paths.problem,
      },
      {
        element: (
          <GuardedRoute>
            <CreateTicketScreen />
          </GuardedRoute>
        ),
        path: paths.createTicket,
      },
    ],

    path: paths.createTicket,
  },
];
