import { ReactComponent as AddCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add-circle.svg';
import { ReactComponent as RemoveCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove-circle.svg';
import { Button, Container, Grid, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import type { Dispatch, FC, RefObject, SetStateAction } from 'react';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../connectors/ticket';
import { IconWrapper } from '../../shared/components/IconWrapper';
import { getAttachmentUploadFormId } from '../../shared/helpers/attachment.helper';
import { scrollToElement } from '../../shared/helpers/window.helper';
import { paths } from '../../shared/routes/paths';
import { loadAccountData } from '../../shared/services/profile.service';
import {
  addAttachmentsToTicket,
  createTicket,
} from '../../shared/services/ticket.service';
import { resetCreateTicketState } from '../../shared/store/createTicketSlice';
import { useT } from '../../shared/translation/translation.provider';
import type { AttachmentForUpload } from '../../shared/types/AttachmentForUpload';
import { AssigneeChooser } from './components/AssigneeChooser';
import { AttachmentsPicker } from './components/AttachmentPicker';
import { EntityChooser } from './components/EntityChooser';
import { IssueChooser } from './components/IssueChooser';
import { QuittingConfirmationModal } from './components/QuittingConfirmationModal';
import { ReporterChooser } from './components/ReporterChooser';
import { TicketDetailFields } from './components/TicketDetailFields';
import { sxProps } from './create-ticket-screen.style';
import type { AttachmentsError, CreateTicketFieldsError } from './util';
import { verifyAttachments, validateTicketForm } from './util';

export type CreateTicketScreenErrorProps = {
  error: CreateTicketFieldsError;
  setError: Dispatch<SetStateAction<CreateTicketFieldsError>>;
};

export const CreateTicketScreen: FC = () => {
  const t = useT();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState<CreateTicketFieldsError>({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentForUpload[]>([]);

  const titleRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const priorityRef = useRef<HTMLDivElement>(null);
  const countryRef = useRef<HTMLDivElement>(null);
  const cityRef = useRef<HTMLDivElement>(null);
  const entityTypeRef = useRef<HTMLDivElement>(null);
  const entityRef = useRef<HTMLDivElement>(null);
  const issueLocationRef = useRef<HTMLDivElement>(null);
  const issueRoomRef = useRef<HTMLDivElement>(null);
  const issueTypeRef = useRef<HTMLDivElement>(null);
  const issueItemRef = useRef<HTMLDivElement>(null);
  const issueSubtypeRef = useRef<HTMLDivElement>(null);
  const isReporterMeRef = useRef<HTMLDivElement>(null);
  const reporterUnitRef = useRef<HTMLDivElement>(null);
  const reporterTenantRef = useRef<HTMLDivElement>(null);
  const isAssigneeMeRef = useRef<HTMLDivElement>(null);
  const departmentRef = useRef<HTMLDivElement>(null);
  const assigneeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loadAccountData();
  }, []);

  const getTopmostRefWithError = (
    error: CreateTicketFieldsError,
  ): RefObject<HTMLDivElement> | undefined => {
    if (error.title) return titleRef;
    if (error.description) return descriptionRef;
    if (error.priority) return priorityRef;
    if (error.country) return countryRef;
    if (error.city) return cityRef;
    if (error.entityType) return entityTypeRef;
    if (error.entity) return entityRef;
    if (error.issueLocation) return issueLocationRef;
    if (error.issueRoom) return issueRoomRef;
    if (error.issueType) return issueTypeRef;
    if (error.issueItem) return issueItemRef;
    if (error.issueSubtype) return issueSubtypeRef;
    if (error.isReporterMe) return isReporterMeRef;
    if (error.reporterUnit) return reporterUnitRef;
    if (error.reporterTenant) return reporterTenantRef;
    if (error.isAssigneeMe) return isAssigneeMeRef;
    if (error.department) return departmentRef;
    if (error.assignee) return assigneeRef;

    return undefined;
  };

  const handleError = (error: CreateTicketFieldsError): void => {
    const ref = getTopmostRefWithError(error);

    setError(error);
    if (ref) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleAttachmentsError = ({
    verifiedAttachments,
    indexError,
  }: AttachmentsError): void => {
    setAttachments(verifiedAttachments);
    scrollToElement(getAttachmentUploadFormId(indexError));
  };

  const onCancelButtonClick = (): void => {
    dispatch(resetCreateTicketState());
    setError({});
    navigate(paths.root);
  };

  const onCreateButtonClick = async (): Promise<void> => {
    let createdTicket:
      | UniversalTicketsWithAdditionalFieldsSerializerDTO
      | undefined;
    const formError = validateTicketForm();
    const attachmentError = verifyAttachments(attachments);

    if (!isEmpty(formError)) {
      handleError(formError);

      return;
    }
    if (attachmentError) {
      handleAttachmentsError(attachmentError);

      return;
    }

    try {
      createdTicket = await createTicket();
    } catch (error) {
      navigate(`${paths.createTicket}/${paths.problem}`);

      return;
    }
    if (createdTicket) {
      await Promise.all(
        attachments.map(async (a) => {
          if (createdTicket) {
            addAttachmentsToTicket(createdTicket, a);
          }
        }),
      );
      dispatch(resetCreateTicketState());
      navigate(
        // eslint-disable-next-line max-len
        `${paths.createTicket}/${paths.success}/${createdTicket.referenceNumber}`,
      );
    }
  };

  return (
    <Container sx={sxProps.createTicketScreen}>
      <Typography variant="h1" sx={sxProps.title}>
        {t('+createTicket.title')}
      </Typography>
      <TicketDetailFields
        error={error}
        setError={setError}
        descriptionRef={descriptionRef}
        titleRef={titleRef}
        priorityRef={priorityRef}
      />
      <EntityChooser
        error={error}
        setError={setError}
        cityRef={cityRef}
        countryRef={countryRef}
        entityRef={entityRef}
        entityTypeRef={entityTypeRef}
      />
      <IssueChooser
        error={error}
        setError={setError}
        issueItemRef={issueItemRef}
        issueLocationRef={issueLocationRef}
        issueRoomRef={issueRoomRef}
        issueSubtypeRef={issueSubtypeRef}
        issueTypeRef={issueTypeRef}
      />
      <ReporterChooser
        error={error}
        setError={setError}
        isReporterMeRef={isReporterMeRef}
        reporterTenantRef={reporterTenantRef}
        reporterUnitRef={reporterUnitRef}
      />
      <AssigneeChooser
        error={error}
        setError={setError}
        assigneeRef={assigneeRef}
        departmentRef={departmentRef}
        isAssigneeMeRef={isAssigneeMeRef}
      />
      <AttachmentsPicker
        error={error}
        setError={setError}
        attachments={attachments}
        setAttachments={setAttachments}
      />
      <Grid container spacing={2} sx={sxProps.buttonContainer}>
        <Grid item xs={4.5}>
          <Button
            data-test="create-ticket-button-cancel"
            startIcon=""
            variant="outlined"
            fullWidth
            sx={sxProps.button}
            onClick={() => {
              setConfirmationModalOpen(true);
            }}
          >
            <IconWrapper icon={RemoveCircleIcon} sx={sxProps.buttonIcon} />
            {t('+createTicket.buttonCancel')}
          </Button>
        </Grid>
        <Grid item xs={4.5}>
          <Button
            data-test="create-ticket-button-confirm"
            variant="contained"
            fullWidth
            sx={sxProps.button}
            onClick={onCreateButtonClick}
          >
            <IconWrapper icon={AddCircleIcon} sx={sxProps.buttonIcon} />
            {t('+createTicket.buttonCreate')}
          </Button>
        </Grid>
      </Grid>
      <QuittingConfirmationModal
        open={Boolean(confirmationModalOpen)}
        close={() => setConfirmationModalOpen(false)}
        confirm={() => {
          onCancelButtonClick();
        }}
      />
    </Container>
  );
};
