export const paths = {
  authCallback: '/auth',
  createTicket: '/create-ticket',
  problem: 'problem',
  profile: '/profile',
  root: '/',
  success: 'success',
  ticket: 'ticket',
  ticketAttachments: 'attachments',
  ticketDetails: '',
  ticketMessages: 'messages',
  welcome: 'welcome',
};
