import { ReactComponent as DashIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/subtract.svg';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useCallback, useState, useMemo } from 'react';

import { mergeSx } from '../../helpers/sx.helper';
import { globalSxProps } from '../../style';
import { useT } from '../../translation/translation.provider';
import { ConfirmationPopup } from '../ConfirmationPopup';
import type { IconType } from '../IconWrapper';
import { IconWrapper } from '../IconWrapper';
import type { InformationPopupData } from '../InformationPopup';
import { InformationPopup } from '../InformationPopup';
import { sxProps } from './modal-select.style';

export type ModalSelectOption = {
  id: string;
  labelKey: string;
  value: string;
  icon?: IconType;
};

export type ModalSelectProps = {
  options: ModalSelectOption[];
  onSelect: (item: ModalSelectOption) => void;
  value: string;
  optionIconSx?: SxProps<Theme>;
  buttonSx?: SxProps<Theme>;
  fieldname: string;
  renderAsSelect?: {
    labelKey: string;
    defaultIcon?: IconType;
    error?: string;
  };
  informationPopupData?: InformationPopupData;
  confirmationPopupData?: InformationPopupData;
  header?: { icon: IconType; titleKey: string };
};

const modalSelectInputIconId = 'modalSelectInputIconId';

export const ModalSelect: FC<ModalSelectProps> = ({
  options,
  onSelect,
  value,
  buttonSx,
  optionIconSx,
  renderAsSelect,
  fieldname,
  informationPopupData,
  confirmationPopupData,
  header,
}: ModalSelectProps) => {
  const t = useT();
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const activeItem = options.find((o) => o.value === value);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const select = useCallback(
    (o: ModalSelectOption) => {
      close();
      onSelect(o);
    },
    [close, onSelect],
  );

  const dashIcon = useMemo(() => {
    return <IconWrapper icon={DashIcon} sx={sxProps.dashIcon} />;
  }, []);

  const popupComponent = useMemo(() => {
    if (informationPopupData) {
      return (
        <InformationPopup
          open={openPopup}
          setOpen={setOpenPopup}
          informationPopupData={informationPopupData}
          onCloseCallback={() => setOpen(true)}
        />
      );
    }

    if (confirmationPopupData) {
      return (
        <ConfirmationPopup
          open={openPopup}
          setOpen={setOpenPopup}
          confirmationPopupData={confirmationPopupData}
          onConfirmCallback={() => setOpen(true)}
        />
      );
    }

    return null;
  }, [confirmationPopupData, informationPopupData, openPopup]);

  const headerComponent = useMemo(() => {
    if (!header) return null;

    const { icon, titleKey } = header;

    return (
      <Box sx={sxProps.headerBox}>
        <IconWrapper icon={icon} sx={sxProps.headerIcon} thin />
        <Typography
          variant="h3"
          sx={mergeSx(sxProps.headerTitle, globalSxProps.textBold)}
        >
          {t(titleKey)}
        </Typography>
      </Box>
    );
  }, [header, t]);

  const modal = useMemo(() => {
    return (
      <Modal open={open} sx={sxProps.modal} onClose={close}>
        <Paper
          sx={sxProps.modalContent}
          data-test={`modal-select-${fieldname}-modal-wrapper`}
        >
          {headerComponent}
          {options.map((opt) => {
            return (
              <Box
                data-test={`modal-select-${fieldname}-modal-item-${opt.id}`}
                key={opt.id}
                onClick={() => select(opt)}
                sx={sxProps.modalSelectOptionBox}
              >
                {opt.icon ? (
                  <IconWrapper
                    icon={opt.icon}
                    sx={
                      optionIconSx
                        ? mergeSx(sxProps.optionIcon, optionIconSx)
                        : sxProps.optionIcon
                    }
                  />
                ) : (
                  dashIcon
                )}
                <Typography variant="body1" sx={globalSxProps.twoLinesOverflow}>
                  {t(opt.labelKey)}
                </Typography>
              </Box>
            );
          })}
        </Paper>
      </Modal>
    );
  }, [
    close,
    dashIcon,
    fieldname,
    headerComponent,
    open,
    optionIconSx,
    options,
    select,
    t,
  ]);

  if (renderAsSelect) {
    const { labelKey, defaultIcon, error } = renderAsSelect;

    if (options.length === 0) return null;

    return (
      <Box>
        <FormControl
          fullWidth
          error={Boolean(error)}
          data-test={`modal-select-${fieldname}-form-control`}
        >
          <InputLabel id={modalSelectInputIconId}>{t(labelKey)}</InputLabel>
          <Select
            data-test={`modal-select-${fieldname}-select`}
            labelId={modalSelectInputIconId}
            value={value}
            label={t(labelKey)}
            onOpen={(e) => {
              e.preventDefault();
              if (informationPopupData || confirmationPopupData) {
                setOpenPopup(true);
              } else {
                setOpen(true);
              }
            }}
            // instead of dropdown menu, we will show modal component
            open={false}
            renderValue={(value) => {
              const selectedOption = options.find((o) => o.value === value);
              const icon = selectedOption?.icon || defaultIcon;

              return (
                <Box sx={sxProps.selectValueBox}>
                  {icon ? (
                    <IconWrapper
                      icon={icon}
                      sx={
                        optionIconSx
                          ? mergeSx(sxProps.optionIcon, optionIconSx)
                          : sxProps.optionIcon
                      }
                    />
                  ) : null}
                  {t(selectedOption?.labelKey || '')}
                </Box>
              );
            }}
          >
            {options.map((o) => {
              return (
                <MenuItem
                  value={o.value}
                  key={o.value}
                  data-test={`modal-select-${fieldname}-select-item`}
                >
                  {t(o.labelKey)}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{t(error || '')}</FormHelperText>
        </FormControl>
        {popupComponent}
        {modal}
      </Box>
    );
  }

  // how should component look when no item is selected?
  // if it is rendered only as a button (as in case of language picker),
  // then it must have an active item and an icon
  if (!activeItem?.icon) return null;

  return (
    <Box>
      <Button
        data-test={`modal-select-${fieldname}-button`}
        onClick={() => setOpen(true)}
        sx={buttonSx ? mergeSx(sxProps.button, buttonSx) : sxProps.button}
      >
        <IconWrapper sx={sxProps.button} icon={activeItem.icon} />
      </Button>
      {modal}
    </Box>
  );
};
