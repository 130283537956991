import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  homeIcon: {
    '& path, & rect, & circle, & ellipse, & line, & polyline, & polygon': {
      stroke: 'currentcolor',
      strokeWidth: '2.5px !important',
    },
    height: (theme) => theme.spacing(1.5),
    width: (theme) => theme.spacing(1.5),
  },
};
