import { ReactComponent as MessageBubbleIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-star.svg';
import { Box, Button, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useRef, useMemo, useState } from 'react';

import type {
  AttachmentSerializerDTO,
  CommentSerializerDTO,
} from '../../../../../../../connectors/ticket';
import { CommentVisibilityEnumDTO } from '../../../../../../../connectors/ticket';
import type { MeSerializerDTO } from '../../../../../../../connectors/user';
import { IconWrapper } from '../../../../../../shared/components/IconWrapper';
import { formatDate } from '../../../../../../shared/helpers/date.helper';
import { mergeSx } from '../../../../../../shared/helpers/sx.helper';
import {
  getUserFullname,
  isAuthorTenant,
} from '../../../../../../shared/helpers/user.helper';
import { useLongPress } from '../../../../../../shared/hooks/useLongPress';
import { useOutsideClickAlerter } from '../../../../../../shared/hooks/useOutsideClickeAlerter';
import { useAppDispatch } from '../../../../../../shared/store/hooks';
import { setDimmedBackground } from '../../../../../../shared/store/responseTemplate';
import { globalSxProps } from '../../../../../../shared/style';
import { useT } from '../../../../../../shared/translation/translation.provider';
import { sxProps } from './comment-wrapper.style';
import { getCommentDisplayConfig } from './util';

export type CommentWrapperProps = {
  comment: CommentSerializerDTO;
  currentUser: MeSerializerDTO;
  openAttachmentModal: (
    attachments: AttachmentSerializerDTO[],
    selectedAttachmentIndex: number,
  ) => void;
  showSaveResponseTemplatePopup: () => void;
};

export const CommentWrapper: FC<CommentWrapperProps> = ({
  comment,
  currentUser,
  openAttachmentModal,
  showSaveResponseTemplatePopup,
}: CommentWrapperProps) => {
  const t = useT();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { author, createdAt, visibility, attachments } = comment;
  const [isFoccused, setIsFoccused] = useState(false);
  const onLongPress = useLongPress({
    onLongPress: () => {
      setIsFoccused(true);
      dispatch(setDimmedBackground(true));
    },
  });

  useOutsideClickAlerter(ref, () => {
    if (isFoccused) {
      setIsFoccused(false);
      dispatch(setDimmedBackground(false));
    }
  });

  const isCurrentUserAuthorOfComment = useMemo(
    () => author.uuid === currentUser.uuid,
    [author.uuid, currentUser.uuid],
  );

  const wraperBoxSx = useMemo(() => {
    return mergeSx(
      sxProps.wrapperBox,
      isCurrentUserAuthorOfComment
        ? sxProps.wrapperBoxRight
        : sxProps.wrapperBoxLeft,
      isFoccused ? sxProps.focussedComment : {},
    );
  }, [isCurrentUserAuthorOfComment, isFoccused]);

  const dateBoxSx = useMemo(() => {
    return mergeSx(
      sxProps.dateBox,
      isCurrentUserAuthorOfComment
        ? sxProps.dateBoxAlignRight
        : sxProps.dateBoxAlignLeft,
    );
  }, [isCurrentUserAuthorOfComment]);

  const messageBox = useMemo(() => {
    const thirdPartySx =
      !isCurrentUserAuthorOfComment && !isAuthorTenant(author)
        ? sxProps.thirdPartyMessageBox
        : {};
    const internalSxProps =
      visibility === CommentVisibilityEnumDTO.Public
        ? {}
        : sxProps.internalMessageBox;
    const focussedSxProps = isFoccused ? sxProps.focussedMessageBox : {};

    return mergeSx(
      sxProps.messageBox,
      thirdPartySx,
      internalSxProps,
      focussedSxProps,
    );
  }, [visibility, author, isCurrentUserAuthorOfComment, isFoccused]);

  const authorSx = useMemo(
    () => (isFoccused ? sxProps.authorSx : {}),
    [isFoccused],
  );

  const authorLabel = useMemo(() => {
    return isCurrentUserAuthorOfComment
      ? t('+ticketView.comments.dateLabelOwner')
      : getUserFullname(author);
  }, [isCurrentUserAuthorOfComment, author, t]);

  const preparedContent = useMemo(() => {
    const commentDisplayConfig = getCommentDisplayConfig(comment);

    return (
      <Typography>
        {commentDisplayConfig.map((cdc) => (
          <Typography
            component="span"
            key={cdc.id}
            sx={cdc.mentionedUser ? sxProps.mentionStyle : undefined}
          >
            {cdc.content}
          </Typography>
        ))}
      </Typography>
    );
  }, [comment]);

  const commentAttachments = useMemo(() => {
    if (!attachments || attachments.length === 0) return null;

    return attachments.map((a, i) => (
      <Box
        key={a.uuid}
        sx={sxProps.attachment}
        component="img"
        src={a.url}
        onClick={() => openAttachmentModal(attachments, i)}
      />
    ));
  }, [attachments, openAttachmentModal]);

  const saveAsATemplateButton = useMemo(() => {
    if (!isFoccused) return null;

    return (
      <Button
        fullWidth
        sx={sxProps.saveAsATemplateButton}
        variant="contained"
        onClick={showSaveResponseTemplatePopup}
      >
        <IconWrapper
          sx={sxProps.saveAsATemplateButtonIcon}
          icon={MessageBubbleIcon}
        />
        {t('+ticketView.comments.responseTemplates.saveAsTemplateButton')}
      </Button>
    );
  }, [isFoccused, showSaveResponseTemplatePopup, t]);

  return (
    <Box
      ref={ref}
      sx={wraperBoxSx}
      data-test={`ticket-view-comments-item-${comment.uuid}`}
    >
      <Box sx={dateBoxSx}>
        <Typography component="span" variant="body2" sx={authorSx}>
          <Typography
            component="span"
            variant="body2"
            sx={mergeSx(sxProps.primaryText, globalSxProps.textBold)}
          >
            {authorLabel}
          </Typography>
          {t('+ticketView.comments.dateLabelWrote')}
        </Typography>
        <Typography
          component="span"
          variant="body2"
          sx={sxProps.primaryText}
        >{`${formatDate(createdAt, 'dd.mm.yyyy')}:`}</Typography>
      </Box>
      <Box {...onLongPress} sx={messageBox}>
        {commentAttachments}
        {preparedContent}
      </Box>
      {saveAsATemplateButton}
    </Box>
  );
};
