import { Grid } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import {
  isFilterActive,
  isFilterDisplayUnassignedSet,
  isFilterOrderSet,
  isFilterOverdueSet,
  isFilterPropertyObjectSet,
  isFilterQuerySet,
  isFilterStatusSet,
} from '../../../../../../shared/helpers/filter.helper';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { selectFilter } from '../../../../../../shared/store/ticketSlice';
import { sxProps } from './active-filters-bar.style';
import { ActiveFiltersItem } from './components/ActiveFilterItem';

export const ActiveFiltersBar: FC = () => {
  const filter = useAppSelector((state) => selectFilter(state));

  const activeQueryFilter = useMemo(() => {
    return isFilterQuerySet(filter) ? (
      <ActiveFiltersItem type="query" filter={filter} />
    ) : null;
  }, [filter]);
  const activeOrderFilter = useMemo(() => {
    return isFilterOrderSet(filter) ? (
      <ActiveFiltersItem type="order" filter={filter} />
    ) : null;
  }, [filter]);
  const activeStatusesFilter = useMemo(() => {
    return isFilterStatusSet(filter) ? (
      <ActiveFiltersItem type="statuses" filter={filter} />
    ) : null;
  }, [filter]);
  const activeOverdueFilter = useMemo(() => {
    return isFilterOverdueSet(filter) ? (
      <ActiveFiltersItem type="overdue" filter={filter} />
    ) : null;
  }, [filter]);
  const activeDisplayUnassignedFilter = useMemo(() => {
    return isFilterDisplayUnassignedSet(filter) ? (
      <ActiveFiltersItem type="displayUnassigned" filter={filter} />
    ) : null;
  }, [filter]);
  const activePropertyObjectFilter = useMemo(() => {
    return isFilterPropertyObjectSet(filter) ? (
      <ActiveFiltersItem type="propertyObject" filter={filter} />
    ) : null;
  }, [filter]);
  const resetActiveFilters = useMemo(() => {
    return isFilterActive(filter) ? (
      <ActiveFiltersItem type="reset" filter={filter} />
    ) : null;
  }, [filter]);

  const activeFiters = useMemo(
    () => [
      { component: activeOrderFilter, key: 'order' },
      { component: activeQueryFilter, key: 'query' },
      { component: activeStatusesFilter, key: 'statuses' },
      { component: activeOverdueFilter, key: 'overdue' },
      { component: activeDisplayUnassignedFilter, key: 'displayUnassigned' },
      { component: activePropertyObjectFilter, key: 'propertyObject' },
      { component: resetActiveFilters, key: 'reset' },
    ],
    [
      activeDisplayUnassignedFilter,
      activeOrderFilter,
      activeOverdueFilter,
      activePropertyObjectFilter,
      activeQueryFilter,
      activeStatusesFilter,
      resetActiveFilters,
    ],
  );

  if (!isFilterActive(filter)) return null;

  return (
    <Grid sx={sxProps.activeFiltersBar} container>
      {activeFiters.map((af) => {
        return af ? (
          <Grid item key={af.key}>
            {af.component}
          </Grid>
        ) : null;
      })}
    </Grid>
  );
};
