import { Fade, Popper, Stack, Box, Avatar, Typography } from '@mui/material';
import type { FC, MutableRefObject } from 'react';
import React from 'react';

import type { EmployeeSerializerDTO } from '../../../../../connectors/user';
import { getUserFullname } from '../../../helpers/user.helper';
import { useAppSelector } from '../../../store/hooks';
import { selectQueriedUsers } from '../../../store/userSlice';
import { popper, sxProps } from '../assignee-picker.style';

export type TypingAssigneePickerPopperProps = {
  anchorRef: MutableRefObject<null>;
  containerRef: MutableRefObject<null>;
  selectUser: (user: EmployeeSerializerDTO) => void;
};

export const TypingAssigneePickerPopper: FC<
  TypingAssigneePickerPopperProps
> = ({
  anchorRef,
  selectUser,
  containerRef,
}: TypingAssigneePickerPopperProps) => {
  const queriedUsers = useAppSelector((state) => selectQueriedUsers(state));

  return (
    <Popper
      open={queriedUsers.length > 0}
      style={popper.style}
      anchorEl={anchorRef.current}
      container={containerRef.current}
      placement="bottom"
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={200}>
          <Box
            sx={sxProps.popperBox}
            data-test="assignee-details-picker-popper"
          >
            <Stack>
              {queriedUsers.map((user) => {
                return (
                  <Box
                    data-test={`assignee-details-picker-item-${user.uuid}`}
                    sx={sxProps.popperBoxItem}
                    onClick={() => selectUser(user)}
                    key={user.uuid}
                  >
                    <Box sx={sxProps.greyIconWrapper}>
                      <Avatar
                        alt={getUserFullname(user)}
                        src={user.profilePicture}
                      />
                    </Box>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      key={user.uuid}
                      sx={sxProps.popperBoxItemTypography}
                    >
                      {getUserFullname(user)}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};
