import { TextField } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { useT } from '../../../../../../shared/translation/translation.provider';

export type TitleInputProps = {
  title: string;
  onChange: (value: string) => void;
  error?: string;
};

export const TitleInput: FC<TitleInputProps> = ({
  title,
  onChange,
  error,
}: TitleInputProps) => {
  const t = useT();

  return (
    <TextField
      data-test="ticket-create-title-input"
      placeholder={t('+createTicket.titlePlaceholder')}
      label={t('+createTicket.titleLabel')}
      fullWidth
      value={title}
      onChange={(e) => onChange(e.target.value)}
      error={Boolean(error)}
      helperText={t(error || '')}
    />
  );
};
