import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  buttonIcon: {
    '& path': {
      strokeWidth: '2px',
    },
    height: (theme) => theme.spacing(2.5),
    marginRight: (theme) => theme.spacing(1),
    width: (theme) => theme.spacing(2.5),
  },
  cancelButton: {
    marginLeft: (theme) => theme.spacing(2),
  },
  confirmButton: {
    marginRight: (theme) => theme.spacing(2),
  },
  inputField: {
    marginBottom: (theme) => theme.spacing(2),
  },

  messageButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(1, 0),
    width: '100%',
  },
  messageFormControl: {
    width: '100%',
  },
  messageTextArea: {
    marginBottom: (theme) => `-${theme.spacing(2)}`,
  },
  modal: {
    margin: '3vh 5vw 3vh 5vw',
  },
  modalContent: {
    maxHeight: '94vh',
    overflowY: 'scroll',
    position: 'relative',
  },

  modalLabel: {
    color: (theme) => theme.palette.primary.main,
  },

  modalTitle: {
    marginBottom: (theme) => theme.spacing(3),
  },
  timeInput: {
    '&:last-child': {
      marginRight: 0,
    },
    marginRight: (theme) => theme.spacing(2),
  },
  timeSpentBox: {
    display: 'flex',
    padding: (theme) => theme.spacing(2, 0),
  },
};
