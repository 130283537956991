import type { AxiosRequestConfig } from 'axios';

import {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
} from '../../../connectors/ticket';
import type { AttachmentForUpload } from '../types/AttachmentForUpload';

const ATTACHMENT_CATEGORY_KEY = 'attachment_category' as const;
const ATTACHMENT_TYPE_KEY = 'attachment_type' as const;

const getEnumValueBasedOnText = (
  text: string,
): AttachmentCategoryEnumDTO | AttachmentTypeEnumDTO | undefined => {
  const parsedText = JSON.parse(text);
  const values = { ...AttachmentCategoryEnumDTO, ...AttachmentTypeEnumDTO };

  return Object.values(values).find((value) => value === parsedText);
};

const replaceFormDataValue = async (
  data: FormData,
  blobValue: Blob | null,
  key: typeof ATTACHMENT_CATEGORY_KEY | typeof ATTACHMENT_TYPE_KEY,
): Promise<void> => {
  if (!blobValue) return;

  const text = await blobValue.text();
  const value = getEnumValueBasedOnText(text);

  if (value) {
    data.set(key, value);
  }
};

export const transformAttachmentFormData = async (
  data: FormData,
): Promise<AxiosRequestConfig['data']> => {
  const attachmentCategoryBlob = data.get(
    ATTACHMENT_CATEGORY_KEY,
  ) as Blob | null;
  const attachmentTypeBlob = data.get(ATTACHMENT_TYPE_KEY) as Blob | null;

  if (attachmentCategoryBlob) {
    await replaceFormDataValue(
      data,
      attachmentCategoryBlob,
      ATTACHMENT_CATEGORY_KEY,
    );
  }

  if (attachmentTypeBlob) {
    await replaceFormDataValue(data, attachmentTypeBlob, ATTACHMENT_TYPE_KEY);
  }

  return data;
};

// used for validation of attachments for upload, but this functionality is not used at this moment
// so parts of validation are commented.
export const verifyAttachmentForUpload = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  att: AttachmentForUpload,
): { description?: string; title?: string } | undefined => {
  // const description = !att.description ? 'error.required' : undefined;
  // const title = !att.title ? 'error.required' : undefined;

  // if (description || title) {
  //   return { description, title };
  // }

  return undefined;
};

export const getAttachmentUploadFormId = (index?: number): string =>
  `attachment-upload-form-${index?.toString() || ''}`;
