import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  CitySerializerDTO,
  CountrySerializerDTO,
  ListCitiesSerializerDTO,
  ListCountriesSerializerDTO,
} from '../../../connectors/company';
import type { RootState } from './index';

export interface CompanyState {
  countries: CountrySerializerDTO[];
  queriedCities: CitySerializerDTO[];
}

const initialState: CompanyState = { countries: [], queriedCities: [] };

/* eslint-disable no-param-reassign */
const companySlice = createSlice({
  initialState,
  name: 'comment',
  reducers: {
    clearQueriedCities: (state) => {
      state.queriedCities = [];
    },
    setCountries: (
      state,
      { payload: { results } }: PayloadAction<ListCountriesSerializerDTO>,
    ) => {
      state.countries = results;
    },
    setQueriedCities: (
      state,
      { payload: { results } }: PayloadAction<ListCitiesSerializerDTO>,
    ) => {
      state.queriedCities = results;
    },
  },
});

export const { clearQueriedCities, setCountries, setQueriedCities } =
  companySlice.actions;
export const selectCountries = (state: RootState): CountrySerializerDTO[] =>
  state.company.countries;
export const selectQueriedCities = (state: RootState): CitySerializerDTO[] =>
  state.company.queriedCities;

export default companySlice.reducer;
