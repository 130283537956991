import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  toggleButtonBox: {
    overflow: 'scroll',
  },

  toggleButtonGroup: {
    height: (theme) => theme.spacing(4),
  },

  toggleButtonSelected: {
    backgroundColor: (theme) => `${theme.palette.secondary.light} !important`,
    color: (theme) => `${theme.palette.secondary.contrastText} !important`,
  },

  toggleButtonUnselected: {
    '&:hover': {
      background: (theme) => theme.palette.grey[300],
    },
    backgroundColor: (theme) => theme.palette.grey[300],
    color: (theme) => theme.palette.grey[700],
  },
};
