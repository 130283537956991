import { Box, TextField } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import { getAttachmentUploadFormId } from '../../../helpers/attachment.helper';
import { useAppSelector } from '../../../store/hooks';
import { selectLoading } from '../../../store/loadingSlice';
import { useT } from '../../../translation/translation.provider';
import type { AttachmentForUpload } from '../../../types/AttachmentForUpload';
import { attachmentSxProps } from '../attachment.style';
import { sxProps } from './attachment-upload-form.style';

export type AttachmentUploadFormProps = {
  attachment: AttachmentForUpload;
  setAttachment: (attachment: AttachmentForUpload) => void;
  onImageClick?: () => void;
  index?: number;
};

export const AttachmentUploadForm: FC<AttachmentUploadFormProps> = ({
  setAttachment,
  attachment,
  onImageClick,
  index,
}: AttachmentUploadFormProps) => {
  const t = useT();
  const { file, description, title, descriptionError, titleError } = attachment;
  const isLoading = useAppSelector((state) => selectLoading(state));

  const onTitleChangeHandler = useCallback(
    (title) => {
      setAttachment({ ...attachment, title, titleError: undefined });
    },
    [attachment, setAttachment],
  );

  const onDesctriptionChangeHandler = useCallback(
    (description) => {
      setAttachment({
        ...attachment,
        description,
        descriptionError: undefined,
      });
    },
    [attachment, setAttachment],
  );

  const onImageClickHandler = useCallback(() => {
    if (onImageClick) onImageClick();
  }, [onImageClick]);

  const formId = useMemo(() => getAttachmentUploadFormId(index), [index]);

  return (
    <Box sx={attachmentSxProps.attachmentItemWrapper} id={formId}>
      <Box
        sx={attachmentSxProps.attachmentItemImageWrapper}
        onClick={() => onImageClickHandler()}
      >
        <img src={URL.createObjectURL(file)} alt="attachment" />
      </Box>
      <TextField
        data-test="attachment-upload-form-title-input"
        onChange={({ target: { value } }) => onTitleChangeHandler(value)}
        sx={sxProps.input}
        value={title || ''}
        fullWidth
        variant="outlined"
        label={t('shared.attachmentUpload.title')}
        error={Boolean(titleError)}
        helperText={t(titleError || '')}
        disabled={isLoading}
      />
      <TextField
        data-test="attachment-upload-form-description-input"
        onChange={({ target: { value } }) => onDesctriptionChangeHandler(value)}
        sx={sxProps.input}
        value={description || ''}
        fullWidth
        variant="outlined"
        label={t('shared.attachmentUpload.description')}
        error={Boolean(descriptionError)}
        helperText={t(descriptionError || '')}
        disabled={isLoading}
      />
    </Box>
  );
};
