import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  description: { mt: 0.5 },

  title: { mt: 2 },

  uploadedLabel: {
    color: (theme) => theme.palette.grey[500],
  },

  uploadedLabelDark: {
    color: (theme) => theme.palette.grey[700],
  },
};
