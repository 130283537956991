import type { CountrySerializerDTO } from '../../../connectors/company';
import type {
  CreateReponseTemplateSerializerDTO,
  LanguageDTO,
  ResponseTemplateSerializerDTO,
} from '../../../connectors/ticket';
import { ResponseTemplateVisibilityEnumDTO } from '../../../connectors/ticket';

export const textToCreateResponseTemplateDto = (
  content: string,
  language: LanguageDTO,
): CreateReponseTemplateSerializerDTO => {
  return {
    content,
    language,
    name: '',
    visibility: ResponseTemplateVisibilityEnumDTO.Private,
  };
};

export const textToResponseTemplateDto = (
  content: string,
  language: LanguageDTO,
  country: CountrySerializerDTO,
): ResponseTemplateSerializerDTO => {
  const createRT = textToCreateResponseTemplateDto(content, language);

  return {
    ...createRT,
    authorUuid: '',
    countryUuid: country.uuid,
    uuid: country.uuid,
  };
};
