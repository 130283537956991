import { Box } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useParams } from 'react-router';

import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../connectors/ticket';
import { paths } from '../../shared/routes/paths';
import { loadTicketByReferenceNumber } from '../../shared/services/ticket.service';
import { useAppSelector } from '../../shared/store/hooks';
import { selectTicketByKey } from '../../shared/store/ticketSlice';
import { TicketViewNavbar } from './components/TicketViewNavbar';
import { sxProps } from './ticket-view-screen.style';
import { TicketContextProvider } from './ticket.context';

type TicketByReferenceNumberResult = {
  seachCompleted: boolean;
  ticket?: UniversalTicketsWithAdditionalFieldsSerializerDTO;
};

export const TicketViewScreen: FC = () => {
  const { ticketKey } = useParams();
  const [ticketByReferenceNumberResult, setTicketByReferenceNumberResult] =
    useState<TicketByReferenceNumberResult>({
      seachCompleted: false,
    });
  const ticketFromStore = useAppSelector((store) =>
    selectTicketByKey(store, ticketKey),
  );

  useEffect(() => {
    if (!ticketFromStore && ticketKey) {
      loadTicketByReferenceNumber(ticketKey).then((ticket) => {
        setTicketByReferenceNumberResult({
          seachCompleted: true,
          ticket,
        });
      });
    }
  }, [ticketFromStore, ticketKey]);

  const ticket = ticketFromStore || ticketByReferenceNumberResult.ticket;

  // if there is no ticket in store with this ticket key
  // and no tickets was fetched by reference number
  if (!ticket) {
    // if search by reference number is not completed return null,
    // and page is still loading
    if (!ticketByReferenceNumberResult.seachCompleted) return null;

    // otherwise there is not ticket to be loaded here, and navigate to home page
    return <Navigate to={paths.root} />;
  }

  return (
    <Box sx={sxProps.ticketViewWrapper}>
      <TicketContextProvider ticket={ticket}>
        <TicketViewNavbar />
        <Outlet />
      </TicketContextProvider>
    </Box>
  );
};
