import { Box, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { getUserFullname } from '../../../../../../shared/helpers/user.helper';
import { useT } from '../../../../../../shared/translation/translation.provider';
import { useTicket } from '../../../../ticket.context';
import { DetailInformation } from './components/DetailInformation';
import { DetailInformationLink } from './components/DetailInformationLink';
import { sxProps } from './ticket-details-information.style';
import type { DetailInformationLinkData, DetailInformationData } from './util';
import {
  getCustomerFields,
  getCustomerLinks,
  getDatesFields,
  getIssueFields,
} from './util';

export const TicketDetailsInformation: FC = () => {
  const ticket = useTicket();
  const t = useT();

  const dateFields = useMemo<DetailInformationData[]>(
    () => getDatesFields(ticket),
    [ticket],
  );
  const issueFields = useMemo<DetailInformationData[]>(
    () => getIssueFields(ticket, t),
    [ticket, t],
  );
  const customerFields = useMemo<DetailInformationData[]>(
    () => getCustomerFields(ticket),
    [ticket],
  );
  const customerLinks = useMemo<DetailInformationLinkData[]>(
    () => getCustomerLinks(ticket),
    [ticket],
  );

  return (
    <Box sx={sxProps.detailInformationsBox}>
      {[...dateFields, ...issueFields].map((field) => {
        return <DetailInformation {...field} key={field.key} />;
      })}
      <Box sx={sxProps.detailInformationDescriptionBox}>
        <Typography variant="overline" sx={sxProps.detailInformationHeader}>
          {t('+ticketView.details.description')}
        </Typography>
      </Box>
      <Box>
        <Typography>{ticket.description}</Typography>
      </Box>
      <Box sx={sxProps.detailInformationCustomerBox}>
        <Typography variant="overline" sx={sxProps.detailInformationHeader}>
          {t('+ticketView.details.customerInfo')}
        </Typography>
        <Box sx={sxProps.detailInformationCustomerTitleBox}>
          <Typography variant="h1">
            {getUserFullname(ticket.reporter)}
          </Typography>
        </Box>
        <Box sx={sxProps.detailInformationCustomerButtonsBox}>
          <Stack>
            {customerLinks.map((link) => {
              return <DetailInformationLink {...link} key={link.value} />;
            })}
          </Stack>
        </Box>
        <Box sx={sxProps.detailInformationCustomerDetailsBox}>
          {customerFields.map((field) => {
            return <DetailInformation {...field} key={field.key} />;
          })}
        </Box>
      </Box>
    </Box>
  );
};
