import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useMemo } from 'react';

import type { TicketStatusEnumDTO } from '../../../../../../../../../connectors/ticket';
import { useT } from '../../../../../../../../shared/translation/translation.provider';
import type { Filter } from '../../../../../../../../shared/types/Filter';
import { sxProps } from './filter-status.style';
import { statusCheckboxes } from './util';

export type FilterStatusProps = {
  filter: Filter;
  setFilter: Dispatch<SetStateAction<Filter>>;
};

export const FilterStatus: FC<FilterStatusProps> = ({
  filter,
  setFilter,
}: FilterStatusProps) => {
  const t = useT();

  const isChecked = useCallback(
    (status: TicketStatusEnumDTO): boolean => {
      return Boolean(filter.status?.find((s) => s === status));
    },
    [filter.status],
  );

  const onChange = useCallback(
    (status: TicketStatusEnumDTO): void => {
      const statuses = filter.status || [];

      if (isChecked(status)) {
        setFilter({ ...filter, status: statuses.filter((s) => s !== status) });
      } else {
        setFilter({ ...filter, status: [...statuses, status] });
      }
    },
    [filter, isChecked, setFilter],
  );

  const checkboxes = useMemo(() => {
    return statusCheckboxes.map(({ status, labelKey, sx }) => {
      return (
        <FormControlLabel
          data-test={`ticket-filter-modal-status-checkbox-label-${status}`}
          key={status}
          control={
            <Checkbox
              checked={isChecked(status)}
              sx={sx}
              data-test={`ticket-filter-modal-status-checkbox-${status}`}
            />
          }
          label={t(labelKey) as string}
          componentsProps={{ typography: { variant: 'button' } }}
          sx={sxProps.checkbox}
          onChange={() => onChange(status)}
        />
      );
    });
  }, [isChecked, onChange, t]);

  return (
    <Box>
      <Typography variant="overline" sx={sxProps.label}>
        {t('+ticketListScreen.filter.modal.statusLabel')}
      </Typography>
      <FormGroup
        sx={sxProps.formGroup}
        data-test="ticket-filter-modal-status-checkbox-group"
      >
        {checkboxes}
      </FormGroup>
    </Box>
  );
};
