import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  chip: {
    '&.MuiChip-root': {
      border: (theme) => `1.5px solid ${theme.palette.primary.main}`,
      borderRadius: (theme) => theme.spacing(1.5),
    },
    background: (theme) => theme.palette.common.white,
    m: 0.5,
    pb: 2.5,
    pt: 2.5,
  },

  moreTemplatesChip: {
    color: (theme) => theme.palette.primary.main,
    justifyContent: 'flex-start',
    width: '100%',
  },

  moreTemplatesIcon: {
    height: (theme) => theme.spacing(3),
    ml: 1.5,
    width: (theme) => theme.spacing(3),
  },
};
