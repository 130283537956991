import axios from 'axios';

import { HttpStatus } from '../http';

export const getErrorStatus = (error: unknown): HttpStatus => {
  if (axios.isAxiosError(error) && error.response)
    return error.response.status as HttpStatus;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (error.status) return error.status as HttpStatus;

  return HttpStatus.INTERNAL_ERROR;
};
