import { ReactComponent as DepartmentIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geometric-full-body-multiple-users/multiple-circle.svg';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import type { DepartmentTypeEnumDTO } from '../../../../connectors/ticket';
import { ModalSelect } from '../ModalSelect';
import { getDepartmentOptions } from './util';

export type DepartmentPickerProps = {
  onChange: (department: DepartmentTypeEnumDTO) => void;
  department?: DepartmentTypeEnumDTO;
  isHidden?: boolean;
  showConfirmationPopup?: boolean;
};

export const DepartmentPicker: FC<DepartmentPickerProps> = ({
  onChange,
  department,
  isHidden,
  showConfirmationPopup,
}: DepartmentPickerProps) => {
  const confirmationPopup = useMemo(
    () =>
      showConfirmationPopup
        ? {
            icon: DepartmentIcon,
            text1Key: 'shared.departmentPicker.confirmationPopup.text1',
            text2Key: 'shared.departmentPicker.confirmationPopup.text2',
            titleKey: 'shared.departmentPicker.confirmationPopup.title',
          }
        : undefined,
    [showConfirmationPopup],
  );

  if (isHidden) return null;

  return (
    <ModalSelect
      fieldname="department"
      value={department || ''}
      onSelect={(item) => onChange(item.value as DepartmentTypeEnumDTO)}
      options={getDepartmentOptions()}
      renderAsSelect={{
        labelKey: 'shared.departmentPicker.label',
      }}
      confirmationPopupData={confirmationPopup}
      header={{
        icon: DepartmentIcon,
        titleKey: 'shared.departmentPicker.selectHeaderTitle',
      }}
    />
  );
};
