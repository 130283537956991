import { Box } from '@mui/material';
import type { Theme } from '@mui/material';
import type { SxProps } from '@mui/system';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import type { FC, ReactNode } from 'react';
import React, { useEffect, useMemo } from 'react';

import type { CoordinatesSerializerDTO } from '../../../../connectors/property';
import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../../connectors/ticket';
import { getEnvValue } from '../../helpers/env.helper';
import { mergeSx } from '../../helpers/sx.helper';
import { getEntityForTicket } from '../../services/property.service';
import { useAppSelector } from '../../store/hooks';
import { selectEntityForTicket } from '../../store/propertySlice';
import { NavigateButton } from './components/NavigateButton';
import { sxProps } from './show-location-map.style';

export type ShowLocationMapType = {
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO;
  containerSx: SxProps<Theme>;
};

export const ShowLocationMap: FC<ShowLocationMapType> = ({
  ticket,
  containerSx,
}: ShowLocationMapType) => {
  const entity = useAppSelector((state) =>
    selectEntityForTicket(state, ticket),
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: getEnvValue('GOOGLE_API_KEY'),
    id: 'google-map-script',
  });

  const center = useMemo<CoordinatesSerializerDTO | undefined>(() => {
    if (!entity) return undefined;

    const { latitude, longitude, uuid, name } = entity;

    return { latitude, longitude, name, uuid };
  }, [entity]);

  useEffect(() => {
    if (!entity) {
      getEntityForTicket(ticket);
    }
  }, [ticket, entity]);

  const marker = useMemo<ReactNode>(() => {
    if (!center) return null;

    return (
      <Marker position={{ lat: center.latitude, lng: center.longitude }} />
    );
  }, [center]);

  const navigateButton = useMemo<ReactNode>(() => {
    if (!center) return null;

    return (
      <NavigateButton
        labelKey="+ticketView.details.actions.navigate"
        buttonSx={sxProps.navigateButton}
        coordinates={center}
      />
    );
  }, [center]);

  if (!isLoaded || !center) return null;

  return (
    <Box sx={mergeSx(sxProps.mapContainer, containerSx)}>
      <GoogleMap
        mapContainerStyle={{ height: '100%', width: '100%' }}
        center={{ lat: center.latitude, lng: center.longitude }}
        zoom={14}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false,
        }}
      >
        {marker}
      </GoogleMap>
      {navigateButton}
    </Box>
  );
};
