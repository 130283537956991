import React, { createContext, useContext } from 'react';
import type { ReactNode } from 'react';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';

interface TranslationProviderProps {
  children?: ReactNode;
}

const TranslationContext = createContext<{ t?: TFunction }>({});

export const useT = (): TFunction<'translation', undefined> => {
  const { t } = useContext(TranslationContext);

  if (!t) {
    throw new Error('Unable to access translations');
  }

  return t;
};

export const TranslationProvider = ({
  children,
}: TranslationProviderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <TranslationContext.Provider value={{ t }}>
      {children}
    </TranslationContext.Provider>
  );
};
