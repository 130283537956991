import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  chip: {
    margin: (theme) => theme.spacing(0, 0.75),
  },

  chipBorder: {
    '&.MuiChip-root': {
      border: (theme) => `2px solid ${theme.palette.secondary.main}`,
      borderRadius: (theme) => theme.spacing(1.5),
    },
  },

  chipBorderReset: {
    '&.MuiChip-root': {
      border: (theme) => `2px solid ${theme.palette.primary.main}`,
      borderRadius: (theme) => theme.spacing(1.5),
    },
  },

  removeIcon: {
    height: (theme) => theme.spacing(2),
    marginRight: (theme) => theme.spacing(1.5),
    width: (theme) => theme.spacing(2),
  },
};
