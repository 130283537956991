import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  counter: {
    borderRadius: (theme) => theme.spacing(1),
    fontSize: (theme) => theme.typography.button.fontSize,
    fontWeight: (theme) => theme.typography.button.fontWeight,
    height: (theme) => theme.spacing(3),
    marginLeft: (theme) => theme.spacing(1),
    textAlign: 'center',
    width: (theme) => theme.spacing(3),
  },

  counterActive: {
    borderRadius: 0,
  },

  counterprimary: {
    background: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.primary.contrastText,
  },

  countersecondary: {
    background: (theme) => theme.palette.secondary.main,
    color: (theme) => theme.palette.primary.contrastText,
  },

  navbarButtonActive: {
    background: (theme) => theme.palette.primary.contrastText,
    color: (theme) => theme.palette.text.primary,
  },

  navbarButtonBox: {
    alignItems: 'center',
    borderRadius: (theme) => theme.spacing(1),
    color: (theme) => theme.palette.primary.contrastText,
    display: 'flex',
    height: (theme) => theme.spacing(3),
    justifyContent: 'center',
    overflow: 'hidden',
    padding: (theme) => theme.spacing(0, 1),
  },

  navbarButtonBoxCounter: {
    paddingLeft: (theme) => theme.spacing(1),
    paddingRight: 0,
  },
};
