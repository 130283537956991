import { Container, Grid } from '@mui/material';
import type { FC, ReactNode } from 'react';
import React from 'react';

import { sxProps } from './image-background.style';

export interface ImageBackgroundProps {
  children?: ReactNode;
}

// This component is background for welcome and login screen
export const ImageBackground: FC<ImageBackgroundProps> = ({
  children,
}: ImageBackgroundProps) => {
  return (
    <Container maxWidth="md" sx={sxProps.imageBackgroundContainer}>
      <Grid
        sx={sxProps.imageBackgroundGrid}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container item xs={6} alignItems="center" justifyContent="center">
          <Grid item xs={11}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
