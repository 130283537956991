import { ReactComponent as DeleteCircle } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/delete/delete-1.svg';
import { ReactComponent as CheckCircle } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/check-circle-1.svg';
import { Button, Card, CardActions, CardContent, Modal } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import { IconWrapper } from '../../../../../../shared/components/IconWrapper';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { selectFilter } from '../../../../../../shared/store/ticketSlice';
import { useT } from '../../../../../../shared/translation/translation.provider';
import type { Filter } from '../../../../../../shared/types/Filter';
import { FilterAdditional } from './components/FilterAdditional';
import { FilterOrder } from './components/FilterOrder';
import { FilterStatus } from './components/FilterStatus';
import { sxProps } from './filter-modal.style';

export type FilterModalProps = {
  open: boolean;
  confirm: (filter: Filter) => void;
  cancel: () => void;
};

export const FilterModal: FC<FilterModalProps> = ({
  open,
  confirm,
  cancel,
}: FilterModalProps) => {
  const t = useT();
  const filterAppState = useAppSelector((state) => selectFilter(state));
  const [filter, setFilter] = useState<Filter>(filterAppState);

  useEffect(() => {
    setFilter(filterAppState);
  }, [filterAppState]);

  const cancelModal = (): void => {
    setFilter(filterAppState);
    cancel();
  };

  return (
    <Modal open={open} sx={sxProps.modal}>
      <Card sx={sxProps.modalContent}>
        <CardContent>
          <FilterStatus filter={filter} setFilter={setFilter} />
          <FilterOrder filter={filter} setFilter={setFilter} />
          <FilterAdditional filter={filter} setFilter={setFilter} />
        </CardContent>
        <CardActions>
          <Button
            data-test="ticket-filter-modal-cance-button"
            fullWidth
            variant="outlined"
            sx={sxProps.cancelButton}
            onClick={cancelModal}
          >
            <IconWrapper icon={DeleteCircle} sx={sxProps.buttonIcon} />
            {t(`+ticketListScreen.filter.modal.cancelButton`)}
          </Button>
          <Button
            data-test="ticket-filter-modal-confirm-button"
            fullWidth
            variant="contained"
            sx={sxProps.confirmButton}
            onClick={() => confirm(filter)}
          >
            <IconWrapper icon={CheckCircle} sx={sxProps.buttonIcon} />
            {t(`+ticketView.details.statusUpdateForm.confirmButton`)}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
