import { ReactComponent as ArrowDownIcon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-down-1.svg';
import { ReactComponent as LockIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/lock-unlock/lock-6.svg';
import { ReactComponent as TouchFingerLock } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/lock-unlock/touch-finger-lock.svg';
import type { SelectChangeEvent, Theme } from '@mui/material';
import { Divider, MenuItem, Select } from '@mui/material';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC, MouseEvent, ReactNode } from 'react';
import React, { useState } from 'react';

import type {
  TicketMetadataSerializerDTO,
  TicketStatusEnumDTO,
} from '../../../../../../connectors/ticket';
import { mergeSx } from '../../../../helpers/sx.helper';
import { useT } from '../../../../translation/translation.provider';
import { IconWrapper } from '../../../IconWrapper';
import { InformationPopup } from '../../../InformationPopup';
import { sxProps } from '../../select-status.style';
import type { SelectMenuItem } from '../../util';
import { statusSelectPickerItems } from '../../util';
import { StatusMenuItem } from '../StatusMenuItem';

export type SelectStatusPickerProps = {
  selectedStatus: TicketStatusEnumDTO;
  setSelectedStatus: (status: TicketStatusEnumDTO) => void;
  disabled: boolean;
  locked: boolean;
  alignListSx?: SxProps<Theme>;
  metadata: TicketMetadataSerializerDTO;
};

export const SelectStatusPicker: FC<SelectStatusPickerProps> = ({
  disabled,
  setSelectedStatus,
  selectedStatus,
  alignListSx,
  metadata,
  locked,
}: SelectStatusPickerProps) => {
  const t = useT();
  const [open, setOpen] = useState<boolean>(false);
  const [lockedPickerModalOpen, setLockedPickerModalOpen] =
    useState<boolean>(false);

  const handleChange = (e: SelectChangeEvent<number>): void => {
    const index = e.target.value;
    const newStatus = statusSelectPickerItems[index as number]?.status;

    if (newStatus) setSelectedStatus(newStatus);
  };

  const getMenuItemFromStatus = (): {
    index: number;
    menuItem: SelectMenuItem;
  } => {
    const index = statusSelectPickerItems.findIndex(
      (item) => item.status === selectedStatus,
    );

    return { index, menuItem: statusSelectPickerItems[index] };
  };

  const renderSelectMenu = (): ReactNode[] => {
    let selectMenu: ReactNode[] = [];

    statusSelectPickerItems.forEach(({ status, labelKey }, i) => {
      // if select component is status changer, we only want to render menu items,
      // that represent status (and not All tickets from dashboard select component)
      const isSelected = status === selectedStatus;
      const availableStatuses = metadata.statuses[selectedStatus];
      const isAvailable = availableStatuses?.find(
        (availableS) => availableS === status,
      );

      const isHidden = !isAvailable || isSelected;

      selectMenu = [
        ...selectMenu,
        <MenuItem
          data-test={`select-status-picker-menu-item-${i}`}
          value={i}
          key={`select-menu-picker-${i * 2}`}
          sx={isHidden ? sxProps.hiddenItem : sxProps.menuItem}
        >
          <StatusMenuItem
            label={t(labelKey)}
            status={status}
            selected={isSelected}
            showCircles={!isSelected}
          />
        </MenuItem>,
        <Divider
          orientation="horizontal"
          variant="middle"
          key={`select-menu-picker-${i * 2 + 1}`}
          sx={isHidden ? sxProps.hiddenItem : sxProps.divider}
        />,
      ];
    });

    return selectMenu;
  };

  // need to handle opening and closing programatically
  // because part of app is not clickable, and disable
  // click when divider is cliced
  const onClick = (e: MouseEvent<HTMLDivElement>): void => {
    if (
      !disabled &&
      e.target instanceof Element &&
      !e.target.classList.contains('MuiDivider-root')
    ) {
      setOpen(!open);
    }
    if (locked) {
      setLockedPickerModalOpen(true);
    }
  };

  const icon = (
    <IconWrapper
      sx={sxProps.selectIcon}
      icon={locked ? LockIcon : ArrowDownIcon}
    />
  );

  if (!selectedStatus) return null;

  return (
    <>
      <Select
        data-test="select-status-picker"
        onClick={onClick}
        variant="filled"
        MenuProps={{
          sx: mergeSx(sxProps.statusSelectMenu, alignListSx || {}),
        }}
        IconComponent={() => icon}
        inputProps={{
          sx: sxProps.statusSelectInput,
        }}
        sx={mergeSx(
          sxProps.statusSelectColored,
          getMenuItemFromStatus().menuItem.sx,
        )}
        disableUnderline
        value={getMenuItemFromStatus().index}
        onChange={handleChange}
        open={open}
      >
        {renderSelectMenu()}
      </Select>
      <InformationPopup
        open={lockedPickerModalOpen}
        setOpen={setLockedPickerModalOpen}
        informationPopupData={{
          icon: TouchFingerLock,
          text1Key: '+ticketView.details.actions.statusSelect.locked.text1',
          text2Key: '+ticketView.details.actions.statusSelect.locked.text2',
          titleKey: '+ticketView.details.actions.statusSelect.locked.title',
        }}
      />
    </>
  );
};
