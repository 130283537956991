import { Box, Grid } from '@mui/material';
import type { FC, ReactNode } from 'react';
import React from 'react';

import type { MeSerializerDTO } from '../../../../../connectors/user';
import { sxProps } from './profile-image.style';

export type ProfileImageProps = { profile: MeSerializerDTO };

export const ProfileImage: FC<ProfileImageProps> = ({
  profile: { profilePicture },
}: ProfileImageProps) => {
  const renderImgComponent = (): ReactNode => {
    return (
      <Box
        component="img"
        src={profilePicture || 'avatar.png'}
        sx={sxProps.profileImage}
      />
    );
  };

  return (
    <Box sx={sxProps.profileImageBox}>
      <Grid container spacing={3}>
        <Grid item xs />
        <Grid item xs={6}>
          {renderImgComponent()}
        </Grid>
        <Grid item xs />
      </Grid>
    </Box>
  );
};
