/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseUserSerializerDTO } from '../dto';
// @ts-ignore
import { DepartmentTypeEnumDTO } from '../dto';
// @ts-ignore
import { DetailedUserSerializerDTO } from '../dto';
// @ts-ignore
import { EntityEnumDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { LanguageDTO } from '../dto';
// @ts-ignore
import { ListCreateUserConsentSerializerDTO } from '../dto';
// @ts-ignore
import { ListEmployeeSerializerDTO } from '../dto';
// @ts-ignore
import { ListLogSerializerDTO } from '../dto';
// @ts-ignore
import { ListNationalitySerializerDTO } from '../dto';
// @ts-ignore
import { ListTermsAndConditionsSerializerDTO } from '../dto';
// @ts-ignore
import { ListUserSerializerDTO } from '../dto';
// @ts-ignore
import { PatchUserSerializerDTO } from '../dto';
// @ts-ignore
import { ServiceEnumDTO } from '../dto';
// @ts-ignore
import { UserConsentResponseSerializerDTO } from '../dto';
// @ts-ignore
import { UserConsentSerializerDTO } from '../dto';
// @ts-ignore
import { UserListLogSerializerDTO } from '../dto';
// @ts-ignore
import { UserSerializerDTO } from '../dto';
// @ts-ignore
import { ValidateUserAccountDTO } from '../dto';
/**
 * UsersAPI - axios parameter creator
 * @export
 */
export const UsersAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User Consents
         * @param {ListCreateUserConsentSerializerDTO} listCreateUserConsentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserConsentsUsersUserConsentsPost: async (listCreateUserConsentSerializerDTO: ListCreateUserConsentSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'listCreateUserConsentSerializerDTO' is not null or undefined
            assertParamExists('createUserConsentsUsersUserConsentsPost', 'listCreateUserConsentSerializerDTO', listCreateUserConsentSerializerDTO)
            const localVarPath = `/users/user_consents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Base64Auth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listCreateUserConsentSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User
         * @param {BaseUserSerializerDTO} baseUserSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUsersPost: async (baseUserSerializerDTO: BaseUserSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseUserSerializerDTO' is not null or undefined
            assertParamExists('createUserUsersPost', 'baseUserSerializerDTO', baseUserSerializerDTO)
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseUserSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsersUserUuidDelete: async (userUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('deleteUserUsersUserUuidDelete', 'userUuid', userUuid)
            const localVarPath = `/users/{user_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Auditlogs For User
         * @param {string} userUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {ServiceEnumDTO} [service] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {string} [entityUuid] 
         * @param {string} [excludeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuditlogsForUserUsersUserUuidAuditlogsGet: async (userUuid: string, page?: number, pageSize?: number, order?: string, search?: string, service?: ServiceEnumDTO, entityType?: EntityEnumDTO, entityUuid?: string, excludeFields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('getAllAuditlogsForUserUsersUserUuidAuditlogsGet', 'userUuid', userUuid)
            const localVarPath = `/users/{user_uuid}/auditlogs`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (service !== undefined) {
                localVarQueryParameter['service'] = service;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (entityUuid !== undefined) {
                localVarQueryParameter['entity_uuid'] = entityUuid;
            }

            if (excludeFields !== undefined) {
                localVarQueryParameter['exclude_fields'] = excludeFields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Auditlogs
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {ServiceEnumDTO} [service] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {string} [entityUuid] 
         * @param {string} [excludeFields] 
         * @param {string} [userUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditlogsUsersAuditlogsGet: async (page?: number, pageSize?: number, order?: string, search?: string, service?: ServiceEnumDTO, entityType?: EntityEnumDTO, entityUuid?: string, excludeFields?: string, userUuid?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/auditlogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (service !== undefined) {
                localVarQueryParameter['service'] = service;
            }

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (entityUuid !== undefined) {
                localVarQueryParameter['entity_uuid'] = entityUuid;
            }

            if (excludeFields !== undefined) {
                localVarQueryParameter['exclude_fields'] = excludeFields;
            }

            if (userUuid !== undefined) {
                localVarQueryParameter['user_uuid'] = userUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Entity Auditlogs For User
         * @param {string} userUuid 
         * @param {ServiceEnumDTO} service 
         * @param {EntityEnumDTO} entityType 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAuditlogsForUserUsersUserUuidServiceAuditlogsGet: async (userUuid: string, service: ServiceEnumDTO, entityType: EntityEnumDTO, excludeFields?: string, page?: number, pageSize?: number, order?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('getEntityAuditlogsForUserUsersUserUuidServiceAuditlogsGet', 'userUuid', userUuid)
            // verify required parameter 'service' is not null or undefined
            assertParamExists('getEntityAuditlogsForUserUsersUserUuidServiceAuditlogsGet', 'service', service)
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getEntityAuditlogsForUserUsersUserUuidServiceAuditlogsGet', 'entityType', entityType)
            const localVarPath = `/users/{user_uuid}/{service}/auditlogs`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)))
                .replace(`{${"service"}}`, encodeURIComponent(String(service)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (entityType !== undefined) {
                localVarQueryParameter['entity_type'] = entityType;
            }

            if (excludeFields !== undefined) {
                localVarQueryParameter['exclude_fields'] = excludeFields;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Nationalities
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNationalitiesUsersNationalityGet: async (search?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/nationality`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Terms And Conditions
         * @param {string} countryUuid 
         * @param {string} [search] 
         * @param {LanguageDTO} [language] 
         * @param {string} [consentType] 
         * @param {string} [name] 
         * @param {string} [consentTypeIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsAndConditionsUsersTermsAndConditionsCountryUuidGet: async (countryUuid: string, search?: string, language?: LanguageDTO, consentType?: string, name?: string, consentTypeIn?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryUuid' is not null or undefined
            assertParamExists('getTermsAndConditionsUsersTermsAndConditionsCountryUuidGet', 'countryUuid', countryUuid)
            const localVarPath = `/users/terms_and_conditions/{country_uuid}`
                .replace(`{${"country_uuid"}}`, encodeURIComponent(String(countryUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Base64Auth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (consentType !== undefined) {
                localVarQueryParameter['consent_type'] = consentType;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (consentTypeIn !== undefined) {
                localVarQueryParameter['consent_type_in'] = consentTypeIn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Consents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserConsentsUsersUserConsentsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user_consents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User
         * @param {string} userUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [phoneNumber] 
         * @param {string} [email] 
         * @param {string} [authExternalId] 
         * @param {string} [authClientId] 
         * @param {string} [userType] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {boolean} [activeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsersUserUuidGet: async (userUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('getUserUsersUserUuidGet', 'userUuid', userUuid)
            const localVarPath = `/users/{user_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['first_name'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['last_name'] = lastName;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phone_number'] = phoneNumber;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (authExternalId !== undefined) {
                localVarQueryParameter['auth_external_id'] = authExternalId;
            }

            if (authClientId !== undefined) {
                localVarQueryParameter['auth_client_id'] = authClientId;
            }

            if (userType !== undefined) {
                localVarQueryParameter['user_type'] = userType;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (excludeRole !== undefined) {
                localVarQueryParameter['exclude_role'] = excludeRole;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (activeUsers !== undefined) {
                localVarQueryParameter['active_users'] = activeUsers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [phoneNumber] 
         * @param {string} [email] 
         * @param {string} [authExternalId] 
         * @param {string} [authClientId] 
         * @param {string} [userType] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {boolean} [activeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUsersGet: async (page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['first_name'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['last_name'] = lastName;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phone_number'] = phoneNumber;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (authExternalId !== undefined) {
                localVarQueryParameter['auth_external_id'] = authExternalId;
            }

            if (authClientId !== undefined) {
                localVarQueryParameter['auth_client_id'] = authClientId;
            }

            if (userType !== undefined) {
                localVarQueryParameter['user_type'] = userType;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (excludeRole !== undefined) {
                localVarQueryParameter['exclude_role'] = excludeRole;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (activeUsers !== undefined) {
                localVarQueryParameter['active_users'] = activeUsers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch User
         * @param {string} userUuid 
         * @param {PatchUserSerializerDTO} patchUserSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserUsersUserUuidPatch: async (userUuid: string, patchUserSerializerDTO: PatchUserSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('patchUserUsersUserUuidPatch', 'userUuid', userUuid)
            // verify required parameter 'patchUserSerializerDTO' is not null or undefined
            assertParamExists('patchUserUsersUserUuidPatch', 'patchUserSerializerDTO', patchUserSerializerDTO)
            const localVarPath = `/users/{user_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUserSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put User
         * @param {string} userUuid 
         * @param {BaseUserSerializerDTO} baseUserSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserUsersUserUuidPut: async (userUuid: string, baseUserSerializerDTO: BaseUserSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUuid' is not null or undefined
            assertParamExists('putUserUsersUserUuidPut', 'userUuid', userUuid)
            // verify required parameter 'baseUserSerializerDTO' is not null or undefined
            assertParamExists('putUserUsersUserUuidPut', 'baseUserSerializerDTO', baseUserSerializerDTO)
            const localVarPath = `/users/{user_uuid}`
                .replace(`{${"user_uuid"}}`, encodeURIComponent(String(userUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseUserSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Employee
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [phoneNumber] 
         * @param {string} [email] 
         * @param {string} [authExternalId] 
         * @param {string} [authClientId] 
         * @param {string} [userType] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {boolean} [activeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEmployeeUsersSearchEmployeeGet: async (page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/search-employee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['first_name'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['last_name'] = lastName;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phone_number'] = phoneNumber;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (authExternalId !== undefined) {
                localVarQueryParameter['auth_external_id'] = authExternalId;
            }

            if (authClientId !== undefined) {
                localVarQueryParameter['auth_client_id'] = authClientId;
            }

            if (userType !== undefined) {
                localVarQueryParameter['user_type'] = userType;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (excludeRole !== undefined) {
                localVarQueryParameter['exclude_role'] = excludeRole;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (department !== undefined) {
                localVarQueryParameter['department'] = department;
            }

            if (activeUsers !== undefined) {
                localVarQueryParameter['active_users'] = activeUsers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Consents
         * @param {UserConsentSerializerDTO} userConsentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserConsentsUsersUserConsentsPatch: async (userConsentSerializerDTO: UserConsentSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userConsentSerializerDTO' is not null or undefined
            assertParamExists('updateUserConsentsUsersUserConsentsPatch', 'userConsentSerializerDTO', userConsentSerializerDTO)
            const localVarPath = `/users/user_consents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userConsentSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateImportDataFormatUsersDataImportPost: async (file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('validateImportDataFormatUsersDataImportPost', 'file', file)
            const localVarPath = `/users/data_import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate User Account
         * @param {ValidateUserAccountDTO} validateUserAccountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUserAccountUsersValidateAccountPost: async (validateUserAccountDTO: ValidateUserAccountDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateUserAccountDTO' is not null or undefined
            assertParamExists('validateUserAccountUsersValidateAccountPost', 'validateUserAccountDTO', validateUserAccountDTO)
            const localVarPath = `/users/validate_account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Base64Auth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateUserAccountDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersAPI - functional programming interface
 * @export
 */
export const UsersAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create User Consents
         * @param {ListCreateUserConsentSerializerDTO} listCreateUserConsentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserConsentsUsersUserConsentsPost(listCreateUserConsentSerializerDTO: ListCreateUserConsentSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserConsentResponseSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserConsentsUsersUserConsentsPost(listCreateUserConsentSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create User
         * @param {BaseUserSerializerDTO} baseUserSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserUsersPost(baseUserSerializerDTO: BaseUserSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserUsersPost(baseUserSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete User
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserUsersUserUuidDelete(userUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserUsersUserUuidDelete(userUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get All Auditlogs For User
         * @param {string} userUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {ServiceEnumDTO} [service] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {string} [entityUuid] 
         * @param {string} [excludeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAuditlogsForUserUsersUserUuidAuditlogsGet(userUuid: string, page?: number, pageSize?: number, order?: string, search?: string, service?: ServiceEnumDTO, entityType?: EntityEnumDTO, entityUuid?: string, excludeFields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListLogSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAuditlogsForUserUsersUserUuidAuditlogsGet(userUuid, page, pageSize, order, search, service, entityType, entityUuid, excludeFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Auditlogs
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {ServiceEnumDTO} [service] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {string} [entityUuid] 
         * @param {string} [excludeFields] 
         * @param {string} [userUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditlogsUsersAuditlogsGet(page?: number, pageSize?: number, order?: string, search?: string, service?: ServiceEnumDTO, entityType?: EntityEnumDTO, entityUuid?: string, excludeFields?: string, userUuid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLogSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditlogsUsersAuditlogsGet(page, pageSize, order, search, service, entityType, entityUuid, excludeFields, userUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Entity Auditlogs For User
         * @param {string} userUuid 
         * @param {ServiceEnumDTO} service 
         * @param {EntityEnumDTO} entityType 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityAuditlogsForUserUsersUserUuidServiceAuditlogsGet(userUuid: string, service: ServiceEnumDTO, entityType: EntityEnumDTO, excludeFields?: string, page?: number, pageSize?: number, order?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListLogSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityAuditlogsForUserUsersUserUuidServiceAuditlogsGet(userUuid, service, entityType, excludeFields, page, pageSize, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Nationalities
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNationalitiesUsersNationalityGet(search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNationalitySerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNationalitiesUsersNationalityGet(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Terms And Conditions
         * @param {string} countryUuid 
         * @param {string} [search] 
         * @param {LanguageDTO} [language] 
         * @param {string} [consentType] 
         * @param {string} [name] 
         * @param {string} [consentTypeIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTermsAndConditionsUsersTermsAndConditionsCountryUuidGet(countryUuid: string, search?: string, language?: LanguageDTO, consentType?: string, name?: string, consentTypeIn?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListTermsAndConditionsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTermsAndConditionsUsersTermsAndConditionsCountryUuidGet(countryUuid, search, language, consentType, name, consentTypeIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User Consents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserConsentsUsersUserConsentsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserConsentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserConsentsUsersUserConsentsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User
         * @param {string} userUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [phoneNumber] 
         * @param {string} [email] 
         * @param {string} [authExternalId] 
         * @param {string} [authClientId] 
         * @param {string} [userType] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {boolean} [activeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserUsersUserUuidGet(userUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedUserSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserUsersUserUuidGet(userUuid, updatedAtLte, updatedAtGt, search, firstName, lastName, phoneNumber, email, authExternalId, authClientId, userType, role, excludeRole, countryUuid, department, activeUsers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Users
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [phoneNumber] 
         * @param {string} [email] 
         * @param {string} [authExternalId] 
         * @param {string} [authClientId] 
         * @param {string} [userType] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {boolean} [activeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersUsersGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUserSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersUsersGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, firstName, lastName, phoneNumber, email, authExternalId, authClientId, userType, role, excludeRole, countryUuid, department, activeUsers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch User
         * @param {string} userUuid 
         * @param {PatchUserSerializerDTO} patchUserSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUserUsersUserUuidPatch(userUuid: string, patchUserSerializerDTO: PatchUserSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUserUsersUserUuidPatch(userUuid, patchUserSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put User
         * @param {string} userUuid 
         * @param {BaseUserSerializerDTO} baseUserSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserUsersUserUuidPut(userUuid: string, baseUserSerializerDTO: BaseUserSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserUsersUserUuidPut(userUuid, baseUserSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search Employee
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [phoneNumber] 
         * @param {string} [email] 
         * @param {string} [authExternalId] 
         * @param {string} [authClientId] 
         * @param {string} [userType] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {boolean} [activeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchEmployeeUsersSearchEmployeeGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListEmployeeSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchEmployeeUsersSearchEmployeeGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, firstName, lastName, phoneNumber, email, authExternalId, authClientId, userType, role, excludeRole, countryUuid, department, activeUsers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User Consents
         * @param {UserConsentSerializerDTO} userConsentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserConsentsUsersUserConsentsPatch(userConsentSerializerDTO: UserConsentSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserConsentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserConsentsUsersUserConsentsPatch(userConsentSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateImportDataFormatUsersDataImportPost(file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateImportDataFormatUsersDataImportPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate User Account
         * @param {ValidateUserAccountDTO} validateUserAccountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateUserAccountUsersValidateAccountPost(validateUserAccountDTO: ValidateUserAccountDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateUserAccountUsersValidateAccountPost(validateUserAccountDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersAPI - factory interface
 * @export
 */
export const UsersAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersAPIFp(configuration)
    return {
        /**
         * 
         * @summary Create User Consents
         * @param {ListCreateUserConsentSerializerDTO} listCreateUserConsentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserConsentsUsersUserConsentsPost(listCreateUserConsentSerializerDTO: ListCreateUserConsentSerializerDTO, options?: any): AxiosPromise<UserConsentResponseSerializerDTO> {
            return localVarFp.createUserConsentsUsersUserConsentsPost(listCreateUserConsentSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create User
         * @param {BaseUserSerializerDTO} baseUserSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserUsersPost(baseUserSerializerDTO: BaseUserSerializerDTO, options?: any): AxiosPromise<UserSerializerDTO> {
            return localVarFp.createUserUsersPost(baseUserSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete User
         * @param {string} userUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsersUserUuidDelete(userUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserUsersUserUuidDelete(userUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Auditlogs For User
         * @param {string} userUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {ServiceEnumDTO} [service] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {string} [entityUuid] 
         * @param {string} [excludeFields] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuditlogsForUserUsersUserUuidAuditlogsGet(userUuid: string, page?: number, pageSize?: number, order?: string, search?: string, service?: ServiceEnumDTO, entityType?: EntityEnumDTO, entityUuid?: string, excludeFields?: string, options?: any): AxiosPromise<UserListLogSerializerDTO> {
            return localVarFp.getAllAuditlogsForUserUsersUserUuidAuditlogsGet(userUuid, page, pageSize, order, search, service, entityType, entityUuid, excludeFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Auditlogs
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {ServiceEnumDTO} [service] 
         * @param {EntityEnumDTO} [entityType] 
         * @param {string} [entityUuid] 
         * @param {string} [excludeFields] 
         * @param {string} [userUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditlogsUsersAuditlogsGet(page?: number, pageSize?: number, order?: string, search?: string, service?: ServiceEnumDTO, entityType?: EntityEnumDTO, entityUuid?: string, excludeFields?: string, userUuid?: string, options?: any): AxiosPromise<ListLogSerializerDTO> {
            return localVarFp.getAuditlogsUsersAuditlogsGet(page, pageSize, order, search, service, entityType, entityUuid, excludeFields, userUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Entity Auditlogs For User
         * @param {string} userUuid 
         * @param {ServiceEnumDTO} service 
         * @param {EntityEnumDTO} entityType 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityAuditlogsForUserUsersUserUuidServiceAuditlogsGet(userUuid: string, service: ServiceEnumDTO, entityType: EntityEnumDTO, excludeFields?: string, page?: number, pageSize?: number, order?: string, options?: any): AxiosPromise<UserListLogSerializerDTO> {
            return localVarFp.getEntityAuditlogsForUserUsersUserUuidServiceAuditlogsGet(userUuid, service, entityType, excludeFields, page, pageSize, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Nationalities
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNationalitiesUsersNationalityGet(search?: string, options?: any): AxiosPromise<ListNationalitySerializerDTO> {
            return localVarFp.getNationalitiesUsersNationalityGet(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Terms And Conditions
         * @param {string} countryUuid 
         * @param {string} [search] 
         * @param {LanguageDTO} [language] 
         * @param {string} [consentType] 
         * @param {string} [name] 
         * @param {string} [consentTypeIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTermsAndConditionsUsersTermsAndConditionsCountryUuidGet(countryUuid: string, search?: string, language?: LanguageDTO, consentType?: string, name?: string, consentTypeIn?: string, options?: any): AxiosPromise<ListTermsAndConditionsSerializerDTO> {
            return localVarFp.getTermsAndConditionsUsersTermsAndConditionsCountryUuidGet(countryUuid, search, language, consentType, name, consentTypeIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Consents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserConsentsUsersUserConsentsGet(options?: any): AxiosPromise<UserConsentSerializerDTO> {
            return localVarFp.getUserConsentsUsersUserConsentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User
         * @param {string} userUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [phoneNumber] 
         * @param {string} [email] 
         * @param {string} [authExternalId] 
         * @param {string} [authClientId] 
         * @param {string} [userType] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {boolean} [activeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsersUserUuidGet(userUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options?: any): AxiosPromise<DetailedUserSerializerDTO> {
            return localVarFp.getUserUsersUserUuidGet(userUuid, updatedAtLte, updatedAtGt, search, firstName, lastName, phoneNumber, email, authExternalId, authClientId, userType, role, excludeRole, countryUuid, department, activeUsers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [phoneNumber] 
         * @param {string} [email] 
         * @param {string} [authExternalId] 
         * @param {string} [authClientId] 
         * @param {string} [userType] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {boolean} [activeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUsersGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options?: any): AxiosPromise<ListUserSerializerDTO> {
            return localVarFp.getUsersUsersGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, firstName, lastName, phoneNumber, email, authExternalId, authClientId, userType, role, excludeRole, countryUuid, department, activeUsers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch User
         * @param {string} userUuid 
         * @param {PatchUserSerializerDTO} patchUserSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserUsersUserUuidPatch(userUuid: string, patchUserSerializerDTO: PatchUserSerializerDTO, options?: any): AxiosPromise<UserSerializerDTO> {
            return localVarFp.patchUserUsersUserUuidPatch(userUuid, patchUserSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put User
         * @param {string} userUuid 
         * @param {BaseUserSerializerDTO} baseUserSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserUsersUserUuidPut(userUuid: string, baseUserSerializerDTO: BaseUserSerializerDTO, options?: any): AxiosPromise<UserSerializerDTO> {
            return localVarFp.putUserUsersUserUuidPut(userUuid, baseUserSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Employee
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [phoneNumber] 
         * @param {string} [email] 
         * @param {string} [authExternalId] 
         * @param {string} [authClientId] 
         * @param {string} [userType] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {string} [countryUuid] 
         * @param {DepartmentTypeEnumDTO} [department] 
         * @param {boolean} [activeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEmployeeUsersSearchEmployeeGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options?: any): AxiosPromise<ListEmployeeSerializerDTO> {
            return localVarFp.searchEmployeeUsersSearchEmployeeGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, firstName, lastName, phoneNumber, email, authExternalId, authClientId, userType, role, excludeRole, countryUuid, department, activeUsers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User Consents
         * @param {UserConsentSerializerDTO} userConsentSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserConsentsUsersUserConsentsPatch(userConsentSerializerDTO: UserConsentSerializerDTO, options?: any): AxiosPromise<UserConsentSerializerDTO> {
            return localVarFp.updateUserConsentsUsersUserConsentsPatch(userConsentSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate Import Data Format
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateImportDataFormatUsersDataImportPost(file: any, options?: any): AxiosPromise<any> {
            return localVarFp.validateImportDataFormatUsersDataImportPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate User Account
         * @param {ValidateUserAccountDTO} validateUserAccountDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateUserAccountUsersValidateAccountPost(validateUserAccountDTO: ValidateUserAccountDTO, options?: any): AxiosPromise<any> {
            return localVarFp.validateUserAccountUsersValidateAccountPost(validateUserAccountDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersAPI - object-oriented interface
 * @export
 * @class UsersAPI
 * @extends {BaseAPI}
 */
export class UsersAPI extends BaseAPI {
    /**
     * 
     * @summary Create User Consents
     * @param {ListCreateUserConsentSerializerDTO} listCreateUserConsentSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public createUserConsentsUsersUserConsentsPost(listCreateUserConsentSerializerDTO: ListCreateUserConsentSerializerDTO, options?: any) {
        return UsersAPIFp(this.configuration).createUserConsentsUsersUserConsentsPost(listCreateUserConsentSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create User
     * @param {BaseUserSerializerDTO} baseUserSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public createUserUsersPost(baseUserSerializerDTO: BaseUserSerializerDTO, options?: any) {
        return UsersAPIFp(this.configuration).createUserUsersPost(baseUserSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete User
     * @param {string} userUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public deleteUserUsersUserUuidDelete(userUuid: string, options?: any) {
        return UsersAPIFp(this.configuration).deleteUserUsersUserUuidDelete(userUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Auditlogs For User
     * @param {string} userUuid 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {string} [search] 
     * @param {ServiceEnumDTO} [service] 
     * @param {EntityEnumDTO} [entityType] 
     * @param {string} [entityUuid] 
     * @param {string} [excludeFields] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public getAllAuditlogsForUserUsersUserUuidAuditlogsGet(userUuid: string, page?: number, pageSize?: number, order?: string, search?: string, service?: ServiceEnumDTO, entityType?: EntityEnumDTO, entityUuid?: string, excludeFields?: string, options?: any) {
        return UsersAPIFp(this.configuration).getAllAuditlogsForUserUsersUserUuidAuditlogsGet(userUuid, page, pageSize, order, search, service, entityType, entityUuid, excludeFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Auditlogs
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {string} [search] 
     * @param {ServiceEnumDTO} [service] 
     * @param {EntityEnumDTO} [entityType] 
     * @param {string} [entityUuid] 
     * @param {string} [excludeFields] 
     * @param {string} [userUuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public getAuditlogsUsersAuditlogsGet(page?: number, pageSize?: number, order?: string, search?: string, service?: ServiceEnumDTO, entityType?: EntityEnumDTO, entityUuid?: string, excludeFields?: string, userUuid?: string, options?: any) {
        return UsersAPIFp(this.configuration).getAuditlogsUsersAuditlogsGet(page, pageSize, order, search, service, entityType, entityUuid, excludeFields, userUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Entity Auditlogs For User
     * @param {string} userUuid 
     * @param {ServiceEnumDTO} service 
     * @param {EntityEnumDTO} entityType 
     * @param {string} [excludeFields] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public getEntityAuditlogsForUserUsersUserUuidServiceAuditlogsGet(userUuid: string, service: ServiceEnumDTO, entityType: EntityEnumDTO, excludeFields?: string, page?: number, pageSize?: number, order?: string, options?: any) {
        return UsersAPIFp(this.configuration).getEntityAuditlogsForUserUsersUserUuidServiceAuditlogsGet(userUuid, service, entityType, excludeFields, page, pageSize, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Nationalities
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public getNationalitiesUsersNationalityGet(search?: string, options?: any) {
        return UsersAPIFp(this.configuration).getNationalitiesUsersNationalityGet(search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Terms And Conditions
     * @param {string} countryUuid 
     * @param {string} [search] 
     * @param {LanguageDTO} [language] 
     * @param {string} [consentType] 
     * @param {string} [name] 
     * @param {string} [consentTypeIn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public getTermsAndConditionsUsersTermsAndConditionsCountryUuidGet(countryUuid: string, search?: string, language?: LanguageDTO, consentType?: string, name?: string, consentTypeIn?: string, options?: any) {
        return UsersAPIFp(this.configuration).getTermsAndConditionsUsersTermsAndConditionsCountryUuidGet(countryUuid, search, language, consentType, name, consentTypeIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Consents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public getUserConsentsUsersUserConsentsGet(options?: any) {
        return UsersAPIFp(this.configuration).getUserConsentsUsersUserConsentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User
     * @param {string} userUuid 
     * @param {string} [updatedAtLte] 
     * @param {string} [updatedAtGt] 
     * @param {string} [search] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [phoneNumber] 
     * @param {string} [email] 
     * @param {string} [authExternalId] 
     * @param {string} [authClientId] 
     * @param {string} [userType] 
     * @param {string} [role] 
     * @param {string} [excludeRole] 
     * @param {string} [countryUuid] 
     * @param {DepartmentTypeEnumDTO} [department] 
     * @param {boolean} [activeUsers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public getUserUsersUserUuidGet(userUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options?: any) {
        return UsersAPIFp(this.configuration).getUserUsersUserUuidGet(userUuid, updatedAtLte, updatedAtGt, search, firstName, lastName, phoneNumber, email, authExternalId, authClientId, userType, role, excludeRole, countryUuid, department, activeUsers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {string} [updatedAtLte] 
     * @param {string} [updatedAtGt] 
     * @param {string} [search] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [phoneNumber] 
     * @param {string} [email] 
     * @param {string} [authExternalId] 
     * @param {string} [authClientId] 
     * @param {string} [userType] 
     * @param {string} [role] 
     * @param {string} [excludeRole] 
     * @param {string} [countryUuid] 
     * @param {DepartmentTypeEnumDTO} [department] 
     * @param {boolean} [activeUsers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public getUsersUsersGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options?: any) {
        return UsersAPIFp(this.configuration).getUsersUsersGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, firstName, lastName, phoneNumber, email, authExternalId, authClientId, userType, role, excludeRole, countryUuid, department, activeUsers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch User
     * @param {string} userUuid 
     * @param {PatchUserSerializerDTO} patchUserSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public patchUserUsersUserUuidPatch(userUuid: string, patchUserSerializerDTO: PatchUserSerializerDTO, options?: any) {
        return UsersAPIFp(this.configuration).patchUserUsersUserUuidPatch(userUuid, patchUserSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put User
     * @param {string} userUuid 
     * @param {BaseUserSerializerDTO} baseUserSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public putUserUsersUserUuidPut(userUuid: string, baseUserSerializerDTO: BaseUserSerializerDTO, options?: any) {
        return UsersAPIFp(this.configuration).putUserUsersUserUuidPut(userUuid, baseUserSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Employee
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {string} [updatedAtLte] 
     * @param {string} [updatedAtGt] 
     * @param {string} [search] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [phoneNumber] 
     * @param {string} [email] 
     * @param {string} [authExternalId] 
     * @param {string} [authClientId] 
     * @param {string} [userType] 
     * @param {string} [role] 
     * @param {string} [excludeRole] 
     * @param {string} [countryUuid] 
     * @param {DepartmentTypeEnumDTO} [department] 
     * @param {boolean} [activeUsers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public searchEmployeeUsersSearchEmployeeGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, firstName?: string, lastName?: string, phoneNumber?: string, email?: string, authExternalId?: string, authClientId?: string, userType?: string, role?: string, excludeRole?: string, countryUuid?: string, department?: DepartmentTypeEnumDTO, activeUsers?: boolean, options?: any) {
        return UsersAPIFp(this.configuration).searchEmployeeUsersSearchEmployeeGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, firstName, lastName, phoneNumber, email, authExternalId, authClientId, userType, role, excludeRole, countryUuid, department, activeUsers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User Consents
     * @param {UserConsentSerializerDTO} userConsentSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public updateUserConsentsUsersUserConsentsPatch(userConsentSerializerDTO: UserConsentSerializerDTO, options?: any) {
        return UsersAPIFp(this.configuration).updateUserConsentsUsersUserConsentsPatch(userConsentSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate Import Data Format
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public validateImportDataFormatUsersDataImportPost(file: any, options?: any) {
        return UsersAPIFp(this.configuration).validateImportDataFormatUsersDataImportPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate User Account
     * @param {ValidateUserAccountDTO} validateUserAccountDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersAPI
     */
    public validateUserAccountUsersValidateAccountPost(validateUserAccountDTO: ValidateUserAccountDTO, options?: any) {
        return UsersAPIFp(this.configuration).validateUserAccountUsersValidateAccountPost(validateUserAccountDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
