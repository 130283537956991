import { store } from '../store';
import { addErrorSnackbar } from '../store/snackbarSlice';

export type AuthProviderError = {
  messageKey: string;
  type: AuthProviderErrorType;
};

export enum AuthProviderErrorType {
  TOKEN_EXPIRED,
  API_ERROR,
  NOT_LOGGED_ERRROR,
  MISSING_ROLE,
}

export const createError = (type: AuthProviderErrorType): AuthProviderError => {
  let messageKey;

  // eslint-disable-next-line default-case
  switch (type) {
    case AuthProviderErrorType.TOKEN_EXPIRED:
      messageKey = 'error.tokenExpired';
      break;
    case AuthProviderErrorType.API_ERROR:
      messageKey = 'error.apiError';
      break;
    case AuthProviderErrorType.NOT_LOGGED_ERRROR:
      messageKey = 'error.userNotLogged';
      break;
    case AuthProviderErrorType.MISSING_ROLE:
      messageKey = 'error.missingRole';
      break;
    default:
      messageKey = '';
  }
  store.dispatch(addErrorSnackbar(messageKey));
  const error: AuthProviderError = { messageKey, type };

  return error;
};
