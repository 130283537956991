import { Grid } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import { paths } from '../../routes/paths';
import { useAppSelector } from '../../store/hooks';
import { selectLoading } from '../../store/loadingSlice';
import { LanguagePicker } from '../LanguagePicker';
import { Loader } from '../Loader';
import { NotificationBell } from '../NotificationBell';
import { ReactComponent as LogoIcon } from './assets/logo-header.svg';
import { sxProps } from './logo-header.style';

// Topmost part of the app.
// When user logs in, notification bell is to be added
// Shows loader when loading app state is set to true
export const LogoHeader: FC = () => {
  const isLoading = useAppSelector((state) => selectLoading(state));

  return (
    <Grid container direction="column" sx={sxProps.headerWrapper}>
      <Grid container item sx={sxProps.logoHeaderContainer}>
        <Grid item sx={sxProps.languagePickerWapper}>
          <LanguagePicker />
        </Grid>
        <Grid
          item
          sx={sxProps.linkLogoWrapper}
          component={Link}
          to={paths.root}
        >
          <LogoIcon />
        </Grid>
        <Grid item sx={sxProps.notificationBellWrapper}>
          <NotificationBell />
        </Grid>
      </Grid>
      <Grid item sx={sxProps.loaderHeaderContainer}>
        <Loader loading={isLoading} />
      </Grid>
    </Grid>
  );
};
