import { ReactComponent as HomeIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-home.svg';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { IconWrapper } from '../../../../../../shared/components/IconWrapper';
import { getUserFullname } from '../../../../../../shared/helpers/user.helper';
import { useT } from '../../../../../../shared/translation/translation.provider';
import { sxProps as parentSxProps } from '../../../../ticket-view-screen.style';
import { useTicket } from '../../../../ticket.context';
import { sxProps } from './comments-header.style';

export const CommentsHeader: FC = () => {
  const t = useT();
  const ticket = useTicket();

  return (
    <Box sx={parentSxProps.ticketHeaderText}>
      <Typography variant="overline">
        {t('+ticketView.comments.headerPart1')}{' '}
        <IconWrapper icon={HomeIcon} sx={sxProps.homeIcon} />{' '}
        <Typography
          variant="overline"
          component="span"
          sx={parentSxProps.ticketHeaderTextPrimary}
        >
          {getUserFullname(ticket.reporter)}
        </Typography>{' '}
        {t('+ticketView.comments.headerPart2')}
      </Typography>
    </Box>
  );
};
