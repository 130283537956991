/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CountryDetailSerializerDTO } from '../dto';
// @ts-ignore
import { EntityEnumDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { ListCitiesSerializerDTO } from '../dto';
// @ts-ignore
import { ListCountriesSerializerDTO } from '../dto';
// @ts-ignore
import { ListRegionsSerializerDTO } from '../dto';
// @ts-ignore
import { PropConfigSerializerDTO } from '../dto';
/**
 * CountriesAPI - axios parameter creator
 * @export
 */
export const CountriesAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Cities
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {EntityEnumDTO} [activesForEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesCountriesCountryUuidCitiesGet: async (countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, name?: string, regionUuid?: string, municipalityUuid?: string, activesForEntityType?: EntityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryUuid' is not null or undefined
            assertParamExists('getCitiesCountriesCountryUuidCitiesGet', 'countryUuid', countryUuid)
            const localVarPath = `/countries/{country_uuid}/cities`
                .replace(`{${"country_uuid"}}`, encodeURIComponent(String(countryUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (municipalityUuid !== undefined) {
                localVarQueryParameter['municipality_uuid'] = municipalityUuid;
            }

            if (activesForEntityType !== undefined) {
                localVarQueryParameter['actives_for_entity_type'] = activesForEntityType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Countries
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountriesCountriesGet: async (page?: number, pageSize?: number, order?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/countries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Prop Configs
         * @param {string} countryUuid 
         * @param {EntityEnumDTO} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropConfigsCountriesPropConfigsGet: async (countryUuid: string, entity: EntityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryUuid' is not null or undefined
            assertParamExists('getPropConfigsCountriesPropConfigsGet', 'countryUuid', countryUuid)
            // verify required parameter 'entity' is not null or undefined
            assertParamExists('getPropConfigsCountriesPropConfigsGet', 'entity', entity)
            const localVarPath = `/countries/prop_configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (entity !== undefined) {
                localVarQueryParameter['entity'] = entity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Regions
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {EntityEnumDTO} [activesForEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsCountriesCountryUuidRegionsGet: async (countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, name?: string, activesForEntityType?: EntityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryUuid' is not null or undefined
            assertParamExists('getRegionsCountriesCountryUuidRegionsGet', 'countryUuid', countryUuid)
            const localVarPath = `/countries/{country_uuid}/regions`
                .replace(`{${"country_uuid"}}`, encodeURIComponent(String(countryUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (activesForEntityType !== undefined) {
                localVarQueryParameter['actives_for_entity_type'] = activesForEntityType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Country
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleCountryCountriesCountryUuidGet: async (countryUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'countryUuid' is not null or undefined
            assertParamExists('getSingleCountryCountriesCountryUuidGet', 'countryUuid', countryUuid)
            const localVarPath = `/countries/{country_uuid}`
                .replace(`{${"country_uuid"}}`, encodeURIComponent(String(countryUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountriesAPI - functional programming interface
 * @export
 */
export const CountriesAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountriesAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Cities
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {EntityEnumDTO} [activesForEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitiesCountriesCountryUuidCitiesGet(countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, name?: string, regionUuid?: string, municipalityUuid?: string, activesForEntityType?: EntityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCitiesSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitiesCountriesCountryUuidCitiesGet(countryUuid, page, pageSize, order, search, name, regionUuid, municipalityUuid, activesForEntityType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Countries
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountriesCountriesGet(page?: number, pageSize?: number, order?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCountriesSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountriesCountriesGet(page, pageSize, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Prop Configs
         * @param {string} countryUuid 
         * @param {EntityEnumDTO} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropConfigsCountriesPropConfigsGet(countryUuid: string, entity: EntityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PropConfigSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropConfigsCountriesPropConfigsGet(countryUuid, entity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Regions
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {EntityEnumDTO} [activesForEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegionsCountriesCountryUuidRegionsGet(countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, name?: string, activesForEntityType?: EntityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRegionsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionsCountriesCountryUuidRegionsGet(countryUuid, page, pageSize, order, search, name, activesForEntityType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Country
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleCountryCountriesCountryUuidGet(countryUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryDetailSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleCountryCountriesCountryUuidGet(countryUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountriesAPI - factory interface
 * @export
 */
export const CountriesAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountriesAPIFp(configuration)
    return {
        /**
         * 
         * @summary Get Cities
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {EntityEnumDTO} [activesForEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitiesCountriesCountryUuidCitiesGet(countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, name?: string, regionUuid?: string, municipalityUuid?: string, activesForEntityType?: EntityEnumDTO, options?: any): AxiosPromise<ListCitiesSerializerDTO> {
            return localVarFp.getCitiesCountriesCountryUuidCitiesGet(countryUuid, page, pageSize, order, search, name, regionUuid, municipalityUuid, activesForEntityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Countries
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountriesCountriesGet(page?: number, pageSize?: number, order?: string, options?: any): AxiosPromise<ListCountriesSerializerDTO> {
            return localVarFp.getCountriesCountriesGet(page, pageSize, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Prop Configs
         * @param {string} countryUuid 
         * @param {EntityEnumDTO} entity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropConfigsCountriesPropConfigsGet(countryUuid: string, entity: EntityEnumDTO, options?: any): AxiosPromise<Array<PropConfigSerializerDTO>> {
            return localVarFp.getPropConfigsCountriesPropConfigsGet(countryUuid, entity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Regions
         * @param {string} countryUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [name] 
         * @param {EntityEnumDTO} [activesForEntityType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegionsCountriesCountryUuidRegionsGet(countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, name?: string, activesForEntityType?: EntityEnumDTO, options?: any): AxiosPromise<ListRegionsSerializerDTO> {
            return localVarFp.getRegionsCountriesCountryUuidRegionsGet(countryUuid, page, pageSize, order, search, name, activesForEntityType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Country
         * @param {string} countryUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleCountryCountriesCountryUuidGet(countryUuid: string, options?: any): AxiosPromise<CountryDetailSerializerDTO> {
            return localVarFp.getSingleCountryCountriesCountryUuidGet(countryUuid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountriesAPI - object-oriented interface
 * @export
 * @class CountriesAPI
 * @extends {BaseAPI}
 */
export class CountriesAPI extends BaseAPI {
    /**
     * 
     * @summary Get Cities
     * @param {string} countryUuid 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {string} [search] 
     * @param {string} [name] 
     * @param {string} [regionUuid] 
     * @param {string} [municipalityUuid] 
     * @param {EntityEnumDTO} [activesForEntityType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesAPI
     */
    public getCitiesCountriesCountryUuidCitiesGet(countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, name?: string, regionUuid?: string, municipalityUuid?: string, activesForEntityType?: EntityEnumDTO, options?: any) {
        return CountriesAPIFp(this.configuration).getCitiesCountriesCountryUuidCitiesGet(countryUuid, page, pageSize, order, search, name, regionUuid, municipalityUuid, activesForEntityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Countries
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesAPI
     */
    public getCountriesCountriesGet(page?: number, pageSize?: number, order?: string, options?: any) {
        return CountriesAPIFp(this.configuration).getCountriesCountriesGet(page, pageSize, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Prop Configs
     * @param {string} countryUuid 
     * @param {EntityEnumDTO} entity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesAPI
     */
    public getPropConfigsCountriesPropConfigsGet(countryUuid: string, entity: EntityEnumDTO, options?: any) {
        return CountriesAPIFp(this.configuration).getPropConfigsCountriesPropConfigsGet(countryUuid, entity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Regions
     * @param {string} countryUuid 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {string} [search] 
     * @param {string} [name] 
     * @param {EntityEnumDTO} [activesForEntityType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesAPI
     */
    public getRegionsCountriesCountryUuidRegionsGet(countryUuid: string, page?: number, pageSize?: number, order?: string, search?: string, name?: string, activesForEntityType?: EntityEnumDTO, options?: any) {
        return CountriesAPIFp(this.configuration).getRegionsCountriesCountryUuidRegionsGet(countryUuid, page, pageSize, order, search, name, activesForEntityType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Country
     * @param {string} countryUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountriesAPI
     */
    public getSingleCountryCountriesCountryUuidGet(countryUuid: string, options?: any) {
        return CountriesAPIFp(this.configuration).getSingleCountryCountriesCountryUuidGet(countryUuid, options).then((request) => request(this.axios, this.basePath));
    }
}
