import { Box } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { ReactComponent as MsButton } from './assets/microsoft-button.svg';
import { sxProps } from './microsoft-button.style';

export type MicrosoftButtonProps = {
  onClick: () => void;
};

export const MicrosoftButton: FC<MicrosoftButtonProps> = ({
  onClick,
}: MicrosoftButtonProps) => {
  return (
    <Box sx={sxProps.wrapperBox}>
      <MsButton onClick={onClick} style={{ width: '100%' }} />
    </Box>
  );
};
