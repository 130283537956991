import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
import { Button, Box, Typography, TextField } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useCallback, useState } from 'react';

import type { CreateCommentSerializerDTO } from '../../../../../../../connectors/ticket';
import { CommentVisibilityEnumDTO } from '../../../../../../../connectors/ticket';
import { CommentTextField } from '../../../../../../shared/components/CommentTextField';
import { addCommentToTicket } from '../../../../../../shared/services/ticket.service';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { selectLoading } from '../../../../../../shared/store/loadingSlice';
import { selectSelectedResponseTemplated } from '../../../../../../shared/store/responseTemplate';
import { useT } from '../../../../../../shared/translation/translation.provider';
import type { AttachmentForUpload } from '../../../../../../shared/types/AttachmentForUpload';
import { useTicket } from '../../../../ticket.context';
import { sxProps } from './comment-input.style';

const initialComment: CreateCommentSerializerDTO = { content: '' };

export const CommentInput: FC = () => {
  const ticket = useTicket();
  const t = useT();
  const isLoading = useAppSelector((state) => selectLoading(state));
  const [comment, setComment] =
    useState<CreateCommentSerializerDTO>(initialComment);
  const [attachments, setAttachments] = useState<AttachmentForUpload[]>([]);
  const [publicCommentForbidden, setPublicCommentForbidden] = useState(false);
  const [commentInputActive, setCommentInputActive] = useState(false);
  const selectedResponseTemplate = useAppSelector((state) =>
    selectSelectedResponseTemplated(state),
  );

  const setCommentContent = useCallback(
    (content: string) => {
      if (!comment.content) {
        setCommentInputActive(true);
      }
      setComment({ ...comment, content });
    },
    [comment],
  );

  useEffect(() => {
    if (selectedResponseTemplate) {
      setCommentInputActive(true);
    }
  }, [selectedResponseTemplate]);

  const sendComment = async (
    visibility: CommentVisibilityEnumDTO,
  ): Promise<void> => {
    if (!comment.content.trim()) return;
    await addCommentToTicket(ticket, { ...comment, visibility }, attachments);
    setAttachments([]);
    setCommentInputActive(false);
    setComment(initialComment);
  };

  if (!commentInputActive) {
    return (
      <TextField
        sx={sxProps.inactiveTextBox}
        placeholder={t(`+ticketView.comments.commentInputLabel`)}
        fullWidth
        onClick={() => setCommentInputActive(true)}
      />
    );
  }

  return (
    <Box>
      <CommentTextField
        commentContent={comment.content}
        setCommentContent={setCommentContent}
        attachments={attachments}
        setAttachments={setAttachments}
        sx={sxProps.commentTextArea}
        minRows={3}
        maxRows={3}
        multiline
        fullWidth
        label={t(`+ticketView.comments.commentInputLabel`)}
        disabled={isLoading}
        setForbidPublicComments={setPublicCommentForbidden}
        focused
      />
      <Box sx={sxProps.buttonsBox}>
        <Button
          data-test="ticket-view-comments-button-internal"
          sx={sxProps.sendInternalButton}
          color="warning"
          variant="contained"
          onClick={() => sendComment(CommentVisibilityEnumDTO.Internal)}
        >
          <CommentIcon />
          <Typography variant="button" sx={sxProps.buttonText}>
            {t('+ticketView.comments.sendInternalButton')}
          </Typography>
        </Button>
        <Button
          data-test="ticket-view-comments-button-public"
          sx={sxProps.sendPublicButton}
          color="primary"
          variant="contained"
          onClick={() => sendComment(CommentVisibilityEnumDTO.Public)}
          disabled={publicCommentForbidden}
        >
          <SendIcon />
          <Typography variant="button" sx={sxProps.buttonText}>
            {t('+ticketView.comments.sendPublicButton')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
