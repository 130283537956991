import { Card, CardContent, Typography, CardActions } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { MicrosoftButton } from '../../../shared/components/MicrosoftButton/microsoft-button.component';
import { loginAzure } from '../../../shared/services/auth.service';
import { globalSxProps } from '../../../shared/style';
import { useT } from '../../../shared/translation/translation.provider';
import { ImageBackground } from '../../shared/ImageBackground';
import { sxProps } from './welcome-screen.style';

export const WelcomeScreen: FC = () => {
  const t = useT();

  const login = async (): Promise<void> => {
    await loginAzure();
  };

  return (
    <ImageBackground>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h1" component="div">
            {t('+login.welcomeCard.title')}
          </Typography>
          <Typography component="div" sx={sxProps.welcomeTextPadding}>
            {t('+login.welcomeCard.message.firstPart')}
            <Typography sx={globalSxProps.textBold} display="inline">
              {t('+login.welcomeCard.message.secondPart')}
            </Typography>
            .
          </Typography>
        </CardContent>
        <CardActions>
          <MicrosoftButton onClick={login} />
        </CardActions>
      </Card>
    </ImageBackground>
  );
};
