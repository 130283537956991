import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import type { MeSerializerDTO } from '../../../../../connectors/user';
import { sxProps } from './profile-details.style';

export type ProfileDetailsProps = {
  profile: MeSerializerDTO;
};

export const ProfileDetails: FC<ProfileDetailsProps> = ({
  profile,
}: ProfileDetailsProps) => {
  return (
    <Box sx={sxProps.profileDetailsBox}>
      <Typography variant="h1">
        {profile.firstName} {profile.lastName}
      </Typography>
      <Typography sx={sxProps.profileDetailsEmail}>{profile.email}</Typography>
      <Typography sx={sxProps.profileDetailsPhoneNumber}>
        {profile.phoneNumber}
      </Typography>
    </Box>
  );
};
