import type {
  ListNotificationSerializerDTO,
  NotificationSerializerDTO,
} from '../../../../connectors/notification';
import {
  NotificationStatusDTO,
  NotificationsAPI,
} from '../../../../connectors/notification';
import { NOTIFICATIONS_PER_PAGE } from '../../helpers/const';
import { getEnvValue } from '../../helpers/env.helper';
import { mieInstance } from '../../mie.instance';

class NotificationClient {
  private notificationClient;

  constructor() {
    const path = getEnvValue('API_PATH');

    this.notificationClient = new NotificationsAPI(
      undefined,
      path,
      mieInstance,
    );
  }

  public getNotifications = async (
    page?: number,
  ): Promise<ListNotificationSerializerDTO> => {
    const response =
      await this.notificationClient.getNotificationsNotificationsGet(
        page || 1,
        NOTIFICATIONS_PER_PAGE,
        undefined,
        undefined,
        NotificationStatusDTO.Sent,
      );

    return response.data;
  };

  public markNotificationAsSeen = async ({
    uuid,
  }: NotificationSerializerDTO): Promise<void> => {
    await this.notificationClient.markNotificationAsSeenNotificationsNotificationUuidSeenPost(
      uuid,
    );
  };

  public markAllNotificationAsSeen = async (): Promise<void> => {
    await this.notificationClient.markNotificationsAsSeenNotificationsSeenPost();
  };
}

export const notificationClient = new NotificationClient();
