import { ReactComponent as UnitIcon } from '@heimstaden/icons-library/img/streamline-regular/building-construction/architectural-features/architecture-door.svg';
import { ReactComponent as PriorityIcon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin.svg';
import { ReactComponent as PriorityObjectIcon } from '@heimstaden/icons-library/img/streamline-regular/real-estate/real-estate/real-estate-building-house.svg';

import { EntityEnumDTO } from '../../../../../../../connectors/ticket';
import type { ModalSelectOption } from '../../../../../../shared/components/ModalSelect';

export const entityTypeOptions: ModalSelectOption[] = [
  {
    icon: PriorityIcon,
    id: EntityEnumDTO.Property,
    labelKey: `entityTypes.${EntityEnumDTO.Property}`,
    value: EntityEnumDTO.Property,
  },
  {
    icon: PriorityObjectIcon,
    id: EntityEnumDTO.PropertyObject,
    labelKey: `entityTypes.${EntityEnumDTO.PropertyObject}`,
    value: EntityEnumDTO.PropertyObject,
  },
  {
    icon: UnitIcon,
    id: EntityEnumDTO.Unit,
    labelKey: `entityTypes.${EntityEnumDTO.Unit}`,
    value: EntityEnumDTO.Unit,
  },
];
