import { formatDate } from '../../../../../../../../shared/helpers/date.helper';

export const reasonAwaitingTenantKey = 'reasonAwaitingTenant';
export const reasonAwaitingTenantOptions = [
  'awaitingTenantReply',
  'futureAppointmentAgreedWithtenant',
];
export const estimatedDateForResumptionOfTaskKey =
  'estimatedDateForResumptionOfTask';
export const estimatedDateForResumtionOfTaskStartValue = formatDate(
  new Date().toDateString(),
  'yyyy-mm-dd',
);
