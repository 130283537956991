import { ReactComponent as BellIconRing } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alert/alarm-bell-ring.svg';
import { ReactComponent as BellIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alert/alarm-bell.svg';
import { Box } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';

import { NOTIFICATION_PULL_INTERVAL } from '../../helpers/const';
import { mergeSx } from '../../helpers/sx.helper';
import {
  loadNextNotificationsPage,
  loadNotifications,
} from '../../services/notificaiton.service';
import { selectLoggedUser } from '../../store/authSlice';
import { useAppSelector } from '../../store/hooks';
import { selectNotifications } from '../../store/notificationsSlice';
import { IconWrapper } from '../IconWrapper';
import { NotificationModal } from './components/NotificationModal';
import { sxProps } from './notification-bell.style';

export const NotificationBell: FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [intervalInstance, setIntervalInstance] = useState<NodeJS.Timer>();
  const notifications = useAppSelector((state) => selectNotifications(state));
  const loggedUser = useAppSelector((state) => selectLoggedUser(state));

  useEffect(() => {
    if (loggedUser) {
      loadNextNotificationsPage();
      const interval = setInterval(
        loadNotifications,
        NOTIFICATION_PULL_INTERVAL,
      );

      setIntervalInstance(interval);
    }
  }, [loggedUser]);

  useEffect(() => {
    if (!loggedUser && intervalInstance) {
      clearInterval(intervalInstance);
      setIntervalInstance(undefined);
    }
  }, [intervalInstance, loggedUser]);

  const bellIcon = useMemo(() => {
    return notifications.length === 0 ? BellIcon : BellIconRing;
  }, [notifications.length]);

  const bellSx = useMemo(() => {
    return notifications.length === 0
      ? sxProps.notificationBellIcon
      : mergeSx(sxProps.notificationBellIcon, sxProps.activeBell);
  }, [notifications.length]);

  if (!loggedUser) return null;

  return (
    <Box>
      <IconWrapper
        icon={bellIcon}
        sx={bellSx}
        onClick={() => setModalOpen(true)}
      />
      <NotificationModal open={modalOpen} setOpen={setModalOpen} />
    </Box>
  );
};
