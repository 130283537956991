// this object contains data needed for displaying navbar tabs
import { paths } from '../../../../shared/routes/paths';
import type { TicketViewNavbarItem } from './types';
import { TicketViewNavbarItemValue } from './types';

// and passing them specific properties
export const ticketViewNavbarProps: {
  [key in TicketViewNavbarItemValue]: TicketViewNavbarItem;
} = {
  [TicketViewNavbarItemValue.ATTACHMENTS]: {
    labelKey: '+ticketView.tabs.attachments',
    path: paths.ticketAttachments,
    value: TicketViewNavbarItemValue.ATTACHMENTS,
  },
  [TicketViewNavbarItemValue.DETAILS]: {
    labelKey: '+ticketView.tabs.details',
    path: paths.ticketDetails,
    value: TicketViewNavbarItemValue.DETAILS,
  },
  [TicketViewNavbarItemValue.MESSAGES]: {
    labelKey: '+ticketView.tabs.messages',
    path: paths.ticketMessages,
    value: TicketViewNavbarItemValue.MESSAGES,
  },
};
