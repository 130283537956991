import type { FC } from 'react';
import React, { useCallback, useMemo, useState, useEffect } from 'react';

import type {
  EntityEnumDTO,
  LocationTypeEnumDTO,
  TicketMetadataSerializerDTO,
} from '../../../../../../../connectors/ticket';
import { ModalSelect } from '../../../../../../shared/components/ModalSelect';
import { IssueLocationConfirmationModal } from './components/IssueLocationConfirmationModal';
import {
  getAvailableIssueLocations,
  getIssueLocationOptions,
  getRecommendedIssueLocation,
  getRecommendedIssueLocations,
} from './util';

export type IssueLocationPickerProps = {
  metadata?: TicketMetadataSerializerDTO;
  entityType?: EntityEnumDTO;
  issueLocation?: LocationTypeEnumDTO;
  onChange: (issueLocation: LocationTypeEnumDTO) => void;
  error?: string;
};

export const IssueLocationPicker: FC<IssueLocationPickerProps> = ({
  metadata,
  entityType,
  issueLocation,
  onChange,
  error,
}: IssueLocationPickerProps) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [issueLocationToBeConfirmed, setIssueLocationToBeConfirmed] =
    useState<LocationTypeEnumDTO>();

  // this is the list of available locations based on metadata
  const availabeLocations = useMemo(
    () => getAvailableIssueLocations(metadata),
    [metadata],
  );

  // this is the recommended issue locations for chosen entity type
  const recommendedIssueLocation = useMemo(
    () =>
      issueLocation ||
      getRecommendedIssueLocation(availabeLocations, entityType),
    [availabeLocations, entityType, issueLocation],
  );

  // if there is a difference between current location and reccomended one, we will update
  // issue location
  useEffect(() => {
    if (recommendedIssueLocation && recommendedIssueLocation !== issueLocation)
      onChange(recommendedIssueLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendedIssueLocation]);

  const issueLocationOptions = useMemo(
    () => getIssueLocationOptions(availabeLocations),
    [availabeLocations],
  );

  // this method will try to change issueLocation. if it is not one of the reccomended
  // locations, it will show confirmation dialog. If force parameter is set to true (which will be done
  // when this function is called from confirmation dialog), it will skip this step, and change issueLocation
  const onIssueLocationChange = useCallback(
    (issueLocation: LocationTypeEnumDTO, force?: boolean) => {
      const recommendedIssueLocations = getRecommendedIssueLocations(
        availabeLocations,
        entityType,
      );

      if (recommendedIssueLocations.includes(issueLocation) || force) {
        onChange(issueLocation);
      } else {
        setIssueLocationToBeConfirmed(issueLocation);
        setConfirmationModalOpen(true);
      }
    },
    [availabeLocations, onChange, entityType],
  );

  if (!recommendedIssueLocation || !availabeLocations) return null;

  return (
    <>
      <ModalSelect
        fieldname="issue_location_picker"
        value={issueLocation || ''}
        onSelect={(item) =>
          onIssueLocationChange(item.value as LocationTypeEnumDTO)
        }
        options={issueLocationOptions}
        renderAsSelect={{
          error,
          labelKey: '+createTicket.issueLocationLabel',
        }}
      />
      <IssueLocationConfirmationModal
        open={Boolean(confirmationModalOpen && issueLocationToBeConfirmed)}
        close={() => setConfirmationModalOpen(false)}
        confirm={() => {
          if (issueLocationToBeConfirmed) {
            onIssueLocationChange(issueLocationToBeConfirmed, true);
            setIssueLocationToBeConfirmed(undefined);
          }
          setConfirmationModalOpen(false);
        }}
      />
    </>
  );
};
