import { Box, Grid, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { IconWrapper } from '../../../../../../../../shared/components/IconWrapper';
import { mergeSx } from '../../../../../../../../shared/helpers/sx.helper';
import { useT } from '../../../../../../../../shared/translation/translation.provider';
import type { DetailInformationData } from '../../util';
import { sxProps } from './detail-information.style';

export const DetailInformation: FC<DetailInformationData> = ({
  labelKey,
  value,
  icon,
  isError = false,
}: DetailInformationData) => {
  const t = useT();

  const sxTextProps = useMemo<SxProps<Theme>>(() => {
    return isError
      ? mergeSx(sxProps.detailInformationText, sxProps.detailInformationError)
      : sxProps.detailInformationText;
  }, [isError]);

  const sxIconProps = useMemo<SxProps<Theme>>(() => {
    return isError
      ? mergeSx(sxProps.detailInformationIcon, sxProps.detailInformationError)
      : sxProps.detailInformationIcon;
  }, [isError]);

  const iconBox = useMemo(() => {
    return icon ? (
      <IconWrapper icon={icon} sx={sxIconProps} />
    ) : (
      <Box sx={sxIconProps} />
    );
  }, [icon, sxIconProps]);

  if (!value) {
    return null;
  }

  return (
    <Grid container sx={sxProps.detailInformationsGridContainer}>
      <Grid item>{iconBox}</Grid>
      <Grid item>
        <Typography variant="overline" sx={sxProps.detailInformationLabel}>
          {t(labelKey)}
        </Typography>
        <Typography sx={sxTextProps}>{value}</Typography>
      </Grid>
    </Grid>
  );
};
