/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { ListNotificationSerializerDTO } from '../dto';
// @ts-ignore
import { NotificationSendBodyDTO } from '../dto';
// @ts-ignore
import { NotificationSerializerDTO } from '../dto';
/**
 * NotificationsAPI - axios parameter creator
 * @export
 */
export const NotificationsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive Notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNotificationsNotificationsArchivePost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/archive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive Single Notification
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSingleNotificationNotificationsNotificationUuidArchivePost: async (notificationUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationUuid' is not null or undefined
            assertParamExists('archiveSingleNotificationNotificationsNotificationUuidArchivePost', 'notificationUuid', notificationUuid)
            const localVarPath = `/notifications/{notification_uuid}/archive`
                .replace(`{${"notification_uuid"}}`, encodeURIComponent(String(notificationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Notifications
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [statuses] 
         * @param {string} [excludeStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsNotificationsGet: async (page?: number, pageSize?: number, order?: string, search?: string, statuses?: string, excludeStatuses?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (statuses !== undefined) {
                localVarQueryParameter['statuses'] = statuses;
            }

            if (excludeStatuses !== undefined) {
                localVarQueryParameter['exclude_statuses'] = excludeStatuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Notification
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleNotificationNotificationsNotificationUuidGet: async (notificationUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationUuid' is not null or undefined
            assertParamExists('getSingleNotificationNotificationsNotificationUuidGet', 'notificationUuid', notificationUuid)
            const localVarPath = `/notifications/{notification_uuid}`
                .replace(`{${"notification_uuid"}}`, encodeURIComponent(String(notificationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark Notification As Seen
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsSeenNotificationsNotificationUuidSeenPost: async (notificationUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationUuid' is not null or undefined
            assertParamExists('markNotificationAsSeenNotificationsNotificationUuidSeenPost', 'notificationUuid', notificationUuid)
            const localVarPath = `/notifications/{notification_uuid}/seen`
                .replace(`{${"notification_uuid"}}`, encodeURIComponent(String(notificationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark Notification As Unseen
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsUnseenNotificationsNotificationUuidUnseenPost: async (notificationUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationUuid' is not null or undefined
            assertParamExists('markNotificationAsUnseenNotificationsNotificationUuidUnseenPost', 'notificationUuid', notificationUuid)
            const localVarPath = `/notifications/{notification_uuid}/unseen`
                .replace(`{${"notification_uuid"}}`, encodeURIComponent(String(notificationUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark Notifications As Seen
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationsAsSeenNotificationsSeenPost: async (page?: number, pageSize?: number, order?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/seen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Notification
         * @param {NotificationSendBodyDTO} notificationSendBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotificationNotificationsSendPost: async (notificationSendBodyDTO: NotificationSendBodyDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationSendBodyDTO' is not null or undefined
            assertParamExists('sendNotificationNotificationsSendPost', 'notificationSendBodyDTO', notificationSendBodyDTO)
            const localVarPath = `/notifications/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationSendBodyDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsAPI - functional programming interface
 * @export
 */
export const NotificationsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive Notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveNotificationsNotificationsArchivePost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveNotificationsNotificationsArchivePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Archive Single Notification
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveSingleNotificationNotificationsNotificationUuidArchivePost(notificationUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveSingleNotificationNotificationsNotificationUuidArchivePost(notificationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Notifications
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [statuses] 
         * @param {string} [excludeStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsNotificationsGet(page?: number, pageSize?: number, order?: string, search?: string, statuses?: string, excludeStatuses?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNotificationSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationsNotificationsGet(page, pageSize, order, search, statuses, excludeStatuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Notification
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSingleNotificationNotificationsNotificationUuidGet(notificationUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSingleNotificationNotificationsNotificationUuidGet(notificationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark Notification As Seen
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationAsSeenNotificationsNotificationUuidSeenPost(notificationUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationAsSeenNotificationsNotificationUuidSeenPost(notificationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark Notification As Unseen
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationAsUnseenNotificationsNotificationUuidUnseenPost(notificationUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationAsUnseenNotificationsNotificationUuidUnseenPost(notificationUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark Notifications As Seen
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationsAsSeenNotificationsSeenPost(page?: number, pageSize?: number, order?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListNotificationSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationsAsSeenNotificationsSeenPost(page, pageSize, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send Notification
         * @param {NotificationSendBodyDTO} notificationSendBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotificationNotificationsSendPost(notificationSendBodyDTO: NotificationSendBodyDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotificationNotificationsSendPost(notificationSendBodyDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsAPI - factory interface
 * @export
 */
export const NotificationsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Archive Notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNotificationsNotificationsArchivePost(options?: any): AxiosPromise<void> {
            return localVarFp.archiveNotificationsNotificationsArchivePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Archive Single Notification
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveSingleNotificationNotificationsNotificationUuidArchivePost(notificationUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.archiveSingleNotificationNotificationsNotificationUuidArchivePost(notificationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Notifications
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [search] 
         * @param {string} [statuses] 
         * @param {string} [excludeStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsNotificationsGet(page?: number, pageSize?: number, order?: string, search?: string, statuses?: string, excludeStatuses?: string, options?: any): AxiosPromise<ListNotificationSerializerDTO> {
            return localVarFp.getNotificationsNotificationsGet(page, pageSize, order, search, statuses, excludeStatuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Notification
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleNotificationNotificationsNotificationUuidGet(notificationUuid: string, options?: any): AxiosPromise<NotificationSerializerDTO> {
            return localVarFp.getSingleNotificationNotificationsNotificationUuidGet(notificationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark Notification As Seen
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsSeenNotificationsNotificationUuidSeenPost(notificationUuid: string, options?: any): AxiosPromise<NotificationSerializerDTO> {
            return localVarFp.markNotificationAsSeenNotificationsNotificationUuidSeenPost(notificationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark Notification As Unseen
         * @param {string} notificationUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsUnseenNotificationsNotificationUuidUnseenPost(notificationUuid: string, options?: any): AxiosPromise<NotificationSerializerDTO> {
            return localVarFp.markNotificationAsUnseenNotificationsNotificationUuidUnseenPost(notificationUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark Notifications As Seen
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationsAsSeenNotificationsSeenPost(page?: number, pageSize?: number, order?: string, options?: any): AxiosPromise<ListNotificationSerializerDTO> {
            return localVarFp.markNotificationsAsSeenNotificationsSeenPost(page, pageSize, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Notification
         * @param {NotificationSendBodyDTO} notificationSendBodyDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendNotificationNotificationsSendPost(notificationSendBodyDTO: NotificationSendBodyDTO, options?: any): AxiosPromise<NotificationSerializerDTO> {
            return localVarFp.sendNotificationNotificationsSendPost(notificationSendBodyDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsAPI - object-oriented interface
 * @export
 * @class NotificationsAPI
 * @extends {BaseAPI}
 */
export class NotificationsAPI extends BaseAPI {
    /**
     * 
     * @summary Archive Notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsAPI
     */
    public archiveNotificationsNotificationsArchivePost(options?: any) {
        return NotificationsAPIFp(this.configuration).archiveNotificationsNotificationsArchivePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Archive Single Notification
     * @param {string} notificationUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsAPI
     */
    public archiveSingleNotificationNotificationsNotificationUuidArchivePost(notificationUuid: string, options?: any) {
        return NotificationsAPIFp(this.configuration).archiveSingleNotificationNotificationsNotificationUuidArchivePost(notificationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Notifications
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {string} [search] 
     * @param {string} [statuses] 
     * @param {string} [excludeStatuses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsAPI
     */
    public getNotificationsNotificationsGet(page?: number, pageSize?: number, order?: string, search?: string, statuses?: string, excludeStatuses?: string, options?: any) {
        return NotificationsAPIFp(this.configuration).getNotificationsNotificationsGet(page, pageSize, order, search, statuses, excludeStatuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Notification
     * @param {string} notificationUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsAPI
     */
    public getSingleNotificationNotificationsNotificationUuidGet(notificationUuid: string, options?: any) {
        return NotificationsAPIFp(this.configuration).getSingleNotificationNotificationsNotificationUuidGet(notificationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark Notification As Seen
     * @param {string} notificationUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsAPI
     */
    public markNotificationAsSeenNotificationsNotificationUuidSeenPost(notificationUuid: string, options?: any) {
        return NotificationsAPIFp(this.configuration).markNotificationAsSeenNotificationsNotificationUuidSeenPost(notificationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark Notification As Unseen
     * @param {string} notificationUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsAPI
     */
    public markNotificationAsUnseenNotificationsNotificationUuidUnseenPost(notificationUuid: string, options?: any) {
        return NotificationsAPIFp(this.configuration).markNotificationAsUnseenNotificationsNotificationUuidUnseenPost(notificationUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark Notifications As Seen
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsAPI
     */
    public markNotificationsAsSeenNotificationsSeenPost(page?: number, pageSize?: number, order?: string, options?: any) {
        return NotificationsAPIFp(this.configuration).markNotificationsAsSeenNotificationsSeenPost(page, pageSize, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Notification
     * @param {NotificationSendBodyDTO} notificationSendBodyDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsAPI
     */
    public sendNotificationNotificationsSendPost(notificationSendBodyDTO: NotificationSendBodyDTO, options?: any) {
        return NotificationsAPIFp(this.configuration).sendNotificationNotificationsSendPost(notificationSendBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
