import type {
  TenantSerializerDTO,
  UnitSerializerDTO,
} from '../../../connectors/property';
import type { DepartmentTypeEnumDTO } from '../../../connectors/ticket';
import { EntityEnumDTO } from '../../../connectors/user';
import { verifyAttachmentForUpload } from '../../shared/helpers/attachment.helper';
import { validateRequired } from '../../shared/helpers/validation.helper';
import { store } from '../../shared/store';
import type { AttachmentForUpload } from '../../shared/types/AttachmentForUpload';

export type CreateTicketFieldsError = {
  title?: string;
  description?: string;
  priority?: string;
  country?: string;
  city?: string;
  entityType?: string;
  entity?: string;
  issueLocation?: string;
  issueRoom?: string;
  issueType?: string;
  issueItem?: string;
  issueSubtype?: string;
  isReporterMe?: string;
  reporterUnit?: string;
  reporterTenant?: string;
  isAssigneeMe?: string;
  department?: string;
  assignee?: string;
  attachments?: string;
};

export type AttachmentsError = {
  verifiedAttachments: AttachmentForUpload[];
  indexError: number | undefined;
};

const validateDepartment = (
  isAssigneeMe: boolean,
  department?: DepartmentTypeEnumDTO,
): boolean => {
  return Boolean(isAssigneeMe || department);
};

const validateReporterUnit = (
  isReporterMe: boolean,
  entityType?: EntityEnumDTO,
  reporterUnit?: UnitSerializerDTO,
): boolean => {
  if (isReporterMe) return true;
  if (entityType === EntityEnumDTO.Unit) return true;

  return Boolean(reporterUnit);
};

const validateReporterTenant = (
  isReporterMe: boolean,
  reporterTenant?: TenantSerializerDTO,
): boolean => {
  return Boolean(isReporterMe || reporterTenant);
};

export const validateTicketForm = (): CreateTicketFieldsError => {
  const errors: CreateTicketFieldsError = {};
  const {
    description,
    title,
    country,
    city,
    entityType,
    entity,
    issueLocation,
    issueRoom,
    issueType,
    issueItem,
    issueSubtype,
    priority,
    isAssigneeMe,
    department,
    isReporterMe,
    reporterTenant,
    reporterUnit,
  } = store.getState().createTicket;

  if (!validateRequired(title)) {
    errors.title = 'error.required';
  }
  if (!validateRequired(description)) {
    errors.description = 'error.required';
  }
  if (!validateRequired(priority)) {
    errors.priority = 'error.required';
  }
  if (!validateRequired(country)) {
    errors.country = 'error.required';
  }
  if (!validateRequired(city)) {
    errors.city = 'error.required';
  }
  if (!validateRequired(entityType)) {
    errors.entityType = 'error.required';
  }
  if (!validateRequired(entity)) {
    errors.entity = 'error.required';
  }
  if (!validateRequired(issueLocation)) {
    errors.issueLocation = 'error.required';
  }
  if (!validateRequired(issueRoom)) {
    errors.issueRoom = 'error.required';
  }
  if (!validateRequired(issueType)) {
    errors.issueType = 'error.required';
  }
  if (!validateRequired(issueItem)) {
    errors.issueItem = 'error.required';
  }
  if (!validateRequired(issueSubtype)) {
    errors.issueSubtype = 'error.required';
  }
  if (!validateRequired(city)) {
    errors.city = 'error.required';
  }
  if (!validateDepartment(isAssigneeMe, department)) {
    errors.department = 'error.required';
  }
  if (!validateReporterUnit(isReporterMe, entityType, reporterUnit)) {
    errors.reporterUnit = 'error.required';
  }
  if (!validateReporterTenant(isReporterMe, reporterTenant)) {
    errors.reporterTenant = 'error.required';
  }

  return errors;
};

// returns updated array of attachments for upload, where error fields are set,
// and returns indexError that represents earliest index of attachment with error
export const verifyAttachments = (
  atts: AttachmentForUpload[],
): AttachmentsError | undefined => {
  let indexError: number | undefined;

  const verifiedAttachments = atts.map((a, i) => {
    const errors = verifyAttachmentForUpload(a);

    if (errors) {
      const { description: descriptionError, title: titleError } = errors;

      if (indexError === undefined) indexError = i;

      return { ...a, descriptionError, titleError };
    }

    return a;
  });

  return indexError === undefined
    ? undefined
    : { indexError, verifiedAttachments };
};
