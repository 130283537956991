import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  activeBell: {
    color: (theme) => theme.palette.primary.main,
  },

  notificationBellIcon: {
    color: (theme) => theme.palette.grey[500],
    height: (theme) => theme.spacing(2.75),
    mt: 1,
    width: (theme) => theme.spacing(2.75),
  },
};
