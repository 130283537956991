import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { WritableDraft } from 'immer/dist/internal';

import type { RootState } from '.';
import type { CountrySerializerDTO } from '../../../connectors/property';
import type {
  ListTicketsSerializerDTO,
  TicketMetadataSerializerDTO,
  TicketsCountByStatusSerializerDTO,
  UniversalTicketSerializerDTO,
  UniversalTicketsWithAdditionalFieldsSerializerDTO,
} from '../../../connectors/ticket';
import { initialFilterState, isFilterActive } from '../helpers/filter.helper';
import {
  filterOnlyUniqueTickets,
  filterTicketForDisplay,
} from '../helpers/ticket.helper';
import type { Filter } from '../types/Filter';

export type MetadataForCountry = Record<string, TicketMetadataSerializerDTO>;

export interface TicketState {
  filter: Filter;
  tickets: UniversalTicketsWithAdditionalFieldsSerializerDTO[];
  filteredTickets: UniversalTicketsWithAdditionalFieldsSerializerDTO[];
  ticketsToDisplay: UniversalTicketsWithAdditionalFieldsSerializerDTO[];
  orderedNextPage?: number;
  filterdNextPage?: number;
  metadata: MetadataForCountry;
  count?: TicketsCountByStatusSerializerDTO;
}

const initialState: TicketState = {
  filter: initialFilterState,
  filterdNextPage: 1,
  filteredTickets: [],
  metadata: {},
  orderedNextPage: 1,
  tickets: [],
  ticketsToDisplay: [],
};
const updateTicketInAList = (
  updatedTicket: UniversalTicketSerializerDTO,
  // eslint-disable-next-line max-len
  ticketList: WritableDraft<UniversalTicketsWithAdditionalFieldsSerializerDTO>[],
): UniversalTicketsWithAdditionalFieldsSerializerDTO[] | undefined => {
  return ticketList.map((t) => {
    if (t.uuid !== updatedTicket.uuid) {
      return t;
    }

    return { ...t, ...updatedTicket };
  });
};

/* eslint-disable no-param-reassign */
const ticketSlice = createSlice({
  initialState,
  name: 'ticket',
  reducers: {
    addTicket: (
      state,
      {
        payload,
      }: PayloadAction<UniversalTicketsWithAdditionalFieldsSerializerDTO>,
    ) => {
      const ticketFound = state.tickets.find((t) => t.uuid, payload);

      // if ticket is already in the list of tickets, we do not want to add it again
      if (!ticketFound) {
        state.tickets = [...state.tickets, payload];
      }
    },

    addTickets: (
      state,
      { payload: { results, next } }: PayloadAction<ListTicketsSerializerDTO>,
    ) => {
      const tickets = filterOnlyUniqueTickets(
        results as UniversalTicketsWithAdditionalFieldsSerializerDTO[],
        state.tickets,
      );
      const newTickets = [...state.tickets, ...tickets];

      state.tickets = newTickets;
      state.ticketsToDisplay = filterTicketForDisplay(newTickets);
      state.orderedNextPage = next;
    },

    clearFilteredTickets: (state) => {
      state.filteredTickets = [];
      state.ticketsToDisplay = [];
      state.filterdNextPage = 1;
    },

    removeTicket: (
      state,
      {
        payload: { uuid },
      }: PayloadAction<UniversalTicketsWithAdditionalFieldsSerializerDTO>,
    ) => {
      state.tickets = state.tickets.filter((t) => t.uuid !== uuid);
      state.filteredTickets = state.filteredTickets.filter(
        (t) => t.uuid !== uuid,
      );
      state.ticketsToDisplay = filterTicketForDisplay(
        state.ticketsToDisplay.filter((t) => t.uuid !== uuid),
      );
    },

    setFilter: (
      state,
      {
        payload: { filter, clearFilter },
      }: PayloadAction<{ filter: Filter; clearFilter?: boolean }>,
    ) => {
      if (clearFilter) {
        state.filter = {};
        state.ticketsToDisplay = filterTicketForDisplay([...state.tickets]);
      } else {
        state.filter = filter;
      }
    },

    setFilteredTickets: (
      state,
      { payload: { next, results } }: PayloadAction<ListTicketsSerializerDTO>,
    ) => {
      const tickets = filterOnlyUniqueTickets(
        results as UniversalTicketsWithAdditionalFieldsSerializerDTO[],
        state.filteredTickets,
      );
      const newTickets = [...state.filteredTickets, ...tickets];

      state.filteredTickets = newTickets;
      state.ticketsToDisplay = filterTicketForDisplay(newTickets);
      state.filterdNextPage = next;
    },

    setMetadataForCountryUuid: (
      state,
      {
        payload,
      }: PayloadAction<{
        countryUuid: string;
        metadata: TicketMetadataSerializerDTO;
      }>,
    ) => {
      const { countryUuid, metadata } = payload;

      state.metadata[countryUuid] = metadata;
    },

    setTicketCount: (
      state,
      { payload }: PayloadAction<TicketsCountByStatusSerializerDTO>,
    ) => {
      state.count = payload;
    },

    updateTicket: (
      state,
      { payload }: PayloadAction<UniversalTicketSerializerDTO>,
    ) => {
      const newTicketList = updateTicketInAList(payload, state.tickets);
      const newFilteredTicketList = updateTicketInAList(
        payload,
        state.filteredTickets,
      );
      const newTicketsToDisplayList = updateTicketInAList(
        payload,
        state.ticketsToDisplay,
      );

      if (newTicketList) state.tickets = newTicketList;
      if (newFilteredTicketList) state.filteredTickets = newFilteredTicketList;
      if (newTicketsToDisplayList)
        state.ticketsToDisplay = filterTicketForDisplay(
          newTicketsToDisplayList,
        );
    },
  },
});

export const {
  addTicket,
  addTickets,
  clearFilteredTickets,
  removeTicket,
  setFilteredTickets,
  setTicketCount,
  setMetadataForCountryUuid,
  updateTicket,
  setFilter,
} = ticketSlice.actions;
export const selectTicketsToDisplay = (
  state: RootState,
): UniversalTicketsWithAdditionalFieldsSerializerDTO[] =>
  state.ticket.ticketsToDisplay;
export const selectTicketByKey = (
  state: RootState,
  key?: string,
): UniversalTicketsWithAdditionalFieldsSerializerDTO | undefined => {
  return state.ticket.tickets.find((t) => t.referenceNumber === key);
};
export const selectHasMoreTickets = (state: RootState): boolean => {
  const { filter, filterdNextPage, orderedNextPage } = state.ticket;

  return Boolean(isFilterActive(filter) ? filterdNextPage : orderedNextPage);
};
export const selectNextPage = (state: RootState): number | undefined => {
  const { filter, filterdNextPage, orderedNextPage } = state.ticket;

  return isFilterActive(filter) ? filterdNextPage : orderedNextPage;
};
export const selectTicketCount = (
  state: RootState,
): TicketsCountByStatusSerializerDTO | undefined => state.ticket.count;
export const selectMetadataForTicket = (
  state: RootState,
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): TicketMetadataSerializerDTO | undefined =>
  state.ticket.metadata[ticket.countryUuid];
export const selectMetadataForCountry = (
  state: RootState,
  country?: CountrySerializerDTO,
): TicketMetadataSerializerDTO | undefined =>
  country ? state.ticket.metadata[country.uuid] : undefined;
export const selectFilter = (state: RootState): Filter => state.ticket.filter;

export default ticketSlice.reducer;
