export enum SnackbarSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export type Snackbar = {
  id: string;
  message: string;
  severity: SnackbarSeverity;
  data?: Record<string, string>;
};
