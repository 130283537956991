import { ReactComponent as CancelIcon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-left-1.svg';
import { ReactComponent as CheckCircle } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/check-circle-1.svg';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Typography,
} from '@mui/material';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useMemo } from 'react';

import { mergeSx } from '../../helpers/sx.helper';
import { globalSxProps } from '../../style';
import { useT } from '../../translation/translation.provider';
import type { IconType } from '../IconWrapper';
import { IconWrapper } from '../IconWrapper';
import { sxProps } from './confirmation-popup.style';

export type ConfirmationPopupData = {
  titleKey?: string;
  text1Key?: string;
  text2Key?: string;
  icon?: IconType;
  confirmButtonKey?: string;
  confirmButtonIcon?: IconType;
  cancelButtonKey?: string;
  cancelButtonIcon?: IconType;
};

export type ConfirmationPopupProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirmCallback?: () => void;
  onCancelCallback?: () => void;
  confirmationPopupData: ConfirmationPopupData;
};

export const ConfirmationPopup: FC<ConfirmationPopupProps> = ({
  confirmationPopupData,
  open,
  setOpen,
  onCancelCallback,
  onConfirmCallback,
}: ConfirmationPopupProps) => {
  const t = useT();
  const {
    cancelButtonIcon,
    cancelButtonKey,
    confirmButtonIcon,
    confirmButtonKey,
    icon,
    text1Key,
    text2Key,
    titleKey,
  } = confirmationPopupData;

  const confirm = (): void => {
    setOpen(false);
    if (onConfirmCallback) onConfirmCallback();
  };

  const cancel = (): void => {
    setOpen(false);
    if (onCancelCallback) onCancelCallback();
  };

  const iconWrapper = useMemo(
    () => (icon ? <IconWrapper icon={icon} sx={sxProps.headerIcon} /> : null),
    [icon],
  );

  const title = useMemo(
    () =>
      titleKey ? (
        <Typography
          variant="h1"
          sx={mergeSx(globalSxProps.textBold, sxProps.title)}
        >
          {t(titleKey)}
        </Typography>
      ) : null,
    [t, titleKey],
  );

  const text1 = useMemo(
    () =>
      text1Key ? <Typography gutterBottom>{t(text1Key)}</Typography> : null,
    [text1Key, t],
  );

  const text2 = useMemo(
    () => (text2Key ? <Typography>{t(text2Key)}</Typography> : null),
    [text2Key, t],
  );

  return (
    <Modal open={open} sx={sxProps.modal}>
      <Card sx={sxProps.modalContent}>
        <CardContent>
          {iconWrapper}
          {title}
          {text1}
          {text2}
        </CardContent>
        <CardActions sx={sxProps.cardActions}>
          <Button
            variant="contained"
            sx={sxProps.button}
            onClick={cancel}
            data-test="locked-picker-modal-button"
          >
            <IconWrapper
              icon={cancelButtonIcon || CancelIcon}
              sx={sxProps.buttonIcon}
            />
            {t(
              cancelButtonKey || 'shared.confirmationPopup.defaultCancelButton',
            )}
          </Button>
          <Button
            variant="contained"
            sx={sxProps.button}
            onClick={confirm}
            data-test="locked-picker-modal-button"
          >
            <IconWrapper
              icon={confirmButtonIcon || CheckCircle}
              sx={sxProps.buttonIcon}
            />
            {t(
              confirmButtonKey ||
                'shared.confirmationPopup.defaultConfirmButton',
            )}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
