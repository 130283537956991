import type {
  LocationTypeEnumDTO,
  TicketMetadataSerializerDTO,
  RoomTypeEnumDTO,
} from '../../../../../../../connectors/ticket';
import { IssueTypeEnumDTO } from '../../../../../../../connectors/ticket';
import type { ModalSelectOption } from '../../../../../../shared/components/ModalSelect';
import { getFailureTypeIcon } from '../../../../../../shared/helpers/failure.helper';

export const getAvailableIssueTypes = (
  metadata?: TicketMetadataSerializerDTO,
  issueLocation?: LocationTypeEnumDTO,
  issueRoom?: RoomTypeEnumDTO,
): IssueTypeEnumDTO[] => {
  if (!metadata?.serviceRequestsValidationMap || !issueLocation || !issueRoom)
    return [];

  return Object.values(IssueTypeEnumDTO).filter((type) =>
    Boolean(
      metadata.serviceRequestsValidationMap[issueLocation][issueRoom][type],
    ),
  );
};

export const getIssueTypeOptions = (
  issuTypes: IssueTypeEnumDTO[],
): ModalSelectOption[] =>
  issuTypes.map((type) => ({
    icon: getFailureTypeIcon(type),
    id: type,
    labelKey: `issue.${type}`,
    value: type,
  }));
