import { Container } from '@mui/material';
import type { FC, ReactNode } from 'react';
import React, { useMemo, useEffect } from 'react';

import {
  loadAccountData,
  loadTicketCount,
} from '../../shared/services/profile.service';
import { loadMyPropertyObjects } from '../../shared/services/property.service';
import { selectLoggedUser } from '../../shared/store/authSlice';
import { useAppSelector } from '../../shared/store/hooks';
import { selectTicketCount } from '../../shared/store/ticketSlice';
import { MyPropertyObjects } from './components/MyPropertyObjects';
import { Overview } from './components/Overview';
import { ProfileDetails } from './components/ProfileDetails';
import { ProfileImage } from './components/ProfileImage';
import { sxProps } from './profile-screen.style';

export const ProfileScreen: FC = () => {
  const profile = useAppSelector((state) => selectLoggedUser(state));
  const ticketCount = useAppSelector((state) => selectTicketCount(state));

  useEffect(() => {
    loadAccountData();
    loadTicketCount();
    loadMyPropertyObjects();
  }, []);

  const ticketCountComponent = useMemo<ReactNode>(() => {
    if (!ticketCount) return null;

    return <Overview ticketCount={ticketCount} />;
  }, [ticketCount]);

  if (!profile?.currentUserData) return null;

  return (
    <Container sx={sxProps.profileScreen}>
      <ProfileImage profile={profile.currentUserData} />
      <ProfileDetails profile={profile.currentUserData} />
      {ticketCountComponent}
      <MyPropertyObjects />
    </Container>
  );
};
