import { Button, Link } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { IconWrapper } from '../../../../../../../../shared/components/IconWrapper';
import type { DetailInformationLinkData } from '../../util';
import { sxProps } from './detail-information-link.style';

export const DetailInformationLink: FC<DetailInformationLinkData> = ({
  link,
  value,
  icon,
}: DetailInformationLinkData) => {
  if (!link || !value) {
    return null;
  }

  return (
    <Link href={link} sx={sxProps.actionLink}>
      <Button color="primary" variant="outlined" sx={sxProps.detailsButton}>
        <IconWrapper icon={icon} sx={sxProps.buttonsIcon} />
        {value}
      </Button>
    </Link>
  );
};
