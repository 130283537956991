import { UsersAPI } from '../../../../connectors/user';
import type { ListEmployeeSerializerDTO } from '../../../../connectors/user/dto/list-employee-serializer-dto';
import { USERS_PER_PAGE } from '../../helpers/const';
import { getEnvValue } from '../../helpers/env.helper';
import { mieInstance } from '../../mie.instance';

class UserClient {
  private userClient;

  constructor() {
    const path = getEnvValue('API_PATH');

    this.userClient = new UsersAPI(undefined, path, mieInstance);
  }

  public fetchUsers = async (
    query: string,
  ): Promise<ListEmployeeSerializerDTO> => {
    const response = await this.userClient.searchEmployeeUsersSearchEmployeeGet(
      1,
      USERS_PER_PAGE,
      undefined, // order
      undefined, // updatedAtLte
      undefined, // updatedAtGt
      query,
    );

    return response.data;
  };
}

export const userClient = new UserClient();
