import { TextField } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useMemo } from 'react';

import type { CountrySerializerDTO } from '../../../../../../../connectors/company';
import type { MeSerializerDTO } from '../../../../../../../connectors/user';
import { ModalSelect } from '../../../../../../shared/components/ModalSelect';
import { loadCountries } from '../../../../../../shared/services/company.service';
import { selectCountries } from '../../../../../../shared/store/companySlice';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { useT } from '../../../../../../shared/translation/translation.provider';
import { sxProps } from './country-selector.style';
import { getCountryOptions } from './util';

export type CountrySelectorProps = {
  accountData: MeSerializerDTO;
  country: CountrySerializerDTO | undefined;
  onChange: (country: CountrySerializerDTO) => void;
  error?: string;
};

export const CountrySelector: FC<CountrySelectorProps> = ({
  accountData,
  country,
  onChange,
  error,
}: CountrySelectorProps) => {
  const t = useT();
  const countries = useAppSelector((state) => selectCountries(state));

  useEffect(() => {
    if (countries.length === 0) {
      loadCountries();
    }
  }, [countries.length]);

  const availableCountries = useMemo(() => {
    return countries.filter((c) => accountData.countryUuids?.includes(c.uuid));
  }, [accountData.countryUuids, countries]);

  useEffect(() => {
    if (!country && availableCountries.length > 0) {
      onChange(availableCountries[0]);
    }
  }, [availableCountries, country, onChange]);

  if (availableCountries.length === 0 || !country) return null;

  if (availableCountries.length === 1) {
    return (
      <TextField
        data-test="create-ticket-country-field"
        value={availableCountries[0].name}
        disabled
        fullWidth
        variant="outlined"
        label={t('+createTicket.countryLabel')}
        error={Boolean(error)}
        helperText={error || ''}
      />
    );
  }

  return (
    <ModalSelect
      fieldname="country_selector"
      value={country.uuid || ''}
      onSelect={(item) => {
        const country = availableCountries.find((c) => c.uuid === item.value);

        if (country) onChange(country);
      }}
      options={getCountryOptions(availableCountries)}
      optionIconSx={sxProps.flag}
      renderAsSelect={{
        error,
        labelKey: '+createTicket.countryLabel',
      }}
    />
  );
};
