import { Box, Typography } from '@mui/material';
import type { FC, RefObject } from 'react';
import React, { useCallback } from 'react';

import type { PriorityTypeEnumDTO } from '../../../../../connectors/ticket';
import {
  selectDescription,
  selectPriority,
  selectTitle,
  setDescription,
  setPriority,
  setTitle,
} from '../../../../shared/store/createTicketSlice';
import { useAppDispatch, useAppSelector } from '../../../../shared/store/hooks';
import { useT } from '../../../../shared/translation/translation.provider';
import type { CreateTicketScreenErrorProps } from '../../create-ticket-screen.component';
import { sxProps } from '../../create-ticket-screen.style';
import { DescriptionInput } from './components/DescriptionInput';
import { PriorityPicker } from './components/PriorityPicker';
import { TitleInput } from './components/TitleInput';

export type TicketDetailFieldsProps = {
  titleRef: RefObject<HTMLDivElement>;
  descriptionRef: RefObject<HTMLDivElement>;
  priorityRef: RefObject<HTMLDivElement>;
};

export const TicketDetailFields: FC<
  CreateTicketScreenErrorProps & TicketDetailFieldsProps
> = ({
  error,
  setError,
  descriptionRef,
  priorityRef,
  titleRef,
}: CreateTicketScreenErrorProps & TicketDetailFieldsProps) => {
  const t = useT();
  const dispatch = useAppDispatch();
  const title = useAppSelector((state) => selectTitle(state));
  const description = useAppSelector((state) => selectDescription(state));
  const priority = useAppSelector((state) => selectPriority(state));

  const titleChangeHandler = useCallback(
    (title: string) => {
      dispatch(setTitle(title));
      setError((error) => {
        return { ...error, title: undefined };
      });
    },
    [dispatch, setError],
  );

  const descriptionChangeHandler = useCallback(
    (description: string) => {
      dispatch(setDescription(description));
      setError((error) => {
        return { ...error, description: undefined };
      });
    },
    [dispatch, setError],
  );

  const priorityChangeHandler = useCallback(
    (priority: PriorityTypeEnumDTO) => {
      dispatch(setPriority(priority));
      setError((error) => {
        return { ...error, priority: undefined };
      });
    },
    [dispatch, setError],
  );

  return (
    <Box>
      <Typography variant="overline" sx={sxProps.overline}>
        {t('+createTicket.description')}
      </Typography>
      <Box sx={sxProps.inputWrapper} ref={titleRef}>
        <TitleInput
          title={title}
          onChange={titleChangeHandler}
          error={error.title}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={descriptionRef}>
        <DescriptionInput
          description={description}
          onChange={descriptionChangeHandler}
          error={error.description}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={priorityRef}>
        <PriorityPicker
          priority={priority}
          onChange={priorityChangeHandler}
          error={error.priority}
        />
      </Box>
    </Box>
  );
};
