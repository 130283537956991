import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { TicketStatusEnumDTO } from '../../../../../../../../../connectors/ticket';
import { sxProps } from './filter-status.style';

export type FilterStatusCheckboxData = {
  labelKey: string;
  status: TicketStatusEnumDTO;
  sx: SxProps<Theme>;
};

export const statusCheckboxes: FilterStatusCheckboxData[] = [
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.Open}`,
    status: TicketStatusEnumDTO.Open,
    sx: sxProps.checkboxOpen,
  },
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.InProgress}`,
    status: TicketStatusEnumDTO.InProgress,
    sx: sxProps.checkboxInProgress,
  },
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.AwaitingTenant}`,
    status: TicketStatusEnumDTO.AwaitingTenant,
    sx: sxProps.checkboxAwaitingTenant,
  },
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.AwaitingExternalProvider}`,
    status: TicketStatusEnumDTO.AwaitingExternalProvider,
    sx: sxProps.checkboxAwaitinExternal,
  },
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.OnHold}`,
    status: TicketStatusEnumDTO.OnHold,
    sx: sxProps.checkboxOnHold,
  },
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.Done}`,
    status: TicketStatusEnumDTO.Done,
    sx: sxProps.checkboxClosed,
  },
];
