import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import backgroundImage from './assets/image-background.jpg';

export const sxProps: Record<string, SxProps<Theme>> = {
  imageBackgroundContainer: {
    // because header is sticky and is 64px height, we need to
    backgroundImage: `url(${backgroundImage})`,
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: { sm: 'none', xs: 'cover' },
    height: '100vh',
    marginTop: (theme) => `-${theme.custom.headerWrapperHeight}`,
  },
  imageBackgroundGrid: {
    height: '100%',
  },
};
