import { TextField } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { useT } from '../../../../../../shared/translation/translation.provider';

export type DescriptionInputProps = {
  description: string;
  onChange: (val: string) => void;
  error?: string;
};

export const DescriptionInput: FC<DescriptionInputProps> = ({
  description,
  onChange,
  error,
}: DescriptionInputProps) => {
  const t = useT();

  return (
    <TextField
      data-test="ticket-create-description-input"
      multiline
      minRows={3}
      placeholder={t('+createTicket.descriptionPlaceholder')}
      label={t('+createTicket.descriptionLabel')}
      fullWidth
      value={description}
      onChange={(e) => onChange(e.target.value)}
      error={Boolean(error)}
      helperText={t(error || '')}
    />
  );
};
