import { Autocomplete, TextField } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import { useDebounce } from '../../hooks/useDebounce';
import { useT } from '../../translation/translation.provider';

export type AutocompleteOption = { label: string; value: string };

export type AutocompleteInputProps = {
  options: AutocompleteOption[];
  onChange: (value?: string) => void;
  onInputChange: (value: string) => void;
  value?: AutocompleteOption;
  labelKey: string;
  placeholderKey: string;
  debounce?: boolean;
  error?: string;
  fieldname: string;
};

export const AutocompleteInput: FC<AutocompleteInputProps> = ({
  options,
  onChange,
  onInputChange,
  value,
  labelKey,
  placeholderKey,
  debounce: isDebounced,
  error,
  fieldname,
}: AutocompleteInputProps) => {
  const t = useT();
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce<string>(inputValue);

  useEffect(() => {
    if (isDebounced) onInputChange(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, isDebounced]);

  return (
    <Autocomplete
      data-test={`autocomplete-${fieldname}-autocomplete`}
      disablePortal
      options={options}
      fullWidth
      value={value || null}
      // when there is new value selected in this component, this function
      // will be triggered
      onChange={(e, newValue) => {
        onChange(newValue?.value);
      }}
      // when there is typing in input field, this will be triggered
      onInputChange={(e, value) => {
        setInputValue(value);
        // if components is debounced, we will use debounce hook
        // if not, we will call callback immediately
        if (!isDebounced) onInputChange(value);
      }}
      // neede for preventing warning message from MUI
      isOptionEqualToValue={(option, value) => option.value === value.value}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          data-test={`autocomplete-${fieldname}-input`}
          {...params}
          label={t(labelKey)}
          placeholder={t(placeholderKey)}
          error={Boolean(error)}
          helperText={t(error || '')}
        />
      )}
    />
  );
};
