import type { FC, FunctionComponent, SVGProps } from 'react';
import React from 'react';

import { mergeSx } from '../../../helpers/sx.helper';
import { IconWrapper } from '../../IconWrapper';
import { sxProps } from './menu-item.style';

export type MenuItemProps = {
  icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  active: boolean;
};

export const MenuItem: FC<MenuItemProps> = ({
  icon,
  active,
}: MenuItemProps) => {
  const menuItemSxProps = mergeSx(
    sxProps.menuItemBox,
    active ? sxProps.menuItemActive : {},
  );

  return <IconWrapper icon={icon} sx={menuItemSxProps} />;
};
