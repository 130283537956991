import { ReactComponent as UndoIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/text-options/undo.svg';
import { ReactComponent as SmileyCheekyIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-cheeky.svg';
import { ReactComponent as SmileyConcernedIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-concerned.svg';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { IconWrapper } from '../../../../../../../../shared/components/IconWrapper';
import { mergeSx } from '../../../../../../../../shared/helpers/sx.helper';
import { globalSxProps } from '../../../../../../../../shared/style';
import { useT } from '../../../../../../../../shared/translation/translation.provider';
import { sxProps } from './issue-location-confirmation-modal.style';

export type IssueLocationConfirmationModalProps = {
  confirm: () => void;
  open: boolean;
  close: () => void;
};

export const IssueLocationConfirmationModal: FC<
  IssueLocationConfirmationModalProps
> = ({ confirm, open, close }: IssueLocationConfirmationModalProps) => {
  const t = useT();

  return (
    <Modal open={open} sx={sxProps.modal} onClose={close}>
      <Card sx={sxProps.modalContent}>
        <CardContent>
          <IconWrapper icon={SmileyConcernedIcon} sx={sxProps.headerIcon} />
          <Typography
            variant="h1"
            sx={mergeSx(globalSxProps.textBold, sxProps.title)}
          >
            {t('+createTicket.issueLocationConfirmationModal.title')}
          </Typography>
          <Typography gutterBottom sx={sxProps.text}>
            {t('+createTicket.issueLocationConfirmationModal.text1')}
          </Typography>
          <Typography gutterBottom sx={sxProps.text}>
            {t('+createTicket.issueLocationConfirmationModal.text2')}
          </Typography>
        </CardContent>
        <CardActions sx={sxProps.cardActions}>
          <Button variant="outlined" sx={sxProps.button} onClick={close}>
            <IconWrapper icon={UndoIcon} sx={sxProps.buttonIcon} />
            {t('+createTicket.issueLocationConfirmationModal.cancel')}
          </Button>
          <Button variant="contained" sx={sxProps.button} onClick={confirm}>
            <IconWrapper icon={SmileyCheekyIcon} sx={sxProps.buttonIcon} />
            {t('+createTicket.issueLocationConfirmationModal.confirm')}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
