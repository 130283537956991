import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import type { CreateCommentSerializerDTO } from '../../../../../../../../../connectors/ticket';
import { CommentVisibilityEnumDTO } from '../../../../../../../../../connectors/ticket';
import { CommentTextField } from '../../../../../../../../shared/components/CommentTextField';
import { useT } from '../../../../../../../../shared/translation/translation.provider';
import type { AttachmentForUpload } from '../../../../../../../../shared/types/AttachmentForUpload';
import { sxProps } from '../../status-update-modal.style';

export type StatusUpdateFormCommentProps = {
  comment: CreateCommentSerializerDTO;
  setComment: Dispatch<SetStateAction<CreateCommentSerializerDTO>>;
  attachments: AttachmentForUpload[];
  setAttachments: Dispatch<SetStateAction<AttachmentForUpload[]>>;
  error?: string;
};

export const StatusUpdateFormComment: FC<StatusUpdateFormCommentProps> = ({
  attachments,
  comment,
  setAttachments,
  setComment,
  error,
}: StatusUpdateFormCommentProps) => {
  const t = useT();
  const [publicCommentForbidden, setPublicCommentForbidden] = useState(false);

  const setVisibility = useCallback(
    (visibility: CommentVisibilityEnumDTO) => {
      setComment((comment) => {
        return { ...comment, visibility };
      });
    },
    [setComment],
  );

  const setCommentContent = useCallback(
    (content: string) => {
      setComment({ ...comment, content });
    },
    [comment, setComment],
  );

  // if publicCommentForbidden is set to true by child component, set visibility to internal
  useEffect(() => {
    if (publicCommentForbidden) {
      setVisibility(CommentVisibilityEnumDTO.Internal);
    }
  }, [publicCommentForbidden, setComment, setVisibility]);

  return (
    <Box>
      <Typography variant="overline" gutterBottom sx={sxProps.modalLabel}>
        {t(`+ticketView.details.statusUpdateForm.commentHeader`)}
      </Typography>
      <FormControl color="secondary" sx={sxProps.messageFormControl}>
        <RadioGroup
          data-test="status-update-modal-form-comment-radio-group"
          sx={sxProps.messageButtons}
          aria-labelledby="status-transition-message-radio-group-id"
          name="controlled-radio-buttons-group"
          value={comment.visibility}
          onChange={(e) => {
            const visibility =
              e.target.value === CommentVisibilityEnumDTO.Public
                ? CommentVisibilityEnumDTO.Public
                : CommentVisibilityEnumDTO.Internal;

            setVisibility(visibility);
          }}
        >
          <FormControlLabel
            value={CommentVisibilityEnumDTO.Public}
            control={<Radio data-test="status-update-modal-comment-public" />}
            label={
              t(`+ticketView.details.statusUpdateForm.commentPublic`) as string
            }
            disabled={publicCommentForbidden}
          />
          <FormControlLabel
            value={CommentVisibilityEnumDTO.Internal}
            control={<Radio data-test="status-update-modal-comment-internal" />}
            label={
              t(
                `+ticketView.details.statusUpdateForm.commentInternal`,
              ) as string
            }
            disabled={publicCommentForbidden}
          />
        </RadioGroup>
      </FormControl>
      <CommentTextField
        commentContent={comment.content}
        setCommentContent={setCommentContent}
        attachments={attachments}
        setAttachments={setAttachments}
        sx={sxProps.messageTextArea}
        minRows={3}
        maxRows={3}
        multiline
        fullWidth
        label={t(`+ticketView.details.statusUpdateForm.comment`)}
        error={Boolean(error)}
        helperText={error ? t(error) : ''}
        setForbidPublicComments={setPublicCommentForbidden}
        disablePortal
      />
    </Box>
  );
};
