import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

import { globalSxProps } from '../../../../style/globalStyles';

export const sxProps: Record<string, SxProps<Theme>> = {
  allCircles: {
    display: 'inline',
    position: 'absolute',
  },
  allCirclesContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: (theme) => theme.spacing(2),
    position: 'relative',
  },
  awaitingExternal: {
    left: (theme) => theme.spacing(1.25),
    ...globalSxProps.statusAwaitingExternal,
    zIndex: 5,
  },
  awaitingTenant: {
    left: (theme) => theme.spacing(1),
    ...globalSxProps.statusAwaitingTenant,
    zIndex: 4,
  },
  closedCircle: {
    left: (theme) => theme.spacing(1.5),
    ...globalSxProps.statusClosed,
    zIndex: 6,
  },
  inProgressCircle: {
    left: (theme) => theme.spacing(0.5),
    ...globalSxProps.statusInProgress,
    zIndex: 2,
  },
  menuItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: (theme) => theme.spacing(0.2, 0, 0, 1.5),
  },
  menuItemAllCirclesBox: {
    padding: (theme) => theme.spacing(0, 0.5, 0.1, 0.5),
  },
  menuItemCircle: {
    borderRadius: '50%',
    height: (theme) => theme.spacing(1.25),
    width: (theme) => theme.spacing(1.25),
  },
  menuItemLabel: {
    '&.Mui-focused': {
      color: (theme) => theme.palette.primary.contrastText,
    },
    color: (theme) => theme.palette.primary.contrastText,
  },
  menuItemSingleCircleBox: {
    padding: (theme) => theme.spacing(0, 0.5, 0.1, 1.25),
  },
  menuItemText: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '250%',
    paddingLeft: (theme) => theme.spacing(1),
  },
  onHold: {
    left: (theme) => theme.spacing(0.75),
    ...globalSxProps.statusOnHold,
    zIndex: 3,
  },
  openCircle: {
    left: (theme) => theme.spacing(0.25),
    ...globalSxProps.statusOpen,
    zIndex: 1,
  },
  selectedMenuItemText: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    lineHeight: '150%',
    maxWidth: (theme) => theme.spacing(20),
    overflow: 'hidden',
    paddingLeft: (theme) => theme.spacing(1),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};
