import { Box } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect } from 'react';

import type { StatusUpdateFormProps } from '../../status-update-modal.component';
import {
  StatusUpdateFormComment,
  StatusUpdateFormDate,
  StatusUpdateFormSelect,
} from '../StatusUpdateFormComponents';
import {
  estimatedDateForResumptionOfTaskKey,
  estimatedDateForResumtionOfTaskStartValue,
  reasonCodeTaskSentToExternalProviderKey,
  reasonCodeTaskSentToExternalProviderOptions,
} from './util';

export const AwaitingExternalProviderStatusForm: FC<StatusUpdateFormProps> = ({
  data,
  setFormData,
  commentError,
  attachments,
  comment,
  setAttachments,
  setComment,
}: StatusUpdateFormProps) => {
  //  setting form data with default values
  useEffect(() => {
    setFormData(
      reasonCodeTaskSentToExternalProviderKey,
      reasonCodeTaskSentToExternalProviderOptions[0],
    );
    setFormData(
      estimatedDateForResumptionOfTaskKey,
      estimatedDateForResumtionOfTaskStartValue,
    );
  }, [setFormData]);

  return (
    <Box>
      <StatusUpdateFormSelect
        value={
          data[reasonCodeTaskSentToExternalProviderKey] ||
          reasonCodeTaskSentToExternalProviderOptions[0]
        }
        options={reasonCodeTaskSentToExternalProviderOptions}
        fieldname={reasonCodeTaskSentToExternalProviderKey}
        onChange={setFormData}
      />
      <StatusUpdateFormDate
        fieldname={estimatedDateForResumptionOfTaskKey}
        value={
          data[estimatedDateForResumptionOfTaskKey] ||
          estimatedDateForResumtionOfTaskStartValue
        }
        onChange={setFormData}
      />
      <StatusUpdateFormComment
        error={commentError}
        comment={comment}
        setComment={setComment}
        attachments={attachments}
        setAttachments={setAttachments}
      />
    </Box>
  );
};
