import { Box, Typography } from '@mui/material';
import type { FC, RefObject } from 'react';
import React, { useEffect, useCallback } from 'react';

import type {
  RoomTypeEnumDTO,
  LocationTypeEnumDTO,
  IssueTypeEnumDTO,
  DamagedItemEnumDTO,
  IssueSubtypeEnumDTO,
} from '../../../../../connectors/ticket';
import { loadMetadata } from '../../../../shared/services/ticket.service';
import {
  selectCountry,
  selectEntityType,
  selectIsEntitySet,
  selectIssueItem,
  selectIssueLocation,
  selectIssueRoom,
  selectIssueSubtype,
  selectIssueType,
  setIssueItem,
  setIssueLocation,
  setIssueRoom,
  setIssueSubtype,
  setIssueType,
} from '../../../../shared/store/createTicketSlice';
import { useAppDispatch, useAppSelector } from '../../../../shared/store/hooks';
import { selectMetadataForCountry } from '../../../../shared/store/ticketSlice';
import { useT } from '../../../../shared/translation/translation.provider';
import type { CreateTicketScreenErrorProps } from '../../create-ticket-screen.component';
import { sxProps } from '../../create-ticket-screen.style';
import { IssueItemPicker } from './components/IssueItemPicker';
import { IssueLocationPicker } from './components/IssueLocationPicker';
import { IssueRoomPicker } from './components/IssueRoomPicker';
import { IssueSubtypePicker } from './components/IssueSubtypePicker';
import { IssueTypePicker } from './components/IssueTypePicker';

export type IssueChooserProps = {
  issueLocationRef: RefObject<HTMLDivElement>;
  issueRoomRef: RefObject<HTMLDivElement>;
  issueTypeRef: RefObject<HTMLDivElement>;
  issueItemRef: RefObject<HTMLDivElement>;
  issueSubtypeRef: RefObject<HTMLDivElement>;
};

export const IssueChooser: FC<
  CreateTicketScreenErrorProps & IssueChooserProps
> = ({
  error,
  setError,
  issueItemRef,
  issueLocationRef,
  issueRoomRef,
  issueSubtypeRef,
  issueTypeRef,
}: CreateTicketScreenErrorProps & IssueChooserProps) => {
  const t = useT();
  const dispatch = useAppDispatch();
  const issueLocation = useAppSelector((state) => selectIssueLocation(state));
  const issueRoom = useAppSelector((state) => selectIssueRoom(state));
  const issueType = useAppSelector((state) => selectIssueType(state));
  const issueItem = useAppSelector((state) => selectIssueItem(state));
  const issueSubtype = useAppSelector((state) => selectIssueSubtype(state));
  const country = useAppSelector((state) => selectCountry(state));
  const entityType = useAppSelector((state) => selectEntityType(state));
  const isEntitySet = useAppSelector((state) => selectIsEntitySet(state));

  const metadata = useAppSelector((state) =>
    selectMetadataForCountry(state, country),
  );

  useEffect(() => {
    if (!metadata && country) {
      loadMetadata(country.uuid);
    }
  });

  const issueSubtypeChangeHandler = useCallback(
    (issueSubtype?: IssueSubtypeEnumDTO) => {
      dispatch(setIssueSubtype(issueSubtype));
      setError((error) => {
        return { ...error, issueSubtype: undefined };
      });
    },
    [dispatch, setError],
  );

  const issueItemChangeHandler = useCallback(
    (issueItem?: DamagedItemEnumDTO) => {
      dispatch(setIssueItem(issueItem));
      issueSubtypeChangeHandler(undefined);
      setError((error) => {
        return { ...error, issueItem: undefined };
      });
    },
    [dispatch, issueSubtypeChangeHandler, setError],
  );

  const issueTypeChangeHandler = useCallback(
    (issueType?: IssueTypeEnumDTO) => {
      dispatch(setIssueType(issueType));
      issueItemChangeHandler(undefined);
      setError((error) => {
        return { ...error, issueType: undefined };
      });
    },
    [dispatch, issueItemChangeHandler, setError],
  );

  const issueRoomChangeHandler = useCallback(
    (issueRoom?: RoomTypeEnumDTO) => {
      dispatch(setIssueRoom(issueRoom));
      issueTypeChangeHandler(undefined);
      setError((error) => {
        return { ...error, issueRoom: undefined };
      });
    },
    [dispatch, issueTypeChangeHandler, setError],
  );

  const issueLocationChangeHandler = useCallback(
    (issueLocation?: LocationTypeEnumDTO) => {
      dispatch(setIssueLocation(issueLocation));
      issueRoomChangeHandler(undefined);
      setError((error) => {
        return { ...error, issueLocation: undefined };
      });
    },
    [dispatch, issueRoomChangeHandler, setError],
  );

  if (!isEntitySet || !metadata) return null;

  return (
    <Box>
      <Typography variant="overline" sx={sxProps.overline}>
        {t('+createTicket.issue')}
      </Typography>
      <Box sx={sxProps.inputWrapper} ref={issueLocationRef}>
        <IssueLocationPicker
          metadata={metadata}
          entityType={entityType}
          issueLocation={issueLocation}
          onChange={issueLocationChangeHandler}
          error={error.issueLocation}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={issueRoomRef}>
        <IssueRoomPicker
          metadata={metadata}
          issueLocation={issueLocation}
          issueRoom={issueRoom}
          onChange={issueRoomChangeHandler}
          error={error.issueRoom}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={issueTypeRef}>
        <IssueTypePicker
          metadata={metadata}
          issueLocation={issueLocation}
          issueRoom={issueRoom}
          issueType={issueType}
          onChange={issueTypeChangeHandler}
          error={error.issueType}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={issueItemRef}>
        <IssueItemPicker
          metadata={metadata}
          issueLocation={issueLocation}
          issueRoom={issueRoom}
          issueType={issueType}
          issueItem={issueItem}
          onChange={issueItemChangeHandler}
          error={error.issueItem}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={issueSubtypeRef}>
        <IssueSubtypePicker
          metadata={metadata}
          issueLocation={issueLocation}
          issueRoom={issueRoom}
          issueType={issueType}
          issueItem={issueItem}
          onChange={issueSubtypeChangeHandler}
          issueSubtype={issueSubtype}
          error={error.issueSubtype}
        />
      </Box>
    </Box>
  );
};
