import { ArrowBackIos } from '@mui/icons-material';
import { Grid } from '@mui/material';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';

import { paths } from '../../../../shared/routes/paths';
import { useT } from '../../../../shared/translation/translation.provider';
import { useTicket } from '../../ticket.context';
import { TicketViewNavbarButton } from './components/TicketViewNavbarButton';
import { sxProps } from './ticket-view-navbar.style';
import type { TicketViewNavbarItem } from './types';
import { ticketViewNavbarProps } from './util';

const navbarItems: TicketViewNavbarItem[] = [
  ticketViewNavbarProps.DETAILS,
  ticketViewNavbarProps.ATTACHMENTS,
  ticketViewNavbarProps.MESSAGES,
];

export const TicketViewNavbar: FC = () => {
  const t = useT();
  const location = useLocation();
  const navigate = useNavigate();
  const { referenceNumber } = useTicket();

  const createNavbarItem = (navbarItem: TicketViewNavbarItem): ReactNode => {
    const path = `/${paths.ticket}/${referenceNumber}/${navbarItem.path}`;
    const active = path === location.pathname;

    return (
      <Grid item key={navbarItem.value}>
        <TicketViewNavbarButton
          label={t(navbarItem.labelKey)}
          path={path}
          active={active}
          navbarItem={navbarItem}
        />
      </Grid>
    );
  };

  const onBackButtonClick = (): void => {
    navigate(paths.root);
  };

  return (
    <Grid container sx={sxProps.ticketViewNavbar}>
      <Grid item onClick={onBackButtonClick}>
        <ArrowBackIos sx={sxProps.backButton} />
      </Grid>
      {navbarItems.map((item) => {
        return createNavbarItem(item);
      })}
    </Grid>
  );
};
