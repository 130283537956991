import { Box, Grid, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { TicketStatusEnumDTO } from '../../../../../../connectors/ticket';
import { mergeSx } from '../../../../helpers/sx.helper';
import { globalSxProps } from '../../../../style';
import { sxProps } from './status-menu-item.style';

export type StatusMenuItemProps = {
  label: string;
  status?: TicketStatusEnumDTO;
  selected: boolean;
  showCircles: boolean;
};

export const StatusMenuItem: FC<StatusMenuItemProps> = ({
  label,
  status,
  selected,
  showCircles,
}: StatusMenuItemProps) => {
  const allCircleSx = useMemo<SxProps<Theme>>(() => {
    return mergeSx(sxProps.allCircles, sxProps.menuItemCircle);
  }, []);

  const circles = useMemo(() => {
    switch (status) {
      case TicketStatusEnumDTO.Open:
        return <Box sx={mergeSx(sxProps.menuItemCircle, sxProps.openCircle)} />;
      case TicketStatusEnumDTO.InProgress:
        return (
          <Box sx={mergeSx(sxProps.menuItemCircle, sxProps.inProgressCircle)} />
        );
      case TicketStatusEnumDTO.OnHold:
        return <Box sx={mergeSx(sxProps.menuItemCircle, sxProps.onHold)} />;
      case TicketStatusEnumDTO.AwaitingTenant:
        return (
          <Box sx={mergeSx(sxProps.menuItemCircle, sxProps.awaitingTenant)} />
        );
      case TicketStatusEnumDTO.AwaitingExternalProvider:
        return (
          <Box sx={mergeSx(sxProps.menuItemCircle, sxProps.awaitingExternal)} />
        );
      case TicketStatusEnumDTO.Done:
        return (
          <Box sx={mergeSx(sxProps.menuItemCircle, sxProps.closedCircle)} />
        );
      default:
        return (
          <Box sx={sxProps.allCirclesContainer}>
            <Box
              sx={mergeSx(
                allCircleSx,
                sxProps.openCircle,
                globalSxProps.statusOpen,
              )}
            />
            <Box sx={mergeSx(allCircleSx, sxProps.inProgressCircle)} />
            <Box sx={mergeSx(allCircleSx, sxProps.onHold)} />
            <Box sx={mergeSx(allCircleSx, sxProps.awaitingTenant)} />
            <Box sx={mergeSx(allCircleSx, sxProps.awaitingExternal)} />
          </Box>
        );
    }
  }, [allCircleSx, status]);

  const showCirclesBox = useMemo(() => {
    return showCircles ? (
      <Grid item>
        <Box
          sx={
            status
              ? sxProps.menuItemSingleCircleBox
              : sxProps.menuItemAllCirclesBox
          }
        >
          {circles}
        </Box>
      </Grid>
    ) : null;
  }, [showCircles, circles, status]);

  return (
    <Grid sx={sxProps.menuItem} container>
      {showCirclesBox}
      <Grid item>
        <Typography
          sx={selected ? sxProps.selectedMenuItemText : sxProps.menuItemText}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};
