import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  ticketListScreenWrapper: {
    backgroundColor: (theme) => theme.palette.background.paper,
    height: (theme) => theme.custom.pageHeight,
    overflow: 'hidden',
    padding: 0,
  },
};
