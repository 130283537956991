import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  ticketHeaderBox: {
    padding: (theme) => theme.spacing(3, 0, 0, 1),
  },

  ticketHeaderText: {
    color: (theme) => theme.palette.grey[600],
    paddingLeft: (theme) => theme.spacing(0.25),
  },

  ticketHeaderTextPrimary: {
    color: (theme) => theme.palette.primary.main,
  },

  ticketViewWrapper: {
    background: (theme) => theme.palette.common.white,
  },
};
