import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  buttonsBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: (theme) => theme.spacing(1.5),
  },
  buttonText: {
    marginLeft: (theme) => theme.spacing(0.25),
  },
  commentInput: {
    marginBottom: (theme) => theme.spacing(2),
    width: '100%',
  },
  inactiveTextBox: { marginBottom: (theme) => theme.spacing(1.5) },
  mentionsBox: {
    backgroundColor: (theme) => theme.palette.common.white,
    display: 'block',
    width: '100%',
  },
  sendInternalButton: {
    color: (theme) => theme.palette.primary.contrastText,
    flex: 4,
    height: (theme) => theme.spacing(5.25),
    marginRight: (theme) => theme.spacing(1),
  },
  sendPublicButton: {
    flex: 3,
    height: (theme) => theme.spacing(5.25),
  },
};
