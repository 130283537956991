import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  checkbox: {
    '& .MuiCheckbox-root': {
      padding: (theme) => theme.spacing(0.25, 0.5, 0.25, 2),
    },
  },

  filterAdditionalWrapper: {
    marginTop: (theme) => theme.spacing(1),
  },

  formGroup: {
    paddingTop: (theme) => theme.spacing(0.5),
  },

  label: {
    color: (theme) => theme.palette.primary.main,
  },
};
