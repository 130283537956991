import type { TenantSerializerDTO } from '../../../../../../../connectors/property';
import type { ModalSelectOption } from '../../../../../../shared/components/ModalSelect';
import { getUserFullname } from '../../../../../../shared/helpers/user.helper';

export const getReporterTenantOptions = (
  availableTenants: TenantSerializerDTO[],
): ModalSelectOption[] => {
  return availableTenants.map((at) => {
    return { id: at.uuid, labelKey: getUserFullname(at), value: at.uuid };
  });
};
