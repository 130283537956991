import type { ModalSelectOption } from '../../../../../../shared/components/ModalSelect';

export const ASSIGNEE_ME = 'ASSIGNEE_ME';
export const ASSIGNEE_HEIMSTADEN = 'ASSIGNEE_HEIMSTADEN';

export const isAssigneeMeOptions: ModalSelectOption[] = [
  { id: '0', labelKey: '+createTicket.assigneeMeOption', value: ASSIGNEE_ME },
  {
    id: '1',
    labelKey: '+createTicket.assigneeHeimstadenOption',
    value: ASSIGNEE_HEIMSTADEN,
  },
];
