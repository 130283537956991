import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  filterOrderWrapper: {
    marginTop: (theme) => theme.spacing(1),
  },

  label: {
    color: (theme) => theme.palette.primary.main,
  },

  select: {
    marginTop: (theme) => theme.spacing(1),
  },
};
