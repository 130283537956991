import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  AttachmentSerializerDTO,
  ListAttachmentSerializerDTO,
  UniversalTicketsWithAdditionalFieldsSerializerDTO,
} from '../../../connectors/ticket';
import type { RootState } from './index';

export interface AttachmentState {
  attachments: Record<string, AttachmentSerializerDTO[]>;
}

const initialState: AttachmentState = { attachments: {} };

/* eslint-disable no-param-reassign */
const attachmentSlice = createSlice({
  initialState,
  name: 'attachment',
  reducers: {
    addAttachments: (
      state,
      action: PayloadAction<{
        ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO;
        attachments: ListAttachmentSerializerDTO;
        append?: boolean;
      }>,
    ) => {
      const {
        ticket: { uuid: ticketUuid },
        attachments: { results },
        append,
      } = action.payload;

      if (append) {
        const oldAttachments = state.attachments[ticketUuid] || [];
        const filteredAttachments = results.filter(
          (newA) => !oldAttachments.find((oldA) => oldA.uuid === newA.uuid),
        );

        state.attachments[ticketUuid] = [
          ...oldAttachments,
          ...filteredAttachments,
        ];
      } else {
        state.attachments[ticketUuid] = results;
      }
    },
  },
});

export const { addAttachments } = attachmentSlice.actions;
export const selectAttachmentsForTicket = (
  state: RootState,
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): AttachmentSerializerDTO[] =>
  state.attachments.attachments[ticket.uuid] || [];

export default attachmentSlice.reducer;
