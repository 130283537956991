import { store } from '../store';
import {
  clearQueriedCities,
  setCountries,
  setQueriedCities,
} from '../store/companySlice';
import { setLoading } from '../store/loadingSlice';
import { companyClient } from './clients/company.client';
import type { QueryCitiesParams } from './types/company.types';

export const loadCountries = async (): Promise<void> => {
  if (store.getState().company.countries.length > 0) return;
  store.dispatch(setLoading(true));
  try {
    const countries = await companyClient.getCountries();

    store.dispatch(setCountries(countries));
  } catch {
    //  fails silently
  }
  store.dispatch(setLoading(false));
};

export const queryCities = async (params: QueryCitiesParams): Promise<void> => {
  if (!params.query) {
    store.dispatch(clearQueriedCities());

    return;
  }
  store.dispatch(setLoading(true));
  try {
    const queriedCities = await companyClient.getCitiesForCountry(params);

    store.dispatch(setQueriedCities(queriedCities));
  } catch {
    //  fails silently
  }
  store.dispatch(setLoading(false));
};
