import type { CountrySerializerDTO } from '../../../connectors/company';
import { LanguageDTO } from '../../../connectors/company';
import enFlag from './assets/en.png';
import nlFlag from './assets/nl.png';
import noFlag from './assets/no.png';
import plFlag from './assets/pl.png';

export const NETHERLANDS = 'Netherlands';
export const POLAND = 'Poland';
export const NORWAY = 'Norway';
export const ENGLAND = 'England';

export const getCountryFlag = (countryName: string): string | undefined => {
  switch (countryName) {
    case NETHERLANDS:
      return nlFlag;
    case POLAND:
      return plFlag;
    case NORWAY:
      return noFlag;
    case ENGLAND:
      return enFlag;
    default:
      return undefined;
  }
};

export const getLanguage = (
  country: CountrySerializerDTO,
): LanguageDTO | undefined => {
  switch (country.name) {
    case NETHERLANDS:
      return LanguageDTO.Nl;
    case POLAND:
      return LanguageDTO.Pl;
    case NORWAY:
      return LanguageDTO.No;
    case ENGLAND:
      return LanguageDTO.En;
    default:
      return undefined;
  }
};

// also adds english
export const mapCountriesToLanguages = (
  countries: CountrySerializerDTO[],
): LanguageDTO[] => {
  return [
    LanguageDTO.En,
    ...(countries
      .map((c) => getLanguage(c))
      .filter((c) => Boolean(c)) as LanguageDTO[]),
  ];
};
