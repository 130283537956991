import type { ModalSelectOption } from '../../../../../../shared/components/ModalSelect';

export const REPORTER_ME = 'REPORTER_ME';
export const REPORTER_TENANT = 'REPORTER_TENANT';

export const isReporterMeOptions: ModalSelectOption[] = [
  { id: '0', labelKey: '+createTicket.reporterMeOption', value: REPORTER_ME },
  {
    id: '1',
    labelKey: '+createTicket.reporterTenantOption',
    value: REPORTER_TENANT,
  },
];
