import type { FC } from 'react';
import React from 'react';

import type { EntityEnumDTO } from '../../../../../../../connectors/ticket';
import { ModalSelect } from '../../../../../../shared/components/ModalSelect';
import { entityTypeOptions } from './util';

export type EntityTypePickerProps = {
  // in case we want to hide this component, we can use this parameter
  isHidden?: boolean;
  entityType?: EntityEnumDTO;
  onChange: (entityType: EntityEnumDTO) => void;
  error?: string;
};

export const entityTypePickerLabelId = 'entityTypePickerLabelId';

export const EntityTypePicker: FC<EntityTypePickerProps> = ({
  isHidden,
  entityType,
  onChange,
  error,
}: EntityTypePickerProps) => {
  if (isHidden) return null;

  return (
    <ModalSelect
      fieldname="entity_type"
      value={entityType || ''}
      onSelect={(item) => onChange(item.value as EntityEnumDTO)}
      options={entityTypeOptions}
      renderAsSelect={{
        error,
        labelKey: '+createTicket.enitityTypeLabel',
      }}
    />
  );
};
