import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  dashboardTicketListItemStatusIndicator: {
    borderRadius: (theme) => theme.spacing(0.75),
    bottom: (theme) => theme.spacing(1),
    maxWidth: (theme) => theme.spacing(1),
    padding: (theme) => theme.spacing(1, 1, 1, 0),
    position: 'absolute',
    right: (theme) => theme.spacing(1),
    top: (theme) => theme.spacing(1),
  },
};
