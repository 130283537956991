import { Box, Typography } from '@mui/material';
import type { FC, RefObject } from 'react';
import React, { useCallback } from 'react';

import type { DepartmentTypeEnumDTO } from '../../../../../connectors/ticket';
import type { EmployeeSerializerDTO } from '../../../../../connectors/user';
import { AssigneePicker } from '../../../../shared/components/AssigneePicker';
import { DepartmentPicker } from '../../../../shared/components/DepartmentPicker';
import {
  selectAssignee,
  selectDepartment,
  selectIsAssigneeMe,
  selectIsEntitySet,
  selectIsIssueSet,
  setAssignee,
  setDepartment,
  setIsAssigneeMe,
} from '../../../../shared/store/createTicketSlice';
import { useAppDispatch, useAppSelector } from '../../../../shared/store/hooks';
import { useT } from '../../../../shared/translation/translation.provider';
import type { CreateTicketScreenErrorProps } from '../../create-ticket-screen.component';
import { sxProps } from '../../create-ticket-screen.style';
import { IsAssigneeMePicker } from './components/IsAssigneeMePicker';

export type AssigneeChooserProps = {
  isAssigneeMeRef: RefObject<HTMLDivElement>;
  assigneeRef: RefObject<HTMLDivElement>;
  departmentRef: RefObject<HTMLDivElement>;
};

export const AssigneeChooser: FC<
  CreateTicketScreenErrorProps & AssigneeChooserProps
> = ({
  error,
  setError,
  assigneeRef,
  departmentRef,
  isAssigneeMeRef,
}: CreateTicketScreenErrorProps & AssigneeChooserProps) => {
  const t = useT();
  const dispatch = useAppDispatch();
  const isAssigneeMe = useAppSelector((state) => selectIsAssigneeMe(state));
  const assignee = useAppSelector((state) => selectAssignee(state));
  const department = useAppSelector((state) => selectDepartment(state));

  const isEntitySet = useAppSelector((state) => selectIsEntitySet(state));
  const isIssueSet = useAppSelector((state) => selectIsIssueSet(state));

  const assigneeChangeHandler = useCallback(
    (assignee?: EmployeeSerializerDTO) => {
      dispatch(setAssignee(assignee));
      setError((error) => {
        return { ...error, assignee: undefined };
      });
    },
    [dispatch, setError],
  );

  const departmentChangeHandler = useCallback(
    (department?: DepartmentTypeEnumDTO) => {
      dispatch(setDepartment(department));
      assigneeChangeHandler(undefined);
      setError((error) => {
        return { ...error, department: undefined };
      });
    },
    [assigneeChangeHandler, dispatch, setError],
  );

  const isAssigneeMeChangeHandler = useCallback(
    (isAssigneeMe: boolean) => {
      dispatch(setIsAssigneeMe(isAssigneeMe));
      departmentChangeHandler(undefined);
      setError((error) => {
        return { ...error, isAssigneeMe: undefined };
      });
    },
    [departmentChangeHandler, dispatch, setError],
  );

  if (!isIssueSet || !isEntitySet) return null;

  return (
    <Box>
      <Typography variant="overline" sx={sxProps.overline}>
        {t('+createTicket.assignee')}
      </Typography>
      <Box sx={sxProps.inputWrapper} ref={isAssigneeMeRef}>
        <IsAssigneeMePicker
          isAssigneeMe={isAssigneeMe}
          onChange={isAssigneeMeChangeHandler}
          error={error.isReporterMe}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={departmentRef}>
        <DepartmentPicker
          department={department}
          onChange={departmentChangeHandler}
          isHidden={isAssigneeMe}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={assigneeRef}>
        <AssigneePicker
          onUserSelect={assigneeChangeHandler}
          assignee={assignee}
          isHidden={isAssigneeMe}
        />
      </Box>
    </Box>
  );
};
