import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  detailInformationCustomerBox: {
    paddingTop: (theme) => theme.spacing(4.5),
  },
  detailInformationCustomerDetailsBox: {
    paddingTop: (theme) => theme.spacing(2),
  },
  detailInformationCustomerTitleBox: {
    paddingBottom: (theme) => theme.spacing(1),
    paddingTop: (theme) => theme.spacing(0.25),
  },
  detailInformationDescriptionBox: {
    paddingTop: (theme) => theme.spacing(5),
  },
  detailInformationError: {
    color: (theme) => theme.palette.error.main,
  },
  detailInformationHeader: {
    color: (theme) => theme.palette.primary.main,
  },
  detailInformationIcon: {
    color: (theme) => theme.palette.grey[500],
    height: (theme) => theme.spacing(4.5),
    margin: (theme) => theme.spacing(0.6, 2.5, 0, 0),
    width: (theme) => theme.spacing(4.5),
  },
  detailInformationLabel: {
    color: (theme) => theme.palette.grey[500],
  },
  detailInformationsBox: {
    padding: (theme) => theme.spacing(2, 1.5, 0, 1.5),
  },
};
