import { List } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { isFilterActive } from '../../../../shared/helpers/filter.helper';
import { mergeSx } from '../../../../shared/helpers/sx.helper';
import { loadTickets } from '../../../../shared/services/ticket.service';
import { useAppSelector } from '../../../../shared/store/hooks';
import { selectLoading } from '../../../../shared/store/loadingSlice';
import {
  selectFilter,
  selectHasMoreTickets,
  selectNextPage,
  selectTicketsToDisplay,
} from '../../../../shared/store/ticketSlice';
import { NoTicketScreen } from './components/NoTicketScreen';
import { TicketListItem } from './components/TicketListItem';
import { sxProps } from './ticket-list-view.style';

const ticketListId = 'ticketListId';

export const TicketListView: FC = () => {
  const tickets = useAppSelector((state) => selectTicketsToDisplay(state));
  const hasMoreTickets = useAppSelector((state) => selectHasMoreTickets(state));
  const nextPage = useAppSelector((state) => selectNextPage(state));
  const isLoading = useAppSelector((state) => selectLoading(state));
  const filter = useAppSelector((state) => selectFilter(state));

  // if no tickets are loaded, filters are not set and we are mounting this component,
  // only then tickets should be loaded. This will prevent showing of happy screen
  // before tickets are loaded for the first time
  useEffect(() => {
    if (tickets.length === 0 && !isFilterActive(filter)) {
      loadTickets({ page: 1 }, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when filters are active, we need make list shorter so it can
  // fit on the screen completely
  const listSx = useMemo(() => {
    const listHeight = isFilterActive(filter)
      ? sxProps.heightWhenFilterIsActive
      : sxProps.fullHeight;

    return mergeSx(listHeight, sxProps.ticketList);
  }, [filter]);

  if (!isLoading && tickets.length === 0) {
    return <NoTicketScreen />;
  }

  return (
    <List sx={listSx} id={ticketListId}>
      <InfiniteScroll
        dataLength={tickets.length}
        next={() => {
          loadTickets({ page: nextPage }, filter);
        }}
        loader={null}
        hasMore={hasMoreTickets}
        scrollableTarget={ticketListId}
      >
        {tickets.map((ticket) => {
          return (
            <TicketListItem key={ticket.referenceNumber} ticket={ticket} />
          );
        })}
      </InfiniteScroll>
    </List>
  );
};
