import type {
  ListPropertiesSerializerDTO,
  ListPropertyObjectsSerializerDTO,
  ListTenantSerializerDTO,
  ListUnitsSerializerDTO,
} from '../../../../connectors/property';
import {
  PropertiesAPI,
  PropertyObjectsAPI,
  UnitsAPI,
} from '../../../../connectors/property';
import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../../connectors/ticket';
import { EntityEnumDTO } from '../../../../connectors/ticket';
import { ENTITIES_PER_PAGE } from '../../helpers/const';
import { getEnvValue } from '../../helpers/env.helper';
import { mieInstance } from '../../mie.instance';
import type {
  EntityType,
  GetUnitsForEntityParams,
  QueryEntitiesParams,
} from '../types/property.types';

class PropertyClient {
  private propertyClient;

  private propertyObjectClient;

  private unitClient;

  constructor() {
    const path = getEnvValue('API_PATH');

    this.propertyClient = new PropertiesAPI(undefined, path, mieInstance);
    this.propertyObjectClient = new PropertyObjectsAPI(
      undefined,
      path,
      mieInstance,
    );
    this.unitClient = new UnitsAPI(undefined, path, mieInstance);
  }

  public getEntity = async (
    ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
  ): Promise<EntityType | undefined> => {
    let response: EntityType | undefined;

    switch (ticket.entityType) {
      case EntityEnumDTO.Property:
        response = (
          await this.propertyClient.getSinglePropertyPropertiesPropertyUuidGet(
            ticket.entityUuid,
          )
        ).data;
        break;
      case EntityEnumDTO.PropertyObject:
        response = (
          await this.propertyObjectClient.getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(
            ticket.entityUuid,
          )
        ).data;
        break;
      case EntityEnumDTO.Unit:
        response = (
          await this.unitClient.getSingleUnitUnitsUnitUuidGet(ticket.entityUuid)
        ).data;
        break;
      default:
    }

    return response;
  };

  public getMyPropertyObjects =
    async (): Promise<ListPropertyObjectsSerializerDTO> => {
      const response =
        await this.propertyObjectClient.getPropertyObjectsPropertyObjectsGet();

      return response.data;
    };

  public queryProperties = async ({
    query,
    countryUuid,
    cityUuid,
  }: QueryEntitiesParams): Promise<ListPropertiesSerializerDTO> => {
    const response = await this.propertyClient.getPropertiesPropertiesGet(
      1,
      ENTITIES_PER_PAGE,
      undefined, // order
      undefined, // updatedAtLte
      undefined, // updatedAtGt
      query,
      undefined, // nickname
      countryUuid,
      undefined, // regionUuid
      undefined, // municipalityUuid
      cityUuid,
    );

    return response.data;
  };

  public queryPropertyObjects = async ({
    query,
    countryUuid,
    cityUuid,
  }: QueryEntitiesParams): Promise<ListPropertyObjectsSerializerDTO> => {
    const response =
      await this.propertyObjectClient.getPropertyObjectsPropertyObjectsGet(
        1,
        ENTITIES_PER_PAGE,
        undefined, // order
        undefined, // updatedAtLte
        undefined, // updatedAtGt
        query,
        undefined, // company uuid
        countryUuid,
        undefined, // regionUuid
        undefined, // municipalityUuid
        cityUuid,
      );

    return response.data;
  };

  public queryUnits = async ({
    query,
    countryUuid,
    cityUuid,
  }: QueryEntitiesParams): Promise<ListUnitsSerializerDTO> => {
    const response = await this.unitClient.getUnitsUnitsGet(
      1,
      ENTITIES_PER_PAGE,
      undefined, // order
      undefined, // updatedAtLte
      undefined, // updatedAtGt
      query,
      undefined, // property object uuid
      undefined, // property uuid
      undefined, // entrance uuid
      countryUuid,
      undefined, // regionUuid
      cityUuid,
    );

    return response.data;
  };

  public getUnitsForProperty = async ({
    uuid,
    query,
  }: GetUnitsForEntityParams): Promise<ListUnitsSerializerDTO> => {
    const response =
      await this.propertyClient.getPropertyUnitsPropertiesPropertyUuidUnitsGet(
        uuid,
        1, // page
        ENTITIES_PER_PAGE, // page size
        undefined, // order
        query,
      );

    return response.data;
  };

  public getUnitsForPropertyObject = async ({
    uuid,
    query,
  }: GetUnitsForEntityParams): Promise<ListUnitsSerializerDTO> => {
    const response =
      await this.propertyObjectClient.getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(
        uuid,
        1, // page
        ENTITIES_PER_PAGE, // page size
        undefined, // order
        query,
      );

    return response.data;
  };

  public getTenantsForUnit = async (
    unitUuid: string,
  ): Promise<ListTenantSerializerDTO> => {
    const response =
      await this.unitClient.getTenantsForUnitUnitsUnitUuidTenantsGet(unitUuid);

    return response.data;
  };
}

export const propertyClient = new PropertyClient();
