import type { TicketMetadataSerializerDTO } from '../../../../../../../connectors/ticket';
import {
  LocationTypeEnumDTO,
  EntityEnumDTO,
} from '../../../../../../../connectors/ticket';
import type { ModalSelectOption } from '../../../../../../shared/components/ModalSelect';
import { getLocationFailureIcon } from '../../../../../../shared/helpers/failure.helper';

const unitLocations = [LocationTypeEnumDTO.Apartment];
const propertyObjectLocations = [LocationTypeEnumDTO.CommonArea];
const propertyLocaitions = [
  LocationTypeEnumDTO.OutdoorArea,
  LocationTypeEnumDTO.CommonOutdoorArea,
  LocationTypeEnumDTO.PrivateOutdoorArea,
];

export const getAvailableIssueLocations = (
  metadata?: TicketMetadataSerializerDTO,
): LocationTypeEnumDTO[] => {
  if (!metadata?.serviceRequestsValidationMap) return [];

  return Object.values(LocationTypeEnumDTO).filter((location) =>
    Boolean(metadata.serviceRequestsValidationMap[location]),
  );
};

export const getIssueLocationOptions = (
  issueLocations: LocationTypeEnumDTO[],
): ModalSelectOption[] =>
  issueLocations.map((loc) => ({
    icon: getLocationFailureIcon(loc),
    id: loc,
    labelKey: `issue.${loc}`,
    value: loc,
  }));

export const getRecommendedIssueLocations = (
  availableLocations: LocationTypeEnumDTO[],
  entityType?: EntityEnumDTO,
): LocationTypeEnumDTO[] => {
  if (availableLocations.length === 0 || !entityType) {
    return [];
  }
  switch (entityType) {
    case EntityEnumDTO.Property:
      return availableLocations.filter((al) => propertyLocaitions.includes(al));
    case EntityEnumDTO.PropertyObject:
      return availableLocations.filter((al) =>
        propertyObjectLocations.includes(al),
      );
    case EntityEnumDTO.Unit:
      return availableLocations.filter((al) => unitLocations.includes(al));
    default:
      return [];
  }
};

export const getRecommendedIssueLocation = (
  availableLocations: LocationTypeEnumDTO[],
  entityType?: EntityEnumDTO,
): LocationTypeEnumDTO | undefined => {
  if (!entityType) return undefined;

  const recommendedIssueLocations = getRecommendedIssueLocations(
    availableLocations,
    entityType,
  );

  return recommendedIssueLocations.length > 0
    ? recommendedIssueLocations[0]
    : undefined;
};
