import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  checkbox: {
    '& .MuiCheckbox-root': {
      padding: (theme) => theme.spacing(0.25, 0.5, 0.25, 2),
    },
  },

  checkboxAwaitinExternal: {
    '&.Mui-checked': {
      color: (theme) => `${theme.custom.waitingExternal} !important`,
    },
  },
  checkboxAwaitingTenant: {
    '&.Mui-checked': {
      color: (theme) => `${theme.custom.activeReading} !important`,
    },
  },

  checkboxClosed: {
    '&.Mui-checked': {
      color: (theme) => `${theme.palette.success.main} !important`,
    },
  },
  checkboxInProgress: {
    '&.Mui-checked': {
      color: (theme) => `${theme.palette.info.main} !important`,
    },
  },

  checkboxOnHold: {
    '&.Mui-checked': {
      color: (theme) => `${theme.palette.error.main} !important`,
    },
  },

  checkboxOpen: {
    '&.Mui-checked': {
      color: (theme) => `${theme.palette.grey[500]} !important`,
    },
  },

  formGroup: {
    paddingTop: (theme) => theme.spacing(0.5),
  },

  label: {
    color: (theme) => theme.palette.primary.main,
  },
};
