import { Box, Typography } from '@mui/material';
import type { FC, Dispatch, SetStateAction } from 'react';
import React, { useCallback, useState } from 'react';

import { AttachmentUploadButton } from '../../../../shared/components/Attachment/AttachmentUploadButton';
import { AttachmentUploadForm } from '../../../../shared/components/Attachment/AttachmentUploadForm';
import { AttachmentViewModal } from '../../../../shared/components/Attachment/AttachmentViewModal';
import {
  selectIsEntitySet,
  selectIsIssueSet,
} from '../../../../shared/store/createTicketSlice';
import { useAppSelector } from '../../../../shared/store/hooks';
import { useT } from '../../../../shared/translation/translation.provider';
import type { AttachmentForUpload } from '../../../../shared/types/AttachmentForUpload';
import type { CreateTicketScreenErrorProps } from '../../create-ticket-screen.component';
import { sxProps } from '../../create-ticket-screen.style';

export type AttachmentsPickerProps = {
  attachments: AttachmentForUpload[];
  setAttachments: Dispatch<SetStateAction<AttachmentForUpload[]>>;
};

export const AttachmentsPicker: FC<
  CreateTicketScreenErrorProps & AttachmentsPickerProps
> = ({
  setError,
  attachments,
  setAttachments,
}: CreateTicketScreenErrorProps & AttachmentsPickerProps) => {
  const t = useT();

  const isEntitySet = useAppSelector((state) => selectIsEntitySet(state));
  const isIssueSet = useAppSelector((state) => selectIsIssueSet(state));

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [clickedImageIndex, setClickedImageIndex] = useState<number>(0);

  const onAttachmentClick = useCallback((index: number): void => {
    setClickedImageIndex(index);
    setModalOpen(true);
  }, []);

  const onFileSelected = async (newAtt: File): Promise<void> => {
    if (attachments.find((a) => a.file.name === newAtt.name)) return;

    setAttachments([...attachments, { file: newAtt, title: newAtt.name }]);
    setError((error) => {
      return { ...error, attachments: undefined };
    });
  };

  const updateAttachment = (
    updatedAttachment: AttachmentForUpload,
    index: number,
  ): void => {
    setAttachments(
      attachments.map((existingAttachment, i) =>
        i === index ? updatedAttachment : existingAttachment,
      ),
    );
  };

  if (!isIssueSet || !isEntitySet) return null;

  return (
    <Box>
      <Typography variant="overline" sx={sxProps.overline}>
        {t('+createTicket.attachments')}
      </Typography>
      <AttachmentViewModal
        attachmentsForUpload={attachments}
        open={modalOpen}
        setOpen={setModalOpen}
        startIndex={clickedImageIndex}
      />
      <Box sx={sxProps.attachmentsBox}>
        {attachments.map((att, index) => {
          return (
            <AttachmentUploadForm
              attachment={att}
              key={att.file.name}
              setAttachment={(att) => updateAttachment(att, index)}
              onImageClick={() => onAttachmentClick(index)}
              index={index}
            />
          );
        })}
      </Box>
      <AttachmentUploadButton onFileSelected={onFileSelected} />
    </Box>
  );
};
