import type { AuthState } from '../store/authSlice';
import type { CreateTicketState } from '../store/createTicketSlice';
import type { LanguageState } from '../store/languageSlice';
import {
  LOCAL_STORAGE_AUTH_ITEM_KEY,
  LOCAL_STORAGE_CREATE_TICKET_ITEM_KEY,
  LOCAL_STORAGE_LANGUAGE_ITEM_KEY,
} from './const';

const loadState = (key: string): object | undefined => {
  try {
    const serializedState = localStorage.getItem(key);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (
  state: AuthState | LanguageState | CreateTicketState,
  key: string,
): void => {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};

export const loadAuthState = (): object | undefined => {
  return loadState(LOCAL_STORAGE_AUTH_ITEM_KEY);
};

export const loadLanguageState = (): LanguageState | undefined => {
  return loadState(LOCAL_STORAGE_LANGUAGE_ITEM_KEY) as
    | LanguageState
    | undefined;
};

export const loadCreateTicketState = (): CreateTicketState | undefined => {
  return loadState(LOCAL_STORAGE_CREATE_TICKET_ITEM_KEY) as
    | CreateTicketState
    | undefined;
};

export const saveAuthState = (state: AuthState): void => {
  saveState(state, LOCAL_STORAGE_AUTH_ITEM_KEY);
};

export const saveLanguageState = (state: LanguageState): void => {
  saveState(state, LOCAL_STORAGE_LANGUAGE_ITEM_KEY);
};

export const saveCreateTicketState = (state: CreateTicketState): void => {
  saveState({ ...state }, LOCAL_STORAGE_CREATE_TICKET_ITEM_KEY);
};
