import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import type { FC, MouseEvent, SetStateAction, Dispatch } from 'react';
import React, { useEffect, useState } from 'react';

import type { LanguageDTO } from '../../../../../../../../../connectors/ticket';
import { mapCountriesToLanguages } from '../../../../../../../../shared/helpers/flag.helper';
import { selectCountries } from '../../../../../../../../shared/store/companySlice';
import { useAppSelector } from '../../../../../../../../shared/store/hooks';
import { useT } from '../../../../../../../../shared/translation/translation.provider';
import { sxProps } from './template-language-picker.style';

export type TemplateLanguagePickerProps = {
  value?: LanguageDTO;
  setValue: Dispatch<SetStateAction<LanguageDTO | undefined>>;
};

export const TemplateLanguagePicker: FC<TemplateLanguagePickerProps> = ({
  setValue,
  value,
}: TemplateLanguagePickerProps) => {
  const t = useT();
  const [languages, setLanguages] = useState<LanguageDTO[]>([]);
  const countries = useAppSelector((state) => selectCountries(state));

  useEffect(() => {
    const languages = mapCountriesToLanguages(countries);

    setLanguages(languages);
  }, [countries, setValue]);

  if (languages.length === 0) return null;

  return (
    <Box sx={sxProps.toggleButtonBox}>
      <ToggleButtonGroup
        sx={sxProps.toggleButtonGroup}
        color="primary"
        value={value}
        exclusive
        onChange={(e: MouseEvent<HTMLElement>, v: LanguageDTO) => {
          setValue(v);
        }}
        fullWidth
      >
        {languages.map((l) => {
          const selected = l === value;
          const sx = selected
            ? sxProps.toggleButtonSelected
            : sxProps.toggleButtonUnselected;

          return (
            <ToggleButton value={l} key={l} sx={sx} selected={selected}>
              {t(`language.${l}`)}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
};
