import { Box } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC, FunctionComponent, SVGProps } from 'react';
import React from 'react';

import { mergeSx } from '../../helpers/sx.helper';
import { sxProps } from './icon-wrapper.style';

export type SvgIconType = FunctionComponent<
  SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export type IconType = SvgIconType | string;

export type IconWrapperProps = {
  sx: SxProps<Theme>;
  icon: IconType;
  onClick?: () => void;
  thin?: boolean;
  medium?: boolean;
};

export const IconWrapper: FC<IconWrapperProps> = ({
  icon,
  sx,
  onClick,
  thin,
  medium,
}: IconWrapperProps) => {
  let boxSx = sxProps.iconWrapperBoxNormal;

  if (thin) boxSx = sxProps.iconWrapperBoxThin;
  if (medium) boxSx = sxProps.iconWrapperBoxMedium;

  const combinedSx = mergeSx(boxSx, sx);

  if (typeof icon === 'string' || icon instanceof String) {
    return (
      <Box
        sx={mergeSx(sxProps.iconWrapperBackground, combinedSx)}
        style={{ background: `url(${icon})` }}
        onClick={onClick}
      />
    );
  }

  return (
    <Box sx={combinedSx} component={icon as SvgIconType} onClick={onClick} />
  );
};
