import { ReactComponent as SearchIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/search/search.svg';
import { TextField } from '@mui/material';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useEffect, useState } from 'react';

import { IconWrapper } from '../../../../../../../../../../shared/components/IconWrapper';
import { useDebounce } from '../../../../../../../../../../shared/hooks/useDebounce';
import { useT } from '../../../../../../../../../../shared/translation/translation.provider';
import { sxProps } from './template-search-box.style';

export type TemplateSearchBoxProps = {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
};

export const TemplateSearchBox: FC<TemplateSearchBoxProps> = ({
  query,
  setQuery,
}: TemplateSearchBoxProps) => {
  const t = useT();
  const [value, setValue] = useState<string>(query || '');
  const debouncedValue = useDebounce<string>(value);

  useEffect(() => {
    setQuery(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <TextField
      size="small"
      onChange={(e) => setValue(e.target.value)}
      fullWidth
      value={value}
      placeholder={t(
        // eslint-disable-next-line max-len
        '+ticketView.comments.responseTemplates.templatePopup.searchBoxPlacehloder',
      )}
      label={t(
        '+ticketView.comments.responseTemplates.templatePopup.searchBoxLabel',
      )}
      InputProps={{
        endAdornment: <IconWrapper sx={sxProps.searchIcon} icon={SearchIcon} />,
      }}
    />
  );
};
