import type { FC } from 'react';
import React, { useEffect } from 'react';

import { mergeSx } from '../../helpers/sx.helper';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeLanguage, selectLanguage } from '../../store/languageSlice';
import { changeTranslation } from '../../translation';
import type { Language } from '../../types/Language';
import type { ModalSelectOption } from '../ModalSelect';
import { ModalSelect } from '../ModalSelect';
import { sxProps } from './language-picker.style';
import { languages } from './util';

export const LanguagePicker: FC = () => {
  const language = useAppSelector((state) => selectLanguage(state));
  const dispatch = useAppDispatch();

  useEffect(() => {
    changeTranslation(language);
  }, [language]);

  const onChangeLanguage = (o: ModalSelectOption): void => {
    dispatch(changeLanguage(o.value as Language));
  };

  return (
    <ModalSelect
      fieldname="language_picker"
      onSelect={onChangeLanguage}
      options={languages}
      value={language}
      buttonSx={sxProps.flagBorder}
      optionIconSx={mergeSx(sxProps.flagIcon, sxProps.flagBorder)}
    />
  );
};
