import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    height: (theme) => theme.spacing(5),
    justifyContent: 'flex-end',
    width: '100%',
  },

  toolbarButton: {
    height: (theme) => theme.spacing(3),
    m: 1,
    width: (theme) => theme.spacing(3),
  },

  toolbarIcon: {
    height: (theme) => theme.spacing(3),
    width: (theme) => theme.spacing(3),
  },
};
