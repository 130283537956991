/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthenticationTypeEnumDTO } from '../dto';
// @ts-ignore
import { BaseMeSerializerDTO } from '../dto';
// @ts-ignore
import { GenerateProspectTokenDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { LoginOutputSerializerDTO } from '../dto';
// @ts-ignore
import { LogoutSerializerDTO } from '../dto';
// @ts-ignore
import { MeSerializerDTO } from '../dto';
// @ts-ignore
import { PatchMeSerializerDTO } from '../dto';
// @ts-ignore
import { ProspectAuthSerializerDTO } from '../dto';
// @ts-ignore
import { RefreshSerializerDTO } from '../dto';
// @ts-ignore
import { UserNotificationConfigSerializerDTO } from '../dto';
// @ts-ignore
import { UserUpdateNotificationConfigSerializerDTO } from '../dto';
/**
 * AuthAPI - axios parameter creator
 * @export
 */
export const AuthAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Auth0 Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth0LogoutAuthAuth0LogoutGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/auth0/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authorize
         * @param {AuthenticationTypeEnumDTO} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeAuthAuthTypeAuthorizeGet: async (authType: AuthenticationTypeEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authType' is not null or undefined
            assertParamExists('authorizeAuthAuthTypeAuthorizeGet', 'authType', authType)
            const localVarPath = `/auth/{auth_type}/authorize`
                .replace(`{${"auth_type"}}`, encodeURIComponent(String(authType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate Token For Prospect
         * @param {GenerateProspectTokenDTO} generateProspectTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenForProspectAuthProspectTokenPost: async (generateProspectTokenDTO: GenerateProspectTokenDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateProspectTokenDTO' is not null or undefined
            assertParamExists('generateTokenForProspectAuthProspectTokenPost', 'generateProspectTokenDTO', generateProspectTokenDTO)
            const localVarPath = `/auth/prospect-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateProspectTokenDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeAuthMeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Me Raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeRawAuthMeRawGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/me/raw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Notification Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotificationConfigAuthMeNotificationConfigurationGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/me/notification-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuthLoginPost: async (username: string, password: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginAuthLoginPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginAuthLoginPost', 'password', password)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout
         * @param {LogoutSerializerDTO} logoutSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutAuthLogoutPost: async (logoutSerializerDTO: LogoutSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'logoutSerializerDTO' is not null or undefined
            assertParamExists('logoutAuthLogoutPost', 'logoutSerializerDTO', logoutSerializerDTO)
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logoutSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Me
         * @param {PatchMeSerializerDTO} patchMeSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMeAuthMePatch: async (patchMeSerializerDTO: PatchMeSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchMeSerializerDTO' is not null or undefined
            assertParamExists('patchMeAuthMePatch', 'patchMeSerializerDTO', patchMeSerializerDTO)
            const localVarPath = `/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchMeSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Me
         * @param {BaseMeSerializerDTO} baseMeSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMeAuthMePut: async (baseMeSerializerDTO: BaseMeSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseMeSerializerDTO' is not null or undefined
            assertParamExists('putMeAuthMePut', 'baseMeSerializerDTO', baseMeSerializerDTO)
            const localVarPath = `/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseMeSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Token
         * @param {AuthenticationTypeEnumDTO} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenAuthAuthTypeTokenGet: async (authType: AuthenticationTypeEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authType' is not null or undefined
            assertParamExists('tokenAuthAuthTypeTokenGet', 'authType', authType)
            const localVarPath = `/auth/{auth_type}/token`
                .replace(`{${"auth_type"}}`, encodeURIComponent(String(authType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Token Refresh
         * @param {string} refreshToken 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefreshAuthRefreshGet: async (refreshToken: string, idToken: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('tokenRefreshAuthRefreshGet', 'refreshToken', refreshToken)
            // verify required parameter 'idToken' is not null or undefined
            assertParamExists('tokenRefreshAuthRefreshGet', 'idToken', idToken)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refreshToken !== undefined) {
                localVarQueryParameter['refresh_token'] = refreshToken;
            }

            if (idToken !== undefined) {
                localVarQueryParameter['id_token'] = idToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Notification Config
         * @param {UserUpdateNotificationConfigSerializerDTO} userUpdateNotificationConfigSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserNotificationConfigAuthMeNotificationConfigurationPatch: async (userUpdateNotificationConfigSerializerDTO: UserUpdateNotificationConfigSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUpdateNotificationConfigSerializerDTO' is not null or undefined
            assertParamExists('updateUserNotificationConfigAuthMeNotificationConfigurationPatch', 'userUpdateNotificationConfigSerializerDTO', userUpdateNotificationConfigSerializerDTO)
            const localVarPath = `/auth/me/notification-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateNotificationConfigSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthAPI - functional programming interface
 * @export
 */
export const AuthAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Auth0 Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async auth0LogoutAuthAuth0LogoutGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.auth0LogoutAuthAuth0LogoutGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authorize
         * @param {AuthenticationTypeEnumDTO} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizeAuthAuthTypeAuthorizeGet(authType: AuthenticationTypeEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeAuthAuthTypeAuthorizeGet(authType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate Token For Prospect
         * @param {GenerateProspectTokenDTO} generateProspectTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTokenForProspectAuthProspectTokenPost(generateProspectTokenDTO: GenerateProspectTokenDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProspectAuthSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTokenForProspectAuthProspectTokenPost(generateProspectTokenDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeAuthMeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeAuthMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Me Raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeRawAuthMeRawGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeRawAuthMeRawGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User Notification Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserNotificationConfigAuthMeNotificationConfigurationGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNotificationConfigSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserNotificationConfigAuthMeNotificationConfigurationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAuthLoginPost(username: string, password: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginOutputSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAuthLoginPost(username, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout
         * @param {LogoutSerializerDTO} logoutSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutAuthLogoutPost(logoutSerializerDTO: LogoutSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutAuthLogoutPost(logoutSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Me
         * @param {PatchMeSerializerDTO} patchMeSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMeAuthMePatch(patchMeSerializerDTO: PatchMeSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMeAuthMePatch(patchMeSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Me
         * @param {BaseMeSerializerDTO} baseMeSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMeAuthMePut(baseMeSerializerDTO: BaseMeSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MeSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMeAuthMePut(baseMeSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Token
         * @param {AuthenticationTypeEnumDTO} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenAuthAuthTypeTokenGet(authType: AuthenticationTypeEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginOutputSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenAuthAuthTypeTokenGet(authType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Token Refresh
         * @param {string} refreshToken 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenRefreshAuthRefreshGet(refreshToken: string, idToken: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenRefreshAuthRefreshGet(refreshToken, idToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User Notification Config
         * @param {UserUpdateNotificationConfigSerializerDTO} userUpdateNotificationConfigSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserNotificationConfigAuthMeNotificationConfigurationPatch(userUpdateNotificationConfigSerializerDTO: UserUpdateNotificationConfigSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNotificationConfigSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserNotificationConfigAuthMeNotificationConfigurationPatch(userUpdateNotificationConfigSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthAPI - factory interface
 * @export
 */
export const AuthAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthAPIFp(configuration)
    return {
        /**
         * 
         * @summary Auth0 Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        auth0LogoutAuthAuth0LogoutGet(options?: any): AxiosPromise<any> {
            return localVarFp.auth0LogoutAuthAuth0LogoutGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authorize
         * @param {AuthenticationTypeEnumDTO} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizeAuthAuthTypeAuthorizeGet(authType: AuthenticationTypeEnumDTO, options?: any): AxiosPromise<any> {
            return localVarFp.authorizeAuthAuthTypeAuthorizeGet(authType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate Token For Prospect
         * @param {GenerateProspectTokenDTO} generateProspectTokenDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTokenForProspectAuthProspectTokenPost(generateProspectTokenDTO: GenerateProspectTokenDTO, options?: any): AxiosPromise<ProspectAuthSerializerDTO> {
            return localVarFp.generateTokenForProspectAuthProspectTokenPost(generateProspectTokenDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeAuthMeGet(options?: any): AxiosPromise<MeSerializerDTO> {
            return localVarFp.getMeAuthMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Me Raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeRawAuthMeRawGet(options?: any): AxiosPromise<any> {
            return localVarFp.getMeRawAuthMeRawGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Notification Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotificationConfigAuthMeNotificationConfigurationGet(options?: any): AxiosPromise<UserNotificationConfigSerializerDTO> {
            return localVarFp.getUserNotificationConfigAuthMeNotificationConfigurationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuthLoginPost(username: string, password: string, options?: any): AxiosPromise<LoginOutputSerializerDTO> {
            return localVarFp.loginAuthLoginPost(username, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout
         * @param {LogoutSerializerDTO} logoutSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutAuthLogoutPost(logoutSerializerDTO: LogoutSerializerDTO, options?: any): AxiosPromise<any> {
            return localVarFp.logoutAuthLogoutPost(logoutSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Me
         * @param {PatchMeSerializerDTO} patchMeSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMeAuthMePatch(patchMeSerializerDTO: PatchMeSerializerDTO, options?: any): AxiosPromise<MeSerializerDTO> {
            return localVarFp.patchMeAuthMePatch(patchMeSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Me
         * @param {BaseMeSerializerDTO} baseMeSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMeAuthMePut(baseMeSerializerDTO: BaseMeSerializerDTO, options?: any): AxiosPromise<MeSerializerDTO> {
            return localVarFp.putMeAuthMePut(baseMeSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Token
         * @param {AuthenticationTypeEnumDTO} authType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenAuthAuthTypeTokenGet(authType: AuthenticationTypeEnumDTO, options?: any): AxiosPromise<LoginOutputSerializerDTO> {
            return localVarFp.tokenAuthAuthTypeTokenGet(authType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Token Refresh
         * @param {string} refreshToken 
         * @param {string} idToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenRefreshAuthRefreshGet(refreshToken: string, idToken: string, options?: any): AxiosPromise<RefreshSerializerDTO> {
            return localVarFp.tokenRefreshAuthRefreshGet(refreshToken, idToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User Notification Config
         * @param {UserUpdateNotificationConfigSerializerDTO} userUpdateNotificationConfigSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserNotificationConfigAuthMeNotificationConfigurationPatch(userUpdateNotificationConfigSerializerDTO: UserUpdateNotificationConfigSerializerDTO, options?: any): AxiosPromise<UserNotificationConfigSerializerDTO> {
            return localVarFp.updateUserNotificationConfigAuthMeNotificationConfigurationPatch(userUpdateNotificationConfigSerializerDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthAPI - object-oriented interface
 * @export
 * @class AuthAPI
 * @extends {BaseAPI}
 */
export class AuthAPI extends BaseAPI {
    /**
     * 
     * @summary Auth0 Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public auth0LogoutAuthAuth0LogoutGet(options?: any) {
        return AuthAPIFp(this.configuration).auth0LogoutAuthAuth0LogoutGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authorize
     * @param {AuthenticationTypeEnumDTO} authType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public authorizeAuthAuthTypeAuthorizeGet(authType: AuthenticationTypeEnumDTO, options?: any) {
        return AuthAPIFp(this.configuration).authorizeAuthAuthTypeAuthorizeGet(authType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate Token For Prospect
     * @param {GenerateProspectTokenDTO} generateProspectTokenDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public generateTokenForProspectAuthProspectTokenPost(generateProspectTokenDTO: GenerateProspectTokenDTO, options?: any) {
        return AuthAPIFp(this.configuration).generateTokenForProspectAuthProspectTokenPost(generateProspectTokenDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public getMeAuthMeGet(options?: any) {
        return AuthAPIFp(this.configuration).getMeAuthMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Me Raw
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public getMeRawAuthMeRawGet(options?: any) {
        return AuthAPIFp(this.configuration).getMeRawAuthMeRawGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Notification Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public getUserNotificationConfigAuthMeNotificationConfigurationGet(options?: any) {
        return AuthAPIFp(this.configuration).getUserNotificationConfigAuthMeNotificationConfigurationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login
     * @param {string} username 
     * @param {string} password 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public loginAuthLoginPost(username: string, password: string, options?: any) {
        return AuthAPIFp(this.configuration).loginAuthLoginPost(username, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout
     * @param {LogoutSerializerDTO} logoutSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public logoutAuthLogoutPost(logoutSerializerDTO: LogoutSerializerDTO, options?: any) {
        return AuthAPIFp(this.configuration).logoutAuthLogoutPost(logoutSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Me
     * @param {PatchMeSerializerDTO} patchMeSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public patchMeAuthMePatch(patchMeSerializerDTO: PatchMeSerializerDTO, options?: any) {
        return AuthAPIFp(this.configuration).patchMeAuthMePatch(patchMeSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Me
     * @param {BaseMeSerializerDTO} baseMeSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public putMeAuthMePut(baseMeSerializerDTO: BaseMeSerializerDTO, options?: any) {
        return AuthAPIFp(this.configuration).putMeAuthMePut(baseMeSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Token
     * @param {AuthenticationTypeEnumDTO} authType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public tokenAuthAuthTypeTokenGet(authType: AuthenticationTypeEnumDTO, options?: any) {
        return AuthAPIFp(this.configuration).tokenAuthAuthTypeTokenGet(authType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Token Refresh
     * @param {string} refreshToken 
     * @param {string} idToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public tokenRefreshAuthRefreshGet(refreshToken: string, idToken: string, options?: any) {
        return AuthAPIFp(this.configuration).tokenRefreshAuthRefreshGet(refreshToken, idToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User Notification Config
     * @param {UserUpdateNotificationConfigSerializerDTO} userUpdateNotificationConfigSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPI
     */
    public updateUserNotificationConfigAuthMeNotificationConfigurationPatch(userUpdateNotificationConfigSerializerDTO: UserUpdateNotificationConfigSerializerDTO, options?: any) {
        return AuthAPIFp(this.configuration).updateUserNotificationConfigAuthMeNotificationConfigurationPatch(userUpdateNotificationConfigSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }
}
