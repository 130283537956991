import { Box, Typography } from '@mui/material';
import type { FC, RefObject } from 'react';
import React, { useCallback } from 'react';

import type {
  CountrySerializerDTO,
  CitySerializerDTO,
} from '../../../../../connectors/company';
import type { EntityEnumDTO } from '../../../../../connectors/ticket';
import { loadMetadata } from '../../../../shared/services/ticket.service';
import type { EntityType } from '../../../../shared/services/types/property.types';
import { selectLoggedUser } from '../../../../shared/store/authSlice';
import { clearQueriedCities } from '../../../../shared/store/companySlice';
import {
  selectCity,
  selectCountry,
  selectEntity,
  selectEntityType,
  setCity,
  setCountry,
  setEntity,
  setEntityType,
  setIsReporterMe,
  setIssueLocation,
  setReporterTenant,
  setReporterUnit,
} from '../../../../shared/store/createTicketSlice';
import { useAppDispatch, useAppSelector } from '../../../../shared/store/hooks';
import { clearQueriedEntities } from '../../../../shared/store/propertySlice';
import { useT } from '../../../../shared/translation/translation.provider';
import type { CreateTicketScreenErrorProps } from '../../create-ticket-screen.component';
import { sxProps } from '../../create-ticket-screen.style';
import { CitySelector } from './components/CitySelector';
import { CountrySelector } from './components/CountrySelector';
import { EntitySelector } from './components/EntitySelector';
import { EntityTypePicker } from './components/EntityTypePicker';

export type EntityChooserProps = {
  countryRef: RefObject<HTMLDivElement>;
  cityRef: RefObject<HTMLDivElement>;
  entityTypeRef: RefObject<HTMLDivElement>;
  entityRef: RefObject<HTMLDivElement>;
};

export const EntityChooser: FC<
  CreateTicketScreenErrorProps & EntityChooserProps
> = ({
  error,
  setError,
  cityRef,
  countryRef,
  entityRef,
  entityTypeRef,
}: CreateTicketScreenErrorProps & EntityChooserProps) => {
  const t = useT();
  const dispatch = useAppDispatch();
  const loggedUser = useAppSelector((state) => selectLoggedUser(state));
  const country = useAppSelector((state) => selectCountry(state));
  const city = useAppSelector((state) => selectCity(state));
  const entity = useAppSelector((state) => selectEntity(state));
  const entityType = useAppSelector((state) => selectEntityType(state));

  const removeReporterData = useCallback(() => {
    dispatch(setIsReporterMe(true));
    dispatch(setReporterTenant(undefined));
    dispatch(setReporterUnit(undefined));
  }, [dispatch]);

  const entityChangeHandler = useCallback(
    (entity?: EntityType) => {
      if (!entity) {
        dispatch(clearQueriedEntities());
      }
      dispatch(setEntity(entity));
      setError((error) => {
        return { ...error, entity: undefined };
      });
      removeReporterData();
    },
    [dispatch, setError, removeReporterData],
  );

  const entityTypeChangeHandler = useCallback(
    (entityType: EntityEnumDTO) => {
      dispatch(setEntityType(entityType));
      dispatch(setIssueLocation(undefined));
      entityChangeHandler(undefined);
      setError((error) => {
        return { ...error, entityType: undefined };
      });
    },
    [dispatch, entityChangeHandler, setError],
  );

  const cityChangeHandler = useCallback(
    (city?: CitySerializerDTO) => {
      if (!city) {
        dispatch(clearQueriedCities());
      }
      dispatch(setCity(city));
      entityChangeHandler(undefined);
      setError((error) => {
        return { ...error, city: undefined };
      });
    },
    [dispatch, entityChangeHandler, setError],
  );

  const countryChangeHandler = useCallback(
    (country: CountrySerializerDTO) => {
      dispatch(setCountry(country));
      loadMetadata(country.uuid);
      cityChangeHandler(undefined);
      setError((error) => {
        return { ...error, country: undefined };
      });
    },
    [cityChangeHandler, dispatch, setError],
  );

  if (!loggedUser?.currentUserData) return null;

  return (
    <Box>
      <Typography variant="overline" sx={sxProps.overline}>
        {t('+createTicket.address')}
      </Typography>
      <Box sx={sxProps.inputWrapper} ref={countryRef}>
        <CountrySelector
          accountData={loggedUser.currentUserData}
          country={country}
          onChange={countryChangeHandler}
          error={error.country}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={cityRef}>
        <CitySelector
          city={city}
          country={country}
          onChange={cityChangeHandler}
          error={error.city}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={entityTypeRef}>
        <EntityTypePicker
          isHidden={!city || !country}
          entityType={entityType}
          onChange={entityTypeChangeHandler}
          error={error.entityType}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={entityRef}>
        <EntitySelector
          city={city}
          country={country}
          entityType={entityType}
          entity={entity}
          onChange={entityChangeHandler}
          error={error.entity}
        />
      </Box>
    </Box>
  );
};
