import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  mapContainer: {
    position: 'relative',
  },

  navigateButton: {
    '&:hover': {
      background: (theme) => theme.palette.primary.contrastText,
    },
    background: (theme) => theme.palette.primary.contrastText,
    bottom: '24px',
    padding: (theme) => theme.spacing(1, 2),
    position: 'absolute',
    right: '26px',
    zIndex: 2,
  },
};
