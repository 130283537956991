import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  attachmentWrapper: {
    position: 'relative',
  },

  imageBox: {
    border: (theme) => `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '8px',
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },

  imageWrapper: {
    height: (theme) => theme.spacing(15),
    width: (theme) => theme.spacing(15),
    zIndex: 1,
  },
  removeIcon: {
    background: (theme) => theme.palette.common.white,
    border: (theme) => `1px solid ${theme.palette.grey[400]}`,
    borderRadius: '50%',
    height: (theme) => theme.spacing(2.5),
    p: 0.5,
    position: 'absolute',
    right: (theme) => theme.spacing(-0.75),
    top: (theme) => theme.spacing(-0.75),
    width: (theme) => theme.spacing(2.5),
    zIndex: 2,
  },
};
