import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  attachmentContainer: {
    alignItems: 'flex-start',
    display: 'flex !important',
    flexDirection: 'column',
    height: '100vh !important',
    justifyContent: 'flex-start',
    position: 'relative',
    width: '100vw !important',
  },
  carouselWrapper: {
    background: (theme) => theme.palette.background.default,
    height: '100vh',
    outline: 'none',
    position: 'relative',
    width: '100vw',
  },
  closeIcon: {
    color: (theme) => theme.palette.primary.contrastText,
    height: (theme) => theme.spacing(3),
    position: 'absolute',
    right: (theme) => theme.spacing(2),
    top: (theme) => theme.spacing(2),
    width: (theme) => theme.spacing(3),
    zIndex: 2,
  },
  description: { mt: 0.5 },

  imageBox: {
    '& img': {
      display: 'block',
      height: '60vh',
      mt: 11.5,
      objectFit: 'contain',
      width: '100vw',
      zIndex: 1,
    },
  },
  textBox: {
    color: (theme) => theme.palette.primary.contrastText,
    overflowWrap: 'break-word',
    pl: 3,
    pr: 3,
    textAlign: 'left',
    width: '100%',
    wordBreak: 'break-word',
  },

  title: { mt: 2 },
};
