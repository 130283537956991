import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  attachmentContainer: {
    height: (theme) => theme.custom.ticketDetailsHeight,
    overflow: 'scroll',
  },

  attachmentsBox: {
    mb: 3,
    mt: 4,
  },
};
