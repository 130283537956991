import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  commentList: {
    margin: (theme) => theme.spacing(3, 0, 1.5, 0),
    overflow: 'scroll',
    width: '100%',
  },
  commentsBox: {
    height: (theme) => theme.custom.ticketDetailsHeight,
    position: 'relative',
  },
  commentsContainer: {
    flexWrap: 'nowrap',
    height: '100%',
    overflowY: 'scroll',
  },

  dimmer: {
    background: (theme) => theme.palette.common.black,
    height: (theme) => theme.custom.ticketDetailsHeight,
    left: 0,
    opacity: 0.7,
    position: 'absolute',
    top: 0,
    width: '100vw',
    zIndex: 2,
  },
};
