import { ReactComponent as CommonFileTextInfo } from '@heimstaden/icons-library/img/streamline-regular/files-folders/common-files/common-file-text-info.svg';
import { ReactComponent as AddCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add-circle.svg';
import { Box, Button, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';

import { IconWrapper } from '../../../../shared/components/IconWrapper';
import { mergeSx } from '../../../../shared/helpers/sx.helper';
import { paths } from '../../../../shared/routes/paths';
import { useT } from '../../../../shared/translation/translation.provider';
import { sxProps } from '../../create-ticket-screen.style';
import { ReactComponent as CreateTicketSuccessImage } from './assets/create-ticket-success.svg';

export const CreateTicketSuccessScreen: FC = () => {
  const t = useT();
  const navigate = useNavigate();
  const { ticketKey } = useParams();

  const onCreateAnotherClick = useCallback(() => {
    navigate(paths.createTicket);
  }, [navigate]);

  const onVisitTicketClick = useCallback(() => {
    navigate(`/${paths.ticket}/${ticketKey}/${paths.ticketDetails}`);
  }, [navigate, ticketKey]);

  return (
    <Box>
      <Stack sx={sxProps.resultScreenStack}>
        <Box sx={sxProps.resultScreenImage}>
          <CreateTicketSuccessImage />
        </Box>
        <Box sx={sxProps.resultScreenText}>
          <Typography variant="h1" sx={sxProps.resultScreenHeadline}>
            {t('+createTicket.successScreen.title')}
          </Typography>
          <Typography>{t('+createTicket.successScreen.text')}</Typography>
        </Box>

        <Box sx={sxProps.successScreenButtonBox}>
          <Button
            data-test="create-ticket-success-visit-ticket-button"
            variant="outlined"
            sx={mergeSx(sxProps.button, sxProps.successScreenButton)}
            onClick={onVisitTicketClick}
          >
            <IconWrapper icon={CommonFileTextInfo} sx={sxProps.buttonIcon} />
            {t('+createTicket.successScreen.visitButton')}
          </Button>

          <Button
            data-test="create-ticket-success-create-another-button"
            variant="contained"
            sx={sxProps.button}
            onClick={onCreateAnotherClick}
          >
            <IconWrapper icon={AddCircleIcon} sx={sxProps.buttonIcon} />
            {t('+createTicket.successScreen.createAnotherButton')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
