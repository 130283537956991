import { ReactComponent as FilterIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/filter/filter-text.svg';
import { Button, Grid } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useState } from 'react';

import { IconWrapper } from '../../../../shared/components/IconWrapper';
import { setNewFilter } from '../../../../shared/services/ticket.service';
import { useAppSelector } from '../../../../shared/store/hooks';
import { selectFilter } from '../../../../shared/store/ticketSlice';
import { useT } from '../../../../shared/translation/translation.provider';
import type { Filter } from '../../../../shared/types/Filter';
import { ActiveFiltersBar } from './components/ActiveFiltersBar';
import { FilterModal } from './components/FilterModal';
import { SearchBox } from './components/SearchBox';
import { sxProps } from './ticket-filter-bar.style';

export const TickeFilterBar: FC = () => {
  const t = useT();
  const { status } = useAppSelector((state) => selectFilter(state));
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = useCallback((): void => {
    setModalOpen(false);
  }, []);

  const confirmModal = useCallback((filter: Filter): void => {
    setNewFilter(filter);
    setModalOpen(false);
  }, []);

  const updateQuery = useCallback(
    (query: string): void => {
      setNewFilter({ order: undefined, query, status });
    },
    [status],
  );

  return (
    <>
      <Grid container sx={sxProps.filterBarWrapper}>
        <Grid item flex={2}>
          <SearchBox updateQuery={updateQuery} />
        </Grid>
        <Grid item flex={1}>
          <Button
            data-test="ticket-filter-bar-filter-button"
            fullWidth
            variant="contained"
            sx={sxProps.filterButton}
            onClick={() => setModalOpen(true)}
          >
            <IconWrapper icon={FilterIcon} sx={sxProps.buttonIcon} />
            {t(`+ticketListScreen.filter.button.label`)}
          </Button>
        </Grid>
      </Grid>
      <FilterModal
        open={modalOpen}
        cancel={closeModal}
        confirm={confirmModal}
      />
      <ActiveFiltersBar />
    </>
  );
};
