import { Box } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { TicketListView } from './components/TicketListView';
import { TickeFilterBar } from './components/TicketsFilterBar';
import { sxProps } from './ticket-list-screen.style';

export const TicketListScreen: FC = () => {
  return (
    <Box sx={sxProps.ticketListScreenWrapper}>
      <TickeFilterBar />
      <TicketListView />
    </Box>
  );
};
