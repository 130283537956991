import React, { useMemo } from 'react';
import type { FC } from 'react';

import type {
  IssueTypeEnumDTO,
  RoomTypeEnumDTO,
  LocationTypeEnumDTO,
  TicketMetadataSerializerDTO,
  DamagedItemEnumDTO,
} from '../../../../../../../connectors/ticket';
import { ModalSelect } from '../../../../../../shared/components/ModalSelect';
import { getAvailableIssueItems, getIssueItemOptions } from './util';

export type IssueItemPickerProps = {
  metadata?: TicketMetadataSerializerDTO;
  issueLocation?: LocationTypeEnumDTO;
  issueRoom?: RoomTypeEnumDTO;
  issueType?: IssueTypeEnumDTO;
  issueItem?: DamagedItemEnumDTO;
  onChange: (issueItem: DamagedItemEnumDTO) => void;
  error?: string;
};

export const IssueItemPicker: FC<IssueItemPickerProps> = ({
  metadata,
  onChange,
  issueItem,
  error,
  issueLocation,
  issueRoom,
  issueType,
}: IssueItemPickerProps) => {
  const isHidden = useMemo(
    () => !issueLocation || !issueRoom || !issueType,
    [issueLocation, issueRoom, issueType],
  );

  const issueItemOptions = useMemo(() => {
    const avaiableItems = getAvailableIssueItems(
      metadata,
      issueLocation,
      issueRoom,
      issueType,
    );

    return getIssueItemOptions(avaiableItems);
  }, [issueLocation, issueRoom, metadata, issueType]);

  if (isHidden) return null;

  return (
    <ModalSelect
      fieldname="issue_item_picer"
      value={issueItem || ''}
      onSelect={(item) => onChange(item.value as DamagedItemEnumDTO)}
      options={issueItemOptions}
      renderAsSelect={{
        error,
        labelKey: '+createTicket.issueItemLabel',
      }}
    />
  );
};
