import { ReactComponent as PriorityIcon } from '@heimstaden/icons-library/img/streamline-regular/business-products/analytics/analytics-bars.svg';
import type { FC } from 'react';
import React from 'react';

import type { PriorityTypeEnumDTO } from '../../../../../../../connectors/ticket';
import { ModalSelect } from '../../../../../../shared/components/ModalSelect';
import { getPriorityOptions } from './util';

export type PriorityPickerProps = {
  priority?: PriorityTypeEnumDTO;
  onChange: (priority: PriorityTypeEnumDTO) => void;
  error?: string;
};

export const priorityPickerLabelId = 'priorityPickerLabelId';

export const PriorityPicker: FC<PriorityPickerProps> = ({
  priority,
  onChange,
  error,
}: PriorityPickerProps) => {
  return (
    <ModalSelect
      fieldname="priority_picker"
      value={priority || ''}
      onSelect={(item) => onChange(item.value as PriorityTypeEnumDTO)}
      options={getPriorityOptions()}
      renderAsSelect={{
        defaultIcon: PriorityIcon,
        error,
        labelKey: '+createTicket.priorityLabel',
      }}
    />
  );
};
