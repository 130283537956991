import { ReactComponent as BinIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/delete/bin-2.svg';
import { ReactComponent as CheckIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/check-double-1.svg';
import { Box, Divider, Modal, Paper, Typography } from '@mui/material';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useCallback, useMemo } from 'react';

import { mergeSx } from '../../../../helpers/sx.helper';
import {
  markAllAsSeen,
  clearNotifications,
} from '../../../../services/notificaiton.service';
import { useAppSelector } from '../../../../store/hooks';
import { selectNotifications } from '../../../../store/notificationsSlice';
import { globalSxProps } from '../../../../style';
import { useT } from '../../../../translation/translation.provider';
import { IconWrapper } from '../../../IconWrapper';
import { NoNotifications } from '../NoNotifications';
import { NotificationList } from '../NotificationList';
import { sxProps } from './notification-modal.style';

export type NotificationModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const NotificationModal: FC<NotificationModalProps> = ({
  open,
  setOpen,
}: NotificationModalProps) => {
  const t = useT();
  const notifications = useAppSelector((state) => selectNotifications(state));

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const notificationList = useMemo(
    () =>
      notifications.length === 0 ? <NoNotifications /> : <NotificationList />,
    [notifications.length],
  );

  return (
    <Modal open={open} sx={sxProps.modal} onClose={closeModal}>
      <Paper sx={sxProps.modalContent}>
        <Box sx={sxProps.topBox}>
          <Typography
            variant="h3"
            sx={mergeSx(globalSxProps.textBold, sxProps.textTitle)}
            component="div"
          >
            {t('shared.notifications.title')}
          </Typography>
          <Typography
            onClick={() => markAllAsSeen()}
            sx={mergeSx(globalSxProps.textBold, sxProps.textLight)}
            component="div"
          >
            <IconWrapper icon={CheckIcon} sx={sxProps.icon} />
            {t('shared.notifications.markAllAsRead')}
          </Typography>
        </Box>
        <Divider variant="fullWidth" />

        <Box sx={sxProps.middleBox}>{notificationList}</Box>
        <Divider variant="fullWidth" />
        <Box sx={sxProps.bottomBox}>
          <Typography
            onClick={() => clearNotifications()}
            variant="button"
            sx={mergeSx(globalSxProps.textBold, sxProps.textLight)}
            component="div"
          >
            <IconWrapper icon={BinIcon} sx={sxProps.icon} />
            {t('shared.notifications.clearAll')}
          </Typography>
        </Box>
      </Paper>
    </Modal>
  );
};
