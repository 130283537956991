import { SnackbarProvider } from 'notistack';
import type { FC, ReactNode } from 'react';
import React from 'react';

import { useAppSelector } from '../../../store/hooks';
import { selectSnackbars } from '../../../store/snackbarSlice';
import { CustomSnackbar } from '../CustomSnackbar/custom-snackbar.component';

export type SnackbarProviderWrapperProps = {
  children: ReactNode;
};

export const SnackbarProviderWrapper: FC<SnackbarProviderWrapperProps> = ({
  children,
}: SnackbarProviderWrapperProps) => {
  const snackbars = useAppSelector((state) => selectSnackbars(state));

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      content={(key) => {
        const snackbar = snackbars.find((s) => {
          return s.id === key;
        });

        if (!snackbar) {
          return null;
        }

        return <CustomSnackbar {...snackbar} key={key} />;
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
