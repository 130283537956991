import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  actionLink: {
    textDecoration: 'none',
  },

  buttonsIcon: {
    height: (theme) => theme.spacing(3),
    paddingRight: (theme) => theme.spacing(1.25),
  },

  detailsButton: {
    fontSize: '0.938rem',
    height: 'auto',
    marginBottom: (theme) => theme.spacing(1),
    marginTop: (theme) => theme.spacing(1),
    minHeight: (theme) => theme.spacing(5.25),
    width: 'auto',
    wordBreak: 'break-all',
  },
};
