/* tslint:disable */
/* eslint-disable */
/**
 * MIE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttachmentCategoryEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentTypeEnumDTO } from '../dto';
// @ts-ignore
import { AttachmentVisibilityEnumDTO } from '../dto';
// @ts-ignore
import { CommonProcessSerializersAttachmentAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ContactSerializerDTO } from '../dto';
// @ts-ignore
import { CoordinatesSerializerDTO } from '../dto';
// @ts-ignore
import { CreateContactSerializerDTO } from '../dto';
// @ts-ignore
import { CreatePropertyObjectSerializerDTO } from '../dto';
// @ts-ignore
import { DetailedPropertyObjectSerializerDTO } from '../dto';
// @ts-ignore
import { HTTPValidationErrorDTO } from '../dto';
// @ts-ignore
import { ListAttachmentSerializerDTO } from '../dto';
// @ts-ignore
import { ListContactSerializerDTO } from '../dto';
// @ts-ignore
import { ListLogSerializerDTO } from '../dto';
// @ts-ignore
import { ListPropertyObjectsSerializerDTO } from '../dto';
// @ts-ignore
import { ListUnitsSerializerDTO } from '../dto';
// @ts-ignore
import { PatchPropertyObjectSerializerDTO } from '../dto';
// @ts-ignore
import { PropertyObjectCategoryEnumDTO } from '../dto';
// @ts-ignore
import { PropertyObjectReassignSerializerDTO } from '../dto';
// @ts-ignore
import { PropertyObjectSerializerDTO } from '../dto';
// @ts-ignore
import { UnitStatusEnumDTO } from '../dto';
/**
 * PropertyObjectsAPI - axios parameter creator
 * @export
 */
export const PropertyObjectsAPIAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreateContactSerializerDTO} createContactSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost: async (propertyObjectUuid: string, createContactSerializerDTO: CreateContactSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'createContactSerializerDTO' is not null or undefined
            assertParamExists('addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost', 'createContactSerializerDTO', createContactSerializerDTO)
            const localVarPath = `/property_objects/{property_object_uuid}/contacts`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createContactSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Property Object
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyObjectPropertyObjectsPost: async (createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPropertyObjectSerializerDTO' is not null or undefined
            assertParamExists('createPropertyObjectPropertyObjectsPost', 'createPropertyObjectSerializerDTO', createPropertyObjectSerializerDTO)
            const localVarPath = `/property_objects/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyObjectSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {string} contactUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete: async (propertyObjectUuid: string, contactUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'contactUuid' is not null or undefined
            assertParamExists('deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete', 'contactUuid', contactUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/contacts/{contact_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)))
                .replace(`{${"contact_uuid"}}`, encodeURIComponent(String(contactUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete: async (propertyObjectUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/attachments/{attachment_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete: async (propertyObjectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet: async (propertyObjectUuid: string, attachmentUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'attachmentUuid' is not null or undefined
            assertParamExists('downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet', 'attachmentUuid', attachmentUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/attachments/{attachment_uuid}/download`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)))
                .replace(`{${"attachment_uuid"}}`, encodeURIComponent(String(attachmentUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Object Attachments
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet: async (propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/attachments`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Object Contacts
         * @param {string} propertyObjectUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet: async (propertyObjectUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, role?: string, excludeRole?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/contacts`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (excludeRole !== undefined) {
                localVarQueryParameter['exclude_role'] = excludeRole;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Object Units
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet: async (propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/units`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (propertyUuid !== undefined) {
                localVarQueryParameter['property_uuid'] = propertyUuid;
            }

            if (entranceUuid !== undefined) {
                localVarQueryParameter['entrance_uuid'] = entranceUuid;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postal_code'] = postalCode;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (excludeCategory !== undefined) {
                localVarQueryParameter['exclude_category'] = excludeCategory;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (contactUuid !== undefined) {
                localVarQueryParameter['contact_uuid'] = contactUuid;
            }

            if (companyUuid !== undefined) {
                localVarQueryParameter['company_uuid'] = companyUuid;
            }

            if (businessRegion !== undefined) {
                localVarQueryParameter['business_region'] = businessRegion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Objects Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet: async (search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/property_objects/coordinates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Property Objects
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [companyUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {PropertyObjectCategoryEnumDTO} [category] 
         * @param {string} [propertyUuid] 
         * @param {string} [contactUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectsPropertyObjectsGet: async (page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, companyUuid?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, postalCode?: string, category?: PropertyObjectCategoryEnumDTO, propertyUuid?: string, contactUuid?: string, businessRegion?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/property_objects/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (updatedAtLte !== undefined) {
                localVarQueryParameter['updated_at_lte'] = (updatedAtLte as any instanceof Date) ?
                    (updatedAtLte as any).toISOString() :
                    updatedAtLte;
            }

            if (updatedAtGt !== undefined) {
                localVarQueryParameter['updated_at_gt'] = (updatedAtGt as any instanceof Date) ?
                    (updatedAtGt as any).toISOString() :
                    updatedAtGt;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (companyUuid !== undefined) {
                localVarQueryParameter['company_uuid'] = companyUuid;
            }

            if (countryUuid !== undefined) {
                localVarQueryParameter['country_uuid'] = countryUuid;
            }

            if (regionUuid !== undefined) {
                localVarQueryParameter['region_uuid'] = regionUuid;
            }

            if (municipalityUuid !== undefined) {
                localVarQueryParameter['municipality_uuid'] = municipalityUuid;
            }

            if (cityUuid !== undefined) {
                localVarQueryParameter['city_uuid'] = cityUuid;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postal_code'] = postalCode;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (propertyUuid !== undefined) {
                localVarQueryParameter['property_uuid'] = propertyUuid;
            }

            if (contactUuid !== undefined) {
                localVarQueryParameter['contact_uuid'] = contactUuid;
            }

            if (businessRegion !== undefined) {
                localVarQueryParameter['business_region'] = businessRegion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Property Object Auditlogs
         * @param {string} propertyObjectUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet: async (propertyObjectUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}/auditlogs`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)

            if (userUuid !== undefined) {
                localVarQueryParameter['user_uuid'] = userUuid;
            }

            if (excludeFields !== undefined) {
                localVarQueryParameter['exclude_fields'] = excludeFields;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Single Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet: async (propertyObjectUuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet', 'propertyObjectUuid', propertyObjectUuid)
            const localVarPath = `/property_objects/{property_object_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Property Object
         * @param {string} propertyObjectUuid 
         * @param {PatchPropertyObjectSerializerDTO} patchPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch: async (propertyObjectUuid: string, patchPropertyObjectSerializerDTO: PatchPropertyObjectSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'patchPropertyObjectSerializerDTO' is not null or undefined
            assertParamExists('patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch', 'patchPropertyObjectSerializerDTO', patchPropertyObjectSerializerDTO)
            const localVarPath = `/property_objects/{property_object_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchPropertyObjectSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPropertyObjectPropertyObjectsPropertyObjectUuidPut: async (propertyObjectUuid: string, createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('putPropertyObjectPropertyObjectsPropertyObjectUuidPut', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'createPropertyObjectSerializerDTO' is not null or undefined
            assertParamExists('putPropertyObjectPropertyObjectsPropertyObjectUuidPut', 'createPropertyObjectSerializerDTO', createPropertyObjectSerializerDTO)
            const localVarPath = `/property_objects/{property_object_uuid}`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPropertyObjectSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reassign Property Object And Related Units
         * @param {string} propertyObjectUuid 
         * @param {PropertyObjectReassignSerializerDTO} propertyObjectReassignSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost: async (propertyObjectUuid: string, propertyObjectReassignSerializerDTO: PropertyObjectReassignSerializerDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'propertyObjectReassignSerializerDTO' is not null or undefined
            assertParamExists('reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost', 'propertyObjectReassignSerializerDTO', propertyObjectReassignSerializerDTO)
            const localVarPath = `/property_objects/{property_object_uuid}/reassign_objects`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(propertyObjectReassignSerializerDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost: async (propertyObjectUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'propertyObjectUuid' is not null or undefined
            assertParamExists('uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost', 'propertyObjectUuid', propertyObjectUuid)
            // verify required parameter 'attachmentCategory' is not null or undefined
            assertParamExists('uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost', 'attachmentCategory', attachmentCategory)
            // verify required parameter 'attachmentType' is not null or undefined
            assertParamExists('uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost', 'attachmentType', attachmentType)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost', 'files', files)
            const localVarPath = `/property_objects/{property_object_uuid}/attachments`
                .replace(`{${"property_object_uuid"}}`, encodeURIComponent(String(propertyObjectUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication AzureOAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "AzureOAuth", [], configuration)


            if (attachmentCategory !== undefined) { 
                localVarFormParams.append('attachment_category', new Blob([JSON.stringify(attachmentCategory)], { type: "application/json", }));
            }
    
            if (attachmentType !== undefined) { 
                localVarFormParams.append('attachment_type', new Blob([JSON.stringify(attachmentType)], { type: "application/json", }));
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (visibility !== undefined) { 
                localVarFormParams.append('visibility', new Blob([JSON.stringify(visibility)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertyObjectsAPI - functional programming interface
 * @export
 */
export const PropertyObjectsAPIFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertyObjectsAPIAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreateContactSerializerDTO} createContactSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(propertyObjectUuid: string, createContactSerializerDTO: CreateContactSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(propertyObjectUuid, createContactSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Property Object
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPropertyObjectPropertyObjectsPost(createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyObjectSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPropertyObjectPropertyObjectsPost(createPropertyObjectSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {string} contactUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(propertyObjectUuid: string, contactUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(propertyObjectUuid, contactUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(propertyObjectUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(propertyObjectUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(propertyObjectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(propertyObjectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(propertyObjectUuid: string, attachmentUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(propertyObjectUuid, attachmentUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Object Attachments
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAttachmentSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(propertyObjectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Object Contacts
         * @param {string} propertyObjectUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(propertyObjectUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, role?: string, excludeRole?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListContactSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(propertyObjectUuid, updatedAtLte, updatedAtGt, search, role, excludeRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Object Units
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUnitsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(propertyObjectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, propertyUuid, entranceUuid, countryUuid, regionUuid, cityUuid, postalCode, category, excludeCategory, status, contactUuid, companyUuid, businessRegion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Objects Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CoordinatesSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(search, countryUuid, regionUuid, cityUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Property Objects
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [companyUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {PropertyObjectCategoryEnumDTO} [category] 
         * @param {string} [propertyUuid] 
         * @param {string} [contactUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyObjectsPropertyObjectsGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, companyUuid?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, postalCode?: string, category?: PropertyObjectCategoryEnumDTO, propertyUuid?: string, contactUuid?: string, businessRegion?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPropertyObjectsSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyObjectsPropertyObjectsGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, companyUuid, countryUuid, regionUuid, municipalityUuid, cityUuid, postalCode, category, propertyUuid, contactUuid, businessRegion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Property Object Auditlogs
         * @param {string} propertyObjectUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(propertyObjectUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListLogSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(propertyObjectUuid, userUuid, excludeFields, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Single Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(propertyObjectUuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DetailedPropertyObjectSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(propertyObjectUuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Property Object
         * @param {string} propertyObjectUuid 
         * @param {PatchPropertyObjectSerializerDTO} patchPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(propertyObjectUuid: string, patchPropertyObjectSerializerDTO: PatchPropertyObjectSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyObjectSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(propertyObjectUuid, patchPropertyObjectSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPropertyObjectPropertyObjectsPropertyObjectUuidPut(propertyObjectUuid: string, createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PropertyObjectSerializerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPropertyObjectPropertyObjectsPropertyObjectUuidPut(propertyObjectUuid, createPropertyObjectSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reassign Property Object And Related Units
         * @param {string} propertyObjectUuid 
         * @param {PropertyObjectReassignSerializerDTO} propertyObjectReassignSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(propertyObjectUuid: string, propertyObjectReassignSerializerDTO: PropertyObjectReassignSerializerDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(propertyObjectUuid, propertyObjectReassignSerializerDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(propertyObjectUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommonProcessSerializersAttachmentAttachmentSerializerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(propertyObjectUuid, attachmentCategory, attachmentType, files, title, description, visibility, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertyObjectsAPI - factory interface
 * @export
 */
export const PropertyObjectsAPIFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertyObjectsAPIFp(configuration)
    return {
        /**
         * 
         * @summary Add Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreateContactSerializerDTO} createContactSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(propertyObjectUuid: string, createContactSerializerDTO: CreateContactSerializerDTO, options?: any): AxiosPromise<ContactSerializerDTO> {
            return localVarFp.addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(propertyObjectUuid, createContactSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Property Object
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPropertyObjectPropertyObjectsPost(createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options?: any): AxiosPromise<PropertyObjectSerializerDTO> {
            return localVarFp.createPropertyObjectPropertyObjectsPost(createPropertyObjectSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Contact For Property Object
         * @param {string} propertyObjectUuid 
         * @param {string} contactUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(propertyObjectUuid: string, contactUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(propertyObjectUuid, contactUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(propertyObjectUuid: string, attachmentUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(propertyObjectUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(propertyObjectUuid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(propertyObjectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {string} attachmentUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(propertyObjectUuid: string, attachmentUuid: string, options?: any): AxiosPromise<any> {
            return localVarFp.downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(propertyObjectUuid, attachmentUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Object Attachments
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [category] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any): AxiosPromise<ListAttachmentSerializerDTO> {
            return localVarFp.getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(propertyObjectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Object Contacts
         * @param {string} propertyObjectUuid 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [role] 
         * @param {string} [excludeRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(propertyObjectUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, role?: string, excludeRole?: string, options?: any): AxiosPromise<ListContactSerializerDTO> {
            return localVarFp.getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(propertyObjectUuid, updatedAtLte, updatedAtGt, search, role, excludeRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Object Units
         * @param {string} propertyObjectUuid 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [propertyUuid] 
         * @param {string} [entranceUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {string} [category] 
         * @param {string} [excludeCategory] 
         * @param {UnitStatusEnumDTO} [status] 
         * @param {string} [contactUuid] 
         * @param {string} [companyUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options?: any): AxiosPromise<ListUnitsSerializerDTO> {
            return localVarFp.getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(propertyObjectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, propertyUuid, entranceUuid, countryUuid, regionUuid, cityUuid, postalCode, category, excludeCategory, status, contactUuid, companyUuid, businessRegion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Objects Coordinates List
         * @param {string} [search] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [cityUuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options?: any): AxiosPromise<Array<CoordinatesSerializerDTO>> {
            return localVarFp.getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(search, countryUuid, regionUuid, cityUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Property Objects
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [order] 
         * @param {string} [updatedAtLte] 
         * @param {string} [updatedAtGt] 
         * @param {string} [search] 
         * @param {string} [companyUuid] 
         * @param {string} [countryUuid] 
         * @param {string} [regionUuid] 
         * @param {string} [municipalityUuid] 
         * @param {string} [cityUuid] 
         * @param {string} [postalCode] 
         * @param {PropertyObjectCategoryEnumDTO} [category] 
         * @param {string} [propertyUuid] 
         * @param {string} [contactUuid] 
         * @param {string} [businessRegion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyObjectsPropertyObjectsGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, companyUuid?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, postalCode?: string, category?: PropertyObjectCategoryEnumDTO, propertyUuid?: string, contactUuid?: string, businessRegion?: string, options?: any): AxiosPromise<ListPropertyObjectsSerializerDTO> {
            return localVarFp.getPropertyObjectsPropertyObjectsGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, companyUuid, countryUuid, regionUuid, municipalityUuid, cityUuid, postalCode, category, propertyUuid, contactUuid, businessRegion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Property Object Auditlogs
         * @param {string} propertyObjectUuid 
         * @param {string} [userUuid] 
         * @param {string} [excludeFields] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(propertyObjectUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<ListLogSerializerDTO> {
            return localVarFp.getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(propertyObjectUuid, userUuid, excludeFields, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Single Property Object
         * @param {string} propertyObjectUuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(propertyObjectUuid: string, options?: any): AxiosPromise<DetailedPropertyObjectSerializerDTO> {
            return localVarFp.getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(propertyObjectUuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Property Object
         * @param {string} propertyObjectUuid 
         * @param {PatchPropertyObjectSerializerDTO} patchPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(propertyObjectUuid: string, patchPropertyObjectSerializerDTO: PatchPropertyObjectSerializerDTO, options?: any): AxiosPromise<PropertyObjectSerializerDTO> {
            return localVarFp.patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(propertyObjectUuid, patchPropertyObjectSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put Property Object
         * @param {string} propertyObjectUuid 
         * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPropertyObjectPropertyObjectsPropertyObjectUuidPut(propertyObjectUuid: string, createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options?: any): AxiosPromise<PropertyObjectSerializerDTO> {
            return localVarFp.putPropertyObjectPropertyObjectsPropertyObjectUuidPut(propertyObjectUuid, createPropertyObjectSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reassign Property Object And Related Units
         * @param {string} propertyObjectUuid 
         * @param {PropertyObjectReassignSerializerDTO} propertyObjectReassignSerializerDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(propertyObjectUuid: string, propertyObjectReassignSerializerDTO: PropertyObjectReassignSerializerDTO, options?: any): AxiosPromise<void> {
            return localVarFp.reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(propertyObjectUuid, propertyObjectReassignSerializerDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Property Object Attachment
         * @param {string} propertyObjectUuid 
         * @param {AttachmentCategoryEnumDTO} attachmentCategory 
         * @param {AttachmentTypeEnumDTO} attachmentType 
         * @param {Array<any>} files 
         * @param {string} [title] 
         * @param {string} [description] 
         * @param {AttachmentVisibilityEnumDTO} [visibility] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(propertyObjectUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any): AxiosPromise<Array<CommonProcessSerializersAttachmentAttachmentSerializerDTO>> {
            return localVarFp.uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(propertyObjectUuid, attachmentCategory, attachmentType, files, title, description, visibility, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertyObjectsAPI - object-oriented interface
 * @export
 * @class PropertyObjectsAPI
 * @extends {BaseAPI}
 */
export class PropertyObjectsAPI extends BaseAPI {
    /**
     * 
     * @summary Add Contact For Property Object
     * @param {string} propertyObjectUuid 
     * @param {CreateContactSerializerDTO} createContactSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(propertyObjectUuid: string, createContactSerializerDTO: CreateContactSerializerDTO, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).addContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsPost(propertyObjectUuid, createContactSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Property Object
     * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public createPropertyObjectPropertyObjectsPost(createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).createPropertyObjectPropertyObjectsPost(createPropertyObjectSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Contact For Property Object
     * @param {string} propertyObjectUuid 
     * @param {string} contactUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(propertyObjectUuid: string, contactUuid: string, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).deleteContactForPropertyObjectPropertyObjectsPropertyObjectUuidContactsContactUuidDelete(propertyObjectUuid, contactUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Property Object Attachment
     * @param {string} propertyObjectUuid 
     * @param {string} attachmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(propertyObjectUuid: string, attachmentUuid: string, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).deletePropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDelete(propertyObjectUuid, attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Property Object
     * @param {string} propertyObjectUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(propertyObjectUuid: string, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).deletePropertyObjectPropertyObjectsPropertyObjectUuidDelete(propertyObjectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Property Object Attachment
     * @param {string} propertyObjectUuid 
     * @param {string} attachmentUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(propertyObjectUuid: string, attachmentUuid: string, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).downloadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsAttachmentUuidDownloadGet(propertyObjectUuid, attachmentUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Object Attachments
     * @param {string} propertyObjectUuid 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {string} [updatedAtLte] 
     * @param {string} [updatedAtGt] 
     * @param {string} [search] 
     * @param {string} [category] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, category?: string, type?: string, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getPropertyObjectAttachmentsPropertyObjectsPropertyObjectUuidAttachmentsGet(propertyObjectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, category, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Object Contacts
     * @param {string} propertyObjectUuid 
     * @param {string} [updatedAtLte] 
     * @param {string} [updatedAtGt] 
     * @param {string} [search] 
     * @param {string} [role] 
     * @param {string} [excludeRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(propertyObjectUuid: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, role?: string, excludeRole?: string, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getPropertyObjectContactsPropertyObjectsPropertyObjectUuidContactsGet(propertyObjectUuid, updatedAtLte, updatedAtGt, search, role, excludeRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Object Units
     * @param {string} propertyObjectUuid 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {string} [updatedAtLte] 
     * @param {string} [updatedAtGt] 
     * @param {string} [search] 
     * @param {string} [propertyUuid] 
     * @param {string} [entranceUuid] 
     * @param {string} [countryUuid] 
     * @param {string} [regionUuid] 
     * @param {string} [cityUuid] 
     * @param {string} [postalCode] 
     * @param {string} [category] 
     * @param {string} [excludeCategory] 
     * @param {UnitStatusEnumDTO} [status] 
     * @param {string} [contactUuid] 
     * @param {string} [companyUuid] 
     * @param {string} [businessRegion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(propertyObjectUuid: string, page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, propertyUuid?: string, entranceUuid?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, postalCode?: string, category?: string, excludeCategory?: string, status?: UnitStatusEnumDTO, contactUuid?: string, companyUuid?: string, businessRegion?: string, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getPropertyObjectUnitsPropertyObjectsPropertyObjectUuidUnitsGet(propertyObjectUuid, page, pageSize, order, updatedAtLte, updatedAtGt, search, propertyUuid, entranceUuid, countryUuid, regionUuid, cityUuid, postalCode, category, excludeCategory, status, contactUuid, companyUuid, businessRegion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Objects Coordinates List
     * @param {string} [search] 
     * @param {string} [countryUuid] 
     * @param {string} [regionUuid] 
     * @param {string} [cityUuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(search?: string, countryUuid?: string, regionUuid?: string, cityUuid?: string, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getPropertyObjectsCoordinatesListPropertyObjectsCoordinatesGet(search, countryUuid, regionUuid, cityUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Property Objects
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [order] 
     * @param {string} [updatedAtLte] 
     * @param {string} [updatedAtGt] 
     * @param {string} [search] 
     * @param {string} [companyUuid] 
     * @param {string} [countryUuid] 
     * @param {string} [regionUuid] 
     * @param {string} [municipalityUuid] 
     * @param {string} [cityUuid] 
     * @param {string} [postalCode] 
     * @param {PropertyObjectCategoryEnumDTO} [category] 
     * @param {string} [propertyUuid] 
     * @param {string} [contactUuid] 
     * @param {string} [businessRegion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getPropertyObjectsPropertyObjectsGet(page?: number, pageSize?: number, order?: string, updatedAtLte?: string, updatedAtGt?: string, search?: string, companyUuid?: string, countryUuid?: string, regionUuid?: string, municipalityUuid?: string, cityUuid?: string, postalCode?: string, category?: PropertyObjectCategoryEnumDTO, propertyUuid?: string, contactUuid?: string, businessRegion?: string, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getPropertyObjectsPropertyObjectsGet(page, pageSize, order, updatedAtLte, updatedAtGt, search, companyUuid, countryUuid, regionUuid, municipalityUuid, cityUuid, postalCode, category, propertyUuid, contactUuid, businessRegion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Property Object Auditlogs
     * @param {string} propertyObjectUuid 
     * @param {string} [userUuid] 
     * @param {string} [excludeFields] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(propertyObjectUuid: string, userUuid?: string, excludeFields?: string, page?: number, pageSize?: number, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getSinglePropertyObjectAuditlogsPropertyObjectsPropertyObjectUuidAuditlogsGet(propertyObjectUuid, userUuid, excludeFields, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Single Property Object
     * @param {string} propertyObjectUuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(propertyObjectUuid: string, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).getSinglePropertyObjectPropertyObjectsPropertyObjectUuidGet(propertyObjectUuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Property Object
     * @param {string} propertyObjectUuid 
     * @param {PatchPropertyObjectSerializerDTO} patchPropertyObjectSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(propertyObjectUuid: string, patchPropertyObjectSerializerDTO: PatchPropertyObjectSerializerDTO, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).patchPropertyObjectPropertyObjectsPropertyObjectUuidPatch(propertyObjectUuid, patchPropertyObjectSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put Property Object
     * @param {string} propertyObjectUuid 
     * @param {CreatePropertyObjectSerializerDTO} createPropertyObjectSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public putPropertyObjectPropertyObjectsPropertyObjectUuidPut(propertyObjectUuid: string, createPropertyObjectSerializerDTO: CreatePropertyObjectSerializerDTO, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).putPropertyObjectPropertyObjectsPropertyObjectUuidPut(propertyObjectUuid, createPropertyObjectSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reassign Property Object And Related Units
     * @param {string} propertyObjectUuid 
     * @param {PropertyObjectReassignSerializerDTO} propertyObjectReassignSerializerDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(propertyObjectUuid: string, propertyObjectReassignSerializerDTO: PropertyObjectReassignSerializerDTO, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).reassignPropertyObjectAndRelatedUnitsPropertyObjectsPropertyObjectUuidReassignObjectsPost(propertyObjectUuid, propertyObjectReassignSerializerDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Property Object Attachment
     * @param {string} propertyObjectUuid 
     * @param {AttachmentCategoryEnumDTO} attachmentCategory 
     * @param {AttachmentTypeEnumDTO} attachmentType 
     * @param {Array<any>} files 
     * @param {string} [title] 
     * @param {string} [description] 
     * @param {AttachmentVisibilityEnumDTO} [visibility] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertyObjectsAPI
     */
    public uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(propertyObjectUuid: string, attachmentCategory: AttachmentCategoryEnumDTO, attachmentType: AttachmentTypeEnumDTO, files: Array<any>, title?: string, description?: string, visibility?: AttachmentVisibilityEnumDTO, options?: any) {
        return PropertyObjectsAPIFp(this.configuration).uploadPropertyObjectAttachmentPropertyObjectsPropertyObjectUuidAttachmentsPost(propertyObjectUuid, attachmentCategory, attachmentType, files, title, description, visibility, options).then((request) => request(this.axios, this.basePath));
    }
}
