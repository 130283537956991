import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  activeFiltersBar: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    height: (theme) => theme.spacing(8),
    overflow: 'auto',
  },
};
