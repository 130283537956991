import { Box } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect } from 'react';

import type { StatusUpdateFormProps } from '../../status-update-modal.component';
import {
  StatusUpdateFormComment,
  StatusUpdateFormDate,
  StatusUpdateFormSelect,
} from '../StatusUpdateFormComponents';
import {
  estimatedDateForResumptionOfTaskKey,
  estimatedDateForResumtionOfTaskStartValue,
  reasonAwaitingTenantKey,
  reasonAwaitingTenantOptions,
} from './util';

export const AwaitingTenantStatusForm: FC<StatusUpdateFormProps> = ({
  data,
  setFormData,
  commentError,
  attachments,
  comment,
  setAttachments,
  setComment,
}: StatusUpdateFormProps) => {
  //  setting form data with default values
  useEffect(() => {
    setFormData(reasonAwaitingTenantKey, reasonAwaitingTenantOptions[0]);
    setFormData(
      estimatedDateForResumptionOfTaskKey,
      estimatedDateForResumtionOfTaskStartValue,
    );
  }, [setFormData]);

  return (
    <Box>
      <StatusUpdateFormSelect
        value={data[reasonAwaitingTenantKey] || reasonAwaitingTenantOptions[0]}
        options={reasonAwaitingTenantOptions}
        fieldname={reasonAwaitingTenantKey}
        onChange={setFormData}
      />
      <StatusUpdateFormDate
        fieldname={estimatedDateForResumptionOfTaskKey}
        value={
          data[estimatedDateForResumptionOfTaskKey] ||
          estimatedDateForResumtionOfTaskStartValue
        }
        onChange={setFormData}
      />
      <StatusUpdateFormComment
        error={commentError}
        attachments={attachments}
        comment={comment}
        setAttachments={setAttachments}
        setComment={setComment}
      />
    </Box>
  );
};
