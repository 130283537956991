import { Typography } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { useT } from '../../../../../../shared/translation/translation.provider';
import { sxProps } from '../../../../ticket-view-screen.style';
import { useTicket } from '../../../../ticket.context';

export const TicketDetailsReferenceLabel: FC = () => {
  const t = useT();
  const ticket = useTicket();

  return (
    <>
      <Typography
        variant="overline"
        component="span"
        sx={sxProps.ticketHeaderText}
      >
        {t('+ticketView.details.requestId')}
      </Typography>{' '}
      <Typography
        variant="overline"
        component="span"
        sx={sxProps.ticketHeaderTextPrimary}
      >
        {ticket.referenceNumber}
      </Typography>
    </>
  );
};
