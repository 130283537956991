import type { NotificationSerializerDTO } from '../../../connectors/notification';

// return only the list of notifications that are unique to new notifications
export const filterOnlyUniqueNotificaions = (
  oldNotifications: NotificationSerializerDTO[],
  newNotifications: NotificationSerializerDTO[],
): NotificationSerializerDTO[] => {
  return newNotifications.filter(
    (newN) => !oldNotifications.find((oldN) => oldN.uuid === newN.uuid),
  );
};
