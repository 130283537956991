import { ReactComponent as LogoutIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/login-logout/logout-2.svg';
import { ReactComponent as ListIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/menu/navigation-menu.svg';
import { ReactComponent as AddTicketIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add-square.svg';
import { ReactComponent as ProfileIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-actions-neutral/single-neutral-actions.svg';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { paths } from '../../../routes/paths';
import { logoutUser } from '../../../services/auth.service';
import { selectLoggedUser } from '../../../store/authSlice';
import { useAppSelector } from '../../../store/hooks';
import { MenuItem } from '../MenuItem/menu-item.component';
import { sxProps } from './menu.style';

enum MenuButtons {
  ROOT = 'ROOT',
  CREATE_TICKET = 'CREATE_TICKET',
  PROFILE = 'PROFILE',
  LOGOUT = 'LOGOUT',
}

const menuIcons = {
  [MenuButtons.ROOT]: ListIcon,
  [MenuButtons.CREATE_TICKET]: AddTicketIcon,
  [MenuButtons.PROFILE]: ProfileIcon,
  [MenuButtons.LOGOUT]: LogoutIcon,
};

export const Menu: FC = () => {
  const loggedUser = useAppSelector((state) => selectLoggedUser(state));
  const location = useLocation();
  const navigation = useNavigate();

  const {
    createTicket: CREATE_TICKET_PATH,
    root: ROOT_PATH,
    profile: PROFILE_PATH,
    welcome: WELCOME_PATH,
  } = paths;

  const active = useMemo(() => {
    if (location.pathname.includes(CREATE_TICKET_PATH)) {
      return MenuButtons.CREATE_TICKET;
    }
    if (location.pathname.includes(PROFILE_PATH)) {
      return MenuButtons.PROFILE;
    }

    return MenuButtons.ROOT;
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const logoutAction = (): void => {
    logoutUser();
    navigation(WELCOME_PATH);
  };

  const onButtonClick = (buttonClicked: MenuButtons): void => {
    switch (buttonClicked) {
      case MenuButtons.ROOT:
        navigation(ROOT_PATH);
        break;
      case MenuButtons.CREATE_TICKET:
        navigation(CREATE_TICKET_PATH);
        break;
      case MenuButtons.PROFILE:
        navigation(PROFILE_PATH);
        break;
      case MenuButtons.LOGOUT:
        logoutAction();
        break;
      default:
    }
  };

  if (!loggedUser) return null;

  return (
    <BottomNavigation sx={sxProps.bottomNavigation}>
      {Object.values(MenuButtons).map((button) => {
        const isActive = active === button;

        return (
          <BottomNavigationAction
            data-test={`menu-item-${button}`}
            key={button}
            icon={<MenuItem active={isActive} icon={menuIcons[button]} />}
            sx={sxProps.bottomNavigationAction}
            onClick={() => onButtonClick(button)}
          />
        );
      })}
    </BottomNavigation>
  );
};
