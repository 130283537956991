import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  button: {
    minWidth: '40%',
    ml: 1,
    mr: 1,
  },

  buttonBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    mt: 2,
  },

  buttonBoxCancelIcon: {
    height: (theme) => theme.spacing(2.5),
    mr: 0.5,
    width: (theme) => theme.spacing(2),
  },

  buttonBoxConfirmationIcon: {
    height: (theme) => theme.spacing(2.5),
    mr: 1,
    width: (theme) => theme.spacing(2.5),
  },

  languagePickerBox: {
    mt: 2,
  },

  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  modalContent: {
    borderRadius: '8px',
    maxHeight: '60vh',
    overflowY: 'scroll',
    p: 3,
    width: '80vw',
  },

  popupInformationBox: {
    mt: 2,
  },

  popupInformationDeleteText: {
    color: (theme) => theme.palette.error.main,
  },

  responseTemplateDetails: {
    background: (theme) => theme.palette.action.hover,
    borderRadius: '8px',
    p: 2,
  },

  responseTemplateDetailsIcon: {
    height: (theme) => theme.spacing(6),
    width: (theme) => theme.spacing(6),
  },
};
