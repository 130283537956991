import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { EmployeeSerializerDTO } from '../../../connectors/user/dto/employee-serializer-dto';
import type { ListEmployeeSerializerDTO } from '../../../connectors/user/dto/list-employee-serializer-dto';
import type { RootState } from './index';

export interface UserState {
  queriedUsers: EmployeeSerializerDTO[];
}

const initialState: UserState = { queriedUsers: [] };

/* eslint-disable no-param-reassign */
const userSlice = createSlice({
  initialState,
  name: 'comment',
  reducers: {
    clearQueriedUsers: (state) => {
      state.queriedUsers = [];
    },

    setQueriedUsers: (
      state,
      { payload: { results } }: PayloadAction<ListEmployeeSerializerDTO>,
    ) => {
      state.queriedUsers = results;
    },
  },
});

export const { setQueriedUsers, clearQueriedUsers } = userSlice.actions;
export const selectQueriedUsers = (state: RootState): EmployeeSerializerDTO[] =>
  state.user.queriedUsers;

export default userSlice.reducer;
