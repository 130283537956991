import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  selectStatusLabel: {
    color: (theme) => theme.palette.grey[500],
    display: 'block',
    marginBottom: (theme) => theme.spacing(0.5),
  },
  ticketDetailsActionLink: {
    textDecoration: 'none',
  },
  ticketDetailsActions: {
    paddingTop: (theme) => theme.spacing(1.4),
  },
  ticketDetailsButton: {
    height: 'auto',
    minHeight: (theme) => theme.spacing(5.25),
    padding: (theme) => theme.spacing(0.75, 2.5),
    width: 'auto',
  },
  ticketDetailsButtonsBox: {
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: (theme) => theme.spacing(0, 1),
  },
  ticketDetailsButtonsIcon: {
    height: (theme) => theme.spacing(3),
    paddingRight: (theme) => theme.spacing(1.25),
  },
  ticketDetailsSelect: {
    padding: (theme) => theme.spacing(1.8, 1, 0, 1),
  },
};
