import type { FC } from 'react';
import React, { useCallback } from 'react';

import type {
  CitySerializerDTO,
  CountrySerializerDTO,
} from '../../../../../../../connectors/company';
import { AutocompleteInput } from '../../../../../../shared/components/AutocompleteInput';
import { queryCities } from '../../../../../../shared/services/company.service';
import { selectQueriedCities } from '../../../../../../shared/store/companySlice';
import { useAppSelector } from '../../../../../../shared/store/hooks';

export type CitySelectorProps = {
  country?: CountrySerializerDTO;
  city?: CitySerializerDTO;
  onChange: (city?: CitySerializerDTO) => void;
  error?: string;
};

export const CitySelector: FC<CitySelectorProps> = ({
  country,
  city,
  onChange,
  error,
}: CitySelectorProps) => {
  const queriedCities = useAppSelector((state) => selectQueriedCities(state));
  const onCitySelect = useCallback(
    (cityUuid?: string) => {
      const selectedCity = queriedCities.find((city) => city.uuid === cityUuid);

      onChange(selectedCity);
    },
    [queriedCities, onChange],
  );

  const onInputChange = useCallback(
    (query: string, { uuid }: CountrySerializerDTO) => {
      queryCities({ countryUuid: uuid, query });
    },
    [],
  );

  if (!country) return null;

  return (
    <AutocompleteInput
      debounce
      labelKey="+createTicket.cityLabel"
      placeholderKey="+createTicket.cityPlaceholder"
      options={queriedCities.map((c) => {
        return { label: c.name, value: c.uuid };
      })}
      value={city ? { label: city.name, value: city.uuid } : undefined}
      onChange={(selectedCityUuid) => {
        onCitySelect(selectedCityUuid);
      }}
      onInputChange={(value) => {
        onInputChange(value, country);
      }}
      error={error}
      fieldname="city_selector"
    />
  );
};
