import type { NotificationSerializerDTO } from '../../../connectors/notification';
import { store } from '../store';
import {
  addNewNotifications,
  addNextNotificationPage,
  removeAllNotifications,
  markAllNotificationsAsSeen,
  markNotificationAsSeen,
} from '../store/notificationsSlice';
// this line is needed to start auth service before notification service is used
import './auth.service';
import { notificationClient } from './clients/notification.client';

export const loadNotifications = async (): Promise<void> => {
  try {
    const notifications = await notificationClient.getNotifications();

    store.dispatch(addNewNotifications(notifications));
  } catch {
    //  fails silently
  }
};

export const loadNextNotificationsPage = async (): Promise<void> => {
  try {
    const { nextPage } = store.getState().notification;
    const notifications = await notificationClient.getNotifications(nextPage);

    store.dispatch(addNextNotificationPage(notifications));
  } catch {
    //  fails silently
  }
};

export const markAsSeen = async (
  notification: NotificationSerializerDTO,
): Promise<void> => {
  try {
    store.dispatch(markNotificationAsSeen(notification));
    await notificationClient.markNotificationAsSeen(notification);
  } catch {
    //  fails silently
  }
};

export const markAllAsSeen = async (): Promise<void> => {
  try {
    store.dispatch(markAllNotificationsAsSeen());
    await notificationClient.markAllNotificationAsSeen();
  } catch {
    //  fails silently
  }
};

export const clearNotifications = async (): Promise<void> => {
  try {
    store.dispatch(removeAllNotifications());
    await notificationClient.markAllNotificationAsSeen();
  } catch {
    //  fails silently
  }
};
