import type {
  ListCitiesSerializerDTO,
  ListCountriesSerializerDTO,
} from '../../../../connectors/company';
import { CountriesAPI } from '../../../../connectors/company';
import { CITIES_PER_PAGE, COUNTRIES_PER_PAGE } from '../../helpers/const';
import { getEnvValue } from '../../helpers/env.helper';
import { mieInstance } from '../../mie.instance';
import type { QueryCitiesParams } from '../types/company.types';

class CompanyClient {
  private countryClient;

  constructor() {
    const path = getEnvValue('API_PATH');

    this.countryClient = new CountriesAPI(undefined, path, mieInstance);
  }

  public getCountries = async (): Promise<ListCountriesSerializerDTO> => {
    const response = await this.countryClient.getCountriesCountriesGet(
      1,
      COUNTRIES_PER_PAGE,
    );

    return response.data;
  };

  public getCitiesForCountry = async ({
    countryUuid,
    query,
  }: QueryCitiesParams): Promise<ListCitiesSerializerDTO> => {
    const response =
      await this.countryClient.getCitiesCountriesCountryUuidCitiesGet(
        countryUuid,
        1,
        CITIES_PER_PAGE,
        undefined,
        query,
      );

    return response.data;
  };
}

export const companyClient = new CompanyClient();
