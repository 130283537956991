import React, { useMemo } from 'react';
import type { FC } from 'react';

import type {
  IssueTypeEnumDTO,
  RoomTypeEnumDTO,
  LocationTypeEnumDTO,
  TicketMetadataSerializerDTO,
} from '../../../../../../../connectors/ticket';
import { ModalSelect } from '../../../../../../shared/components/ModalSelect';
import { getAvailableIssueTypes, getIssueTypeOptions } from './util';

export type IssueTypePickerProps = {
  metadata?: TicketMetadataSerializerDTO;
  issueLocation?: LocationTypeEnumDTO;
  issueRoom?: RoomTypeEnumDTO;
  issueType?: IssueTypeEnumDTO;
  onChange: (issueType: IssueTypeEnumDTO) => void;
  error?: string;
};

export const IssueTypePicker: FC<IssueTypePickerProps> = ({
  metadata,
  onChange,
  error,
  issueLocation,
  issueRoom,
  issueType,
}: IssueTypePickerProps) => {
  const isHidden = useMemo(
    () => !issueLocation || !issueRoom,
    [issueLocation, issueRoom],
  );

  const issueTypeOptions = useMemo(() => {
    const avaiableTypes = getAvailableIssueTypes(
      metadata,
      issueLocation,
      issueRoom,
    );

    return getIssueTypeOptions(avaiableTypes);
  }, [issueLocation, issueRoom, metadata]);

  if (isHidden) return null;

  return (
    <ModalSelect
      fieldname="issue_type_picker"
      value={issueType || ''}
      onSelect={(item) => onChange(item.value as IssueTypeEnumDTO)}
      options={issueTypeOptions}
      renderAsSelect={{
        error,
        labelKey: '+createTicket.issueTypeLabel',
      }}
    />
  );
};
