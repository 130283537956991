import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { useT } from '../../../../../../../../shared/translation/translation.provider';
import { sxProps } from '../../status-update-modal.style';

export type StatusUpdateFormSelectProps = {
  options: string[];
  fieldname: string;
  value: string;
  onChange: (key: string, value: string) => void;
};

export const StatusUpdateFormSelect: FC<StatusUpdateFormSelectProps> = ({
  fieldname,
  options,
  value,
  onChange,
}: StatusUpdateFormSelectProps) => {
  const t = useT();

  return (
    <FormControl
      data-test={`status-update-modal-form-form-control-${fieldname}`}
      fullWidth
      key={fieldname}
      color="secondary"
      sx={sxProps.inputField}
    >
      <InputLabel id={`selectFieldLabel${fieldname}`}>
        {t(`+ticketView.details.statusUpdateForm.${fieldname}`)}
      </InputLabel>
      <Select
        data-test={`status-update-modal-form-select-${fieldname}`}
        labelId={`selectFieldLabel${fieldname}`}
        value={value}
        label={t(`+ticketView.details.statusUpdateForm.${fieldname}`)}
        onChange={(e) => onChange(fieldname, e.target.value)}
      >
        {options?.map((option) => {
          return (
            <MenuItem value={option} key={option}>
              {t(`+ticketView.details.statusUpdateForm.${option}`)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
