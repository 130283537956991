import { MAX_FILE_SIZE } from '../../../helpers/const';
import { store } from '../../../store';
import { addErrorSnackbar } from '../../../store/snackbarSlice';

export const validateFile = (file: File): boolean => {
  if (file.size > MAX_FILE_SIZE) {
    store.dispatch(addErrorSnackbar('error.fileTooBig'));

    return false;
  }

  return true;
};
