import type {
  CommentSerializerDTO,
  CreateCommentSerializerDTO,
  CreateReponseTemplateSerializerDTO,
  CreateServiceRequestSerializerDTO,
  LanguageDTO,
  ListAttachmentSerializerDTO,
  ListCommentSerializerDTO,
  ListResponseTemplateSerializerDTO,
  ListTicketsSerializerDTO,
  ResponseTemplateSerializerDTO,
  TicketMetadataSerializerDTO,
  TicketsCountByStatusSerializerDTO,
  UniversalTicketSerializerDTO,
  UniversalTicketsWithAdditionalFieldsSerializerDTO,
} from '../../../../connectors/ticket';
import {
  AttachmentCategoryEnumDTO,
  AttachmentTypeEnumDTO,
  ComplaintsAPI,
  GeneralRequestsAPI,
  ServiceRequestsAPI,
  TicketsAPI,
  TicketTypeEnumDTO,
} from '../../../../connectors/ticket';
import {
  ATTACHMENTS_PER_PAGE,
  COMMENTS_PER_PAGE,
  RESPONSE_TEMPLATES_PER_PAGE,
} from '../../helpers/const';
import { getEnvValue } from '../../helpers/env.helper';
import { transformKeys } from '../../http';
import { mieInstance } from '../../mie.instance';
import type { AttachmentForUpload } from '../../types/AttachmentForUpload';
import type {
  GetTicketsParams,
  UpdateTicketFields,
} from '../types/ticket.types';

class TicketClient {
  private client;

  private serviceRequestClient;

  private complaintRequestsClient;

  private generalRequestsClient;

  constructor() {
    const path = getEnvValue('API_PATH');

    this.client = new TicketsAPI(undefined, path, mieInstance);
    this.complaintRequestsClient = new ComplaintsAPI(
      undefined,
      path,
      mieInstance,
    );
    this.serviceRequestClient = new ServiceRequestsAPI(
      undefined,
      path,
      mieInstance,
    );
    this.generalRequestsClient = new GeneralRequestsAPI(
      undefined,
      path,
      mieInstance,
    );
  }

  public getTickets = async (
    params: GetTicketsParams,
  ): Promise<ListTicketsSerializerDTO> => {
    const {
      page,
      pageSize,
      order,
      search,
      updatedAtGt,
      updatedAtLte,
      name,
      summary,
      reporterUuid,
      assigneeUuid,
      unassigned,
      entityUuid,
      propertyObjectUuid,
      propertyUuid,
      issueType,
      issueSubtype,
      issueLocation,
      roomWithIssue,
      priority,
      entityType,
      ticketType,
      status,
      complaintType,
      addressLine,
      cityUuid,
      countryUuid,
      description,
      dueDateGt,
      dueDateLt,
      referenceNumber,
      regionUuid,
      department,
      options,
    } = params;

    const response = await this.client.getTicketsTicketsGet(
      {
        addressLine,
        assigneeUuid,
        cityUuid,
        complaintType,
        countryUuid,
        department,
        description,
        dueDateGt,
        dueDateLt,
        entityType,
        entityUuid,
        issueLocation,
        issueSubtype,
        issueType,
        name,
        order,
        page,
        pageSize,
        priority,
        propertyObjectUuid,
        propertyUuid,
        referenceNumber,
        regionUuid,
        reporterUuid,
        roomWithIssue,
        search,
        status,
        summary,
        ticketType,
        unassigned,
        updatedAtGt,
        updatedAtLte,
      },
      options,
    );

    return response.data;
  };

  public getTicketCount = async (
    assigneeUuid?: string,
  ): Promise<TicketsCountByStatusSerializerDTO> => {
    const response =
      await this.client.getServiceRequestsForEntityTicketsCountsGet(
        undefined, // ticket type
        assigneeUuid,
      );
    const responseData = response.data;
    // changing keys to snake case, which will match our statuses.
    // they are automatcally transformet to camel case by client configuration
    // so this change is needed
    const ticketsCount = transformKeys(
      responseData.ticketsCount,
      'SNAKE_CASE',
      true,
    );

    responseData.ticketsCount = ticketsCount;

    return responseData;
  };

  public updateTicket = async (
    ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
    fields: UpdateTicketFields,
  ): Promise<UniversalTicketSerializerDTO | undefined> => {
    let response;

    switch (ticket.ticketType) {
      case TicketTypeEnumDTO.ServiceRequest:
        response =
          await this.serviceRequestClient.patchServiceRequestServiceRequestsServiceRequestUuidPatch(
            {
              patchServiceRequestSerializerDTO: fields,
              serviceRequestUuid: ticket.uuid,
            },
          );
        break;
      case TicketTypeEnumDTO.Complaint:
        response =
          await this.complaintRequestsClient.patchComplaintComplaintsComplaintUuidPatch(
            {
              complaintUuid: ticket.uuid,
              patchComplaintSerializerDTO: fields,
            },
          );
        break;
      case TicketTypeEnumDTO.GeneralRequest:
        response =
          await this.generalRequestsClient.patchGeneralRequestGeneralRequestsGeneralRequestUuidPatch(
            {
              generalRequestUuid: ticket.uuid,
              patchGeneralRequestSerializerDTO: fields,
            },
          );
        break;
      default:
        break;
    }

    return response ? response.data : undefined;
  };

  public createTicketComment = async (
    ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
    comment: CreateCommentSerializerDTO,
  ): Promise<CommentSerializerDTO> => {
    const res = await this.client.addTicketCommentTicketsTicketUuidCommentsPost(
      {
        createCommentSerializerDTO: comment,
        ticketUuid: ticket.uuid,
      },
    );

    return res.data;
  };

  public fetchTicketComments = async (
    ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
  ): Promise<ListCommentSerializerDTO> => {
    const response =
      await this.client.getTicketCommentsTicketsTicketUuidCommentsGet({
        order: 'created_at',
        page: 1,
        pageSize: COMMENTS_PER_PAGE,
        ticketUuid: ticket.uuid,
      });

    return response.data;
  };

  public createTicketAttachments = async (
    ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
    attachmentCategory: AttachmentCategoryEnumDTO,
    files: Array<File>,
    title: string,
    description: string,
  ): Promise<void> => {
    await this.client.uploadTicketAttachmentTicketsTicketUuidAttachmentsPost({
      attachmentCategory,
      attachmentType: AttachmentTypeEnumDTO.Gallery,
      description,
      files,
      ticketUuid: ticket.uuid,
      title,
    });
  };

  public fetchTicketAttachment = async (
    ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
  ): Promise<ListAttachmentSerializerDTO> => {
    const response =
      await this.client.getTicketAttachmentsTicketsTicketUuidAttachmentsGet({
        page: 1,
        pageSize: ATTACHMENTS_PER_PAGE,
        ticketUuid: ticket.uuid,
      });

    return response.data;
  };

  public getMetadataForCountry = async (
    countryUuid: string,
  ): Promise<TicketMetadataSerializerDTO> => {
    const response =
      await this.client.getTicketMetadataForCountryTicketsCountryUuidMetadataGet(
        { countryUuid },
      );
    const responseData = response.data;
    // changing keys to snake case, which will match our statuses.
    // they are automatcally transformet to camel case by client configuration
    // so this change is needed
    const statuses = transformKeys(responseData.statuses, 'SNAKE_CASE', true);
    const serviceRequestsValidationMap = transformKeys(
      responseData.serviceRequestsValidationMap,
      'SNAKE_CASE',
      true,
    );
    const complaintTypes = transformKeys(
      responseData.complaintTypes,
      'SNAKE_CASE',
      true,
    );

    return {
      ...responseData,
      complaintTypes,
      serviceRequestsValidationMap,
      statuses,
    };
  };

  public refreshTicket = async (
    ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
  ): Promise<UniversalTicketSerializerDTO | undefined> => {
    let response;

    switch (ticket.ticketType) {
      case TicketTypeEnumDTO.ServiceRequest:
        response =
          await this.serviceRequestClient.getServiceRequestDetailsServiceRequestsServiceRequestUuidGet(
            { serviceRequestUuid: ticket.uuid },
          );
        break;
      case TicketTypeEnumDTO.Complaint:
        response =
          await this.complaintRequestsClient.getComplaintDetailsComplaintsComplaintUuidGet(
            { complaintUuid: ticket.uuid },
          );
        break;

      case TicketTypeEnumDTO.GeneralRequest:
        response =
          await this.generalRequestsClient.getGeneralRequestDetailsGeneralRequestsGeneralRequestUuidGet(
            { generalRequestUuid: ticket.uuid },
          );
        break;
      default:
        break;
    }

    return response
      ? (response.data as UniversalTicketSerializerDTO)
      : undefined;
  };

  public createTicket = async (
    serviceRequest: CreateServiceRequestSerializerDTO,
  ): Promise<UniversalTicketSerializerDTO> => {
    const resp =
      await this.serviceRequestClient.createServiceRequestServiceRequestsPost({
        createServiceRequestSerializerDTO: serviceRequest,
      });

    return resp.data;
  };

  public createCommentAttachment = async (
    ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
    comment: CommentSerializerDTO,
    { file, description, title }: AttachmentForUpload,
  ): Promise<void> => {
    await this.client.uploadCommentAttachmentTicketsTicketUuidCommentsCommentUuidAttachmentsPost(
      {
        attachmentCategory: AttachmentCategoryEnumDTO.Image,
        attachmentType: AttachmentTypeEnumDTO.Gallery,
        commentUuid: comment.uuid,
        description,
        files: [file],
        ticketUuid: ticket.uuid,
        title,
      },
    );
  };

  public fetchCommentAttachments = async (
    ticket: UniversalTicketSerializerDTO,
    comment: CommentSerializerDTO,
  ): Promise<ListAttachmentSerializerDTO> => {
    const res =
      await this.client.getCommentAttachmentsTicketsTicketUuidCommentsCommentUuidAttachmentsGet(
        {
          commentUuid: comment.uuid,
          ticketUuid: ticket.uuid,
        },
      );

    return res.data;
  };

  public getResponseTemplates = async ({
    query,
    language,
  }: {
    query?: string;
    language?: LanguageDTO;
  }): Promise<ListResponseTemplateSerializerDTO> => {
    const res =
      await this.client.getResponseTemplatesTicketsResponseTemplatesGet({
        language,
        page: 1,
        pageSize: RESPONSE_TEMPLATES_PER_PAGE,
        search: query,
      });

    return res.data;
  };

  public createResponseTemplate = async (
    template: CreateReponseTemplateSerializerDTO,
  ): Promise<ResponseTemplateSerializerDTO> => {
    const res =
      await this.client.createResponseTemplateTicketsResponseTemplatesPost({
        createReponseTemplateSerializerDTO: template,
      });

    return res.data;
  };

  public deleteResponseTemplate = async (
    templateUuid: string,
  ): Promise<void> => {
    await this.client.deleteResponseTemplateTicketsResponseTemplatesResponseTemplateUuidDelete(
      {
        responseTemplateUuid: templateUuid,
      },
    );
  };
}

export const ticketClient = new TicketClient();
