import type { TFunction } from 'react-i18next';

import { TicketTypeEnumDTO } from '../../../../../../../connectors/ticket';
import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../../../../../connectors/ticket/dto/universal-tickets-with-additional-fields-serializer-dto';

export const createIssueString = (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
  t: TFunction,
): string => {
  let issueString = '';
  const issues = [
    ticket.issueLocation,
    ticket.roomWithIssue,
    ticket.issueType,
    ticket.issueSubtype,
  ];

  switch (ticket.ticketType) {
    case TicketTypeEnumDTO.Complaint:
      return t('issue.customerComplaint');
    case TicketTypeEnumDTO.GeneralRequest:
      return t('issue.general');
    default:
      issues.forEach((issue) => {
        if (issue) issueString += `${t(`issue.${issue}`)} / `;
      });
      //  removing trailing / character
      if (issueString.length)
        issueString = issueString.substring(0, issueString.length - 2);

      return issueString;
  }
};
