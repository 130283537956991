import { ReactComponent as RemoveIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import { Modal, Box, Typography } from '@mui/material';
import type { Dispatch, FC, SetStateAction } from 'react';
import React from 'react';
import type { Settings } from 'react-slick';
import Carousel from 'react-slick';

import type { AttachmentSerializerDTO } from '../../../../../connectors/company';
import { mergeSx } from '../../../helpers/sx.helper';
import { globalSxProps } from '../../../style';
import type { AttachmentForUpload } from '../../../types/AttachmentForUpload';
import { IconWrapper } from '../../IconWrapper';
import { sxProps } from './attachment-view-modal.style';

export type AttachmentViewModalProps = {
  uploadedAttachments?: AttachmentSerializerDTO[];
  attachmentsForUpload?: AttachmentForUpload[];
  startIndex: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const AttachmentViewModal: FC<AttachmentViewModalProps> = ({
  attachmentsForUpload,
  uploadedAttachments,
  startIndex,
  open,
  setOpen,
}: AttachmentViewModalProps) => {
  const settings: Settings = {
    arrows: false,
    dots: false,
    infinite: true,
    initialSlide: startIndex,
    slidesToScroll: 1,
    slidesToShow: 1,
    swipe: true,
  };

  let carouselItems:
    | {
        url: string;
        contentType: string;
        description?: string;
        key: string;
        title?: string;
      }[]
    | undefined;

  if (uploadedAttachments)
    carouselItems = uploadedAttachments.map(
      ({ url, contentType, uuid, title, description }) => {
        return { contentType, description, key: uuid, title, url };
      },
    );
  if (attachmentsForUpload)
    carouselItems = attachmentsForUpload.map(({ file, description, title }) => {
      return {
        contentType: file.name,
        description,
        key: file.name,
        title,
        url: URL.createObjectURL(file),
      };
    });

  if (!carouselItems || carouselItems.length === 0) return null;

  return (
    <Modal open={open}>
      <Box sx={sxProps.carouselWrapper}>
        <IconWrapper
          icon={RemoveIcon}
          sx={sxProps.closeIcon}
          onClick={() => {
            setOpen(false);
          }}
        />
        <Carousel {...settings}>
          {carouselItems.map(
            ({ contentType, url, key, title, description }) => {
              return (
                <Box sx={sxProps.attachmentContainer} key={key}>
                  <Box sx={sxProps.imageBox}>
                    <img src={url} alt={contentType} />
                  </Box>
                  <Box sx={sxProps.textBox}>
                    <Typography
                      variant="h3"
                      sx={mergeSx(sxProps.title, globalSxProps.textBold)}
                    >
                      {title}
                    </Typography>
                    <Typography sx={sxProps.description}>
                      {description}
                    </Typography>
                  </Box>
                </Box>
              );
            },
          )}
        </Carousel>
      </Box>
    </Modal>
  );
};
