import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  buttonIcon: {
    '& path': {
      strokeWidth: '2px',
    },
    height: (theme) => theme.spacing(2.5),
    mr: (theme) => theme.spacing(1),
    width: (theme) => theme.spacing(2.5),
  },
  cancelButton: {
    ml: (theme) => theme.spacing(2),
  },
  confirmButton: {
    mr: (theme) => theme.spacing(2),
  },

  modal: {
    margin: '15vh 5vw 10vh 5vw',
  },
  modalContent: {
    maxHeight: '94vh',
    overflowY: 'scroll',
    position: 'relative',
  },
};
