import { Box, TextField } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type {
  BaseSyntheticEvent,
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
} from 'react';
import React, { useEffect, useState, useRef, useMemo } from 'react';

import type { EmployeeSerializerDTO } from '../../../../connectors/user/dto/employee-serializer-dto';
import { MENTION_CHAR } from '../../helpers/const';
import { getUserMentionString } from '../../helpers/user.helper';
import { useDebounce } from '../../hooks/useDebounce';
import { queryUsers } from '../../services/user.service';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  clearResponseTemplate,
  selectSelectedResponseTemplated,
} from '../../store/responseTemplate';
import { clearQueriedUsers } from '../../store/userSlice';
import type { AttachmentForUpload } from '../../types/AttachmentForUpload';
import { sxProps } from './comment-text-field.style';
import { AddedAttachments } from './components/AddedAttachments';
import { CommentToolbar } from './components/CommentToolbar';
import { MentionsPopper } from './components/MentionsPopper';
import { prepareTicketCommentFormat } from './util';

export type CommentTextFieldProps = {
  minRows?: number;
  maxRows?: number;
  multiline?: boolean;
  fullWidth?: boolean;
  label?: string;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  commentContent: string;
  attachments: AttachmentForUpload[];
  setCommentContent: (content: string) => void;
  setAttachments: Dispatch<SetStateAction<AttachmentForUpload[]>>;
  setForbidPublicComments: Dispatch<SetStateAction<boolean>>;
  sx: SxProps<Theme>;
  disablePortal?: boolean;
  focused?: boolean;
};

export const CommentTextField: FC<CommentTextFieldProps> = ({
  minRows = 1,
  maxRows = 5,
  multiline = true,
  fullWidth = false,
  label,
  error,
  helperText,
  disabled,
  setCommentContent,
  commentContent,
  attachments,
  setAttachments,
  setForbidPublicComments,
  sx,
  disablePortal,
  focused,
}: CommentTextFieldProps) => {
  const dispatch = useAppDispatch();
  // used as an anchor for popper
  const inputEl = useRef(null);
  // value of the input field - if mentions are present, it will be different from comment content
  const [value, setValue] = useState(commentContent || '');
  // value before key is up - this is used to save value of input before delete or backspace keys
  // are pressed
  const [valueBeforeKeyUp, setValueBeforeKeyUp] = useState('');
  // this is the current query for user mentions functionality
  // it is used as a reminder what should be replaced
  const [query, setQuery] = useState('');
  // flag that will open mention popup for selecting user for mentions
  const [isQuerying, setIsQuerying] = useState(false);
  // list of users that have been mentioned while typing a comment
  const [mentionedUsers, setMentionedUsers] = useState<EmployeeSerializerDTO[]>(
    [],
  );
  // active mention, that is currenlty manipulated
  const [activeMention, setActiveMention] = useState<{
    text: string;
    user?: EmployeeSerializerDTO;
  }>();
  const debouncedQuery = useDebounce(query);
  const selectedResponseTemplate = useAppSelector((state) =>
    selectSelectedResponseTemplated(state),
  );

  // when response template is selected, reset value and mentioned user
  useEffect(() => {
    if (selectedResponseTemplate) {
      dispatch(clearResponseTemplate());
      setValue(selectedResponseTemplate.content);
      setCommentContent(selectedResponseTemplate.content);
      setMentionedUsers([]);
    }
  }, [dispatch, selectedResponseTemplate, setCommentContent]);

  useEffect(() => {
    if (debouncedQuery) {
      queryUsers(debouncedQuery);
    } else {
      dispatch(clearQueriedUsers());
    }
  }, [debouncedQuery, dispatch]);

  const isMentionsSet = useMemo(() => {
    return mentionedUsers.length > 0;
  }, [mentionedUsers.length]);

  // when mentions are used in comment, we need to forbit using of public comments
  useEffect(() => {
    setForbidPublicComments(isMentionsSet);
  }, [isMentionsSet, setForbidPublicComments]);

  // when comment content is set to empty string after comment is sent
  // we need to set value to empty string as well
  useEffect(() => {
    if (!commentContent) setValue('');
  }, [commentContent, value]);

  // when input is changed, this will update input value, and also will update
  // comment content
  const onValueChange = (
    val: string,
    users: EmployeeSerializerDTO[] = mentionedUsers,
  ): void => {
    setValue(val);
    setCommentContent(prepareTicketCommentFormat(val, users));
  };

  // depending on position of the cursor, this function will detect
  // if we are updating mentioned part of the comment
  const detectActiveMention = (cursorPosition: number): void => {
    mentionedUsers.forEach((user) => {
      const mentionString = getUserMentionString(user);
      const start = value.indexOf(`${MENTION_CHAR}${mentionString}`);
      const end = start + mentionString.length + 1;
      const active: boolean = start < cursorPosition && end > cursorPosition;

      if (active) setActiveMention({ text: mentionString, user });
    });
  };

  // this function will extract query part of the message that is
  // beeing typed at this moment
  const detectTypedUser = (
    inputValue: string,
    cursorPosition: number | null,
  ): void => {
    const cursor = cursorPosition || 0;
    const slicedInput = inputValue.slice(
      inputValue.lastIndexOf(MENTION_CHAR),
      cursor,
    );

    if (slicedInput.indexOf(MENTION_CHAR) === -1) {
      setQuery('');
      setIsQuerying(false);
    } else {
      setQuery(slicedInput.slice(1));
    }
  };

  const selectUser = (user: EmployeeSerializerDTO): void => {
    const mentionString = getUserMentionString(user);
    const newValue = value.replaceAll(query, mentionString);
    const newMentionedUsers = [...mentionedUsers, user];

    setMentionedUsers(newMentionedUsers);
    setIsQuerying(false);
    setQuery('');
    dispatch(clearQueriedUsers());
    onValueChange(newValue, newMentionedUsers);
  };

  const onInput = ({ nativeEvent }: BaseSyntheticEvent<InputEvent>): void => {
    if (nativeEvent.data === '@') {
      setIsQuerying(true);
    }
    if (isMentionsSet) {
      // we will store value before key is pressed for purposes of
      // deleting mentions on Backspace or Delete key press
      setValueBeforeKeyUp(value);
    }
  };
  const onChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    onValueChange(e.target.value);
    if (isMentionsSet) {
      detectActiveMention(e.target.selectionEnd || 0);
    }
    if (isQuerying) {
      detectTypedUser(e.target.value, e.target.selectionEnd);
    }
  };
  const onKeyUp = (): void => {
    // if edditing is done on active mention, delete the active mention and mentioned user
    // from the mentionedUsers array. For this we are using valueBeforeKeyUp vartiable
    // that keeps value that was in the input field before last key was pressed
    if (activeMention && activeMention.text.length > 0) {
      const newValue = valueBeforeKeyUp.replaceAll(
        `@${activeMention.text}`,
        '',
      );

      onValueChange(newValue);
      setMentionedUsers((prev) =>
        prev.filter((user) => user.uuid !== activeMention.user?.uuid),
      );
      setActiveMention(undefined);
    }
  };

  const onFileSelected = (file: File): void => {
    // dont add duplicates
    if (attachments.find((a) => a.title === file.name)) return;

    const newAttachment: AttachmentForUpload = { file, title: file.name };

    setAttachments([...attachments, newAttachment]);
  };

  const onAttachmentRemove = (attachment: AttachmentForUpload): void => {
    setAttachments(attachments.filter((a) => a.title !== attachment.title));
  };

  return (
    <Box sx={sx}>
      <CommentToolbar onFileSelected={onFileSelected} />
      <MentionsPopper
        anchorRef={inputEl}
        open={isQuerying}
        selectUser={selectUser}
        disablePortal={disablePortal}
      />
      <TextField
        data-test="comment-text-area"
        ref={inputEl}
        sx={sxProps.messageInput}
        disabled={disabled}
        value={value}
        variant="outlined"
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
        fullWidth={fullWidth}
        label={label}
        error={error}
        helperText={helperText}
        onInput={onInput}
        onChange={onChange}
        onKeyUp={onKeyUp}
        focused={focused}
        autoFocus={focused}
        InputProps={{
          startAdornment: (
            <AddedAttachments
              attachments={attachments}
              onRemove={onAttachmentRemove}
            />
          ),
        }}
      />
    </Box>
  );
};
