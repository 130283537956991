import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { loadLanguageState } from '../helpers/storage.helper';
import type { Language } from '../types/Language';
import type { RootState } from './index';

export interface LanguageState {
  language: Language;
}

const persistedState = loadLanguageState();
const initialState: LanguageState = persistedState || { language: 'en' };

/* eslint-disable no-param-reassign */
const languageSlice = createSlice({
  initialState,
  name: 'comment',
  reducers: {
    changeLanguage: (state, { payload }: PayloadAction<Language>) => {
      state.language = payload;
    },
  },
});

export const { changeLanguage } = languageSlice.actions;
export const selectLanguage = (state: RootState): Language =>
  state.language.language;

export default languageSlice.reducer;
