import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  countBox: {
    alignItems: 'center',
    background: (theme) => theme.palette.grey[200],
    borderRadius: (theme) => theme.spacing(4),
    display: 'flex',
    marginBottom: (theme) => theme.spacing(1.5),
    padding: (theme) => theme.spacing(1),
    width: '95%',
  },
  countCircle: {
    alignItems: 'center',
    background: '#C4C4C4',
    borderRadius: '50%',
    display: 'flex',
    height: (theme) => theme.spacing(5.5),
    justifyContent: 'center',
    marginRight: (theme) => theme.spacing(1),
    minWidth: (theme) => theme.spacing(5.5),
  },
  countNumber: {
    fontWeight: 700,
    letterSpacing: '-0.5px',
  },
  divider: {
    color: (theme) => theme.palette.divider,
    marginBottom: (theme) => theme.spacing(2.5),
    width: '100%',
  },

  overviewBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: (theme) => theme.spacing(4),
  },
  overviewGrid: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: (theme) => theme.spacing(3),
  },
  overviewLabel: {
    color: (theme) => theme.palette.secondary.light,
    display: 'block',
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '3rem',
    textTransform: 'uppercase',
  },
  totalCount: {
    color: (theme) => theme.palette.text.primary,
  },
  totalCountNumber: {
    fontWeight: 700,
  },
};
