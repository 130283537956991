import type { RefObject } from 'react';
import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideClickAlerter = (
  ref: RefObject<HTMLDivElement>,
  onOutsideClickCallback: () => void,
): void => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        event?.target &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        onOutsideClickCallback();
      }
    };
    // Bind the event listener

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClickCallback, ref]);
};
