import { Navigation } from '@mui/icons-material';
import type { Theme } from '@mui/material';
import { Button } from '@mui/material';
import type { SxProps } from '@mui/system';
import type { FC } from 'react';
import React from 'react';

import type { CoordinatesSerializerDTO } from '../../../../../../connectors/property';
import { useT } from '../../../../translation/translation.provider';
import { navigateOnMap } from '../../util';
import { sxProps } from './navigate-button.style';

export type NavigateButtonProps = {
  labelKey: string;
  buttonSx: SxProps<Theme>;
  coordinates: CoordinatesSerializerDTO;
};

export const NavigateButton: FC<NavigateButtonProps> = ({
  buttonSx,
  labelKey,
  coordinates,
}: NavigateButtonProps) => {
  const t = useT();

  const onClick = async (): Promise<void> => {
    navigateOnMap(coordinates);
  };

  return (
    <Button variant="outlined" color="primary" sx={buttonSx} onClick={onClick}>
      <Navigation sx={sxProps.navigateButtonIcon} />
      {t(labelKey)}
    </Button>
  );
};
