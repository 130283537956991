import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  noTicketsHeadline: {
    fontWeight: 700,
    paddingBottom: (theme) => theme.spacing(1),
  },
  noTicketsImage: {
    padding: (theme) => theme.spacing(5, 0, 5, 0),
  },
  noTicketsStack: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: (theme) => theme.spacing(0, 1),
  },

  noTicketsText: {
    color: (theme) => theme.palette.common.black,
    textAlign: 'center',
  },
};
