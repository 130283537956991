import { ThemeProvider, CssBaseline } from '@mui/material';
import React from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router';

import { AppContainer } from './components/AppContainer';
import { LogoHeader } from './components/LogoHeader';
import { Menu } from './components/MenuComponents';
import { SnackbarProviderWrapper } from './components/Snackbar';
import { getRoutes } from './routes/routes.const';
import { theme } from './style';

export const App: FC = () => {
  const routes = getRoutes();
  const content = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProviderWrapper>
        <AppContainer>
          <LogoHeader />
          {content}
          <Menu />
        </AppContainer>
      </SnackbarProviderWrapper>
    </ThemeProvider>
  );
};
