import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { loadLanguageState } from '../helpers/storage.helper';
import type { Language } from '../types/Language';
import enTrans from './enTrans.json';
import nlTrans from './nlTrans.json';
import plTrans from './plTrans.json';

export const initTransaltion = (): void => {
  const lang = loadLanguageState();

  i18next.use(initReactI18next).init({
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    lng: lang?.language || 'en',
    resources: {
      en: { translation: enTrans },
      nl: { translation: nlTrans },
      pl: { translation: plTrans },
    },
  });
};

export const changeTranslation = (lang: Language): void => {
  i18next.changeLanguage(lang);
};
