import { Box } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect } from 'react';

import type { StatusUpdateFormProps } from '../../status-update-modal.component';
import {
  StatusUpdateFormComment,
  StatusUpdateFormSelect,
} from '../StatusUpdateFormComponents';
import {
  solutionKey,
  solutionOptions,
  tenantConfirmationThatTaskIsClosedKey,
  tenantConfirmationThatTaskIsClosedOptions,
} from './util';

export const CloseStatusForm: FC<StatusUpdateFormProps> = ({
  data,
  setFormData,
  commentError,
  attachments,
  comment,
  setAttachments,
  setComment,
}: StatusUpdateFormProps) => {
  //  setting form data with default values
  useEffect(() => {
    setFormData(
      tenantConfirmationThatTaskIsClosedKey,
      tenantConfirmationThatTaskIsClosedOptions[0],
    );
    setFormData(solutionKey, solutionOptions[0]);
  }, [setFormData]);

  return (
    <Box>
      <StatusUpdateFormSelect
        value={
          data[tenantConfirmationThatTaskIsClosedKey] ||
          tenantConfirmationThatTaskIsClosedOptions[0]
        }
        options={tenantConfirmationThatTaskIsClosedOptions}
        fieldname={tenantConfirmationThatTaskIsClosedKey}
        onChange={setFormData}
      />
      <StatusUpdateFormSelect
        value={data[solutionKey] || solutionOptions[0]}
        options={solutionOptions}
        fieldname={solutionKey}
        onChange={setFormData}
      />
      <StatusUpdateFormComment
        error={commentError}
        comment={comment}
        setComment={setComment}
        attachments={attachments}
        setAttachments={setAttachments}
      />
    </Box>
  );
};
