import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  mention: {
    '&:last-child': {
      borderBottom: '1px solid',
    },
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.common.white,
    borderColor: (theme) => theme.palette.grey['400'],
    borderTop: '1px solid',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 1,
  },

  mentionAvatar: {
    height: (theme) => theme.spacing(6),
    margin: (theme) => theme.spacing(0, 2),
    width: (theme) => theme.spacing(6),
  },
};
