import type { Filter } from '../types/Filter';

export const filterOrder = [
  {
    activeFilterLabelKey: '+ticketListScreen.filter.activeFilter.byDateOldest',
    modalLabelKey: '+ticketListScreen.filter.modal.order.byDateOldest',
    value: 'created_at',
  },
  {
    activeFilterLabelKey: '+ticketListScreen.filter.activeFilter.byDateNewest',
    modalLabelKey: '+ticketListScreen.filter.modal.order.byDateNewest',
    value: '-created_at',
  },
];

export const defaultFilterOrder = filterOrder[0];

export const initialFilterState: Filter = {
  order: defaultFilterOrder.value,
};

// if default value is set, don't show active filter - this is default behavior.
// if query is also set - only then show ordering  by default value active filter.
export const isFilterOrderSet = (filter?: Filter): boolean => {
  if (filter?.query && filter?.order) return true;

  return Boolean(filter?.order && filter.order !== defaultFilterOrder.value);
};

export const isFilterOverdueSet = (filter?: Filter): boolean => {
  return Boolean(filter?.isOverdue);
};

export const isFilterStatusSet = (filter?: Filter): boolean => {
  return Boolean(filter?.status && filter.status.length > 0);
};

export const isFilterQuerySet = (filter?: Filter): boolean => {
  return Boolean(filter?.query && filter.query.trim().length > 0);
};

export const isFilterPropertyObjectSet = (filter?: Filter): boolean => {
  return Boolean(filter?.propertyObject);
};

export const isFilterDisplayUnassignedSet = (filter?: Filter): boolean => {
  return Boolean(filter?.displayUnassigned);
};

export const isFilterActive = (filter?: Filter): boolean => {
  return (
    isFilterOrderSet(filter) ||
    isFilterOverdueSet(filter) ||
    isFilterStatusSet(filter) ||
    isFilterQuerySet(filter) ||
    isFilterPropertyObjectSet(filter) ||
    isFilterDisplayUnassignedSet(filter)
  );
};
