export type TicketViewNavbarItem = {
  labelKey: string;
  value: TicketViewNavbarItemValue;
  path: string;
};

export type Counter = {
  type: CounterTypes;
  value: number;
};

export enum CounterTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  DISABLED = 'DISABLED',
}

export enum TicketViewNavbarItemValue {
  DETAILS = 'DETAILS',
  ATTACHMENTS = 'ATTACHMENTS',
  MESSAGES = 'MESSAGES',
}
