import { Box, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { useT } from '../../../../translation/translation.provider';
import { ReactComponent as NoTicketImage } from './assets/no-tickets-image.svg';
import { sxProps } from './no-notifications.style';

export const NoNotifications: FC = () => {
  const t = useT();

  return (
    <Stack sx={sxProps.noNotificationsStack}>
      <Box />
      <Box sx={sxProps.noNotificationsImage}>
        <NoTicketImage />
      </Box>
      <Box sx={sxProps.noNotificationsText}>
        <Typography variant="h3" sx={sxProps.noNotificationsHeadline}>
          {t('shared.notifications.noNotifications.title')}
        </Typography>
        <Typography>
          {t('shared.notifications.noNotifications.text')}
        </Typography>
      </Box>
    </Stack>
  );
};
