import type { AxiosError } from 'axios';
import camelCase from 'lodash-es/camelCase';
import isNil from 'lodash-es/isNil';
import snakeCase from 'lodash-es/snakeCase';

import { refreshToken } from '../services/auth.service';
import { HttpStatus } from './type';

type TransformType = 'CAMEL_CASE' | 'SNAKE_CASE';

const getTransformByType = (
  type: TransformType,
): typeof camelCase | typeof snakeCase => {
  switch (type) {
    case 'SNAKE_CASE': {
      return snakeCase;
    }

    case 'CAMEL_CASE':
    default: {
      return camelCase;
    }
  }
};

export const transformKeys = (
  // TODO: Fix types
  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  obj: any,
  type: TransformType,
  parseKeys = false,
  // TODO: Fix types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any | undefined => {
  if (isNil(obj)) {
    return obj;
  }

  let parsedObj = obj;

  if (parseKeys) {
    try {
      parsedObj = JSON.parse(obj);
    } catch {
      // do nothing
    }
  }

  if (Array.isArray(parsedObj)) {
    return parsedObj.map((v) => transformKeys(v, type));
  }

  if (parsedObj.constructor === Object) {
    return Object.keys(parsedObj).reduce(
      (result, key) => ({
        ...result,
        [getTransformByType(type)(key)]: transformKeys(parsedObj[key], type),
      }),
      {},
    );
  }

  return parsedObj;
};

export const handleError = async (
  error: AxiosError,
): Promise<AxiosError | unknown> => {
  if (!error.response) {
    return Promise.reject(error);
  }

  switch (error.response.status) {
    case HttpStatus.UNAUTHORIZED: {
      return refreshToken(error);
    }

    default: {
      return Promise.reject(transformKeys(error.response, 'CAMEL_CASE'));
    }
  }
};
