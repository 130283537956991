import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  button: {
    height: (theme) => theme.spacing(5.5),
  },

  buttonContainer: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    mt: 4,
  },

  buttonIcon: {
    height: (theme) => theme.spacing(3),
    mr: 1,
    width: (theme) => theme.spacing(3),
  },

  createTicketScreen: {
    background: (theme) => theme.palette.common.white,
    height: (theme) => theme.custom.pageHeight,
    overflow: 'scroll',
    pb: 7,
  },

  inputWrapper: {
    mb: 2,
  },

  overline: {
    color: (theme) => theme.palette.primary.main,
    display: 'block',
    mb: 2,
    mt: 2,
  },

  resultScreenHeadline: {
    fontWeight: 700,
    paddingBottom: (theme) => theme.spacing(1),
  },
  resultScreenImage: {
    padding: (theme) => theme.spacing(5, 0, 5, 0),
  },
  resultScreenStack: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: (theme) => theme.spacing(0, 1),
  },

  resultScreenText: {
    color: (theme) => theme.palette.common.black,
    textAlign: 'center',
  },
  successScreenButton: {
    mb: 1,
  },
  successScreenButtonBox: {
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    mt: 3,
  },

  title: {
    mt: 4.5,
  },
};
