import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  profileImage: {
    borderRadius: '50%',
    width: '100%',
  },

  profileImageBox: {
    marginTop: (theme) => theme.spacing(6.25),
  },
};
