import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  ticketsSearchBoxWrapper: {
    paddingRight: (theme) => theme.spacing(1),
  },
  ticketsSearchField: {
    background: (theme) => theme.palette.common.white,
    borderRadius: (theme) => theme.spacing(2),
  },
};
