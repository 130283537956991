import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  ticketDetailsBox: {
    color: (theme) => theme.palette.common.black,
    height: (theme) => theme.custom.ticketDetailsHeight,
    overflow: 'scroll',
  },
  ticketDetailsMap: {
    height: (theme) => theme.spacing(50),
    marginTop: (theme) => theme.spacing(5.5),
    width: '100%',
  },
};
