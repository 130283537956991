import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  divider: {
    color: (theme) => theme.palette.divider,
    marginBottom: (theme) => theme.spacing(2.5),
    width: '100%',
  },

  listIcon: {
    height: (theme) => theme.spacing(2),
    marginRight: (theme) => theme.spacing(2.5),
    width: (theme) => theme.spacing(2),
  },

  propertyBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: (theme) => theme.spacing(4),
  },

  propertyButton: {
    marginRight: (theme) => theme.spacing(1),
    marginTop: (theme) => theme.spacing(2.25),
    width: '90%',
  },

  propertyIcon: {
    height: (theme) => theme.spacing(4),
    width: (theme) => theme.spacing(4),
  },

  propertyItemGrid: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: (theme) => theme.spacing(2),
    textAlign: 'center',
  },

  propertyLabel: {
    color: (theme) => theme.palette.secondary.light,
    display: 'block',
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '3rem',
    textTransform: 'uppercase',
  },
};
