import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  bottomBox: {
    alignItems: 'center',
    display: 'flex',
    height: '7.5vh',
    justifyContent: 'center',
    width: '100%',
  },

  icon: {
    height: (theme) => theme.spacing(2),
    mr: 0.5,
    width: (theme) => theme.spacing(2),
  },

  middleBox: {
    height: '55vh',
    overflowY: 'scroll',
  },

  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  modalContent: {
    borderRadius: '4px',
    minHeight: '70vh',
    width: '80vw',
  },
  textLight: {
    color: (theme) => theme.palette.secondary.light,
    textAlign: 'center',
  },

  textTitle: {
    fontSize: '1.125rem',
  },

  topBox: {
    alignItems: 'center',
    display: 'flex',
    height: '7.5vh',
    justifyContent: 'space-between',
    pl: 2,
    pr: 2,
    width: '100%',
  },
};
