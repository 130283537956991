import { Box, Divider, Grid, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC, ReactNode } from 'react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';

import type { TicketsCountByStatusSerializerDTO } from '../../../../../connectors/ticket';
import { TicketStatusEnumDTO } from '../../../../../connectors/ticket';
import { mergeSx } from '../../../../shared/helpers/sx.helper';
import { paths } from '../../../../shared/routes/paths';
import { setNewFilter } from '../../../../shared/services/ticket.service';
import { globalSxProps } from '../../../../shared/style';
import { useT } from '../../../../shared/translation/translation.provider';
import { sxProps } from './overview.style';

export type ProfileTicketsProps = {
  ticketCount: TicketsCountByStatusSerializerDTO;
};

export const Overview: FC<ProfileTicketsProps> = ({
  ticketCount,
}: ProfileTicketsProps) => {
  const t = useT();
  const navigation = useNavigate();
  const { ticketsCount } = ticketCount;
  const totalCount = ticketsCount.total || 0;
  const openCount = ticketsCount[TicketStatusEnumDTO.Open] || 0;
  const inProgressCount = ticketsCount[TicketStatusEnumDTO.InProgress] || 0;
  const onHoldCount = ticketsCount[TicketStatusEnumDTO.OnHold] || 0;
  const awaitingTenant = ticketsCount[TicketStatusEnumDTO.AwaitingTenant] || 0;
  const awaitingExternalCount =
    ticketsCount[TicketStatusEnumDTO.AwaitingExternalProvider] || 0;
  const doneCount = ticketsCount[TicketStatusEnumDTO.Done] || 0;

  const onCountBoxClick = useCallback(
    (status: TicketStatusEnumDTO) => {
      setNewFilter({ status: [status] });
      navigation(paths.root);
    },
    [navigation],
  );

  const renderCountBox = (
    status: TicketStatusEnumDTO,
    count: number,
    label: string,
    isCountColorBlack: boolean,
    backgroundSx: SxProps<Theme>,
  ): ReactNode => {
    return (
      <Box
        sx={sxProps.countBox}
        onClick={() => onCountBoxClick(status)}
        data-test={`ticket-count-item-wrapper-${status}`}
      >
        <Box component="span" sx={mergeSx(sxProps.countCircle, backgroundSx)}>
          <Typography
            sx={sxProps.countNumber}
            color={isCountColorBlack ? 'black' : 'white'}
            variant="h3"
          >
            {count}
          </Typography>
        </Box>
        <Typography component="span">{label}</Typography>
      </Box>
    );
  };

  return (
    <Box sx={sxProps.overviewBox}>
      <Typography sx={sxProps.overviewLabel}>
        {t('+profile.overview.label')}
      </Typography>
      <Divider sx={sxProps.divider} variant="fullWidth" />
      <Typography variant="body2">
        <Typography component="span" sx={sxProps.totalCountNumber}>
          {totalCount}
        </Typography>{' '}
        {t('+profile.overview.total')}
      </Typography>
      <Grid container direction="column" sx={sxProps.overviewGrid}>
        <Grid item container xs={12}>
          <Grid item container xs={6}>
            {renderCountBox(
              TicketStatusEnumDTO.Open,
              openCount,
              t('+profile.overview.open'),
              true,
              globalSxProps.statusOpen,
            )}
          </Grid>
          <Grid item container xs={6}>
            {renderCountBox(
              TicketStatusEnumDTO.InProgress,
              inProgressCount,
              t('+profile.overview.inProgress'),
              false,
              globalSxProps.statusInProgress,
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item container xs={6}>
            {renderCountBox(
              TicketStatusEnumDTO.AwaitingTenant,
              awaitingTenant,
              t('+profile.overview.awaitingTenant'),
              true,
              globalSxProps.statusAwaitingTenant,
            )}
          </Grid>
          <Grid item container xs={6}>
            {renderCountBox(
              TicketStatusEnumDTO.OnHold,
              onHoldCount,
              t('+profile.overview.onHold'),
              false,
              globalSxProps.statusOnHold,
            )}
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item container xs={6}>
            {renderCountBox(
              TicketStatusEnumDTO.AwaitingExternalProvider,
              awaitingExternalCount,
              t('+profile.overview.awaitingExternal'),
              false,
              globalSxProps.statusAwaitingExternal,
            )}
          </Grid>
          <Grid item container xs={6}>
            {renderCountBox(
              TicketStatusEnumDTO.Done,
              doneCount,
              t('+profile.overview.closed'),
              false,
              globalSxProps.statusClosed,
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
