import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  noNotificationsHeadline: {
    fontWeight: 700,
    paddingBottom: (theme) => theme.spacing(1),
  },
  noNotificationsImage: {
    mb: 2,
    mt: 4,
  },
  noNotificationsStack: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: (theme) => theme.spacing(0, 1),
  },

  noNotificationsText: {
    color: (theme) => theme.palette.grey[700],
    pl: 3,
    pr: 3,
    textAlign: 'center',
  },
};
