import { ReactComponent as DeleteCircle } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/delete/delete-1.svg';
import { ReactComponent as CheckCircle } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/form-validation/check-circle-1.svg';
import {
  Modal,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from '@mui/material';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';

import type { CreateCommentSerializerDTO } from '../../../../../../../connectors/ticket';
import {
  CommentVisibilityEnumDTO,
  TicketStatusEnumDTO,
} from '../../../../../../../connectors/ticket';
import { IconWrapper } from '../../../../../../shared/components/IconWrapper';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { selectLoading } from '../../../../../../shared/store/loadingSlice';
import { useT } from '../../../../../../shared/translation/translation.provider';
import type { AttachmentForUpload } from '../../../../../../shared/types/AttachmentForUpload';
import { AwaitingExternalProviderStatusForm } from './components/AwaitingExternalProviderForm';
import { AwaitingTenantStatusForm } from './components/AwaitingTenantForm';
import { CloseStatusForm } from './components/CloseStatusForm';
import { sxProps } from './status-update-modal.style';
import { createUpdateCommentForFormData, validateComment } from './util';

export type StatusUpdateFormProps = {
  data: Record<string, string>;
  setFormData: (key: string, value: string) => void;
  comment: CreateCommentSerializerDTO;
  setComment: Dispatch<SetStateAction<CreateCommentSerializerDTO>>;
  attachments: AttachmentForUpload[];
  setAttachments: Dispatch<SetStateAction<AttachmentForUpload[]>>;
  commentError?: string;
};

export type StatusUpdateModalProps = {
  open: boolean;
  confirm: (
    status: TicketStatusEnumDTO,
    comment?: CreateCommentSerializerDTO,
    attachments?: AttachmentForUpload[],
    statusUpdateInformation?: CreateCommentSerializerDTO,
  ) => Promise<void>;
  cancel: () => void;
  status?: TicketStatusEnumDTO;
};

const initialTicketComment: CreateCommentSerializerDTO = {
  content: '',
  visibility: CommentVisibilityEnumDTO.Internal,
};

export const StatusUpdateModal: FC<StatusUpdateModalProps> = ({
  open,
  confirm,
  cancel,
  status,
}: StatusUpdateModalProps) => {
  const t = useT();
  const isLoading = useAppSelector((state) => selectLoading(state));
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [comment, setComment] =
    useState<CreateCommentSerializerDTO>(initialTicketComment);
  const [attachments, setAttachments] = useState<AttachmentForUpload[]>([]);
  const [commentError, setCommentError] = useState<string>();

  useEffect(() => {
    if (commentError) {
      setCommentError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment]);

  const clearForm = useCallback(() => {
    setFormData({});
    setComment(initialTicketComment);
    setAttachments([]);
    setCommentError(undefined);
  }, []);

  const onFormDataChange = useCallback((key: string, value: string): void => {
    setFormData((prevData) => {
      return { ...prevData, [key]: value };
    });
  }, []);

  const validateFormAndConfirm = useCallback(async (): Promise<void> => {
    const errorCode = validateComment(comment);

    if (errorCode) {
      setCommentError(errorCode);
    } else if (status) {
      await confirm(
        status,
        comment,
        attachments,
        createUpdateCommentForFormData(formData, t),
      );
      clearForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, commentError, comment, status]);

  const clearFormAndCancel = useCallback((): void => {
    clearForm();
    cancel();
  }, [cancel, clearForm]);

  const updateForm = useMemo<ReactNode>(() => {
    switch (status) {
      case TicketStatusEnumDTO.AwaitingExternalProvider:
        return (
          <AwaitingExternalProviderStatusForm
            data={formData}
            setFormData={onFormDataChange}
            commentError={commentError}
            comment={comment}
            attachments={attachments}
            setComment={setComment}
            setAttachments={setAttachments}
          />
        );
      case TicketStatusEnumDTO.AwaitingTenant:
        return (
          <AwaitingTenantStatusForm
            data={formData}
            setFormData={onFormDataChange}
            commentError={commentError}
            comment={comment}
            attachments={attachments}
            setComment={setComment}
            setAttachments={setAttachments}
          />
        );
      case TicketStatusEnumDTO.Done:
        return (
          <CloseStatusForm
            data={formData}
            setFormData={onFormDataChange}
            commentError={commentError}
            comment={comment}
            attachments={attachments}
            setComment={setComment}
            setAttachments={setAttachments}
          />
        );
      default:
        return null;
    }
  }, [status, formData, onFormDataChange, commentError, comment, attachments]);

  if (!status) return null;

  return (
    <Modal open={open} sx={sxProps.modal} data-test="status-update-modal">
      <Card sx={sxProps.modalContent}>
        <CardContent>
          <Typography variant="overline" gutterBottom sx={sxProps.modalLabel}>
            {t(`+ticketView.details.statusUpdateForm.changeStatusLabel`)}
          </Typography>
          <Typography variant="h1" component="div" sx={sxProps.modalTitle}>
            {t(`shared.status.${status}`)}
          </Typography>
          {updateForm}
        </CardContent>
        <CardActions>
          <Button
            data-test="status-update-modal-cancel-button"
            fullWidth
            variant="outlined"
            sx={sxProps.cancelButton}
            onClick={() => clearFormAndCancel()}
            disabled={isLoading}
          >
            <IconWrapper icon={DeleteCircle} sx={sxProps.buttonIcon} />
            {t(`+ticketView.details.statusUpdateForm.cancelButton`)}
          </Button>
          <Button
            data-test="status-update-modal-confirm-button"
            fullWidth
            variant="contained"
            sx={sxProps.confirmButton}
            onClick={validateFormAndConfirm}
            disabled={isLoading}
          >
            <IconWrapper icon={CheckCircle} sx={sxProps.buttonIcon} />
            {t(`+ticketView.details.statusUpdateForm.confirmButton`)}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
