import type { FC, ReactNode } from 'react';
import React, { useContext, createContext } from 'react';

import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../connectors/ticket/dto/universal-tickets-with-additional-fields-serializer-dto';

interface TicketContextProps {
  children: ReactNode;
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO;
}

const TicketContext = createContext<{
  ticket?: UniversalTicketsWithAdditionalFieldsSerializerDTO;
}>({});

export const useTicket =
  (): UniversalTicketsWithAdditionalFieldsSerializerDTO => {
    const { ticket } = useContext(TicketContext);

    if (!ticket)
      throw new Error(
        'Ticket is not present. Hook is called outside of context provider.',
      );

    return ticket;
  };

export const TicketContextProvider: FC<TicketContextProps> = ({
  children,
  ticket,
}: TicketContextProps) => {
  if (!ticket) return null;

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <TicketContext.Provider value={{ ticket }}>
      {children}
    </TicketContext.Provider>
  );
};
