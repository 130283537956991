import { ReactComponent as MessageIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-double.svg';
import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router';

import type { CreateCommentSerializerDTO } from '../../../../../../../connectors/ticket';
import { TicketStatusEnumDTO } from '../../../../../../../connectors/ticket';
import { IconWrapper } from '../../../../../../shared/components/IconWrapper';
import { SelectStatusPicker } from '../../../../../../shared/components/SelectStatus/components/SelectStatusPicker';
import { paths } from '../../../../../../shared/routes/paths';
import {
  loadMetadata,
  updateTicketStatus,
} from '../../../../../../shared/services/ticket.service';
import { selectLoggedUser } from '../../../../../../shared/store/authSlice';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { selectLoading } from '../../../../../../shared/store/loadingSlice';
import { selectMetadataForTicket } from '../../../../../../shared/store/ticketSlice';
import { useT } from '../../../../../../shared/translation/translation.provider';
import type { AttachmentForUpload } from '../../../../../../shared/types/AttachmentForUpload';
import { useTicket } from '../../../../ticket.context';
import { StatusUpdateModal } from '../StatusUpdateModal';
import { sxProps } from './ticket-details-actions.style';

export const TicketDetailsActions: FC = () => {
  const t = useT();
  const location = useLocation();
  const navigate = useNavigate();
  const ticket = useTicket();
  const isLoading = useAppSelector((state) => selectLoading(state));
  const metadata = useAppSelector((state) =>
    selectMetadataForTicket(state, ticket),
  );
  const loggedUser = useAppSelector((state) => selectLoggedUser(state));
  const [modalOpen, setModalOpen] = useState(false);
  const [statusToUpdate, setStatusToUpdate] = useState<TicketStatusEnumDTO>();

  // eslint-disable-next-line max-len
  const path = `/${paths.ticket}/${ticket.referenceNumber}/${paths.ticketMessages}`;
  const active = path === location.pathname;

  useEffect(() => {
    if (!metadata) {
      loadMetadata(ticket.countryUuid);
    }
  }, [metadata, ticket]);

  const onMessageButtonClick = useCallback((): void => {
    return active ? undefined : navigate(path);
  }, [active, navigate, path]);

  const closeModal = (): void => {
    setModalOpen(false);
    setStatusToUpdate(undefined);
  };

  const updateStatus = useCallback(
    async (
      status: TicketStatusEnumDTO,
      comment?: CreateCommentSerializerDTO,
      attachments?: AttachmentForUpload[],
      statusUpdateInformation?: CreateCommentSerializerDTO,
    ): Promise<void> => {
      await updateTicketStatus(
        ticket,
        status,
        comment,
        attachments,
        statusUpdateInformation,
      );
      closeModal();
    },
    [ticket],
  );

  const statusChangeHandler = useCallback(
    (status: TicketStatusEnumDTO): void => {
      if (!status) return;

      switch (status) {
        case TicketStatusEnumDTO.AwaitingExternalProvider:
        case TicketStatusEnumDTO.AwaitingTenant:
        case TicketStatusEnumDTO.Done:
          setStatusToUpdate(status);
          setModalOpen(true);
          break;
        default:
          updateStatus(status);
      }
    },
    [updateStatus],
  );

  const statusPicker = useMemo(() => {
    if (!metadata || !ticket.status) return null;
    const locked = loggedUser?.currentUserData?.uuid !== ticket.assignee?.uuid;
    const disabled = locked || isLoading;

    return (
      <SelectStatusPicker
        disabled={disabled}
        locked={locked}
        setSelectedStatus={statusChangeHandler}
        selectedStatus={ticket.status}
        metadata={metadata}
      />
    );
  }, [
    metadata,
    ticket.status,
    ticket.assignee?.uuid,
    loggedUser?.currentUserData?.uuid,
    isLoading,
    statusChangeHandler,
  ]);

  return (
    <Box sx={sxProps.ticketDetailsActions}>
      <Box sx={sxProps.ticketDetailsButtonsBox}>
        <Button
          data-test="ticket-view-details-message-center-button"
          color="primary"
          variant="contained"
          sx={sxProps.ticketDetailsButton}
          onClick={onMessageButtonClick}
        >
          <IconWrapper
            icon={MessageIcon}
            sx={sxProps.ticketDetailsButtonsIcon}
          />
          {t('+ticketView.details.actions.message')}
        </Button>
      </Box>
      <Box sx={sxProps.ticketDetailsSelect}>
        <Typography variant="overline" sx={sxProps.selectStatusLabel}>
          {t('+ticketView.details.actions.statusSelect.label')}
        </Typography>
        {statusPicker}
        <StatusUpdateModal
          status={statusToUpdate}
          open={modalOpen}
          confirm={updateStatus}
          cancel={closeModal}
        />
      </Box>
    </Box>
  );
};
