import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  ListNotificationSerializerDTO,
  NotificationSerializerDTO,
} from '../../../connectors/notification';
import { NotificationStatusDTO } from '../../../connectors/notification';
import { filterOnlyUniqueNotificaions } from '../helpers/notification.helper';
import type { RootState } from './index';

export interface NotificationsState {
  notifications: NotificationSerializerDTO[];
  nextPage?: number;
}

const initialState: NotificationsState = { nextPage: 0, notifications: [] };

/* eslint-disable no-param-reassign */
const notificationSlice = createSlice({
  initialState,
  name: 'notification',
  reducers: {
    // this is used when new notifications are pulled from backend periodically.
    // that's why we add new notifications to the beggining of the array in app state.
    addNewNotifications: (
      state,
      {
        payload: { results: newNotifications },
      }: PayloadAction<ListNotificationSerializerDTO>,
    ) => {
      const filteredNoticiations = filterOnlyUniqueNotificaions(
        state.notifications,
        newNotifications,
      );

      state.notifications = [...filteredNoticiations, ...state.notifications];
    },

    // this is used when new notifications are pulled from backend by using endless scroll.
    // that's why we add new notifications to the end of the array in app state, and store the information about next page.
    addNextNotificationPage: (
      state,
      {
        payload: { results: newNotifications, next },
      }: PayloadAction<ListNotificationSerializerDTO>,
    ) => {
      const filteredNoticiations = filterOnlyUniqueNotificaions(
        state.notifications,
        newNotifications,
      );

      state.nextPage = next;
      state.notifications = [...state.notifications, ...filteredNoticiations];
    },

    markAllNotificationsAsSeen: (state) => {
      state.notifications = state.notifications.map((n) => {
        return { ...n, status: NotificationStatusDTO.Seen };
      });
    },

    markNotificationAsSeen: (
      state,
      { payload }: PayloadAction<NotificationSerializerDTO>,
    ) => {
      state.notifications = state.notifications.map((n) =>
        n.uuid === payload.uuid
          ? { ...payload, status: NotificationStatusDTO.Seen }
          : n,
      );
    },

    removeAllNotifications: (state) => {
      state.notifications = [];
    },
  },
});

export const {
  addNewNotifications,
  addNextNotificationPage,
  markAllNotificationsAsSeen,
  markNotificationAsSeen,
  removeAllNotifications,
} = notificationSlice.actions;
export const selectNotifications = (
  state: RootState,
): NotificationSerializerDTO[] => state.notification.notifications;
export const selectNotificationsNextPage = (
  state: RootState,
): number | undefined => state.notification.nextPage;
export default notificationSlice.reducer;
