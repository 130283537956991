import { EntityEnumDTO } from '../../../../../../../connectors/ticket';

export const getPlaceholderKey = (
  entityType: EntityEnumDTO | undefined,
): string => {
  switch (entityType) {
    case EntityEnumDTO.Property:
      return '+createTicket.propertyPlaceholder';
    case EntityEnumDTO.PropertyObject:
      return '+createTicket.propertyObjectPlaceholder';
    case EntityEnumDTO.Unit:
      return '+createTicket.unitPlaceholder';
    default:
      return '';
  }
};

export const getLabelKey = (entityType: EntityEnumDTO | undefined): string => {
  switch (entityType) {
    case EntityEnumDTO.Property:
      return '+createTicket.propertyLabel';
    case EntityEnumDTO.PropertyObject:
      return '+createTicket.propertyObjectLabel';
    case EntityEnumDTO.Unit:
      return '+createTicket.unitLabel';
    default:
      return '';
  }
};
