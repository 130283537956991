import { ReactComponent as SearchIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-search.svg';
import { Box, InputAdornment, TextField } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useRef, useState, useCallback } from 'react';

import type { EmployeeSerializerDTO } from '../../../../../connectors/user';
import { useDebounce } from '../../../hooks/useDebounce';
import { queryUsers } from '../../../services/user.service';
import { useAppDispatch } from '../../../store/hooks';
import { clearQueriedUsers } from '../../../store/userSlice';
import { useT } from '../../../translation/translation.provider';
import { IconWrapper } from '../../IconWrapper';
import { sxProps } from '../assignee-picker.style';
import { TypingAssigneePickerPopper } from './typing-assignee-picker-popper.component';

export type TypingAssigneePickerProps = {
  quitTyping: () => void;
  onAssigneeSelect: (user: EmployeeSerializerDTO) => void;
};

export const TypingAssigneePicker: FC<TypingAssigneePickerProps> = ({
  onAssigneeSelect,
  quitTyping,
}: TypingAssigneePickerProps) => {
  const t = useT();
  const anchorRef = useRef(null);
  const containerRef = useRef(null);

  const dispatch = useAppDispatch();
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce(value);

  useEffect(() => {
    if (debouncedValue) {
      queryUsers(debouncedValue);
    } else {
      dispatch(clearQueriedUsers());
    }
  }, [debouncedValue, dispatch]);

  const blur = useCallback(() => {
    setTimeout(() => {
      setValue('');
      dispatch(clearQueriedUsers());
      quitTyping();
    }, 200);
  }, [dispatch, quitTyping]);

  const onUserSelect = (user: EmployeeSerializerDTO): void => {
    blur();
    onAssigneeSelect(user);
  };

  return (
    <Box ref={containerRef}>
      <TextField
        data-test="assignee-details-picker-input"
        ref={anchorRef}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus
        fullWidth
        placeholder={t('shared.assigneePicker.labelTyping')}
        onBlur={blur}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Box sx={sxProps.greyIconWrapper}>
                <IconWrapper sx={sxProps.icon} icon={SearchIcon} />
              </Box>
            </InputAdornment>
          ),
          sx: sxProps.typingPickerInput,
        }}
      />
      <TypingAssigneePickerPopper
        anchorRef={anchorRef}
        containerRef={anchorRef}
        selectUser={onUserSelect}
      />
    </Box>
  );
};
