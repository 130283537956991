import type { ThunkAction, Action } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import watch from 'redux-watch';

import {
  saveAuthState,
  saveCreateTicketState,
  saveLanguageState,
} from '../helpers/storage.helper';
import attachmentReducer from './attachmentSlice';
import type { AuthState } from './authSlice';
import authReducer from './authSlice';
import commentReducer from './commentSlice';
import companyReducer from './companySlice';
import type { CreateTicketState } from './createTicketSlice';
import createTicketReducer from './createTicketSlice';
import type { LanguageState } from './languageSlice';
import languageReducer from './languageSlice';
import loadingReducer from './loadingSlice';
import notificationReducer from './notificationsSlice';
import propertyReducer from './propertySlice';
import responseTemplateReducer from './responseTemplate';
import snackbarReducer from './snackbarSlice';
import ticketReducer from './ticketSlice';
import userReducer from './userSlice';

export const store = configureStore({
  reducer: {
    attachments: attachmentReducer,
    auth: authReducer,
    comment: commentReducer,
    company: companyReducer,
    createTicket: createTicketReducer,
    language: languageReducer,
    loading: loadingReducer,
    notification: notificationReducer,
    property: propertyReducer,
    responseTemplate: responseTemplateReducer,
    snackbar: snackbarReducer,
    ticket: ticketReducer,
    user: userReducer,
  },
});

// every time auth state us changed it will be saved in local storage
const watchAuth = watch(store.getState, 'auth');
const watchLanguage = watch(store.getState, 'language');
const watchCreateTicket = watch(store.getState, 'createTicket');

store.subscribe(
  watchAuth((newVal: AuthState, oldVal: AuthState) => {
    if (newVal !== oldVal) {
      saveAuthState(newVal);
    }
  }),
);
store.subscribe(
  watchLanguage((newVal: LanguageState, oldVal: LanguageState) => {
    if (newVal !== oldVal) {
      saveLanguageState(newVal);
    }
  }),
);

store.subscribe(
  watchCreateTicket((newVal: CreateTicketState, oldVal: CreateTicketState) => {
    if (newVal !== oldVal) {
      saveCreateTicketState(newVal);
    }
  }),
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
