import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  detailInformationError: {
    color: (theme) => theme.palette.error.main,
  },
  detailInformationIcon: {
    color: (theme) => theme.palette.common.black,
    height: (theme) => theme.spacing(3),
    margin: (theme) => theme.spacing(1.25, 1.125, 0, 0),
    width: (theme) => theme.spacing(3),
  },

  detailInformationLabel: {
    color: (theme) => theme.palette.grey[500],
  },
  detailInformationsGridContainer: {
    padding: (theme) => theme.spacing(0.75, 0),
  },
  detailInformationText: {
    marginTop: '-5px',
  },
};
