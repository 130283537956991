import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  CommentSerializerDTO,
  ListCommentSerializerDTO,
  UniversalTicketsWithAdditionalFieldsSerializerDTO,
} from '../../../connectors/ticket';
import type { RootState } from './index';

export interface CommentState {
  comments: Record<string, CommentSerializerDTO[]>;
}

const initialState: CommentState = { comments: {} };

/* eslint-disable no-param-reassign */
const commentSlice = createSlice({
  initialState,
  name: 'comment',
  reducers: {
    addComments: (
      state,
      action: PayloadAction<{
        ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO;
        comments: ListCommentSerializerDTO;
      }>,
    ) => {
      const { ticket, comments } = action.payload;

      state.comments[ticket.uuid] = comments.results;
    },
  },
});

export const { addComments } = commentSlice.actions;
export const selectCommentsForTicket = (
  state: RootState,
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): CommentSerializerDTO[] => state.comment.comments[ticket.uuid] || [];

export default commentSlice.reducer;
