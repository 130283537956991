import { Box, Typography } from '@mui/material';
import type { FC, RefObject } from 'react';
import React, { useCallback } from 'react';

import type {
  TenantSerializerDTO,
  UnitSerializerDTO,
} from '../../../../../connectors/property';
import {
  selectIsReporterMe,
  selectReporterTenant,
  selectReporterUnit,
  selectEntity,
  selectEntityType,
  setIsReporterMe,
  setReporterTenant,
  setReporterUnit,
  selectIsIssueSet,
} from '../../../../shared/store/createTicketSlice';
import { useAppDispatch, useAppSelector } from '../../../../shared/store/hooks';
import { useT } from '../../../../shared/translation/translation.provider';
import type { CreateTicketScreenErrorProps } from '../../create-ticket-screen.component';
import { sxProps } from '../../create-ticket-screen.style';
import { IsReporterMePicker } from './components/IsReporterMePicker';
import { ReporterTenantSelector } from './components/ReporterTenantSelector';
import { ReporterUnitSelector } from './components/ReporterUnitSelector';

export type ReporterChooserProps = {
  isReporterMeRef: RefObject<HTMLDivElement>;
  reporterTenantRef: RefObject<HTMLDivElement>;
  reporterUnitRef: RefObject<HTMLDivElement>;
};

export const ReporterChooser: FC<
  CreateTicketScreenErrorProps & ReporterChooserProps
> = ({
  error,
  setError,
  isReporterMeRef,
  reporterTenantRef,
  reporterUnitRef,
}: CreateTicketScreenErrorProps & ReporterChooserProps) => {
  const t = useT();
  const dispatch = useAppDispatch();
  const isReporterMe = useAppSelector((state) => selectIsReporterMe(state));
  const reporterTenant = useAppSelector((state) => selectReporterTenant(state));
  const reporterUnit = useAppSelector((state) => selectReporterUnit(state));
  // fields selected in previous steps
  const selectedEntity = useAppSelector((state) => selectEntity(state));
  const entityType = useAppSelector((state) => selectEntityType(state));
  const isIssueSet = useAppSelector((state) => selectIsIssueSet(state));

  const reporterTenantChangeHandler = useCallback(
    (reporterTenant?: TenantSerializerDTO) => {
      dispatch(setReporterTenant(reporterTenant));
      setError((error) => {
        return { ...error, reporterTenant: undefined };
      });
    },
    [dispatch, setError],
  );

  const reporterUnitChangeHandler = useCallback(
    (reporterUnit?: UnitSerializerDTO) => {
      dispatch(setReporterUnit(reporterUnit));
      reporterTenantChangeHandler(undefined);
      setError((error) => {
        return { ...error, reporterUnit: undefined };
      });
    },
    [dispatch, reporterTenantChangeHandler, setError],
  );

  const isReporterMeChangeHandler = useCallback(
    (isReporterMe: boolean) => {
      dispatch(setIsReporterMe(isReporterMe));
      reporterUnitChangeHandler(undefined);
      setError((error) => {
        return { ...error, isReporterMe: undefined };
      });
    },
    [dispatch, reporterUnitChangeHandler, setError],
  );

  if (!isIssueSet || !entityType || !selectedEntity) return null;

  return (
    <Box>
      <Typography variant="overline" sx={sxProps.overline}>
        {t('+createTicket.reporter')}
      </Typography>
      <Box sx={sxProps.inputWrapper} ref={isReporterMeRef}>
        <IsReporterMePicker
          isReporterMe={isReporterMe}
          onChange={isReporterMeChangeHandler}
          error={error.isReporterMe}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={reporterUnitRef}>
        <ReporterUnitSelector
          entityType={entityType}
          error={error.reporterUnit}
          isReporterMe={isReporterMe}
          onChange={reporterUnitChangeHandler}
          reporterUnit={reporterUnit}
          selectedEntity={selectedEntity}
        />
      </Box>
      <Box sx={sxProps.inputWrapper} ref={reporterTenantRef}>
        <ReporterTenantSelector
          entityType={entityType}
          selectedEntity={selectedEntity}
          error={error.reporterTenant}
          tenant={reporterTenant}
          reporterUnit={reporterUnit}
          isReporterMe={isReporterMe}
          onChange={reporterTenantChangeHandler}
        />
      </Box>
    </Box>
  );
};
