import { ReactComponent as BackArrowIcon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-left-1.svg';
import { ReactComponent as TryAgainIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/multimedia-controls/button-refresh-arrows.svg';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { IconWrapper } from '../../../../shared/components/IconWrapper';
import { paths } from '../../../../shared/routes/paths';
import { resetCreateTicketState } from '../../../../shared/store/createTicketSlice';
import { useT } from '../../../../shared/translation/translation.provider';
import { sxProps } from '../../create-ticket-screen.style';
import { ReactComponent as CreateTicketProblemImage } from './assets/create-ticket-problem.svg';

export const CreateTicketProblemScreen: FC = () => {
  const t = useT();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tryAgainAction = useCallback(() => {
    navigate(paths.createTicket);
  }, [navigate]);

  const backAction = useCallback(() => {
    dispatch(resetCreateTicketState());
    navigate(paths.root);
  }, [dispatch, navigate]);

  return (
    <Box>
      <Stack sx={sxProps.resultScreenStack}>
        <Box sx={sxProps.resultScreenImage}>
          <CreateTicketProblemImage />
        </Box>
        <Box sx={sxProps.resultScreenText}>
          <Typography variant="h1" sx={sxProps.resultScreenHeadline}>
            {t('+createTicket.problemScreen.title')}
          </Typography>
          <Typography>{t('+createTicket.problemScreen.text')}</Typography>
        </Box>
        <Grid container spacing={2} sx={sxProps.buttonContainer}>
          <Grid item xs={4}>
            <Button
              data-test="create-ticket-problem-back-button"
              variant="outlined"
              fullWidth
              sx={sxProps.button}
              onClick={backAction}
            >
              <IconWrapper icon={BackArrowIcon} sx={sxProps.buttonIcon} />
              {t('+createTicket.problemScreen.backButton')}
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              data-test="create-ticket-problem-try-again-button"
              variant="contained"
              fullWidth
              sx={sxProps.button}
              onClick={tryAgainAction}
            >
              <IconWrapper icon={TryAgainIcon} sx={sxProps.buttonIcon} />
              {t('+createTicket.problemScreen.tryAgainButton')}
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};
