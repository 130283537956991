import { ReactComponent as BellIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/alert/alarm-bell.svg';
import { Box, Typography } from '@mui/material';
import { t } from 'i18next';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { NotificationStatusDTO } from '../../../../../../connectors/notification';
import type { NotificationSerializerDTO } from '../../../../../../connectors/notification';
import { mergeSx } from '../../../../helpers/sx.helper';
import { markAsSeen } from '../../../../services/notificaiton.service';
import { IconWrapper } from '../../../IconWrapper';
import { sxProps } from './notification-item.style';

export type NotificationItemProps = {
  notification: NotificationSerializerDTO;
};

export const NotificationItem: FC<NotificationItemProps> = ({
  notification,
}: NotificationItemProps) => {
  const { name, status } = notification;
  const isSeen = useMemo(() => status === NotificationStatusDTO.Seen, [status]);

  const notificationBoxSx = useMemo(
    () =>
      isSeen
        ? mergeSx(sxProps.notificationBox, sxProps.seen)
        : mergeSx(sxProps.notificationBox, sxProps.unseenBackground),
    [isSeen],
  );

  const notificationType = useMemo(() => {
    return t('shared.notifications.general');
  }, []);

  const onNotificationClick = (): void => {
    if (isSeen) return;

    markAsSeen(notification);
  };

  return (
    <Box sx={notificationBoxSx} onClick={onNotificationClick}>
      <Box sx={sxProps.iconWrapper}>
        <IconWrapper icon={BellIcon} sx={sxProps.icon} />
      </Box>
      <Box>
        <Typography variant="subtitle2" component="div">
          {name}
        </Typography>
        <Typography variant="body2" component="div" sx={sxProps.textSecondary}>
          {notificationType}
        </Typography>
      </Box>
    </Box>
  );
};
