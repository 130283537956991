import { Alert } from '@mui/material';
import { SnackbarContent, useSnackbar } from 'notistack';
import React, { forwardRef } from 'react';

import { useT } from '../../../translation/translation.provider';
import type { Snackbar as SnackbarProps } from '../../../types/Snackbar';

export const CustomSnackbar = forwardRef<HTMLDivElement, SnackbarProps>(
  (props, ref) => {
    const t = useT();
    const { closeSnackbar } = useSnackbar();

    const handleClose = (): void => {
      closeSnackbar(props.id);
    };

    return (
      <SnackbarContent ref={ref}>
        <Alert
          variant="filled"
          onClose={handleClose}
          severity={props.severity}
          sx={{ width: '100%' }}
        >
          {t(props.message, props.data)}
        </Alert>
      </SnackbarContent>
    );
  },
);
