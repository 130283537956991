import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  button: {
    height: (theme) => theme.spacing(3),
    minWidth: (theme) => theme.spacing(3),
    overflow: 'hidden',
    p: 0,
    width: (theme) => theme.spacing(3),
  },

  dashIcon: {
    height: (theme) => theme.spacing(1),
    mr: 1,
    width: (theme) => theme.spacing(1),
  },

  headerBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
  },

  headerIcon: {
    color: (theme) => theme.palette.primary.main,
    height: (theme) => theme.spacing(8),
    ml: 1,
    width: (theme) => theme.spacing(8),
  },

  headerTitle: {
    ml: 1,
  },

  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  modalContent: {
    borderRadius: '8px',
    maxHeight: '40vh',
    overflowY: 'scroll',
    p: 1,
    width: '60vw',
  },

  modalSelectOptionBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    m: 1.5,
  },

  optionIcon: {
    height: (theme) => theme.spacing(2.5),
    mr: 1,
    width: (theme) => theme.spacing(2.5),
  },

  selectValueBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },
};
