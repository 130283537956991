import type { ParsedQuery } from 'query-string';
import qs from 'query-string';
import React, { useEffect } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import { AuthenticationTypeEnumDTO } from '../../../../connectors/user';
import { paths } from '../../../shared/routes/paths';
import { authorizationAzure } from '../../../shared/services/auth.service';
import { ImageBackground } from '../../shared/ImageBackground';

export const AuthCallbackScreen: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authType } = useParams();

  const authorize = async (
    provider: string,
    queryParams: ParsedQuery<string>,
  ): Promise<void> => {
    if (provider === AuthenticationTypeEnumDTO.Azure) {
      await authorizationAzure(queryParams);
      navigate(paths.root);
    }
  };

  useEffect(() => {
    const queryParams = qs.parse(location.search);

    if (authType) authorize(authType, queryParams);
  });

  return <ImageBackground />;
};
