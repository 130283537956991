import { createTheme } from '@mui/material/styles';
import type { PaletteColor } from '@mui/material/styles';

const primary = {
  contrastText: '#FFFFFF',
  dark: '#D04100',
  light: '#FF7435',
  main: '#FF5000',
};
const secondary = {
  contrastText: '#FFFFFF',
  dark: '#39475A',
  light: '#5D728F',
  main: '#44546A',
};
const info = {
  contrastText: '#FFFFFF',
  dark: '#0046DF',
  light: '#266AFF',
  main: '#0050FF',
};
const error = {
  contrastText: '#FFFFFF',
  dark: '#E31B0C',
  light: '#F88078',
  main: '#F44336',
};
const warning = {
  contrastText: 'rgba(0, 0, 0, 0.87)',
  dark: '#C77700',
  light: '#FFB547',
  main: '#FF9800',
};
const success = {
  contrastText: '#FFFFFF',
  dark: '#00842F',
  light: '#00E251',
  main: '#00AF3F',
};

const action = {
  disabled: 'rgba(0, 0, 0, 0.26)',
  disabledBackground: 'rgba(0, 0, 0, 0.12)',
  hover: 'rgba(0, 0, 0, 0.04)',
  selected: 'rgba(0, 0, 0, 0.08)',
};

const text = {
  disabled: 'rgba(0, 0, 0, 0.38)',
  primary: '#333132',
  secondary: 'rgba(68, 84, 106, 0.86)',
};

const other = {
  activeReading: '#FFB400',
  backdrop: 'rgba(0, 0, 0, 0.5)',
  border: 'rgba(0, 0, 0, 0.23)',
  divider: '#E0E0E0',
  snackbar: '#323232',
  waitingExternal: '#8754F3',
};

const grey = {
  '50': '#FAFAFA',
  '100': '#F5F5F5',
  '200': '#EEEEEE',
  '300': '#E0E0E0',
  '400': '#BDBDBD',
  '500': '#9E9E9E',
  '600': '#9E9E9E',
  '700': '#616161',
  '800': '#424242',
  '900': '#212121',
  a100: '#D5D5D5',
  a200: '#AAAAAA',
  a400: '#616161',
  a700: '#303030',
};

const background = {
  default: '#333132',
  paper: '#F4F7FA',
};
const boxShadow =
  '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)';

export const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          borderRadius: '64px',
          boxShadow,
          textTransform: 'none',
        },
      },
      styleOverrides: {
        containedPrimary: {
          '&:hover': {
            md: {
              backgroundColor: primary.dark,
            },
          },
          backgroundColor: primary.main,
          color: primary.contrastText,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        style: {
          borderRadius: '16px',
          boxShadow,
        },
      },
    },
    MuiCardActions: {
      defaultProps: {
        style: { padding: '1rem' },
      },
    },
    MuiDivider: {
      styleOverrides: {
        fullWidth: {
          color: other.divider,
          width: '100%',
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        style: {
          borderRadius: '16px',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        style: {
          boxShadow,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        color: 'secondary',
      },
    },
  },
  custom: {
    activeReading: other.activeReading,
    filterBarHeight: '8.4vh',
    headerContainerHeight: '9vh',
    headerWrapperHeight: '9.6vh',
    menuHeight: '9.4vh',
    notificationListHeight: '33vh',
    pageHeight: '81.4vh',
    tabMenuHeight: '6vh',
    ticketDetailsHeight: '75vh',
    ticketListHeight: '73vh',
    ticketListHeightWhenFilterActive: '65vh',
    waitingExternal: other.waitingExternal,
    warning,
  },
  palette: {
    action,
    background,
    divider: other.divider,
    error,
    grey,
    info,
    primary,
    secondary,
    success,
    text,
    warning,
  },
  typography: {
    body1: {
      fontSize: '1rem',
      letterSpacing: '0.15px',
      lineHeight: '150%',
    },
    body2: {
      fontSize: '0.8125rem', // 13px
      letterSpacing: '0.15px',
      lineHeight: '143%',
    },
    button: {
      fontSize: '0.875rem', //  14px
      fontWeight: 'bold',
      lineHeight: '150%',
      textTransform: 'none',
    },
    fontFamily: 'Arial',
    h1: { fontSize: '2rem', letterSpacing: '-1.5px', lineHeight: '112%' },
    h3: {
      fontSize: '1.375rem',
      lineHeight: '116.7%',
    },
    overline: {
      fontSize: '0.625rem',
      fontWeight: 700,
      lineHeight: '200%',
    },
    subtitle2: {
      fontWeight: 700,
      lineHeight: '150%',
      size: '0.875rem',
    },
  },
});

declare module '@mui/material/styles' {
  interface Theme {
    custom: {
      headerWrapperHeight: string;
      headerContainerHeight: string;
      menuHeight: string;
      tabMenuHeight: string;
      notificationListHeight: string;
      ticketListHeight: string;
      ticketListHeightWhenFilterActive: string;
      ticketDetailsHeight: string;
      filterBarHeight: string;
      pageHeight: string;
      activeReading: string;
      waitingExternal: string;
      warning: PaletteColor;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    custom?: {
      headerWrapperHeight?: string;
      headerContainerHeight?: string;
      menuHeight?: string;
      tabMenuHeight?: string;
      notificationListHeight?: string;
      ticketListHeight?: string;
      ticketListHeightWhenFilterActive?: string;
      ticketDetailsHeight?: string;
      filterBarHeight?: string;
      pageHeight?: string;
      activeReading?: string;
      waitingExternal?: string;
      warning?: PaletteColor;
    };
  }
}
