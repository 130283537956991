import type { TFunction } from 'i18next';

import type { CountrySerializerDTO } from '../../../../../../../connectors/company';
import type { ResponseTemplateSerializerDTO } from '../../../../../../../connectors/ticket';
import { getLanguage } from '../../../../../../shared/helpers/flag.helper';
import { textToResponseTemplateDto } from '../../../../../../shared/helpers/responseTemplate.helper';

export const getDefaultTemplates = (
  countries: CountrySerializerDTO[],
  t: TFunction,
): (ResponseTemplateSerializerDTO | null)[] => {
  return countries.map((c) => {
    const language = getLanguage(c);

    if (!language) return null;

    const content = t(
      '+ticketView.comments.responseTemplates.defaultConfirmTemplate',
      { lng: language },
    );

    return textToResponseTemplateDto(content, language, c);
  });
};
