import { Box, Stack, Typography } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { useT } from '../../../../../../shared/translation/translation.provider';
import { ReactComponent as NoTicketImage } from './assets/no-tickets-image.svg';
import { sxProps } from './no-ticket-screen.style';

export const NoTicketScreen: FC = () => {
  const t = useT();

  return (
    <Box>
      <Stack sx={sxProps.noTicketsStack}>
        <Box sx={sxProps.noTicketsImage}>
          <NoTicketImage />
        </Box>
        <Box sx={sxProps.noTicketsText}>
          <Typography variant="h1" sx={sxProps.noTicketsHeadline}>
            {t('+ticketListScreen.ticketList.noTickets.headline')}
          </Typography>
          <Typography>
            {t('+ticketListScreen.ticketList.noTickets.message.firstPart')}
          </Typography>
          <Typography>
            {t('+ticketListScreen.ticketList.noTickets.message.secondPart')}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
