import { useCallback, useRef, useState } from 'react';

import { LONG_PRESS_DELAY } from '../helpers/const';

interface PressHandlers<T> {
  onLongPress: (e: React.MouseEvent<T> | React.TouchEvent<T>) => void;
  onClick?: (e: React.MouseEvent<T> | React.TouchEvent<T>) => void;
}

interface OnLongPressResponse<T> {
  onMouseDown: (e: React.MouseEvent<T>) => void;
  onTouchStart: (e: React.TouchEvent<T>) => void;
  onMouseUp: (e: React.MouseEvent<T>) => void;
  onMouseLeave: (e: React.MouseEvent<T>) => void;
  onTouchEnd: (e: React.TouchEvent<T>) => void;
}

const isTouchEvent = (e: Event): e is TouchEvent => {
  return e && 'touches' in e;
};

const preventDefault = (e: Event): void => {
  if (!isTouchEvent(e)) return;

  if (e.touches.length < 2 && e.preventDefault) {
    e.preventDefault();
  }
};

interface Options {
  delay?: number;
  shouldPreventDefault?: boolean;
}

export function useLongPress<T>(
  { onLongPress, onClick }: PressHandlers<T>,
  { delay = LONG_PRESS_DELAY, shouldPreventDefault = true }: Options = {},
): OnLongPressResponse<T> {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef<NodeJS.Timeout>();
  const target = useRef<EventTarget>();

  const start = useCallback(
    (e: React.MouseEvent<T> | React.TouchEvent<T>) => {
      e.persist();
      const clonedEvent = { ...e };

      if (shouldPreventDefault && e.target) {
        e.target.addEventListener('touchend', preventDefault, {
          passive: false,
        });
        target.current = e.target;
      }

      timeout.current = setTimeout(() => {
        onLongPress(clonedEvent);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault],
  );

  const clear = useCallback(
    (
      e: React.MouseEvent<T> | React.TouchEvent<T>,
      shouldTriggerClick = true,
    ) => {
      if (timeout.current) clearTimeout(timeout.current);
      if (shouldTriggerClick && !longPressTriggered) onClick?.(e);

      setLongPressTriggered(false);

      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered],
  );

  return {
    onMouseDown: (e: React.MouseEvent<T>) => start(e),
    onMouseLeave: (e: React.MouseEvent<T>) => clear(e, false),
    onMouseUp: (e: React.MouseEvent<T>) => clear(e),
    onTouchEnd: (e: React.TouchEvent<T>) => clear(e),
    onTouchStart: (e: React.TouchEvent<T>) => start(e),
  };
}
