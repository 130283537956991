import { ReactComponent as AddUserIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geometric-full-body-multiple-users-actions/multiple-actions-add.svg';
import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { useT } from '../../../translation/translation.provider';
import { IconWrapper } from '../../IconWrapper';
import { sxProps } from '../assignee-picker.style';

export type EmptyAssigneePickerProps = {
  onClick: () => void;
};

export const EmptyAssigneePicker: FC<EmptyAssigneePickerProps> = ({
  onClick,
}: EmptyAssigneePickerProps) => {
  const t = useT();

  return (
    <Box
      sx={sxProps.emptyPickerWrapper}
      onClick={onClick}
      data-test="assignee-details-empty"
    >
      <Box sx={sxProps.greyIconWrapper}>
        <IconWrapper sx={sxProps.icon} icon={AddUserIcon} />
      </Box>
      <Typography variant="subtitle2" sx={sxProps.emptyPickerTypoghraphy}>
        {t('shared.assigneePicker.labelEmpty')}
      </Typography>
    </Box>
  );
};
