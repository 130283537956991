import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  assigneeDisplayButton: {
    alignItems: 'center',
    background: (theme) => theme.palette.grey[300],
    borderRadius: '50%',
    display: 'flex',
    height: (theme) => theme.spacing(3.5),
    justifyContent: 'center',
    mr: 0.75,
    p: 1,
    width: (theme) => theme.spacing(3.5),
  },

  assigneeDisplayButtonRemoveIcon: {
    color: (theme) => theme.palette.error.main,
    height: (theme) => theme.spacing(1.5),
    width: (theme) => theme.spacing(1.5),
  },
  assigneeDisplayButtons: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  assigneeDisplayButtonSearchIcon: {
    color: (theme) => theme.palette.secondary.light,
    height: (theme) => theme.spacing(1.5),
    width: (theme) => theme.spacing(1.5),
  },

  assigneeDisplayName: {
    color: (theme) => theme.palette.primary.main,
  },

  assigneeDisplayRole: {
    color: (theme) => theme.palette.text.secondary,
  },

  assigneeDisplayWrapper: {
    alignItems: 'center',
    backgroundColor: (theme) => theme.palette.grey[100],
    borderRadius: '36px',
    display: 'flex',
    justifyContent: 'flex-start',
    p: 0.5,
  },

  emptyPickerTypoghraphy: {
    color: (theme) => theme.palette.secondary.light,
  },

  emptyPickerWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    p: 0.5,
  },

  greyIconWrapper: {
    alignItems: 'center',
    background: (theme) => theme.palette.grey[200],
    borderRadius: '50%',
    display: 'flex',
    height: (theme) => theme.spacing(5),
    justifyContent: 'center',
    mr: 1,
    width: (theme) => theme.spacing(5),
  },

  icon: {
    height: (theme) => theme.spacing(3),
    width: (theme) => theme.spacing(3),
  },

  popperBox: {
    alignItems: 'flex-start',
    background: (theme) => theme.palette.common.white,
    borderRadius: '16px',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'scroll',
    padding: '8px',
    width: '100%',
  },

  popperBoxItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    pb: 0.5,
    pl: 1.5,
    pr: 1.5,
    pt: 0.5,
  },
  popperBoxItemAvatarWrapper: {
    alignItems: 'center',
    background: (theme) => theme.palette.grey[200],
    borderRadius: '50%',
    display: 'flex',
    height: (theme) => theme.spacing(5),
    justifyContent: 'center',
    mr: 1,
    width: (theme) => theme.spacing(5),
  },
  popperBoxItemTypography: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    whiteSpace: 'pre-wrap',
  },
  typingPickerInput: {
    borderRadius: '36px !important ',
    pl: 1,
  },
};

export const popper = {
  style: {
    borderRadius: '16px',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    maxHeight: '30vh',
    overflow: 'scroll',
    width: '100%',
    zIndex: 99,
  },
};
