import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  button: { height: (theme) => theme.spacing(4.5) },

  buttonCancel: {
    width: '40%',
  },

  buttonConfirm: {
    width: '60%',
  },

  buttonIcon: {
    '& path': {
      strokeWidth: '2px',
    },
    height: (theme) => theme.spacing(2.5),
    mr: 1,
    width: (theme) => theme.spacing(2.5),
  },
  cardActions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  headerIcon: {
    '& path': {
      strokeWidth: '0.75px',
    },
    height: (theme) => theme.spacing(8),
    mb: 3,
    width: (theme) => theme.spacing(8),
  },
  modal: {
    margin: '15vh 5vw 15vh 5vw',
  },
  modalContent: {
    borderColor: (theme) => theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: '2px',
    maxHeight: '70vh',
    overflowY: 'scroll',
    p: 1,
    position: 'relative',
  },

  text: {
    letterSpacing: '0.15px',
    lineHeight: '1.3rem',
  },

  title: {
    pb: 2,
  },
};
