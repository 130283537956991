import { ReactComponent as AttachmentIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/link-unlink/attachment.svg';
import { Box } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { AttachmentUploadButton } from '../../../Attachment/AttachmentUploadButton';
import { sxProps } from './comment-toolbar.style';

export type CommentToolbarProps = {
  onFileSelected: (attachments: File) => void;
};

export const CommentToolbar: FC<CommentToolbarProps> = ({
  onFileSelected,
}: CommentToolbarProps) => {
  return (
    <Box sx={sxProps.toolbar}>
      <AttachmentUploadButton
        onFileSelected={onFileSelected}
        forceCameraCapture
        customIconSx={sxProps.toolbarIcon}
        customWrapperSx={sxProps.toolbarButton}
        hideLabel
      />
      <AttachmentUploadButton
        onFileSelected={onFileSelected}
        customIconSx={sxProps.toolbarIcon}
        customWrapperSx={sxProps.toolbarButton}
        customIcon={AttachmentIcon}
        hideLabel
      />
    </Box>
  );
};

export default CommentToolbar;
