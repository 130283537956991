import { PriorityTypeEnumDTO } from '../../../../../../../connectors/ticket';
import type { ModalSelectOption } from '../../../../../../shared/components/ModalSelect';

const priorities: PriorityTypeEnumDTO[] = [
  PriorityTypeEnumDTO.Highest,
  PriorityTypeEnumDTO.High,
  PriorityTypeEnumDTO.Medium,
  PriorityTypeEnumDTO.Low,
  PriorityTypeEnumDTO.Lowest,
];

export const getPriorityOptions = (): ModalSelectOption[] => {
  return priorities.map((p) => {
    return { id: p, labelKey: `priority.${p}`, value: p };
  });
};
