import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  icon: {
    height: (theme) => theme.spacing(2.5),
    width: (theme) => theme.spacing(2.5),
  },

  iconWrapper: {
    alignItems: 'center',
    background: (theme) => theme.palette.primary.main,
    borderRadius: '50%',
    color: (theme) => theme.palette.primary.contrastText,
    display: 'flex',
    height: (theme) => theme.spacing(5),
    justifyContent: 'center',
    ml: 2,
    mr: 2,
    width: (theme) => theme.spacing(5),
  },

  notificationBox: {
    alignItems: 'center',
    display: 'flex',
    height: (theme) => theme.spacing(8.5),
    justifyContent: 'flex-start',
    width: '100%',
  },

  seen: {
    opacity: 0.7,
  },

  textSecondary: {
    color: (theme) => theme.palette.text.secondary,
  },

  unseenBackground: {
    background: (theme) => `${theme.palette.primary.main}30`,
  },
};
