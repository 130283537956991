import { ReactComponent as AddCircleIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/add-circle.svg';
import { ReactComponent as UploadIcon } from '@heimstaden/icons-library/img/streamline-regular/internet-networks-servers/upload-download/upload-button.svg';
import { Button, Grid, Box } from '@mui/material';
import type { Dispatch, FC, SetStateAction } from 'react';
import React from 'react';

import { AttachmentUploadForm } from '../../../../../../shared/components/Attachment/AttachmentUploadForm';
import { IconWrapper } from '../../../../../../shared/components/IconWrapper';
import { verifyAttachmentForUpload } from '../../../../../../shared/helpers/attachment.helper';
import { useT } from '../../../../../../shared/translation/translation.provider';
import type { AttachmentForUpload } from '../../../../../../shared/types/AttachmentForUpload';
import { sxProps } from './ticket-attachment-upload-form.style';

export type TicketAttachmentUploadFormProps = {
  attachmentForUpload: AttachmentForUpload;
  setAttachmentForUpload: Dispatch<
    SetStateAction<AttachmentForUpload | undefined>
  >;
  onAttachmentConfirm: () => void;
  onAttachmentCancel: () => void;
};

export const TicketAttachmentUploadForm: FC<
  TicketAttachmentUploadFormProps
> = ({
  attachmentForUpload,
  onAttachmentCancel,
  onAttachmentConfirm,
  setAttachmentForUpload,
}: TicketAttachmentUploadFormProps) => {
  const t = useT();

  const verifyAndUpload = (): void => {
    const error = verifyAttachmentForUpload(attachmentForUpload);

    if (error) {
      const { description: descriptionError, title: titleError } = error;

      setAttachmentForUpload({
        ...attachmentForUpload,
        descriptionError,
        titleError,
      });
    } else {
      onAttachmentConfirm();
    }
  };

  return (
    <Box>
      <AttachmentUploadForm
        attachment={attachmentForUpload}
        setAttachment={(att) => setAttachmentForUpload(att)}
      />
      <Grid container spacing={2} sx={sxProps.buttonContainer}>
        <Grid item xs={4.5}>
          <Button
            data-test="ticket-view-attachment-upload-button-cancel"
            startIcon=""
            variant="outlined"
            fullWidth
            sx={sxProps.button}
            onClick={onAttachmentCancel}
          >
            <IconWrapper icon={UploadIcon} sx={sxProps.buttonIcon} />
            {t('+ticketView.attachments.buttonCancel')}
          </Button>
        </Grid>
        <Grid item xs={4.5}>
          <Button
            data-test="ticket-view-attachment-upload-button-confirm"
            variant="contained"
            fullWidth
            sx={sxProps.button}
            onClick={verifyAndUpload}
          >
            <IconWrapper icon={AddCircleIcon} sx={sxProps.buttonIcon} />
            {t('+ticketView.attachments.buttonUpload')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
