import type {
  CommentSerializerDTO,
  LinkedUserSerializerDTO,
} from '../../../../../../../connectors/ticket';
import { MENTION_CHAR } from '../../../../../../shared/helpers/const';
import { getUserMentionString } from '../../../../../../shared/helpers/user.helper';

// this functions splits CommentSerializerDTO content by mentioned/tagged users
// and returns array of elements that can be styled
export const getCommentDisplayConfig = ({
  content,
  taggedUsers,
}: CommentSerializerDTO): {
  content: string;
  id: number;
  mentionedUser?: LinkedUserSerializerDTO;
}[] => {
  if (!taggedUsers || taggedUsers.length === 0) return [{ content, id: 0 }];

  const regExp = new RegExp(
    taggedUsers.map((user) => `(${MENTION_CHAR}${user.uuid})`).join('|'),
    'g',
  );
  const splittedContent = content.split(regExp).filter((s) => s);

  return splittedContent.map((phrase, idx) => {
    const mentionedUser = taggedUsers.find(
      (user) => phrase === `${MENTION_CHAR}${user.uuid}`,
    );

    return {
      content: mentionedUser
        ? `${MENTION_CHAR}${getUserMentionString(mentionedUser)}`
        : phrase,
      id: idx,
      mentionedUser,
    };
  });
};
