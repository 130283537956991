import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  chip: {
    '&.MuiChip-root': {
      border: (theme) => `1.5px solid ${theme.palette.primary.main}`,
      borderRadius: '12px',
    },
    background: (theme) => theme.palette.common.white,
    height: '100%',
    justifyContent: 'left',
    mb: 2,
    pb: 2.5,
    pt: 2.5,
    width: '100%',
  },

  chipTypography: {
    whiteSpace: 'normal',
  },

  selectedChip: {
    '&.MuiChip-root': {
      background: (theme) => `${theme.palette.primary.main}20`,
      border: (theme) => `1.5px solid ${theme.palette.error.main}`,
      borderRadius: '12px',
    },
  },
};
