import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from './index';

export interface LoadingState {
  loading: boolean;
}

const initialState: LoadingState = { loading: false };

/* eslint-disable no-param-reassign */
const loadingSlice = createSlice({
  initialState,
  name: 'loading',
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoading } = loadingSlice.actions;
export const selectLoading = (state: RootState): boolean =>
  state.loading.loading;

export default loadingSlice.reducer;
