import { store } from '../store';
import { setQueriedUsers } from '../store/userSlice';
import { userClient } from './clients/user.client';

export const queryUsers = async (query: string): Promise<void> => {
  try {
    const users = await userClient.fetchUsers(query);

    store.dispatch(setQueriedUsers(users));
  } catch {
    //  fails silently
  }
};
