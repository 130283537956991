import { ReactComponent as RemoveIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/subtract.svg';
import { ReactComponent as SearchIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/search/search.svg';
import { ReactComponent as AssigneeIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-users-actions-man/single-man-actions-refresh.svg';
import { Avatar, Box, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useState, useCallback, useMemo } from 'react';

import type { LinkedUserSerializerDTO } from '../../../../../connectors/ticket';
import type { EmployeeSerializerDTO } from '../../../../../connectors/user';
import { getUserFullname } from '../../../helpers/user.helper';
import { useT } from '../../../translation/translation.provider';
import { ConfirmationPopup } from '../../ConfirmationPopup';
import { IconWrapper } from '../../IconWrapper';
import { sxProps } from '../assignee-picker.style';

export type AssigneeDetailsProps = {
  onSearch: () => void;
  onRemove: () => void;
  assignee: LinkedUserSerializerDTO | EmployeeSerializerDTO;
  showConfirmationPopup?: boolean;
};

export const AssigneeDetails: FC<AssigneeDetailsProps> = ({
  onSearch,
  onRemove,
  assignee,
  showConfirmationPopup,
}: AssigneeDetailsProps) => {
  const t = useT();
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [isRemoveAction, setIsRemoveAction] = useState(false);

  const confirmationPopupComponent = useMemo(() => {
    if (!showConfirmationPopup) return null;
    const text1Key = isRemoveAction
      ? 'shared.assigneePicker.removePopup.text1'
      : 'shared.assigneePicker.searchPopup.text1';
    const text2Key = isRemoveAction
      ? 'shared.assigneePicker.removePopup.text2'
      : 'shared.assigneePicker.searchPopup.text2';
    const titleKey = isRemoveAction
      ? 'shared.assigneePicker.removePopup.title'
      : 'shared.assigneePicker.searchPopup.title';

    return (
      <ConfirmationPopup
        open={openConfirmationPopup}
        setOpen={setOpenConfirmationPopup}
        confirmationPopupData={{
          confirmButtonIcon: AssigneeIcon,
          icon: AssigneeIcon,
          text1Key,
          text2Key,
          titleKey,
        }}
        onConfirmCallback={() =>
          setTimeout(() => {
            if (isRemoveAction) onRemove();
            else onSearch();
          }, 10)
        }
      />
    );
  }, [
    isRemoveAction,
    onRemove,
    onSearch,
    openConfirmationPopup,
    showConfirmationPopup,
  ]);

  const assigneeRoles = useMemo(() => {
    const { roles } = assignee as LinkedUserSerializerDTO;

    // employee doesnt have role data
    if (!roles) {
      return null;
    }

    return (
      <Typography variant="body2" sx={sxProps.assigneeDisplayRole}>
        {roles[0] ? t(`roles.${roles[0]}`) : ''}
      </Typography>
    );
  }, [assignee, t]);

  const onSearchClick = useCallback(() => {
    if (showConfirmationPopup) {
      setIsRemoveAction(false);
      setOpenConfirmationPopup(true);
    } else onSearch();
  }, [onSearch, showConfirmationPopup]);

  const onRemoveClick = useCallback(() => {
    if (showConfirmationPopup) {
      setIsRemoveAction(true);
      setOpenConfirmationPopup(true);
    } else onRemove();
  }, [onRemove, showConfirmationPopup]);

  return (
    <Box
      sx={sxProps.assigneeDisplayWrapper}
      data-test="assignee-details-component"
    >
      <Box sx={sxProps.greyIconWrapper}>
        <Avatar alt={getUserFullname(assignee)} src={assignee.profilePicture} />
      </Box>
      <Box>
        <Typography variant="subtitle2" sx={sxProps.assigneeDisplayName}>
          {getUserFullname(assignee)}
        </Typography>
        {assigneeRoles}
      </Box>
      <Box sx={sxProps.assigneeDisplayButtons}>
        <Box sx={sxProps.assigneeDisplayButton} onClick={onSearchClick}>
          <IconWrapper
            sx={sxProps.assigneeDisplayButtonIcon}
            icon={SearchIcon}
          />
        </Box>
        <Box sx={sxProps.assigneeDisplayButton} onClick={onRemoveClick}>
          <IconWrapper
            sx={sxProps.assigneeDisplayButtonRemoveIcon}
            icon={RemoveIcon}
          />
        </Box>
      </Box>
      {confirmationPopupComponent}
    </Box>
  );
};
