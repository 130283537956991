import type {
  LocationTypeEnumDTO,
  TicketMetadataSerializerDTO,
  RoomTypeEnumDTO,
  IssueTypeEnumDTO,
  DamagedItemEnumDTO,
  DepartmentTypeEnumDTO,
  IssueSubtypeEnumDTO,
} from '../../../../../../../connectors/ticket';
import type { ModalSelectOption } from '../../../../../../shared/components/ModalSelect';

export type SubtypesAndDepartments = {
  subtype: IssueSubtypeEnumDTO;
  department?: DepartmentTypeEnumDTO;
  business_days?: number;
};

export const getAvailableIssueSubtypesAndDepartments = (
  metadata?: TicketMetadataSerializerDTO,
  issueLocation?: LocationTypeEnumDTO,
  issueRoom?: RoomTypeEnumDTO,
  issueType?: IssueTypeEnumDTO,
  issueItem?: DamagedItemEnumDTO,
): SubtypesAndDepartments[] => {
  if (
    !metadata?.serviceRequestsValidationMap ||
    !issueLocation ||
    !issueRoom ||
    !issueType ||
    !issueItem
  )
    return [];

  const issueSubtypesAndDepartments: SubtypesAndDepartments[] =
    metadata.serviceRequestsValidationMap[issueLocation][issueRoom][issueType][
      issueItem
    ];

  return issueSubtypesAndDepartments;
};

export const getIssueSubtypesOptions = (
  issueSubtypesAndDepartments: SubtypesAndDepartments[],
): ModalSelectOption[] =>
  issueSubtypesAndDepartments.map((s) => ({
    id: s.subtype,
    labelKey: `issue.${s.subtype}`,
    value: s.subtype,
  }));
