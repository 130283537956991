import dateFormat from 'dateformat';

export type DateFormat = 'd mmm' | 'dd.mm.yyyy' | 'yyyy-mm-dd';

export const formatDate = (stringDate: string, format: DateFormat): string => {
  return dateFormat(stringDate, format);
};

export const isOverdue = (stringDate?: string): boolean => {
  if (!stringDate) return false;
  const date = Date.parse(stringDate);

  return date < Date.now();
};
