import type { Theme } from '@mui/material';
import type { SxProps } from '@mui/system';

import { TicketStatusEnumDTO } from '../../../../connectors/ticket';
import { globalSxProps } from '../../style';
import { sxProps } from './select-status.style';

export type SelectMenuItem = {
  status?: TicketStatusEnumDTO;
  labelKey: string;
  sx: SxProps<Theme>;
};

export const statusSelectPickerItems: {
  status: TicketStatusEnumDTO;
  labelKey: string;
  sx: SxProps<Theme>;
}[] = [
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.Open}`,
    status: TicketStatusEnumDTO.Open,
    sx: globalSxProps.statusOpen,
  },
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.InProgress}`,
    status: TicketStatusEnumDTO.InProgress,
    sx: globalSxProps.statusInProgress,
  },
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.OnHold}`,
    status: TicketStatusEnumDTO.OnHold,
    sx: globalSxProps.statusOnHold,
  },
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.AwaitingTenant}`,
    status: TicketStatusEnumDTO.AwaitingTenant,
    sx: globalSxProps.statusAwaitingTenant,
  },
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.AwaitingExternalProvider}`,
    status: TicketStatusEnumDTO.AwaitingExternalProvider,
    sx: globalSxProps.statusAwaitingExternal,
  },
  {
    labelKey: `shared.status.${TicketStatusEnumDTO.Done}`,
    status: TicketStatusEnumDTO.Done,
    sx: globalSxProps.statusClosed,
  },
];

export const statusSelectFilterItems: {
  status?: TicketStatusEnumDTO;
  labelKey: string;
  sx: SxProps<Theme>;
}[] = [
  {
    labelKey: 'shared.status.active',
    status: undefined,
    sx: sxProps.openMenuBackground,
  },
  ...statusSelectPickerItems,
];
