import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  loaderWrapper: {
    display: 'block',
    height: '4px',
    width: '100%',
  },
};
