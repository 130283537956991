import React, { useCallback, useEffect, useMemo } from 'react';
import type { FC } from 'react';

import type {
  TenantSerializerDTO,
  UnitSerializerDTO,
} from '../../../../../../../connectors/property';
import { EntityEnumDTO } from '../../../../../../../connectors/ticket';
import { ModalSelect } from '../../../../../../shared/components/ModalSelect';
import { getTenantsForUnit } from '../../../../../../shared/services/property.service';
import type { EntityType } from '../../../../../../shared/services/types/property.types';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { selectTenantsForUnit } from '../../../../../../shared/store/propertySlice';
import { getReporterTenantOptions } from './util';

export type ReporterTenantSelectorProps = {
  isReporterMe: boolean;
  entityType: EntityEnumDTO;
  selectedEntity: EntityType;
  reporterUnit?: UnitSerializerDTO;
  tenant?: TenantSerializerDTO;
  onChange: (tenant?: TenantSerializerDTO) => void;
  error?: string;
};

export const ReporterTenantSelector: FC<ReporterTenantSelectorProps> = ({
  isReporterMe,
  onChange,
  selectedEntity,
  entityType,
  tenant,
  reporterUnit,
  error,
}: ReporterTenantSelectorProps) => {
  const tenantsForUnit = useAppSelector((state) => selectTenantsForUnit(state));

  // if entity type is unit we will return uuid of previously selected entity
  // otherwise we will return uuid of tenant unit, or undefined
  const unitUuid = useMemo(() => {
    if (isReporterMe) return undefined;

    if (entityType === EntityEnumDTO.Unit) {
      return selectedEntity.uuid;
    }

    return reporterUnit?.uuid;
  }, [entityType, isReporterMe, selectedEntity.uuid, reporterUnit?.uuid]);

  const tenantOptions = useMemo(
    () => getReporterTenantOptions(tenantsForUnit),
    [tenantsForUnit],
  );

  useEffect(() => {
    if (unitUuid) getTenantsForUnit(unitUuid);
  }, [unitUuid]);

  const onChangeHandler = useCallback(
    (value: string) => {
      const selectedTenant = tenantsForUnit.find((t) => t.uuid === value);

      if (selectedTenant) onChange(selectedTenant);
    },
    [onChange, tenantsForUnit],
  );

  if (!unitUuid) return null;

  return (
    <ModalSelect
      fieldname="tenant_selector"
      value={tenant?.uuid || ''}
      onSelect={(item) => onChangeHandler(item.value)}
      options={tenantOptions}
      renderAsSelect={{
        error,
        labelKey: '+createTicket.reporterTenantLabel',
      }}
    />
  );
};
