import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { globalSxProps } from '../../../../shared/style';
import { useTicket } from '../../ticket.context';

export type TicketHeaderProps = {
  ellipsis?: boolean;
};

export const TicketHeader: FC<TicketHeaderProps> = ({
  ellipsis,
}: TicketHeaderProps) => {
  const ticket = useTicket();

  const typographyStyle = useMemo(() => {
    return ellipsis ? globalSxProps.twoLinesOverflow : undefined;
  }, [ellipsis]);

  return (
    <Box>
      <Typography variant="h1" sx={typographyStyle}>
        {ticket.summary}
      </Typography>
    </Box>
  );
};
