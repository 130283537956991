import { Container } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { useSnackbarWatcher } from '../../hooks';
import { sxProps } from './app-container.style';

export interface AppContainerProps {
  children: React.ReactNode;
}

// Container for whole application
// Will surround application on screens larger then 600px
// On smaller screens will not be shown
export const AppContainer: FC<AppContainerProps> = ({
  children,
}: AppContainerProps) => {
  useSnackbarWatcher();

  return (
    <Container sx={sxProps.appContainer} maxWidth="sm">
      {children}
    </Container>
  );
};
