import type {
  ListTenantSerializerDTO,
  ListUnitsSerializerDTO,
} from '../../../connectors/property';
import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../connectors/ticket';
import { EntityEnumDTO } from '../../../connectors/ticket';
import { store } from '../store';
import { setLoading } from '../store/loadingSlice';
import {
  addEntity,
  clearQueriedEntities,
  clearTenantsForUnit,
  setMyPropertyObjects,
  setQueriedProperties,
  setQueriedPropertyObjects,
  setQueriedUnits,
  setTenantsForUnit,
  setUnitsForEntity,
} from '../store/propertySlice';
import { addWarningSnackbar } from '../store/snackbarSlice';
import { propertyClient } from './clients/property.client';
import type {
  GetUnitsForEntityParams,
  QueryEntitiesParams,
} from './types/property.types';

export const getEntityForTicket = async (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): Promise<void> => {
  let entity;

  store.dispatch(setLoading(true));
  try {
    entity = await propertyClient.getEntity(ticket);
    if (entity) store.dispatch(addEntity(entity));
  } catch {
    //  fail silently
  }
  store.dispatch(setLoading(false));
};

export const loadMyPropertyObjects = async (): Promise<void> => {
  store.dispatch(setLoading(true));
  try {
    const myProperties = await propertyClient.getMyPropertyObjects();

    store.dispatch(setMyPropertyObjects(myProperties));
  } catch {
    //  fail silently
  }
  store.dispatch(setLoading(false));
};

const queryUnits = async (params: QueryEntitiesParams): Promise<void> => {
  const res = await propertyClient.queryUnits(params);

  store.dispatch(setQueriedUnits(res));
};

const queryPropertyObjects = async (
  params: QueryEntitiesParams,
): Promise<void> => {
  const res = await propertyClient.queryPropertyObjects(params);

  store.dispatch(setQueriedPropertyObjects(res));
};

const queryProperties = async (params: QueryEntitiesParams): Promise<void> => {
  const res = await propertyClient.queryProperties(params);

  store.dispatch(setQueriedProperties(res));
};

export const queryEntities = async (
  params: QueryEntitiesParams,
  entityType: EntityEnumDTO,
): Promise<void> => {
  store.dispatch(clearQueriedEntities);

  if (!params.query) {
    return;
  }
  store.dispatch(setLoading(true));
  try {
    switch (entityType) {
      case EntityEnumDTO.Property:
        await queryProperties(params);
        break;
      case EntityEnumDTO.PropertyObject:
        await queryPropertyObjects(params);
        break;
      case EntityEnumDTO.Unit:
        await queryUnits(params);
        break;
      default:
        break;
    }
  } catch {
    //  fail silently
  }
  store.dispatch(setLoading(false));
};

export const getUnitsForEntity = async (
  params: GetUnitsForEntityParams,
  entityType: EntityEnumDTO,
): Promise<void> => {
  let unitsForEntity: ListUnitsSerializerDTO | undefined;

  store.dispatch(clearQueriedEntities());
  store.dispatch(setLoading(true));
  try {
    switch (entityType) {
      case EntityEnumDTO.Property:
        unitsForEntity = await propertyClient.getUnitsForProperty(params);
        break;
      case EntityEnumDTO.PropertyObject:
        unitsForEntity = await propertyClient.getUnitsForPropertyObject(params);
        break;
      default:
        break;
    }
  } catch {
    //  fail silently
  }

  if (unitsForEntity && unitsForEntity.results.length > 0) {
    store.dispatch(setUnitsForEntity(unitsForEntity));
  } else {
    store.dispatch(addWarningSnackbar({ message: 'error.noUnitsForEntity' }));
  }
  store.dispatch(setLoading(false));
};

export const getTenantsForUnit = async (unitUuid: string): Promise<void> => {
  store.dispatch(setLoading(true));
  store.dispatch(clearTenantsForUnit());
  let res: ListTenantSerializerDTO | undefined;

  try {
    res = await propertyClient.getTenantsForUnit(unitUuid);

    if (res?.results && res.results.length > 0) {
      store.dispatch(setTenantsForUnit(res));
    } else {
      store.dispatch(addWarningSnackbar({ message: 'error.noTenantsForUnit' }));
    }
  } catch {
    //  fail silently
  }
  store.dispatch(setLoading(false));
};
