import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  bottomNavigation: {
    background: (theme) => theme.palette.primary.main,
    height: (theme) => theme.custom.menuHeight,
    marginTop: 'auto',
  },

  bottomNavigationAction: {
    '&.Mui-disabled': {
      color: (theme) => theme.palette.grey[600],
    },
    alignItems: 'center',
    color: (theme) => theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
  },
};
