import { Box, Chip, Typography } from '@mui/material';
import type { Dispatch, FC, SetStateAction } from 'react';
import React from 'react';

import type { ResponseTemplateSerializerDTO } from '../../../../../../../../../../../connectors/ticket';
import { mergeSx } from '../../../../../../../../../../shared/helpers/sx.helper';
import { sxProps } from './template-list.style';

export type TemplateListProps = {
  templates: ResponseTemplateSerializerDTO[];
  selectedTemplate?: ResponseTemplateSerializerDTO;
  setSelectedTemplate: Dispatch<
    SetStateAction<ResponseTemplateSerializerDTO | undefined>
  >;
};

export const TemplateList: FC<TemplateListProps> = ({
  templates,
  selectedTemplate,
  setSelectedTemplate,
}: TemplateListProps) => {
  return (
    <Box>
      {templates.map((t) => {
        const selectedSx =
          t.uuid === selectedTemplate?.uuid ? sxProps.selectedChip : {};

        return (
          <Chip
            key={t.uuid}
            label={
              <Typography sx={sxProps.chipTypography}>{t.content}</Typography>
            }
            sx={mergeSx(sxProps.chip, selectedSx)}
            onClick={() => setSelectedTemplate(t)}
          />
        );
      })}
    </Box>
  );
};
