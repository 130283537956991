import {
  ENGLAND,
  getCountryFlag,
  NETHERLANDS,
  POLAND,
} from '../../helpers/flag.helper';
import type { ModalSelectOption } from '../ModalSelect';

export const languages: ModalSelectOption[] = [
  {
    icon: getCountryFlag(ENGLAND),
    id: 'en',
    labelKey: 'language.en',
    value: 'en',
  },
  {
    icon: getCountryFlag(NETHERLANDS),
    id: 'nl',
    labelKey: 'language.nl',
    value: 'nl',
  },
  {
    icon: getCountryFlag(POLAND),
    id: 'pl',
    labelKey: 'language.pl',
    value: 'pl',
  },
];
