import type { Theme } from '@mui/material';
import { Box } from '@mui/material';
import type { SxProps } from '@mui/system';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { TicketStatusEnumDTO } from '../../../../../../../connectors/ticket';
import { mergeSx } from '../../../../../../shared/helpers/sx.helper';
import { globalSxProps } from '../../../../../../shared/style';
import { sxProps } from './list-item-status-indicator.style';

export type ListItemStatusIndicatorProps = {
  status?: TicketStatusEnumDTO;
};

export const ListItemStatusIndicator: FC<ListItemStatusIndicatorProps> = ({
  status,
}: ListItemStatusIndicatorProps) => {
  //  depending of ticket status different background collor is given to status indicator
  const statusIndicatorSxProps = useMemo<SxProps<Theme>>(() => {
    switch (status) {
      case TicketStatusEnumDTO.Open:
        return mergeSx(
          sxProps.dashboardTicketListItemStatusIndicator,
          globalSxProps.statusOpen,
        );
      case TicketStatusEnumDTO.InProgress:
        return mergeSx(
          sxProps.dashboardTicketListItemStatusIndicator,
          globalSxProps.statusInProgress,
        );
      case TicketStatusEnumDTO.Done:
        return mergeSx(
          sxProps.dashboardTicketListItemStatusIndicator,
          globalSxProps.statusClosed,
        );
      case TicketStatusEnumDTO.OnHold:
        return mergeSx(
          sxProps.dashboardTicketListItemStatusIndicator,
          globalSxProps.statusOnHold,
        );
      case TicketStatusEnumDTO.AwaitingTenant:
        return mergeSx(
          sxProps.dashboardTicketListItemStatusIndicator,
          globalSxProps.statusAwaitingTenant,
        );
      case TicketStatusEnumDTO.AwaitingExternalProvider:
        return mergeSx(
          sxProps.dashboardTicketListItemStatusIndicator,
          globalSxProps.statusAwaitingExternal,
        );
      default:
        return sxProps.dashboardTicketListItemStatusIndicator;
    }
  }, [status]);

  return (
    <Box
      sx={statusIndicatorSxProps}
      bgcolor={(theme) => theme.palette.success.main}
    />
  );
};
