import type { CountrySerializerDTO } from '../../../../../../../connectors/company';
import type { ModalSelectOption } from '../../../../../../shared/components/ModalSelect';
import { getCountryFlag } from '../../../../../../shared/helpers/flag.helper';

export const getCountryOptions = (
  availableCountries: CountrySerializerDTO[],
): ModalSelectOption[] => {
  return availableCountries.map((c) => {
    return {
      icon: getCountryFlag(c.name),
      id: c.uuid,
      labelKey: c.name,
      value: c.uuid,
    };
  });
};
