export enum Platfrom {
  ANDROID,
  IOS,
  LINUX,
  WINDOWS,
  MACOS,
}

const { userAgent } = window.navigator;
const { platform } = window.navigator;
const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

export const detectPlatform = (): Platfrom => {
  if (macosPlatforms.indexOf(platform) !== -1) {
    return Platfrom.MACOS;
  }
  if (iosPlatforms.indexOf(platform) !== -1) {
    return Platfrom.IOS;
  }
  if (windowsPlatforms.indexOf(platform) !== -1) {
    return Platfrom.WINDOWS;
  }
  if (/Android/.test(userAgent)) {
    return Platfrom.ANDROID;
  }

  return Platfrom.LINUX;
};

export const isApplePlatform = (platform: Platfrom): boolean => {
  return platform === Platfrom.IOS || platform === Platfrom.MACOS;
};
