import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  appContainer: {
    backgroundColor: (theme) => theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    minHeight: '100vh',
    padding: () => ({ xs: 0 }),
  },
};
