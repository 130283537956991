import { ReactComponent as BackArrowIcon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-left-1.svg';
import { ReactComponent as HandTrashIcon } from '@heimstaden/icons-library/img/streamline-regular/ecology/recycling/recycling-hand-trash.svg';
import { ReactComponent as SmileyMadIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/smileys/smiley-concerned.svg';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { IconWrapper } from '../../../../shared/components/IconWrapper';
import { mergeSx } from '../../../../shared/helpers/sx.helper';
import { globalSxProps } from '../../../../shared/style';
import { useT } from '../../../../shared/translation/translation.provider';
import { sxProps } from './quitting-confirmation-modal.style';

export type QuittingConfirmationModalProps = {
  confirm: () => void;
  open: boolean;
  close: () => void;
};

export const QuittingConfirmationModal: FC<QuittingConfirmationModalProps> = ({
  confirm,
  open,
  close,
}: QuittingConfirmationModalProps) => {
  const t = useT();

  return (
    <Modal open={open} sx={sxProps.modal} onClose={close}>
      <Card sx={sxProps.modalContent}>
        <CardContent>
          <IconWrapper icon={SmileyMadIcon} sx={sxProps.headerIcon} />
          <Typography
            variant="h1"
            sx={mergeSx(globalSxProps.textBold, sxProps.title)}
          >
            {t('+createTicket.quittingConfirmationModal.title')}
          </Typography>
          <Typography gutterBottom sx={sxProps.text}>
            {t('+createTicket.quittingConfirmationModal.text')}
          </Typography>
        </CardContent>
        <CardActions sx={sxProps.cardActions}>
          <Button
            data-test="ticket-create-quit-modal-cancel-button"
            variant="outlined"
            sx={mergeSx(sxProps.button, sxProps.buttonCancel)}
            onClick={close}
          >
            <IconWrapper icon={BackArrowIcon} sx={sxProps.buttonIcon} />
            {t('+createTicket.quittingConfirmationModal.cancel')}
          </Button>
          <Button
            data-test="ticket-create-quit-modal-confirm-button"
            variant="contained"
            sx={mergeSx(sxProps.button, sxProps.buttonConfirm)}
            onClick={confirm}
          >
            <IconWrapper icon={HandTrashIcon} sx={sxProps.buttonIcon} />
            {t('+createTicket.quittingConfirmationModal.confirm')}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};
