import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  CountrySerializerDTO,
  CitySerializerDTO,
} from '../../../connectors/company';
import type {
  UnitSerializerDTO,
  TenantSerializerDTO,
} from '../../../connectors/property';
import type {
  EntityEnumDTO,
  PriorityTypeEnumDTO,
  DamagedItemEnumDTO,
  DepartmentTypeEnumDTO,
  IssueSubtypeEnumDTO,
  IssueTypeEnumDTO,
  LocationTypeEnumDTO,
  RoomTypeEnumDTO,
} from '../../../connectors/ticket';
import type { EmployeeSerializerDTO } from '../../../connectors/user';
import { loadCreateTicketState } from '../helpers/storage.helper';
import type { EntityType } from '../services/types/property.types';
import type { RootState } from './index';

export interface CreateTicketState {
  title: string;
  description: string;
  priority?: PriorityTypeEnumDTO;
  country?: CountrySerializerDTO;
  city?: CitySerializerDTO;
  entityType?: EntityEnumDTO;
  entity?: EntityType;
  issueLocation?: LocationTypeEnumDTO;
  issueRoom?: RoomTypeEnumDTO;
  issueType?: IssueTypeEnumDTO;
  issueItem?: DamagedItemEnumDTO;
  issueSubtype?: IssueSubtypeEnumDTO;
  isReporterMe: boolean;
  reporterUnit?: UnitSerializerDTO;
  reporterTenant?: TenantSerializerDTO;
  isAssigneeMe: boolean;
  assignee?: EmployeeSerializerDTO;
  department?: DepartmentTypeEnumDTO;
}

const persistedState = loadCreateTicketState();
const defaultState = {
  description: '',
  isAssigneeMe: true,
  isReporterMe: true,
  title: '',
};
const initialState: CreateTicketState = persistedState || defaultState;

/* eslint-disable no-param-reassign */
const createTicketSlice = createSlice({
  initialState,
  name: 'createTicket',
  reducers: {
    resetCreateTicketState: () => defaultState,
    setAssignee: (
      state,
      { payload: assignee }: PayloadAction<EmployeeSerializerDTO | undefined>,
    ) => {
      state.assignee = assignee;
    },
    setCity: (
      state,
      { payload: city }: PayloadAction<CitySerializerDTO | undefined>,
    ) => {
      state.city = city;
    },
    setCountry: (
      state,
      { payload: country }: PayloadAction<CountrySerializerDTO | undefined>,
    ) => {
      state.country = country;
    },
    setDepartment: (
      state,
      { payload: department }: PayloadAction<DepartmentTypeEnumDTO | undefined>,
    ) => {
      state.department = department;
    },
    setDescription: (
      state,
      { payload: description }: PayloadAction<string>,
    ) => {
      state.description = description;
    },
    setEntity: (
      state,
      { payload: entity }: PayloadAction<EntityType | undefined>,
    ) => {
      state.entity = entity;
    },
    setEntityType: (
      state,
      { payload: entityType }: PayloadAction<EntityEnumDTO | undefined>,
    ) => {
      state.entityType = entityType;
    },
    setIsAssigneeMe: (
      state,
      { payload: isAssigneeMe }: PayloadAction<boolean>,
    ) => {
      state.isAssigneeMe = isAssigneeMe;
    },
    setIsReporterMe: (
      state,
      { payload: isReporterMe }: PayloadAction<boolean>,
    ) => {
      state.isReporterMe = isReporterMe;
    },
    setIssueItem: (
      state,
      { payload: issueItem }: PayloadAction<DamagedItemEnumDTO | undefined>,
    ) => {
      state.issueItem = issueItem;
    },
    setIssueLocation: (
      state,
      {
        payload: issueLocation,
      }: PayloadAction<LocationTypeEnumDTO | undefined>,
    ) => {
      state.issueLocation = issueLocation;
    },
    setIssueRoom: (
      state,
      { payload: issueRoom }: PayloadAction<RoomTypeEnumDTO | undefined>,
    ) => {
      state.issueRoom = issueRoom;
    },
    setIssueSubtype: (
      state,
      { payload: issueSubtype }: PayloadAction<IssueSubtypeEnumDTO | undefined>,
    ) => {
      state.issueSubtype = issueSubtype;
    },
    setIssueType: (
      state,
      { payload: issueType }: PayloadAction<IssueTypeEnumDTO | undefined>,
    ) => {
      state.issueType = issueType;
    },
    setPriority: (
      state,
      { payload: priority }: PayloadAction<PriorityTypeEnumDTO | undefined>,
    ) => {
      state.priority = priority;
    },
    setReporterTenant: (
      state,
      {
        payload: reporterTenant,
      }: PayloadAction<TenantSerializerDTO | undefined>,
    ) => {
      state.reporterTenant = reporterTenant;
    },
    setReporterUnit: (
      state,
      { payload: reporterUnit }: PayloadAction<UnitSerializerDTO | undefined>,
    ) => {
      state.reporterUnit = reporterUnit;
    },

    setTitle: (state, { payload: title }: PayloadAction<string>) => {
      state.title = title;
    },
  },
});

export const {
  resetCreateTicketState,
  setAssignee,
  setCity,
  setCountry,
  setDepartment,
  setDescription,
  setEntity,
  setEntityType,
  setIsAssigneeMe,
  setIsReporterMe,
  setIssueItem,
  setIssueLocation,
  setIssueRoom,
  setIssueSubtype,
  setIssueType,
  setPriority,
  setReporterTenant,
  setReporterUnit,
  setTitle,
} = createTicketSlice.actions;

export const selectAssignee = (
  state: RootState,
): EmployeeSerializerDTO | undefined => state.createTicket.assignee;
export const selectCity = (state: RootState): CitySerializerDTO | undefined =>
  state.createTicket.city;
export const selectCountry = (
  state: RootState,
): CountrySerializerDTO | undefined => state.createTicket.country;
export const selectDepartment = (
  state: RootState,
): DepartmentTypeEnumDTO | undefined => state.createTicket.department;
export const selectDescription = (state: RootState): string =>
  state.createTicket.description;
export const selectEntity = (state: RootState): EntityType | undefined =>
  state.createTicket.entity;
export const selectEntityType = (state: RootState): EntityEnumDTO | undefined =>
  state.createTicket.entityType;
export const selectIsAssigneeMe = (state: RootState): boolean =>
  state.createTicket.isAssigneeMe;
export const selectIsReporterMe = (state: RootState): boolean =>
  state.createTicket.isReporterMe;
export const selectIssueItem = (
  state: RootState,
): DamagedItemEnumDTO | undefined => state.createTicket.issueItem;
export const selectIssueLocation = (
  state: RootState,
): LocationTypeEnumDTO | undefined => state.createTicket.issueLocation;
export const selectIssueRoom = (
  state: RootState,
): RoomTypeEnumDTO | undefined => state.createTicket.issueRoom;
export const selectIssueSubtype = (
  state: RootState,
): IssueSubtypeEnumDTO | undefined => state.createTicket.issueSubtype;
export const selectIssueType = (
  state: RootState,
): IssueTypeEnumDTO | undefined => state.createTicket.issueType;
export const selectPriority = (
  state: RootState,
): PriorityTypeEnumDTO | undefined => state.createTicket.priority;
export const selectReporterTenant = (
  state: RootState,
): TenantSerializerDTO | undefined => state.createTicket.reporterTenant;
export const selectReporterUnit = (
  state: RootState,
): UnitSerializerDTO | undefined => state.createTicket.reporterUnit;
export const selectTitle = (state: RootState): string =>
  state.createTicket.title;
export const selectIsEntitySet = (state: RootState): boolean => {
  const { entityType, entity } = state.createTicket;

  return Boolean(entity && entityType);
};
export const selectIsIssueSet = (state: RootState): boolean => {
  const { issueItem, issueLocation, issueRoom, issueSubtype, issueType } =
    state.createTicket;

  return Boolean(
    issueItem && issueLocation && issueRoom && issueSubtype && issueType,
  );
};

export default createTicketSlice.reducer;
