import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  LoginOutputSerializerDTO,
  MeSerializerDTO,
} from '../../../connectors/user';
import { loadAuthState } from '../helpers/storage.helper';
import type { AuthUser } from '../types/AuthUser';
import type { RootState } from './index';

export interface AuthState {
  loggedUser?: AuthUser;
}

const persistedState = loadAuthState();
const initialState: AuthState = persistedState || {};

/* eslint-disable no-param-reassign */
const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    login: (state, action: PayloadAction<AuthUser>) => {
      state.loggedUser = action.payload;
    },
    logout: (state) => {
      state.loggedUser = undefined;
    },
    setCurrentUser: (state, action: PayloadAction<MeSerializerDTO>) => {
      if (state.loggedUser) {
        state.loggedUser.currentUserData = action.payload;
      }
    },
    updateTokens: (state, action: PayloadAction<LoginOutputSerializerDTO>) => {
      if (state.loggedUser) {
        state.loggedUser.tokens = action.payload;
      }
    },
  },
});

export const { login, logout, updateTokens, setCurrentUser } =
  authSlice.actions;
export const selectLoggedUser = (state: RootState): AuthUser | undefined =>
  state.auth.loggedUser;

export default authSlice.reducer;
