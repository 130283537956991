import React from 'react';
import type { FC } from 'react';

import { ModalSelect } from '../../../../../../shared/components/ModalSelect';
import { ASSIGNEE_HEIMSTADEN, ASSIGNEE_ME, isAssigneeMeOptions } from './util';

export type IsAssigneeMePickerProps = {
  isAssigneeMe: boolean;
  onChange: (isReporterMe: boolean) => void;
  error?: string;
};

export const IsAssigneeMePicker: FC<IsAssigneeMePickerProps> = ({
  isAssigneeMe,
  onChange,
  error,
}: IsAssigneeMePickerProps) => {
  return (
    <ModalSelect
      fieldname="is_assignee_me"
      value={isAssigneeMe ? ASSIGNEE_ME : ASSIGNEE_HEIMSTADEN}
      onSelect={(item) => onChange(item.value === ASSIGNEE_ME)}
      options={isAssigneeMeOptions}
      renderAsSelect={{
        error,
        labelKey: '+createTicket.assigneeLabel',
      }}
    />
  );
};
