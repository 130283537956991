import React from 'react';
import type { FC } from 'react';

import { ModalSelect } from '../../../../../../shared/components/ModalSelect';
import { isReporterMeOptions, REPORTER_ME, REPORTER_TENANT } from './util';

export type IsReporterMePickerProps = {
  isReporterMe: boolean;
  onChange: (isReporterMe: boolean) => void;
  error?: string;
};

export const IsReporterMePicker: FC<IsReporterMePickerProps> = ({
  isReporterMe,
  onChange,
  error,
}: IsReporterMePickerProps) => {
  return (
    <ModalSelect
      fieldname="is_reporter_me"
      value={isReporterMe ? REPORTER_ME : REPORTER_TENANT}
      onSelect={(item) => onChange(item.value === REPORTER_ME)}
      options={isReporterMeOptions}
      renderAsSelect={{
        error,
        labelKey: '+createTicket.reporterLabel',
      }}
    />
  );
};
