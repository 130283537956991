import type { CreateServiceRequestSerializerDTO } from '../../../connectors/ticket';
import { EntityEnumDTO, TicketTypeEnumDTO } from '../../../connectors/ticket';
import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../connectors/ticket/dto/universal-tickets-with-additional-fields-serializer-dto';
import type { MeSerializerDTO } from '../../../connectors/user';
import type { CreateTicketState } from '../store/createTicketSlice';

export const filterOnlyUniqueTickets = (
  ticketsToFiter: UniversalTicketsWithAdditionalFieldsSerializerDTO[],
  ticketsToCompareTo: UniversalTicketsWithAdditionalFieldsSerializerDTO[],
): UniversalTicketsWithAdditionalFieldsSerializerDTO[] => {
  return ticketsToFiter.filter((ttf) => {
    return !ticketsToCompareTo.find((ttct) => {
      return ttf.uuid === ttct.uuid;
    });
  });
};

// TODO : remove this at some point when backend has functionality to update user profile
// without creating ticket. In the meantime, ticket that has entity type "user" and ticket type
// "general" will not be shown in the app
const isTicketForDisplay = ({
  entityType,
  ticketType,
}: UniversalTicketsWithAdditionalFieldsSerializerDTO): boolean => {
  switch (ticketType) {
    case TicketTypeEnumDTO.ServiceRequest:
    case TicketTypeEnumDTO.Complaint:
      return true;
    case TicketTypeEnumDTO.GeneralRequest:
      // TODO : remove this at some point when backend has functionality to update user profile
      // without creating ticket. In the meantime, ticket that has entity type "user" and ticket type
      // "general" will not be shown in the app
      return entityType !== EntityEnumDTO.User;
    default:
      return false;
  }
};

export const filterTicketForDisplay = (
  tickets: UniversalTicketsWithAdditionalFieldsSerializerDTO[],
): UniversalTicketsWithAdditionalFieldsSerializerDTO[] => {
  return tickets.filter((t) => isTicketForDisplay(t));
};

export const getCreateTicketData = (
  state: CreateTicketState,
  currentUser: MeSerializerDTO,
): CreateServiceRequestSerializerDTO | undefined => {
  const {
    title,
    description,
    priority,
    entityType,
    entity,
    department,
    isReporterMe,
    reporterTenant,
    isAssigneeMe,
    assignee,
    issueLocation,
    issueRoom,
    issueType,
    issueItem,
    issueSubtype,
  } = state;

  if (!title || !description || !priority) return undefined;
  if (!entityType || !entity) return undefined;
  if (!issueLocation || !issueRoom || !issueType || !issueItem || !issueSubtype)
    return undefined;

  return {
    assigneeUuid: isAssigneeMe ? currentUser.uuid : assignee?.uuid,
    damagedItem: issueItem,
    department,
    description,
    entityType,
    entityUuid: entity.uuid,
    issueLocation,
    issueSubtype,
    issueType,
    priority,
    reporterUuid: isReporterMe ? currentUser.uuid : reporterTenant?.uuid,
    roomWithIssue: issueRoom,
    summary: title,
  };
};
