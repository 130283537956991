import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

// this are styles that are used accross application
// but are to specific to be put into theme.ts
export const globalSxProps: Record<string, SxProps<Theme>> = {
  buttonLong: {
    flex: 1,
  },

  cleanLink: {
    '&:visited': {
      color: (theme) => theme.palette.primary.main,
    },
    textDecoration: 'none',
  },

  singleLineOverflow: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
  },

  statusAwaitingExternal: {
    background: (theme) => `${theme.custom.waitingExternal} !important`,
  },
  statusAwaitingTenant: {
    background: (theme) => `${theme.custom.activeReading} !important`,
  },
  statusClosed: {
    background: (theme) => `${theme.palette.success.main} !important`,
  },
  statusInProgress: {
    background: (theme) => `${theme.palette.info.main} !important`,
  },
  statusOnHold: {
    background: (theme) => `${theme.palette.error.main} !important`,
  },
  statusOpen: {
    background: (theme) => `${theme.palette.grey[500]} !important`,
  },
  textBold: {
    fontWeight: 700,
  },

  twoLinesOverflow: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
};
