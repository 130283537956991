import { ReactComponent as XIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import { ReactComponent as UseMessageIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-download.svg';
import { ReactComponent as MessageBubbleIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-star.svg';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import type { Dispatch, FC, SetStateAction } from 'react';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import type {
  LanguageDTO,
  ResponseTemplateSerializerDTO,
} from '../../../../../../../../../connectors/ticket';
import { IconWrapper } from '../../../../../../../../shared/components/IconWrapper';
import { mergeSx } from '../../../../../../../../shared/helpers/sx.helper';
import {
  deleteResponseTemplate,
  loadResponseTemplates,
  queryResponseTemplates,
} from '../../../../../../../../shared/services/ticket.service';
import { useAppSelector } from '../../../../../../../../shared/store/hooks';
import {
  selectResponseTemplate,
  selectResponseTemplates,
} from '../../../../../../../../shared/store/responseTemplate';
import { globalSxProps } from '../../../../../../../../shared/style';
import { useT } from '../../../../../../../../shared/translation/translation.provider';
import { ResponseTemplateConfirmationPopup } from '../ResponseTemplateConfirmationPopup';
import { TemplateLanguagePicker } from '../TemplateLanguagePicker';
import { TemplateList } from './components/TemplateList';
import { TemplateSearchBox } from './components/TemplateSearchBox';
import { sxProps } from './template-popup.style';

export type TemplatePopupProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const TemplatePopup: FC<TemplatePopupProps> = ({
  open,
  setOpen,
}: TemplatePopupProps) => {
  const t = useT();
  const dispath = useDispatch();
  const [language, setLanguage] = useState<LanguageDTO>();
  const [searchQuery, setSearchQuery] = useState('');
  const [confirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<ResponseTemplateSerializerDTO>();
  const responseTemplates = useAppSelector((state) =>
    selectResponseTemplates(state),
  );

  useEffect(() => {
    loadResponseTemplates();
  }, []);

  useEffect(() => {
    queryResponseTemplates(searchQuery || undefined, language);
  }, [language, searchQuery]);

  const close = useCallback(() => {
    setSearchQuery('');
    setLanguage(undefined);
    setSelectedTemplate(undefined);
    setConfirmationModalOpen(false);
    setOpen(false);
  }, [setOpen]);

  const useResponseTemplate = useCallback(() => {
    if (selectedTemplate) dispath(selectResponseTemplate(selectedTemplate));
    close();
  }, [close, dispath, selectedTemplate]);

  const removalConfirmationPopup = useMemo(() => {
    if (!selectedTemplate) return null;

    return (
      <ResponseTemplateConfirmationPopup
        action="delete"
        open={confirmationModalOpen}
        close={() => setConfirmationModalOpen(false)}
        popupContent={selectedTemplate}
        onDeleteCallback={() => {
          deleteResponseTemplate(selectedTemplate);
          close();
        }}
      />
    );
  }, [close, confirmationModalOpen, selectedTemplate]);

  return (
    <>
      {removalConfirmationPopup}
      <Modal open={open} sx={sxProps.modal} onClose={close}>
        <Paper sx={sxProps.modalContent}>
          <Box sx={sxProps.header}>
            <IconWrapper icon={MessageBubbleIcon} sx={sxProps.headerIcon} />
            <Typography
              sx={mergeSx(sxProps.headerTitle, globalSxProps.textBold)}
              variant="h3"
            >
              {t('+ticketView.comments.responseTemplates.templatePopup.title')}
            </Typography>
            <IconWrapper
              sx={sxProps.headerCloseIcon}
              icon={XIcon}
              onClick={close}
            />
          </Box>
          <Box sx={sxProps.languagePickerBox}>
            <TemplateLanguagePicker value={language} setValue={setLanguage} />
          </Box>
          <Box sx={sxProps.searchBoxBox}>
            <TemplateSearchBox query={searchQuery} setQuery={setSearchQuery} />
          </Box>
          <Box sx={sxProps.templateListBox}>
            <TemplateList
              setSelectedTemplate={setSelectedTemplate}
              templates={responseTemplates}
              selectedTemplate={selectedTemplate}
            />
          </Box>
          <Box sx={sxProps.buttonsBox}>
            <Button
              sx={sxProps.button}
              variant="outlined"
              disabled={!selectedTemplate}
              onClick={() => setConfirmationModalOpen(true)}
            >
              <IconWrapper sx={sxProps.buttonIcon} icon={XIcon} />
              {t(
                // eslint-disable-next-line max-len
                '+ticketView.comments.responseTemplates.templatePopup.removeButton',
              )}
            </Button>
            <Button
              sx={sxProps.button}
              variant="contained"
              disabled={!selectedTemplate}
              onClick={useResponseTemplate}
            >
              <IconWrapper sx={sxProps.buttonIcon} icon={UseMessageIcon} />
              {t(
                // eslint-disable-next-line max-len
                '+ticketView.comments.responseTemplates.templatePopup.useButton',
              )}
            </Button>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};
