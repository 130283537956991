import { Fade, Popper, Stack, Box } from '@mui/material';
import type { FC, MutableRefObject } from 'react';
import React from 'react';

import type { EmployeeSerializerDTO } from '../../../../../../connectors/user/dto/employee-serializer-dto';
import { useAppSelector } from '../../../../store/hooks';
import { selectQueriedUsers } from '../../../../store/userSlice';
import { MentionsPopperItem } from '../MentionsPopperItem';
import { sxProps, popper } from './mentions-popper.style';

export type MentionsPopperProps = {
  open: boolean;
  anchorRef: MutableRefObject<HTMLDivElement | null>;
  selectUser: (user: EmployeeSerializerDTO) => void;
  disablePortal?: boolean;
};

export const MentionsPopper: FC<MentionsPopperProps> = ({
  open,
  anchorRef,
  selectUser,
  disablePortal,
}: MentionsPopperProps) => {
  const queriedUsers = useAppSelector((state) => selectQueriedUsers(state));

  return (
    <Popper
      style={popper.style}
      open={open}
      anchorEl={anchorRef.current}
      placement="top-start"
      transition
      disablePortal={disablePortal}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={200}>
          <Box sx={sxProps.mentionsBox}>
            <Stack>
              {queriedUsers.map((user) => {
                return (
                  <MentionsPopperItem
                    key={user.uuid}
                    selectUser={selectUser}
                    user={user}
                  />
                );
              })}
            </Stack>
          </Box>
        </Fade>
      )}
    </Popper>
  );
};
