import { ReactComponent as BackArrowIcon } from '@heimstaden/icons-library/img/streamline-regular/arrows-diagrams/arrows/arrow-left-1.svg';
import { ReactComponent as SaveTemplateIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-add.svg';
import { ReactComponent as DeleteTemplateIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-disable.svg';
import { ReactComponent as ResponseTemplateIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-star.svg';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import { t } from 'i18next';
import type { FC } from 'react';
import React, { useState, useMemo, useCallback } from 'react';

import type {
  CommentSerializerDTO,
  LanguageDTO,
  ResponseTemplateSerializerDTO,
} from '../../../../../../../../../connectors/ticket';
import { IconWrapper } from '../../../../../../../../shared/components/IconWrapper';
import { TemplateLanguagePicker } from '../TemplateLanguagePicker';
import { sxProps } from './response-template-comfirmation-popup.style';

export type ResponseTemplateConfirmationPopupProps = {
  onSaveCallback?: (content: string, language: LanguageDTO) => void;
  onDeleteCallback?: () => void;
  close: () => void;
  open: boolean;
  popupContent: ResponseTemplateSerializerDTO | CommentSerializerDTO;
  action: 'save' | 'delete';
};

export const ResponseTemplateConfirmationPopup: FC<
  ResponseTemplateConfirmationPopupProps
> = ({
  action,
  close,
  onDeleteCallback,
  onSaveCallback,
  open,
  popupContent: { content },
}: ResponseTemplateConfirmationPopupProps) => {
  const [language, setLanguage] = useState<LanguageDTO>();
  const isSaveAction = action === 'save';

  const saveAction = useCallback(() => {
    if (isSaveAction && onSaveCallback && language) {
      onSaveCallback(content, language);
    } else {
      close();
    }
  }, [close, content, isSaveAction, language, onSaveCallback]);

  const deleteAction = useCallback(() => {
    if (!isSaveAction && onDeleteCallback) {
      onDeleteCallback();
    } else {
      close();
    }
  }, [close, isSaveAction, onDeleteCallback]);

  const confirmButton = useMemo(() => {
    const icon = action === 'delete' ? DeleteTemplateIcon : SaveTemplateIcon;
    const buttonTextKey =
      action === 'delete'
        ? // eslint-disable-next-line max-len
          '+ticketView.comments.responseTemplates.confirmationPopup.deleteButton'
        : '+ticketView.comments.responseTemplates.confirmationPopup.saveButton';
    const disabled = action === 'save' && !language;

    return (
      <Button
        disabled={disabled}
        onClick={isSaveAction ? saveAction : deleteAction}
        variant="contained"
        sx={sxProps.button}
      >
        <IconWrapper sx={sxProps.buttonBoxConfirmationIcon} icon={icon} />
        {t(buttonTextKey)}
      </Button>
    );
  }, [action, deleteAction, isSaveAction, language, saveAction]);

  const responseTemplateDetails = useMemo(() => {
    return (
      <Box sx={sxProps.responseTemplateDetails}>
        <IconWrapper
          medium
          sx={sxProps.responseTemplateDetailsIcon}
          icon={ResponseTemplateIcon}
        />
        <Typography variant="h3">{content}</Typography>
      </Box>
    );
  }, [content]);

  const popupInformation = useMemo(() => {
    const textKey =
      action === 'delete'
        ? '+ticketView.comments.responseTemplates.confirmationPopup.deleteText'
        : '+ticketView.comments.responseTemplates.confirmationPopup.saveText';
    const textSx =
      action === 'delete' ? sxProps.popupInformationDeleteText : {};

    return (
      <Box sx={sxProps.popupInformationBox}>
        <Typography sx={textSx}>{t(textKey)}</Typography>
      </Box>
    );
  }, [action]);

  const languagePickerBox = useMemo(() => {
    if (action === 'delete') return null;

    return (
      <Box sx={sxProps.languagePickerBox}>
        <TemplateLanguagePicker setValue={setLanguage} value={language} />
      </Box>
    );
  }, [action, language]);

  return (
    <Modal open={open} sx={sxProps.modal} onClose={close}>
      <Paper sx={sxProps.modalContent}>
        {responseTemplateDetails}
        {popupInformation}
        {languagePickerBox}
        <Box sx={sxProps.buttonBox}>
          <Button onClick={close} variant="outlined" sx={sxProps.button}>
            <IconWrapper
              sx={sxProps.buttonBoxCancelIcon}
              icon={BackArrowIcon}
            />
            {t(
              // eslint-disable-next-line max-len
              '+ticketView.comments.responseTemplates.confirmationPopup.cancelButton',
            )}
          </Button>
          {confirmButton}
        </Box>
      </Paper>
    </Modal>
  );
};
