import { Box, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { FC, ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { mergeSx } from '../../../../../../shared/helpers/sx.helper';
import { useTicket } from '../../../../ticket.context';
import type { Counter, TicketViewNavbarItem } from '../../types';
import { TicketViewNavbarItemValue, CounterTypes } from '../../types';
import { sxProps } from './ticket-view-navbar-button.style';

export type TicketViewNavbarButtonProps = {
  path: string;
  label: string;
  active: boolean;
  navbarItem: TicketViewNavbarItem;
};

export const TicketViewNavbarButton: FC<TicketViewNavbarButtonProps> = ({
  path,
  label,
  active,
  navbarItem,
}: TicketViewNavbarButtonProps) => {
  const navigate = useNavigate();
  const ticket = useTicket();

  const counter = useMemo((): Counter => {
    switch (navbarItem.value) {
      case TicketViewNavbarItemValue.ATTACHMENTS:
        return {
          type: CounterTypes.SECONDARY,
          value: ticket.attachmentsCount || 0,
        };
      case TicketViewNavbarItemValue.MESSAGES:
        return {
          type: CounterTypes.PRIMARY,
          value: ticket.messageCount,
        };
      default:
        return { type: CounterTypes.DISABLED, value: 0 };
    }
  }, [navbarItem, ticket]);

  const sx = useMemo<SxProps<Theme>>(() => {
    return active
      ? mergeSx(sxProps.navbarButtonBox, sxProps.navbarButtonActive)
      : sxProps.navbarButtonBox;
  }, [active]);

  const sxContainsCounter = useMemo<SxProps<Theme>>(() => {
    return counter.type !== CounterTypes.DISABLED
      ? mergeSx(sx, sxProps.navbarButtonBoxCounter)
      : sx;
  }, [sx, counter.type]);

  const sxCounter = useMemo<SxProps<Theme>>(() => {
    return active
      ? mergeSx(
          sxProps.counter,
          sxProps[`counter${counter.type.toLowerCase()}`],
          sxProps.counterActive,
        )
      : mergeSx(
          sxProps.counter,
          sxProps[`counter${counter.type}`.toLowerCase()],
        );
  }, [active, counter.type]);

  const counterBox = useMemo((): ReactNode => {
    return <Box sx={sxCounter}>{counter.value}</Box>;
  }, [counter.value, sxCounter]);

  const onClick = useCallback((): void => {
    if (!active) navigate(path);
  }, [active, navigate, path]);

  return (
    <Box
      sx={sxContainsCounter}
      onClick={onClick}
      data-test={`ticket-view-navbar-item-${navbarItem.value}`}
    >
      <Typography variant="button">{label}</Typography>
      {counter.type !== CounterTypes.DISABLED ? counterBox : ''}
    </Box>
  );
};
