import { Container } from '@mui/material';
import type { FC } from 'react';
import React, { useState, useMemo } from 'react';

import { selectCountries } from '../../../../../../shared/store/companySlice';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { useT } from '../../../../../../shared/translation/translation.provider';
import { ResponseTemplateChip } from './component/ResponseTemplateChip';
import { TemplatePopup } from './component/TemplatesPopup';
import { getDefaultTemplates } from './util';

export const ResponseTemplates: FC = () => {
  const t = useT();
  const countries = useAppSelector((state) => selectCountries(state));
  const [open, setOpen] = useState(false);

  const defaultTemplates = useMemo(
    () => getDefaultTemplates(countries, t),
    [countries, t],
  );

  if (defaultTemplates.length === 0) return null;

  return (
    <Container>
      {defaultTemplates.map((rt) => {
        return rt ? (
          <ResponseTemplateChip key={rt.uuid} responseTempalte={rt} />
        ) : null;
      })}
      <ResponseTemplateChip
        key="more-templates"
        openTemplatePopup={() => setOpen(true)}
      />
      <TemplatePopup open={open} setOpen={setOpen} />
    </Container>
  );
};
