import type {
  CreateCommentSerializerDTO,
  DepartmentTypeEnumDTO,
  LanguageDTO,
  ResponseTemplateSerializerDTO,
  TicketStatusEnumDTO,
  UniversalTicketsWithAdditionalFieldsSerializerDTO,
} from '../../../connectors/ticket';
import {
  ResponseTemplateVisibilityEnumDTO,
  AttachmentCategoryEnumDTO,
} from '../../../connectors/ticket';
import type { EmployeeSerializerDTO } from '../../../connectors/user';
import { TICKETS_PER_PAGE } from '../helpers/const';
import { getErrorStatus } from '../helpers/error.helper';
import {
  defaultFilterOrder,
  initialFilterState,
  isFilterActive,
} from '../helpers/filter.helper';
import { history } from '../helpers/history.helper';
import { getCreateTicketData } from '../helpers/ticket.helper';
import { HttpStatus } from '../http';
import { paths } from '../routes/paths';
import { store } from '../store';
import { addAttachments } from '../store/attachmentSlice';
import { addComments } from '../store/commentSlice';
import { setLoading } from '../store/loadingSlice';
import {
  addResponseTemplate,
  addResponseTemplates,
  removeResponseTemplate,
  setQueriedResposeTemplates,
} from '../store/responseTemplate';
import {
  addErrorSnackbar,
  addInfoSnackbar,
  addSuccessSnackbar,
  addWarningSnackbar,
} from '../store/snackbarSlice';
import {
  updateTicket,
  setMetadataForCountryUuid,
  setFilteredTickets,
  addTickets,
  setFilter,
  clearFilteredTickets,
  removeTicket,
  addTicket,
} from '../store/ticketSlice';
import type { AttachmentForUpload } from '../types/AttachmentForUpload';
import type { Filter } from '../types/Filter';
import { ticketClient } from './clients/ticket.client';
import { loadAccountData } from './profile.service';
import type { GetTicketsParams } from './types/ticket.types';

const refreshTicket = async (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): Promise<void> => {
  const refreshedTicket = await ticketClient.refreshTicket(ticket);

  if (refreshedTicket) store.dispatch(updateTicket(refreshedTicket));
};

const isFilterUnchanged = (filter: Filter): boolean => {
  if (filter.query === store.getState().ticket.filter.query) {
    return true;
  }

  return false;
};

export const loadTickets = async (
  params: GetTicketsParams,
  filter: Filter,
): Promise<void> => {
  const currentUser = await loadAccountData();
  const getTicketParams = { ...params };

  store.dispatch(setLoading(true));
  getTicketParams.order = 'status';
  getTicketParams.pageSize = TICKETS_PER_PAGE;
  if (currentUser) {
    getTicketParams.assigneeUuid = currentUser.uuid;
  }

  if (isFilterActive(filter)) {
    if (filter?.query) getTicketParams.search = filter.query;
    if (filter?.status) getTicketParams.status = filter.status.join(',');
    if (filter?.order) getTicketParams.order += filter.order;
    else {
      getTicketParams.order = filter?.query
        ? undefined
        : defaultFilterOrder.value;
    }
    if (filter?.isOverdue) getTicketParams.dueDateLt = new Date().toISOString();
    if (filter?.propertyObject)
      getTicketParams.propertyObjectUuid = filter?.propertyObject.uuid;
    if (filter?.displayUnassigned) getTicketParams.assigneeUuid = undefined;
  }
  try {
    const ticketList = await ticketClient.getTickets(getTicketParams);

    if (isFilterActive(filter)) {
      if (isFilterUnchanged(filter))
        store.dispatch(setFilteredTickets(ticketList));
    } else {
      store.dispatch(addTickets(ticketList));
    }
  } catch {
    //  fails silently
  }
  store.dispatch(setLoading(false));
};

export const setNewFilter = async (filter: Filter): Promise<void> => {
  store.dispatch(setFilter({ filter }));
  store.dispatch(clearFilteredTickets());

  loadTickets({}, filter);
};

export const clearFilter = (): void => {
  store.dispatch(setFilter({ clearFilter: true, filter: initialFilterState }));
};

export const addCommentToTicket = async (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
  comment: CreateCommentSerializerDTO,
  attachments: AttachmentForUpload[],
  // - disableLoadingChanges is used when we do not want this function to change loading state.
  // it is used when it's called from some other service function that already menaging loading state.
  // - throwExceptionOnError is used when we want this function to throw errors when sending message fails.
  options?: {
    throwExceptionOnError?: boolean;
    disableLoadingChanges?: boolean;
  },
): Promise<void> => {
  if (!options?.disableLoadingChanges) store.dispatch(setLoading(true));
  try {
    const savedComment = await ticketClient.createTicketComment(
      ticket,
      comment,
    );

    await Promise.all(
      attachments.map(async (a) => {
        await ticketClient.createCommentAttachment(ticket, savedComment, {
          ...a,
          description: comment.content,
        });
      }),
    );

    await refreshTicket(ticket);
  } catch (e) {
    const status = getErrorStatus(e);

    switch (status) {
      case HttpStatus.FORBIDDEN:
        store.dispatch(addErrorSnackbar('error.commentForbidden'));
        break;
      default:
        store.dispatch(addErrorSnackbar('error.commentErrorOther'));
    }
    if (options?.throwExceptionOnError) {
      throw e;
    }
  }
  if (!options?.disableLoadingChanges) store.dispatch(setLoading(false));
};

export const loadTicketComments = async (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): Promise<void> => {
  store.dispatch(setLoading(true));
  try {
    const comments = await ticketClient.fetchTicketComments(ticket);

    store.dispatch(addComments({ comments, ticket }));
  } catch {
    //  fails silently
  }
  store.dispatch(setLoading(false));
};

export const addAttachmentsToTicket = async (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
  { file, title, description }: AttachmentForUpload,
): Promise<void> => {
  store.dispatch(setLoading(true));
  try {
    await ticketClient.createTicketAttachments(
      ticket,
      AttachmentCategoryEnumDTO.Image,
      [file],
      title || '',
      description || '',
    );
    await refreshTicket(ticket);
  } catch (e) {
    const status = getErrorStatus(e);

    switch (status) {
      case HttpStatus.FORBIDDEN:
        store.dispatch(addErrorSnackbar('error.attachmentForbidden'));
        break;
      default:
        store.dispatch(addErrorSnackbar('error.atthacmentErrorOther'));
    }
  }
  store.dispatch(setLoading(false));
};

export const loadTicketAttachments = async (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): Promise<void> => {
  store.dispatch(setLoading(true));
  try {
    const attachments = await ticketClient.fetchTicketAttachment(ticket);

    store.dispatch(addAttachments({ attachments, ticket }));
  } catch {
    //  fails silently
  }
  store.dispatch(setLoading(false));
};

export const updateTicketStatus = async (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
  status: TicketStatusEnumDTO,
  ticketComment?: CreateCommentSerializerDTO,
  attachments?: AttachmentForUpload[],
  recordUpdateComment?: CreateCommentSerializerDTO,
): Promise<void> => {
  store.dispatch(setLoading(true));
  //  if comment posting fails, we will not update ticket status
  try {
    if (ticketComment) {
      await addCommentToTicket(ticket, ticketComment, attachments || [], {
        disableLoadingChanges: true,
        throwExceptionOnError: true,
      });
    }
    if (recordUpdateComment) {
      await addCommentToTicket(ticket, recordUpdateComment, [], {
        disableLoadingChanges: true,
        throwExceptionOnError: true,
      });
    }
  } catch (e) {
    store.dispatch(setLoading(false));

    return;
  }
  //  after comments are posted, we are updating status and refreshing token
  try {
    await ticketClient.updateTicket(ticket, { status });
    await refreshTicket(ticket);
  } catch (e) {
    const status = getErrorStatus(e);

    switch (status) {
      case HttpStatus.FORBIDDEN:
        store.dispatch(addErrorSnackbar('error.updateStatusForbidden'));
        break;
      default:
        store.dispatch(addErrorSnackbar('error.updateStatusErrorOther'));
    }
  }
  store.dispatch(setLoading(false));
};

export const updateTicketDepartment = async (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
  department?: DepartmentTypeEnumDTO,
): Promise<void> => {
  store.dispatch(setLoading(true));
  try {
    await ticketClient.updateTicket(ticket, { department });
    await refreshTicket(ticket);
  } catch (e) {
    const status = getErrorStatus(e);

    switch (status) {
      case HttpStatus.FORBIDDEN:
        store.dispatch(addErrorSnackbar('error.updateDepartmentForbidden'));
        break;
      default:
        store.dispatch(addErrorSnackbar('error.updateDepartmentErrorOther'));
    }
  }
  store.dispatch(setLoading(false));
};

export const updateTicketAssignee = async (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
  assignee?: EmployeeSerializerDTO,
): Promise<void> => {
  store.dispatch(setLoading(true));
  try {
    await ticketClient.updateTicket(ticket, {
      assignee_uuid: assignee?.uuid || null,
    });
    await refreshTicket(ticket);
  } catch (e) {
    const status = getErrorStatus(e);

    switch (status) {
      case HttpStatus.FORBIDDEN:
        store.dispatch(addErrorSnackbar('error.updateAssigneeForbidden'));
        break;
      case HttpStatus.NOT_FOUND:
        store.dispatch(removeTicket(ticket));
        store.dispatch(
          addInfoSnackbar({
            data: { referenceNumber: ticket.referenceNumber },
            message: 'info.ticketReasigned',
          }),
        );
        history.push(paths.root);
        break;
      default:
        store.dispatch(addErrorSnackbar('error.updateAssigneeErrorOther'));
    }
  }
  store.dispatch(setLoading(false));
};

export const loadMetadata = async (countryUuid: string): Promise<void> => {
  store.dispatch(setLoading(true));
  try {
    const metadata = await ticketClient.getMetadataForCountry(countryUuid);

    store.dispatch(setMetadataForCountryUuid({ countryUuid, metadata }));
  } catch {
    //  fails silently
  }
  store.dispatch(setLoading(false));
};

export const createTicket =
  // eslint-disable-next-line max-len
  async (): Promise<UniversalTicketsWithAdditionalFieldsSerializerDTO> => {
    const userProfile = await loadAccountData();

    if (!userProfile) throw new Error('User data is missing');
    store.dispatch(setLoading(true));

    try {
      const createTicketData = getCreateTicketData(
        store.getState().createTicket,
        userProfile,
      );

      if (!createTicketData) throw new Error('Failed to create ticket');
      const createdTicket = (await ticketClient.createTicket(
        createTicketData,
      )) as UniversalTicketsWithAdditionalFieldsSerializerDTO;

      store.dispatch(setLoading(false));

      return createdTicket;
    } catch (e) {
      store.dispatch(setLoading(false));
      throw e;
    }
  };

export const loadTicketByReferenceNumber = async (
  referenceNumber: string,
): Promise<UniversalTicketsWithAdditionalFieldsSerializerDTO | undefined> => {
  let ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO | undefined;

  store.dispatch(setLoading(true));
  try {
    const res = await ticketClient.getTickets({ referenceNumber });

    if (res.results.length === 0) {
      throw new Error('error.ticketNotFound');
    }
    ticket = res
      .results[0] as UniversalTicketsWithAdditionalFieldsSerializerDTO;

    store.dispatch(addTicket(ticket));
  } catch (error) {
    store.dispatch(
      addWarningSnackbar({
        data: { referenceNumber },
        message: error.message,
      }),
    );
    history.push(paths.root);
  }
  store.dispatch(setLoading(false));

  return ticket;
};

export const loadResponseTemplates = async (): Promise<void> => {
  store.dispatch(setLoading(true));
  try {
    const res = await ticketClient.getResponseTemplates({});

    store.dispatch(addResponseTemplates(res));
  } catch {
    // fails silently
  }

  store.dispatch(setLoading(false));
};

export const queryResponseTemplates = async (
  query?: string,
  language?: LanguageDTO,
): Promise<void> => {
  if (!query && !language) {
    store.dispatch(setQueriedResposeTemplates(undefined));

    return;
  }

  store.dispatch(setLoading(true));
  try {
    const res = await ticketClient.getResponseTemplates({ language, query });

    store.dispatch(setQueriedResposeTemplates(res));
  } catch {
    // fails silently
  }

  store.dispatch(setLoading(false));
};

export const createResponseTemplate = async (
  content: string,
  language: LanguageDTO,
): Promise<void> => {
  store.dispatch(setLoading(true));

  try {
    const res = await ticketClient.createResponseTemplate({
      content,
      language,
      name: content,
      visibility: ResponseTemplateVisibilityEnumDTO.Private,
    });

    store.dispatch(addResponseTemplate(res));
    store.dispatch(
      addSuccessSnackbar({
        message: '+ticketView.comments.responseTemplates.creationSuccess',
      }),
    );
  } catch {
    store.dispatch(
      addWarningSnackbar({
        message: '+ticketView.comments.responseTemplates.creationFailed',
      }),
    );
  }
  store.dispatch(setLoading(false));
};
export const deleteResponseTemplate = async (
  respTemplate: ResponseTemplateSerializerDTO,
): Promise<void> => {
  store.dispatch(setLoading(true));

  try {
    await ticketClient.deleteResponseTemplate(respTemplate.uuid);
    store.dispatch(removeResponseTemplate(respTemplate));
    store.dispatch(
      addSuccessSnackbar({
        message: '+ticketView.comments.responseTemplates.deletionSuccess',
      }),
    );
  } catch {
    store.dispatch(
      addWarningSnackbar({
        message: '+ticketView.comments.responseTemplates.deletionFailed',
      }),
    );
  }
  store.dispatch(setLoading(false));
};
