import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { ParsedQuery } from 'query-string';

import {
  AuthenticationTypeEnumDTO,
  UserRoleEnumDTO,
} from '../../../connectors/user';
import { history } from '../helpers/history.helper';
import {
  AuthProviderErrorType,
  createError,
} from '../helpers/mie-error.helper';
import { paths } from '../routes/paths';
import { store } from '../store';
import { login, logout, updateTokens } from '../store/authSlice';
import { setLoading } from '../store/loadingSlice';
import { authClient } from './clients/auth.client';
import { loadAccountData } from './profile.service';

// first step in Azure login
// which redirects to Azure login page
export const loginAzure = async (): Promise<void> => {
  store.dispatch(setLoading(true));
  await authClient.loginByProvider(AuthenticationTypeEnumDTO.Azure);
};

export const logoutUser = (): void => {
  store.dispatch(logout());
};

// second step in Azure login
// which will be called after Azure redirects back to the application
export const authorizationAzure = async (query: ParsedQuery): Promise<void> => {
  const requestConfig: AxiosRequestConfig = {
    params: { ...query },
  };

  store.dispatch(setLoading(true));
  try {
    const tokens = await authClient.authorize(
      AuthenticationTypeEnumDTO.Azure,
      requestConfig,
    );

    store.dispatch(login({ tokens, type: AuthenticationTypeEnumDTO.Azure }));

    // we have to load user profile and check if he has Janitor role
    const userProfile = await loadAccountData();

    //  if not, we must logout this user
    const isSuperUser = userProfile?.roles.includes(UserRoleEnumDTO.SuperUser);
    const isJanitor = userProfile?.roles.includes(UserRoleEnumDTO.Janitor);

    if (!isSuperUser && !isJanitor) {
      logoutUser();
      history.push(paths.welcome);
      createError(AuthProviderErrorType.MISSING_ROLE);
    }
  } catch {
    createError(AuthProviderErrorType.API_ERROR);
  }
  store.dispatch(setLoading(false));
};

export const refreshToken = async (
  error: AxiosError,
): Promise<AxiosResponse | void> => {
  const { loggedUser } = store.getState().auth;

  if (!loggedUser) {
    return logoutUser();
  }
  const { response, tokens } = await authClient.refreshToken(
    error,
    loggedUser.tokens,
  );

  store.dispatch(updateTokens(tokens));

  return response;
};
