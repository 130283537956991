import { Box, Typography } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import type { DepartmentTypeEnumDTO } from '../../../../../../../connectors/ticket';
import type { EmployeeSerializerDTO } from '../../../../../../../connectors/user';
import { AssigneePicker } from '../../../../../../shared/components/AssigneePicker';
import { DepartmentPicker } from '../../../../../../shared/components/DepartmentPicker';
import {
  updateTicketAssignee,
  updateTicketDepartment,
} from '../../../../../../shared/services/ticket.service';
import { useT } from '../../../../../../shared/translation/translation.provider';
import { useTicket } from '../../../../ticket.context';
import { sxProps } from './ticket-details-assignee.style';

export const TicketDetailsAssignee: FC = () => {
  const t = useT();
  const ticket = useTicket();

  const onDepartmentChange = (department: DepartmentTypeEnumDTO): void => {
    updateTicketDepartment(ticket, department);
  };

  const onAssigneeSelected = (user?: EmployeeSerializerDTO): void => {
    updateTicketAssignee(ticket, user);
  };

  return (
    <Box sx={sxProps.boxWrapper}>
      <Typography variant="overline" sx={sxProps.assigneeLabel}>
        {t('+ticketView.details.assignee.header')}
      </Typography>
      <Box>
        <DepartmentPicker
          onChange={onDepartmentChange}
          department={ticket.department}
          showConfirmationPopup
        />
      </Box>
      <Box sx={sxProps.assigneePickerWrapper}>
        <AssigneePicker
          onUserSelect={onAssigneeSelected}
          assignee={ticket.assignee}
          showConfirmationPopup
        />
      </Box>
    </Box>
  );
};
