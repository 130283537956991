import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  fullHeight: {
    height: (theme) => theme.custom.ticketListHeight,
  },

  heightWhenFilterIsActive: {
    height: (theme) => theme.custom.ticketListHeightWhenFilterActive,
  },

  ticketList: {
    overflow: 'scroll',
    paddingTop: 0,
  },
};
