import { ReactComponent as EmailIcon } from '@heimstaden/icons-library/img/streamline-regular/emails/email-actions/email-action-send-2.svg';
import { ReactComponent as CalendarIconCheck } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-check-1.svg';
import { ReactComponent as CalendarIconFavorite } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/date-calendar/calendar-favorite-1.svg';
import { ReactComponent as DoorIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/login-logout/login-2.svg';
import { ReactComponent as MapsPinIcon } from '@heimstaden/icons-library/img/streamline-regular/maps-navigation/maps/maps-pin-1.svg';
import { ReactComponent as PhoneIcon } from '@heimstaden/icons-library/img/streamline-regular/phones-mobile-devices/phone/phone.svg';
import { ReactComponent as CreatorIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geomertic-close-up-single-user-neutral/single-neutral-id-card-double.svg';
import { ReactComponent as ReporterIcon } from '@heimstaden/icons-library/img/streamline-regular/users/geometric-full-body-single-user-actions-man/single-man-actions-warning.svg';
import type { TFunction } from 'i18next';
import type { FunctionComponent, SVGProps } from 'react';

import type { UniversalTicketsWithAdditionalFieldsSerializerDTO } from '../../../../../../../connectors/ticket';
import { TicketTypeEnumDTO } from '../../../../../../../connectors/ticket';
import {
  formatDate,
  isOverdue,
} from '../../../../../../shared/helpers/date.helper';
import {
  getFailureTypeIcon,
  getLocationFailureIcon,
  getRoomFailureIcon,
} from '../../../../../../shared/helpers/failure.helper';
import { getUserFullname } from '../../../../../../shared/helpers/user.helper';

export type DetailInformationData = {
  labelKey: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  key: string;
  value: string;
  isError?: boolean;
};

export type DetailInformationLinkData = {
  link: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  value: string;
};

export const getDatesFields = (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): DetailInformationData[] => {
  const { createdAt, dueDate, createdBy } = ticket;
  const fields: DetailInformationData[] = [];

  fields.push({
    icon: CalendarIconFavorite,
    key: `ticket_details_information_creation_date`,
    labelKey: '+ticketView.details.creationDate',
    value: formatDate(createdAt, 'dd.mm.yyyy'),
  });
  if (createdBy) {
    fields.push({
      icon: CreatorIcon,
      key: `ticket_details_information_created_by`,
      labelKey: '+ticketView.details.createdBy',
      value: getUserFullname(createdBy),
    });
  }
  if (dueDate) {
    fields.push({
      icon: CalendarIconCheck,
      isError: isOverdue(dueDate),
      key: `ticket_details_information_due_date`,
      labelKey: '+ticketView.details.dueDate',
      value: formatDate(dueDate, 'dd.mm.yyyy'),
    });
  }

  return fields;
};

export const getIssueFields = (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
  t: TFunction,
): DetailInformationData[] => {
  const {
    issueLocation,
    roomWithIssue,
    issueType,
    issueSubtype,
    damagedItem,
    reporter,
    ticketType,
  } = ticket;
  const fields: DetailInformationData[] = [];

  if (ticketType === TicketTypeEnumDTO.Complaint) {
    fields.push({
      icon: ReporterIcon,
      key: `ticket_details_information_ticket_type`,
      labelKey: '+ticketView.details.type',
      value: `${t('issue.customerComplaint')}`,
    });
  }
  if (reporter) {
    fields.push({
      icon: ReporterIcon,
      key: `ticket_details_information_reporter`,
      labelKey: '+ticketView.details.reporter',
      value: getUserFullname(reporter),
    });
  }
  if (issueLocation) {
    fields.push({
      icon: getLocationFailureIcon(issueLocation),
      key: `ticket_details_information_issue_location`,
      labelKey: '+ticketView.details.category',
      value: t(`issue.${issueLocation.toString()}`),
    });
  }
  if (roomWithIssue) {
    fields.push({
      icon: getRoomFailureIcon(roomWithIssue),
      key: `ticket_details_information_issue_room`,
      labelKey: '+ticketView.details.room',
      value: t(`issue.${roomWithIssue.toString()}`),
    });
  }
  if (issueType) {
    fields.push({
      icon: getFailureTypeIcon(issueType),
      key: `ticket_details_information_issue_type`,
      labelKey: '+ticketView.details.issueType',
      value: t(`issue.${issueType.toString()}`),
    });
  }
  if (damagedItem) {
    fields.push({
      key: `ticket_details_information_issue_item`,
      labelKey: '+ticketView.details.issueItem',
      value: t(`issue.${damagedItem.toString()}`),
    });
  }
  if (issueSubtype) {
    fields.push({
      key: `ticket_details_information_issue_subtype`,
      labelKey: '+ticketView.details.issueSubtype',
      value: t(`issue.${issueSubtype.toString()}`),
    });
  }

  return fields;
};

export const getCustomerFields = (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): DetailInformationData[] => {
  const { addressLine, apartmentNumber } = ticket;
  const fields: DetailInformationData[] = [];

  if (addressLine) {
    fields.push({
      icon: MapsPinIcon,
      key: `ticket_details_information_customer_address`,
      labelKey: '+ticketView.details.customerAddress',
      value: addressLine,
    });
  }
  if (apartmentNumber) {
    fields.push({
      icon: DoorIcon,
      key: `ticket_details_information_apartment_number`,
      labelKey: '+ticketView.details.apartmentNumber',
      value: apartmentNumber,
    });
  }

  return fields;
};

export const generatePhoneLink = (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): string => {
  return `tel:${ticket.reporter.phoneNumber}`;
};

export const generateEmailLink = ({
  referenceNumber,
  reporter,
  summary,
  assignee,
}: UniversalTicketsWithAdditionalFieldsSerializerDTO): string => {
  if (!assignee) return '';

  let body = `Hi, this is ${getUserFullname(
    assignee,
    // eslint-disable-next-line max-len
  )} from Heimstaden. I am writing to you regarding your service request in MinBolig ${referenceNumber}.`;

  body = encodeURIComponent(body);

  // eslint-disable-next-line max-len
  return `mailto:${reporter.email}?subject=${referenceNumber} - ${summary}&body=${body}`;
};

export const getCustomerLinks = (
  ticket: UniversalTicketsWithAdditionalFieldsSerializerDTO,
): DetailInformationLinkData[] => {
  const fields: DetailInformationLinkData[] = [];
  const { reporter, assignee } = ticket;
  const { email, phoneNumber } = reporter;

  if (assignee) {
    fields.push({
      icon: EmailIcon,
      link: generateEmailLink(ticket),
      value: email,
    });
  }

  if (phoneNumber) {
    fields.push({
      icon: PhoneIcon,
      link: generatePhoneLink(ticket),
      value: phoneNumber,
    });
  }

  return fields;
};
