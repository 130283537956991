import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';

export const sxProps: Record<string, SxProps<Theme>> = {
  headerWrapper: {
    background: (theme) => theme.palette.divider,
    height: (theme) => theme.custom.headerWrapperHeight,
    position: 'sticky',
    top: 0,
  },

  languagePickerWapper: {
    flex: 1,
    pl: 3,
  },

  linkLogoWrapper: {
    alignItems: 'center',
    display: 'flex',
    flex: 4,
    justifyContent: 'center',
  },

  loaderHeaderContainer: {
    top: (theme) => theme.custom.headerContainerHeight,
  },

  logoHeaderContainer: {
    alignItems: 'center',
    background: (theme) => theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'row',
    height: (theme) => theme.custom.headerContainerHeight,
    justifyContent: 'center',
    left: 0,
    paddingBottom: (theme) => theme.spacing(1),
    paddingTop: (theme) => theme.spacing(1),
    right: 0,
    top: 0,
  },

  notificationBellWrapper: {
    flex: 1,
    paddingLeft: (theme) => theme.spacing(2.5),
  },
};
