import type { EmployeeSerializerDTO } from '../../../../connectors/user/dto/employee-serializer-dto';
import { MENTION_CHAR } from '../../helpers/const';
import { getUserMentionString } from '../../helpers/user.helper';

// this function transforms input value into CommentSerializerDTO object
export const prepareTicketCommentFormat = (
  unpreparedContent: string,
  taggedUsers: EmployeeSerializerDTO[],
): string => {
  if (taggedUsers.length === 0) return unpreparedContent;

  return taggedUsers.reduce(
    (content, user) =>
      content.replaceAll(
        `${MENTION_CHAR}${getUserMentionString(user)}`,
        `${MENTION_CHAR}${user.uuid}`,
      ),
    unpreparedContent,
  );
};
