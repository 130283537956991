import type { TenantSerializerDTO } from '../../../connectors/property';
import type {
  LinkedUserEssentialInfoSerializerDTO,
  LinkedUserSerializerDTO,
} from '../../../connectors/ticket';
import type { EmployeeSerializerDTO } from '../../../connectors/user/dto/employee-serializer-dto';

export const isAuthorTenant = ({ roles }: LinkedUserSerializerDTO): boolean => {
  return roles.includes('Tenant');
};

export const getUserMentionString = ({
  firstName,
  lastName,
}: EmployeeSerializerDTO | LinkedUserSerializerDTO): string => {
  return `${firstName}${lastName}`;
};

export const getUserFullname = ({
  firstName,
  lastName,
}:
  | EmployeeSerializerDTO
  | LinkedUserSerializerDTO
  | TenantSerializerDTO
  | LinkedUserEssentialInfoSerializerDTO): string => {
  return `${firstName} ${lastName || ''}`;
};
