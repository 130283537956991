import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import watch from 'redux-watch';

import { transformAttachmentFormData } from './helpers/attachment.helper';
import { handleError, transformKeys } from './http';
import { store } from './store';
import type { AuthState } from './store/authSlice';

export const mieInstance = axios.create();

const createBearerToken = (): string => {
  const accessToken = store.getState().auth.loggedUser?.tokens?.accessToken;

  return accessToken ? `Bearer ${accessToken}` : '';
};

mieInstance.defaults.headers.common.Authorization = createBearerToken();
mieInstance.defaults.headers.common['Content-Type'] = 'application/json';

mieInstance.interceptors.response.use(
  (response: AxiosResponse) => ({
    ...response,
    data: transformKeys(response.data, 'CAMEL_CASE'),
  }),

  (error) => handleError(error),
);

mieInstance.interceptors.request.use(
  async (request: AxiosRequestConfig) => {
    const data =
      request.data instanceof FormData
        ? await transformAttachmentFormData(request.data)
        : request.data;

    return {
      ...request,
      data: transformKeys(data, 'SNAKE_CASE', true),
    };
  },
  (error) => Promise.reject(error),
);

// every time auth state us changed, we are updating bearer token
const w = watch(store.getState, 'auth');

store.subscribe(
  w((newVal: AuthState, oldVal: AuthState) => {
    if (newVal !== oldVal) {
      mieInstance.defaults.headers.common.Authorization = createBearerToken();
    }
  }),
);
