import { ReactComponent as XIcon } from '@heimstaden/icons-library/img/streamline-regular/interface-essential/remove-add/remove.svg';
import { Chip, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';

import { IconWrapper } from '../../../../../../../../shared/components/IconWrapper';
import { filterOrder } from '../../../../../../../../shared/helpers/filter.helper';
import { mergeSx } from '../../../../../../../../shared/helpers/sx.helper';
import {
  clearFilter,
  setNewFilter,
} from '../../../../../../../../shared/services/ticket.service';
import { globalSxProps } from '../../../../../../../../shared/style';
import { useT } from '../../../../../../../../shared/translation/translation.provider';
import type { Filter } from '../../../../../../../../shared/types/Filter';
import { sxProps } from './active-fiilter-item.style';

export type ActiveFilterItemType =
  | 'overdue'
  | 'statuses'
  | 'order'
  | 'query'
  | 'reset'
  | 'propertyObject'
  | 'displayUnassigned';

export type ActiveFilterItemProps = {
  type: ActiveFilterItemType;
  filter: Filter;
};

export const ActiveFiltersItem: FC<ActiveFilterItemProps> = ({
  filter,
  type,
}: ActiveFilterItemProps) => {
  const t = useT();

  const activeOrderFilterItem = useMemo(() => {
    const activeOrder = filterOrder.find((fo) => fo.value === filter.order);

    if (!activeOrder) return null;

    return (
      <Typography>
        {t('+ticketListScreen.filter.activeFilter.orderLabel')}{' '}
        <Typography component="span" sx={globalSxProps.textBold}>
          {t(activeOrder.activeFilterLabelKey)}
        </Typography>
      </Typography>
    );
  }, [filter.order, t]);

  const activeOverdueFilterItem = useMemo(() => {
    return (
      <Typography sx={globalSxProps.textBold}>
        {t('+ticketListScreen.filter.activeFilter.overdueLabel')}
      </Typography>
    );
  }, [t]);

  const activeDisplayUnassignedFilterItem = useMemo(() => {
    return (
      <Typography sx={globalSxProps.textBold}>
        {t('+ticketListScreen.filter.activeFilter.displayUnassignedLabel')}
      </Typography>
    );
  }, [t]);

  const activeQueryFilterItem = useMemo(() => {
    return (
      <Typography>
        {t('+ticketListScreen.filter.activeFilter.queryLabel')}{' '}
        <Typography component="span" sx={globalSxProps.textBold}>
          {filter.query}
        </Typography>
      </Typography>
    );
  }, [filter.query, t]);

  const activeStatusesFilterItem = useMemo(() => {
    if (!filter.status) return null;
    const statusesString = filter.status
      .map((s) => {
        return t(`shared.status.${s}`);
      })
      .join(', ');

    return (
      <Typography>
        {t('+ticketListScreen.filter.activeFilter.statusLabel')}{' '}
        <Typography component="span" sx={globalSxProps.textBold}>
          {statusesString}
        </Typography>
      </Typography>
    );
  }, [filter.status, t]);

  const activePropertyObjectFilterItem = useMemo(() => {
    if (!filter.propertyObject) return null;

    return (
      <Typography>
        {t('+ticketListScreen.filter.activeFilter.propertyObjectLabel')}{' '}
        <Typography component="span" sx={globalSxProps.textBold}>
          {filter.propertyObject.name}
        </Typography>
      </Typography>
    );
  }, [filter.propertyObject, t]);

  const resetActiveFiltersItem = useMemo(() => {
    return (
      <Typography sx={globalSxProps.textBold}>
        {t('+ticketListScreen.filter.activeFilter.resetLabel')}
      </Typography>
    );
  }, [t]);

  const label = useMemo(() => {
    switch (type) {
      case 'order':
        return activeOrderFilterItem;
      case 'overdue':
        return activeOverdueFilterItem;
      case 'displayUnassigned':
        return activeDisplayUnassignedFilterItem;
      case 'query':
        return activeQueryFilterItem;
      case 'statuses':
        return activeStatusesFilterItem;
      case 'propertyObject':
        return activePropertyObjectFilterItem;
      case 'reset':
        return resetActiveFiltersItem;

      default:
        return '';
    }
  }, [
    activeOrderFilterItem,
    activeOverdueFilterItem,
    activeDisplayUnassignedFilterItem,
    activeQueryFilterItem,
    activeStatusesFilterItem,
    activePropertyObjectFilterItem,
    resetActiveFiltersItem,
    type,
  ]);

  const closeFilter = (type: ActiveFilterItemType): void => {
    switch (type) {
      case 'order': {
        setNewFilter({ ...filter, order: undefined });
        break;
      }
      case 'overdue': {
        setNewFilter({ ...filter, isOverdue: undefined });
        break;
      }
      case 'displayUnassigned': {
        setNewFilter({ ...filter, displayUnassigned: undefined });
        break;
      }
      case 'query': {
        setNewFilter({ ...filter, query: undefined });
        break;
      }
      case 'statuses': {
        setNewFilter({ ...filter, status: undefined });
        break;
      }
      case 'propertyObject': {
        setNewFilter({ ...filter, propertyObject: undefined });
        break;
      }
      case 'reset': {
        clearFilter();
        break;
      }
      default:
    }
  };

  return (
    <Chip
      data-test={`ticket-filter-active-filter-item-${type}`}
      sx={mergeSx(
        sxProps.chip,
        type === 'reset' ? sxProps.chipBorderReset : sxProps.chipBorder,
      )}
      onDelete={() => closeFilter(type)}
      label={label}
      variant="outlined"
      deleteIcon={<IconWrapper sx={sxProps.removeIcon} icon={XIcon} />}
    />
  );
};
