import { LinearProgress, Box } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import { sxProps } from './loader.style';

export type LoaderProps = {
  loading: boolean;
};

export const Loader: FC<LoaderProps> = ({ loading }: LoaderProps) => {
  return (
    <Box sx={sxProps.loaderWrapper}>{loading ? <LinearProgress /> : null}</Box>
  );
};
