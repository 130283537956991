import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  ListResponseTemplateSerializerDTO,
  ResponseTemplateSerializerDTO,
} from '../../../connectors/ticket';
import type { RootState } from './index';

export interface ResponseTemplateState {
  backgroundDimmed: boolean;
  responseTemplates: ResponseTemplateSerializerDTO[];
  queriedResponseTemplates?: ResponseTemplateSerializerDTO[];
  selectedResponse?: ResponseTemplateSerializerDTO;
}

const initialState: ResponseTemplateState = {
  backgroundDimmed: false,
  queriedResponseTemplates: undefined,
  responseTemplates: [],
};

/* eslint-disable no-param-reassign */
const responseTemplateSlice = createSlice({
  initialState,
  name: 'loading',
  reducers: {
    addResponseTemplate: (
      state,
      action: PayloadAction<ResponseTemplateSerializerDTO>,
    ) => {
      state.responseTemplates = [action.payload, ...state.responseTemplates];
    },

    addResponseTemplates: (
      state,
      action: PayloadAction<ListResponseTemplateSerializerDTO>,
    ) => {
      state.responseTemplates = action.payload.results;
    },

    clearResponseTemplate: (state) => {
      state.selectedResponse = undefined;
    },

    removeResponseTemplate: (
      state,
      action: PayloadAction<ResponseTemplateSerializerDTO>,
    ) => {
      state.responseTemplates = state.responseTemplates.filter(
        (rt) => rt.uuid !== action.payload.uuid,
      );
    },

    selectResponseTemplate: (
      state,
      action: PayloadAction<ResponseTemplateSerializerDTO>,
    ) => {
      state.selectedResponse = action.payload;
    },

    setDimmedBackground: (state, action: PayloadAction<boolean>) => {
      state.backgroundDimmed = action.payload;
    },

    setQueriedResposeTemplates: (
      state,
      action: PayloadAction<ListResponseTemplateSerializerDTO | undefined>,
    ) => {
      state.queriedResponseTemplates = action.payload
        ? action.payload.results
        : undefined;
    },
  },
});

export const {
  addResponseTemplate,
  addResponseTemplates,
  removeResponseTemplate,
  selectResponseTemplate,
  clearResponseTemplate,
  setDimmedBackground,
  setQueriedResposeTemplates,
} = responseTemplateSlice.actions;
export const selectResponseTemplates = ({
  responseTemplate: { responseTemplates, queriedResponseTemplates },
}: RootState): ResponseTemplateSerializerDTO[] =>
  queriedResponseTemplates || responseTemplates;
export const selectSelectedResponseTemplated = ({
  responseTemplate: { selectedResponse },
}: RootState): ResponseTemplateSerializerDTO | undefined => selectedResponse;
export const selectIsBackgroundDimmed = ({
  responseTemplate: { backgroundDimmed },
}: RootState): boolean => backgroundDimmed;

export default responseTemplateSlice.reducer;
