import { ReactComponent as DefaultCameraIcon } from '@heimstaden/icons-library/img/streamline-regular/images-photography/cameras/camera-1.svg';
import { Box, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import type { SxProps } from '@mui/system/styleFunctionSx';
import type { ChangeEvent, FC } from 'react';
import React, { useMemo, useRef } from 'react';

import { mergeSx } from '../../../helpers/sx.helper';
import { useAppSelector } from '../../../store/hooks';
import { selectLoading } from '../../../store/loadingSlice';
import { useT } from '../../../translation/translation.provider';
import type { IconType } from '../../IconWrapper';
import { IconWrapper } from '../../IconWrapper';
import { attachmentSxProps } from '../attachment.style';
import { sxProps } from './attachment-upload-button.style';
import { validateFile } from './util';

export type AttachmentUploadButtonProps = {
  onFileSelected: (attachments: File) => void;
  hideLabel?: boolean;
  customIconSx?: SxProps<Theme>;
  customIcon?: IconType;
  forceCameraCapture?: boolean;
  customWrapperSx?: SxProps<Theme>;
};

export const AttachmentUploadButton: FC<AttachmentUploadButtonProps> = ({
  onFileSelected,
  customWrapperSx,
  customIconSx,
  customIcon,
  hideLabel,
  forceCameraCapture,
}: AttachmentUploadButtonProps) => {
  const loading = useAppSelector((state) => selectLoading(state));
  const t = useT();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const sxWrapper = useMemo(
    () =>
      customWrapperSx ||
      mergeSx(sxProps.attachmentUploadBox, attachmentSxProps.attachmentBorder),
    [customWrapperSx],
  );

  const sxIcon = useMemo(
    () => customIconSx || sxProps.attachmentUploadIcon,
    [customIconSx],
  );

  const icon = useMemo(() => customIcon || DefaultCameraIcon, [customIcon]);

  const label = useMemo(() => {
    return hideLabel ? null : (
      <Typography variant="overline" sx={sxProps.attachmentUploadLabel}>
        {t('shared.attachmentUpload.label')}
      </Typography>
    );
  }, [hideLabel, t]);

  const onClickHandler = (): void => {
    if (!loading) {
      fileInputRef.current?.click();
    }
  };

  const onSelectHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const selectedFile = e.target.files?.item(0);

    if (!selectedFile || !validateFile(selectedFile)) return;
    onFileSelected(selectedFile);
  };

  if (loading) return null;

  return (
    <Box
      data-test="attachment-upload-button-wrapper"
      onClick={onClickHandler}
      sx={sxWrapper}
    >
      <input
        data-test="attachment-upload-button"
        type="file"
        hidden
        accept="image/*"
        capture={forceCameraCapture ? 'environment' : undefined}
        ref={fileInputRef}
        onChange={onSelectHandler}
      />
      <IconWrapper icon={icon} sx={sxIcon} />
      {label}
    </Box>
  );
};
