import { formatDate } from '../../../../../../../../shared/helpers/date.helper';

export const reasonCodeTaskSentToExternalProviderKey =
  'reasonCodeTaskSentToExternalProvider';
export const reasonCodeTaskSentToExternalProviderOptions = [
  'lackOfInternalExpertise',
  'lackOfInternalResources',
  'other',
];
export const estimatedDateForResumptionOfTaskKey =
  'estimatedDateForResumptionOfTask';
export const estimatedDateForResumtionOfTaskStartValue = formatDate(
  new Date().toString(),
  'yyyy-mm-dd',
);
