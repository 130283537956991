import { TextField, Box } from '@mui/material';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import { useDebounce } from '../../../../../../shared/hooks/useDebounce';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { selectFilter } from '../../../../../../shared/store/ticketSlice';
import { useT } from '../../../../../../shared/translation/translation.provider';
import { sxProps } from './search-box.style';

export type SearchBoxProps = {
  updateQuery: (query: string) => void;
};

export const SearchBox: FC<SearchBoxProps> = ({
  updateQuery,
}: SearchBoxProps) => {
  const { query: filterQuery } = useAppSelector((state) => selectFilter(state));
  const [value, setValue] = useState<string>(filterQuery || '');
  const debouncedValue = useDebounce<string>(value);
  const t = useT();

  useEffect(() => {
    setValue(filterQuery || '');
  }, [filterQuery]);

  useEffect(() => {
    updateQuery(debouncedValue.trim());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Box sx={sxProps.ticketsSearchBoxWrapper}>
      <TextField
        data-test="ticket-filter-bar-search-box"
        size="small"
        onChange={(e) => setValue(e.target.value)}
        fullWidth
        value={value}
        sx={sxProps.ticketsSearchField}
        placeholder={t('+ticketListScreen.filter.searchBox.label')}
      />
    </Box>
  );
};
