import { ReactComponent as MessageBubbleIcon } from '@heimstaden/icons-library/img/streamline-regular/messages-chat-smileys/messages-speech-bubbles/messages-bubble-star.svg';
import { Chip, Typography } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback } from 'react';

import type { ResponseTemplateSerializerDTO } from '../../../../../../../../../connectors/ticket';
import { IconWrapper } from '../../../../../../../../shared/components/IconWrapper';
import { mergeSx } from '../../../../../../../../shared/helpers/sx.helper';
import { useAppDispatch } from '../../../../../../../../shared/store/hooks';
import { selectResponseTemplate } from '../../../../../../../../shared/store/responseTemplate';
import { globalSxProps } from '../../../../../../../../shared/style';
import { useT } from '../../../../../../../../shared/translation/translation.provider';
import { sxProps } from './response-template-chip.style';

export type ResponseTemplateChipProps = {
  responseTempalte?: ResponseTemplateSerializerDTO;
  openTemplatePopup?: () => void;
};

export const ResponseTemplateChip: FC<ResponseTemplateChipProps> = ({
  responseTempalte,
  openTemplatePopup,
}: ResponseTemplateChipProps) => {
  const t = useT();
  const dispatch = useAppDispatch();

  const onChipClick = useCallback(() => {
    if (responseTempalte) dispatch(selectResponseTemplate(responseTempalte));
  }, [dispatch, responseTempalte]);

  if (!responseTempalte) {
    return (
      <Chip
        sx={mergeSx(sxProps.chip, sxProps.moreTemplatesChip)}
        label={
          <Typography sx={globalSxProps.textBold}>
            {t('+ticketView.comments.responseTemplates.moreTemplates')}
          </Typography>
        }
        icon={
          <IconWrapper
            sx={sxProps.moreTemplatesIcon}
            icon={MessageBubbleIcon}
          />
        }
        onClick={() => openTemplatePopup && openTemplatePopup()}
      />
    );
  }

  return (
    <Chip
      sx={sxProps.chip}
      label={<Typography>{responseTempalte.content}</Typography>}
      onClick={onChipClick}
    />
  );
};
