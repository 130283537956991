import { Box, Container } from '@mui/material';
import type { FC } from 'react';
import React, { useCallback, useState, useMemo, useEffect } from 'react';

import { AttachmentItem } from '../../../../shared/components/Attachment/AttachmentItem';
import { AttachmentUploadButton } from '../../../../shared/components/Attachment/AttachmentUploadButton';
import { AttachmentViewModal } from '../../../../shared/components/Attachment/AttachmentViewModal';
import { loadAccountData } from '../../../../shared/services/profile.service';
import {
  addAttachmentsToTicket,
  loadTicketAttachments,
} from '../../../../shared/services/ticket.service';
import { selectAttachmentsForTicket } from '../../../../shared/store/attachmentSlice';
import { useAppSelector } from '../../../../shared/store/hooks';
import type { AttachmentForUpload } from '../../../../shared/types/AttachmentForUpload';
import { useTicket } from '../../ticket.context';
import { TicketAttachmentUploadForm } from './components/TicketAttachmentUploadForm';
import { sxProps } from './ticket-attachments.style';

export const TicketAttachments: FC = () => {
  const ticket = useTicket();
  const loadedAttachments = useAppSelector((state) =>
    selectAttachmentsForTicket(state, ticket),
  );

  const [open, setOpen] = useState<boolean>(false);
  const [attachmentForUpload, setAttachmentForUpload] =
    useState<AttachmentForUpload>();
  const [clickedImageIndex, setClickedImageIndex] = useState<number>(0);

  useEffect(() => {
    loadTicketAttachments(ticket);
    loadAccountData();
  }, [ticket]);

  const onAttachmentClick = (index: number): void => {
    setClickedImageIndex(index);
    setOpen(true);
  };

  const onAttachmentUpload = useCallback(async (): Promise<void> => {
    if (attachmentForUpload) {
      await addAttachmentsToTicket(ticket, attachmentForUpload);
      setAttachmentForUpload(undefined);
    }
  }, [attachmentForUpload, ticket]);

  const onAttachmentCancel = (): void => {
    setAttachmentForUpload(undefined);
  };

  const attachmentUpload = useMemo(() => {
    if (attachmentForUpload) {
      return (
        <TicketAttachmentUploadForm
          attachmentForUpload={attachmentForUpload}
          setAttachmentForUpload={setAttachmentForUpload}
          onAttachmentConfirm={onAttachmentUpload}
          onAttachmentCancel={onAttachmentCancel}
        />
      );
    }

    return (
      <AttachmentUploadButton
        onFileSelected={(file) =>
          setAttachmentForUpload({ file, title: file.name })
        }
      />
    );
  }, [attachmentForUpload, onAttachmentUpload]);

  return (
    <Container sx={sxProps.attachmentContainer}>
      <AttachmentViewModal
        uploadedAttachments={loadedAttachments}
        open={open}
        setOpen={setOpen}
        startIndex={clickedImageIndex}
      />
      <Box sx={sxProps.attachmentsBox} data-test="ticket-view-attachments">
        {loadedAttachments.map((att, index) => {
          return (
            <AttachmentItem
              attachment={att}
              key={att.uuid}
              onClick={() => onAttachmentClick(index)}
            />
          );
        })}
        {attachmentUpload}
      </Box>
    </Container>
  );
};
