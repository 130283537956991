import type { TFunction } from 'i18next';

import type { CreateCommentSerializerDTO } from '../../../../../../../connectors/ticket';
import { CommentVisibilityEnumDTO } from '../../../../../../../connectors/ticket';

export const createUpdateCommentForFormData = (
  data: Record<string, string>,
  t: TFunction,
): CreateCommentSerializerDTO => {
  let content = '';

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(data)) {
    const tKey = t(`+ticketView.details.statusUpdateForm.${key}`);
    // if value is a date, we will not translate it
    const tValue =
      key === 'estimatedDateForResumptionOfTask'
        ? value
        : t(`+ticketView.details.statusUpdateForm.${value}`);

    content += `${tKey} - ${tValue} \n`;
  }

  return {
    content,
    visibility: CommentVisibilityEnumDTO.Internal,
  } as CreateCommentSerializerDTO;
};

export const validateComment = (
  comment: CreateCommentSerializerDTO,
): string | undefined => {
  if (!comment.content) {
    return 'error.commentIsMissing';
  }

  return undefined;
};
