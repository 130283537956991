import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import type {
  LoginOutputSerializerDTO,
  MeSerializerDTO,
  AuthenticationTypeEnumDTO,
} from '../../../../connectors/user';
import { AuthAPI } from '../../../../connectors/user';
import { getEnvValue } from '../../helpers/env.helper';
import { mieInstance } from '../../mie.instance';
import { logout } from '../../store/authSlice';

class AuthClient {
  private client;

  constructor() {
    const path = getEnvValue('API_PATH');

    this.client = new AuthAPI(undefined, path, mieInstance);
  }

  // this is the first step in multistep authentication which will redirect
  // to azure login form. After success authentication there, user will be redirected
  // back to the app to `redirectUri`
  public loginByProvider = (authType: AuthenticationTypeEnumDTO): void => {
    const provider = authType.toUpperCase();
    const authorizeUrl = getEnvValue(`${provider}_AUTHORIZE_URL`);
    const redirectUri = window.origin + authorizeUrl;

    const origin = getEnvValue('API_PATH');
    const url = origin + authorizeUrl;

    window.location.href = `${url}?redirect_uri=${redirectUri}`;
  };

  // this is the second step in multistep authentication which will be triggered
  // by user returning to app from azure login page. Url will contain parameteres
  // which will be send back to Mia api and exchanged for token,
  // which can be later used for authenticationg user.
  public authorize = async (
    authType: AuthenticationTypeEnumDTO,
    requestConfig: AxiosRequestConfig,
  ): Promise<LoginOutputSerializerDTO> => {
    const response = await this.client.tokenAuthAuthTypeTokenGet(
      authType,
      requestConfig,
    );

    return response.data;
  };

  public me = async (): Promise<MeSerializerDTO> => {
    const response = await this.client.getMeAuthMeGet();

    return response.data;
  };

  public refreshToken = async (
    error: AxiosError,
    { idToken, refreshToken }: LoginOutputSerializerDTO,
  ): Promise<{ tokens: LoginOutputSerializerDTO; response: AxiosResponse }> => {
    const { config } = error;

    try {
      const refreshResponse = await this.client.tokenRefreshAuthRefreshGet(
        refreshToken,
        idToken,
      );
      const tokens: LoginOutputSerializerDTO = {
        accessToken: refreshResponse.data.accessToken,
        idToken: refreshResponse.data.idToken,
        refreshToken,
      };
      const response = await mieInstance.request({
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      return { response, tokens };
    } catch (e) {
      logout();

      return Promise.reject(error.response);
    }
  };
}

export const authClient = new AuthClient();
