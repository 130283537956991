import React, { useEffect, useCallback } from 'react';
import type { FC } from 'react';

import type { UnitSerializerDTO } from '../../../../../../../connectors/property';
import { EntityEnumDTO } from '../../../../../../../connectors/ticket';
import { AutocompleteInput } from '../../../../../../shared/components/AutocompleteInput';
import { getUnitsForEntity } from '../../../../../../shared/services/property.service';
import type { EntityType } from '../../../../../../shared/services/types/property.types';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { selectUnitsForEntity } from '../../../../../../shared/store/propertySlice';

export type ReporterUnitSelectorProps = {
  entityType: EntityEnumDTO;
  //  selected entity is the one that was selected previously during ticket creation flow
  selectedEntity: EntityType;
  isReporterMe: boolean;
  reporterUnit?: UnitSerializerDTO;
  onChange: (reporterUnit?: UnitSerializerDTO) => void;
  error?: string;
};

export const ReporterUnitSelector: FC<ReporterUnitSelectorProps> = ({
  isReporterMe,
  onChange,
  error,
  entityType,
  selectedEntity,
  reporterUnit,
}: ReporterUnitSelectorProps) => {
  const unitsForEntity = useAppSelector((state) => selectUnitsForEntity(state));

  useEffect(() => {
    if (!isReporterMe && entityType !== EntityEnumDTO.Unit) {
      // load available units
      getUnitsForEntity({ uuid: selectedEntity.uuid }, entityType);
    }
  }, [entityType, isReporterMe, selectedEntity.uuid]);

  const onInputChange = useCallback(
    (query: string) => {
      getUnitsForEntity({ query, uuid: selectedEntity.uuid }, entityType);
    },
    [entityType, selectedEntity.uuid],
  );

  const onReporterUnitSelect = useCallback(
    (unitUuid?: string) => {
      const selectedUnit = unitsForEntity.find((u) => u.uuid === unitUuid);

      onChange(selectedUnit);
    },
    [onChange, unitsForEntity],
  );

  if (isReporterMe || entityType === EntityEnumDTO.Unit) return null;

  return (
    <AutocompleteInput
      debounce
      labelKey="+createTicket.reporterUnitLabel"
      placeholderKey="+createTicket.reporterUnitLabel"
      options={unitsForEntity.map((u) => {
        return { label: u.name, value: u.uuid };
      })}
      value={
        reporterUnit
          ? { label: reporterUnit.name, value: reporterUnit.uuid }
          : undefined
      }
      onChange={(selectedCityUuid) => onReporterUnitSelect(selectedCityUuid)}
      onInputChange={(value) => {
        onInputChange(value);
      }}
      error={error}
      fieldname="reporter_unit_selector"
    />
  );
};
