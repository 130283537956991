import { RoomTypeEnumDTO } from '../../../../../../../connectors/ticket';
import type {
  LocationTypeEnumDTO,
  TicketMetadataSerializerDTO,
} from '../../../../../../../connectors/ticket';
import type { ModalSelectOption } from '../../../../../../shared/components/ModalSelect';
import { getRoomFailureIcon } from '../../../../../../shared/helpers/failure.helper';

export const getAvailableIssueRooms = (
  metadata?: TicketMetadataSerializerDTO,
  issueLocation?: LocationTypeEnumDTO,
): RoomTypeEnumDTO[] => {
  if (!metadata?.serviceRequestsValidationMap || !issueLocation) return [];

  return Object.values(RoomTypeEnumDTO).filter((room) =>
    Boolean(metadata.serviceRequestsValidationMap[issueLocation][room]),
  );
};

export const getIssueRoomOptions = (
  issueRooms: RoomTypeEnumDTO[],
): ModalSelectOption[] =>
  issueRooms.map((room) => ({
    icon: getRoomFailureIcon(room),
    id: room,
    labelKey: `issue.${room}`,
    value: room,
  }));
