import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';

import type {
  CitySerializerDTO,
  CountrySerializerDTO,
} from '../../../../../../../connectors/company';
import type { EntityEnumDTO } from '../../../../../../../connectors/ticket';
import { AutocompleteInput } from '../../../../../../shared/components/AutocompleteInput';
import { queryEntities } from '../../../../../../shared/services/property.service';
import type { EntityType } from '../../../../../../shared/services/types/property.types';
import { useAppSelector } from '../../../../../../shared/store/hooks';
import { selectQueriedEntities } from '../../../../../../shared/store/propertySlice';
import { getLabelKey, getPlaceholderKey } from './util';

export type EntitySelectorProps = {
  country?: CountrySerializerDTO;
  city?: CitySerializerDTO;
  entityType?: EntityEnumDTO;
  entity?: EntityType;
  onChange: (entity?: EntityType) => void;
  error?: string;
};

export const EntitySelector: FC<EntitySelectorProps> = ({
  country,
  city,
  entity,
  entityType,
  onChange,
  error,
}: EntitySelectorProps) => {
  const queriedEntities = useAppSelector((state) =>
    selectQueriedEntities(state),
  );

  const onEntitySelect = useCallback(
    (entityUuid?: string) => {
      const selectedEntity = queriedEntities.find(
        (entity) => entity.uuid === entityUuid,
      );

      onChange(selectedEntity);
    },
    [queriedEntities, onChange],
  );

  const onInputChange = useCallback(
    (
      query: string,
      { uuid: countryUuid }: CountrySerializerDTO,
      { uuid: cityUuid }: CitySerializerDTO,
      entityType: EntityEnumDTO,
    ) => {
      queryEntities({ cityUuid, countryUuid, query }, entityType);
    },
    [],
  );

  const labelKey = useMemo(() => getLabelKey(entityType), [entityType]);
  const placeholderKey = useMemo(
    () => getPlaceholderKey(entityType),
    [entityType],
  );

  if (!country || !city || !entityType) return null;

  return (
    <AutocompleteInput
      debounce
      labelKey={labelKey}
      placeholderKey={placeholderKey}
      options={queriedEntities.map((c) => {
        return { label: c.name, value: c.uuid };
      })}
      value={entity ? { label: entity.name, value: entity.uuid } : undefined}
      onChange={(selectedEntityUuid) => {
        onEntitySelect(selectedEntityUuid);
      }}
      onInputChange={(value) => {
        onInputChange(value, country, city, entityType);
      }}
      error={error}
      fieldname="entity_selector"
    />
  );
};
